import React, { HTMLAttributes } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Icon from '../icon/Icon';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  left?: 'back' | 'none' | 'exit';
  onLeftClick?: () => void;
  title?: string;
  label?: string;
  rightNode?: React.ReactNode;
  right?: 'map' | 'search' | 'share' | 'trash' | 'exit' | 'none';
  onRightClick?: () => void;
  border?: boolean;
}

const TopBar: React.FC<Props> = ({
  left,
  onLeftClick,
  title,
  label,
  rightNode,
  right,
  onRightClick,
  border,
  ...props
}) => {
  const navigation = useNavigate();

  const handleBack = () => {
    navigation(-1);
    // if (window.history.state && window.history.state.idx > 0) {
    //   navigation(-1);
    // } else {
    //   navigation('/', { replace: true });
    // }
  };

  return (
    <Container border={border ? border : false} {...props}>
      <Left onClick={!onLeftClick ? handleBack : onLeftClick}>
        {left && left === 'back' && <Icon icon="IconArrowLeft" />}
        {left && left === 'exit' && <Icon icon="IconX" />}
      </Left>
      <Middle>
        {title && <Label kind="title">{title}</Label>}
        {label && <Label kind="label">{label}</Label>}
      </Middle>
      <Right onClick={onRightClick}>
        {rightNode && rightNode}
        {right === 'map' && <Icon icon="IconMap" size={19} />}
        {right === 'search' && (
          <Icon icon="IconSearch" style={{ marginRight: '10px' }} />
        )}
        {right === 'exit' && <Icon icon="IconX" />}
        {right === 'trash' && (
          <Icon icon="IconTrashBin" themeColor="destructive600" />
        )}
      </Right>
    </Container>
  );
};

export default TopBar;

TopBar.defaultProps = {
  left: 'back',
};
const Container = styled.header<{ border: boolean }>`
  z-index: 15;
  position: sticky;
  top: 0;
  background: white;
  margin: 0 -24px;
  padding: 0 14px;
  width: calc(100% + 48px);
  height: 56px;
  display: flex;
  align-items: center;
  border-bottom: ${({ border }) => (border ? '1px solid #f5f5f5' : '')};
`;
const Left = styled.div`
  width: 48px;
  display: flex;
`;
const Middle = styled.div`
  width: 100%;
`;
const Label = styled.div<{ kind: 'title' | 'label' }>`
  color: #404040;
  ${props => {
    switch (props.kind) {
      case 'title':
        return css`
          text-align: center;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
        `;
      case 'label':
        return css`
          padding-left: 16px;
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
        `;
    }
  }}
`;
const Right = styled.div`
  width: 48px;
  display: flex;
  justify-content: flex-end;
`;
