import { makeAutoObservable } from 'mobx';
import { PrintTicket } from 'types/job/print-job/print-ticket/PrintTicket';
import { FileDocument } from '../file/File';
import { PrintJobBuilder } from './PrintJobBuilder';
import { PrintJobStore } from './PrintJobStore';

/**
 * 아래 순서대로 사용
 * 1. createBuilders 로 file 을 집어넣어서 builder 들을 생성
 * 2. setKiosk 로 출력할 프린터 kioskId 설정
 * 3. current getter 에서 자동으로 설정해야 할 builder 들이 나옴
 *    builder 에서 ticket 을 설정하면 자동을 getter 의 값이 바뀜
 *    isLast getter 를 이용해서 하단의 버튼의 구성을 분기함.
 * 4. createToServer 로 해당 작업들을 서버로 보낸다.
 * 5. 서버에 요청이 성공적으로 종료되면 clear 로 store 를 비운다.
 */
export class PrintJobBuilderStore {
  isLoading = false;
  printJobBuilders: PrintJobBuilder[] = [];

  constructor(public readonly printJobStore: PrintJobStore) {
    makeAutoObservable(this, {});
  }

  createBuilders(files: FileDocument[]) {
    this.printJobBuilders = files.map(
      f => new PrintJobBuilder(this.printJobStore, f)
    );
  }

  /**
   * 키오스크는 한 곳에서 한꺼번에 설정하는 것이므로
   * @param kioskId
   */
  setKiosk(kioskId: string) {
    this.printJobBuilders.forEach(pjb => (pjb.kioskId = kioskId));
  }

  async createToServer() {
    this.startLoading();

    try {
      // 원래 백엔드 api 가 한번에 여러개의 job 을 받아야 하는데 그렇게 설계되어있지 않아서
      // 인증번호가 여러개로 등록되는 오류가 발생함. 그래서 동기적으로 작업을 생성하도록 설계함.
      for (const pjb of this.printJobBuilders) {
        await pjb.createToServer();
      }
    } finally {
      this.printJobStore.getPrintJobs();
      this.endLoading();
    }
  }

  clear() {
    this.printJobBuilders = [];
  }

  /**
   * ticket 설정이 되지 않은 작업을 가져온다.
   * 모두 설정되었다면 마지막 작업 가져오고,
   * 작업이 없다면 undefined
   */
  get current() {
    return (
      this.printJobBuilders.find(pjb => !pjb.ticket) ??
      this.printJobBuilders[this.printJobBuilders.length - 1]
    );
  }

  get currentIdx() {
    return (
      this.printJobBuilders.findIndex(pjb => !pjb.ticket) ??
      this.printJobBuilders.length - 1
    );
  }

  getPrintJobByFileId(fileId: string) {
    return this.printJobBuilders.find(pjb => pjb.file.id === fileId);
  }

  setPrintJobTicket(fileId: string, ticket: PrintTicket) {
    this.getPrintJobByFileId(fileId)?.setTicket(ticket);
  }

  /**
   * 마지막 작업이면 아래 CTA 버튼이 바뀌어야 해서 만든 getter
   */
  get isLast() {
    return this.printJobBuilders.filter(pjb => !pjb.ticket).length <= 1;
  }

  startLoading() {
    this.isLoading = true;
  }

  endLoading() {
    this.isLoading = false;
  }
}
