import { action, autorun, makeAutoObservable } from 'mobx';
import { LogInDto } from 'types/auth/LogIn.dto';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { User } from './User';
import { UserDto } from 'types/user/User.dto';
import { HttpRequestService } from 'services/http-request.service';
import { LoginResponseDto } from 'types/auth/LoginResponse.dto';
import { RefreshResponseDto } from 'types/auth/RefreshResponse.dto';
import * as amplitude from '@amplitude/analytics-browser';
import * as sentry from '@sentry/react';

type TAuthErrors = {
  loginError?: Error;
  loginPasswordError?: Error;
  loginEmailError?: Error;
};

type UserLocation = {
  lat: number;
  lng: number;
};

export class UserStore {
  isLoading = false;
  userId: string | null = null;
  accessToken: string | null = null;
  user: User | null = null;
  isKorea: boolean = false;
  reload = true;
  errorModal = false;
  currentLocation: UserLocation | null = null;
  errors: TAuthErrors = {};

  refreshTimer: NodeJS.Timer | undefined;
  refreshInterval = 55 * 60_000;

  constructor(private readonly http: HttpRequestService) {
    makeAutoObservable(this, {});

    this.refreshTimer = setInterval(() => {
      this.refresh();
    }, this.refreshInterval);

    /**
     * 중요!!
     * amplitude 유저 행동 추적에 userId 를 설정하는것은 매우 중요함.
     * autorun 을 이용해서 userId 가 변경될 때 마다 amplitude 에 userId 를 설정 할 수 있도록 설정되었음.
     * 따라서 로그인 시 userId 를 설정하고 (당연하지만) 로그아웃 시 null 로 처리하는데 유의해야 함.
     */
    autorun(() => {
      amplitude.setUserId(this.userId ?? undefined);
      sentry.setUser(this.userId ? { id: this.userId } : null);
    });
  }

  async login(email: string, password: string) {
    this.startLoading();

    const dto: LogInDto = {
      email,
      password,
    };

    const config: AxiosRequestConfig = {
      headers: {
        'Access-Control-Allow-Origin': process.env
          .REACT_APP_SERVER_V4_URL as string,
      },
      withCredentials: true,
    };

    try {
      const res = await axios
        .create(config)
        .post<LoginResponseDto>('auth/log-in', dto);
      this.userId = res.data.userId;
      this.accessToken = res.data.accessToken;
      this.http.setAccessToken(res.data.accessToken);

      await this.loadUser();
    } catch (err) {
      const { response } = err as AxiosError;
      const { data } = response as AxiosResponse;

      if (data.message === 'Invalid Password')
        this.errors.loginPasswordError = data;
      else if (data.message === 'Invalid Email')
        this.errors.loginEmailError = data;
      else this.errors.loginError = data;

      throw new Error('login failed!');
    } finally {
      this.endLoading();
    }
  }

  async loadUser() {
    try {
      this.startLoading();

      const res = await this.http
        .create(true)
        .get<UserDto>(`users/${this.userId}`);

      this.updateUserFromServer(res.data);
    } catch (err) {
      throw new Error('load user error!!');
    } finally {
      this.endLoading();
    }
  }

  updateUserFromServer(userDto: UserDto | null) {
    this.user = userDto ? new User(userDto) : null;
  }

  // 로그인 여부 판별
  async refresh() {
    const config: AxiosRequestConfig = {
      headers: {
        'Access-Control-Allow-Origin': process.env
          .REACT_APP_SERVER_V4_URL as string,
      },
      withCredentials: true,
    };

    try {
      this.startLoading();
      await axios
        .create(config)
        .post<RefreshResponseDto>('auth/refresh')
        .then(
          action('fetchUser_Success', res => {
            this.accessToken = res.data.accessToken;
            this.userId = res.data.userId;
            this.http.setAccessToken(res.data.accessToken);
          })
        );

      // refresh 성공시 유저 정보 불러오기
      await this.loadUser();
    } catch (err) {
      this.accessToken = null;
      this.userId = null;
    } finally {
      this.endLoading();
    }
  }

  async logout() {
    const config: AxiosRequestConfig = {
      headers: {
        'Access-Control-Allow-Origin': process.env
          .REACT_APP_SERVER_V4_URL as string,
      },
      withCredentials: true,
    };

    try {
      this.startLoading();
      await axios.create(config).post('auth/log-out');
      this.accessToken = null;
      this.userId = null;
      this.user = null;
    } catch (err) {
      throw new Error('logout Error!!');
    } finally {
      window.location.reload();
      this.endLoading();
    }
  }

  loadCurrentLocation() {
    const options = {
      enableHighAccuracy: true,
      timeout: 10000,
      maximumAge: 0,
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        action('fetchLocation_Success', position => {
          this.currentLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
        }),
        error => console.log(error),
        options
      );
    }
  }

  getUserLanguage() {
    this.startLoading();
    try {
      /**
       * RFC 5646에서는 언어 코드를 소문자로 표기하고, 국가/지역 코드를 대문자로 표기합니다.
       * iOS 15부터는 RFC 5646 언어 태그 규격을 따르기 시작했습니다.
       */
      if (
        navigator.language.toLowerCase() === 'ko-kr' ||
        navigator.language.toLowerCase() === 'ko'
      ) {
        this.isKorea = true;
      }
    } finally {
      this.endLoading();
    }
  }

  setUserId(id: string) {
    this.userId = id;
  }

  setAccessToken(token: string) {
    this.accessToken = token;
  }

  updateReload(re: boolean) {
    this.reload = re;
  }

  showErrorModal() {
    this.errorModal = true;
  }

  clearError() {
    this.errors = {};
  }

  private startLoading() {
    this.isLoading = true;
  }

  private endLoading() {
    this.isLoading = false;
  }
}
