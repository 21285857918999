import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

type Props = {
  imageUrls: string[];
  isPrintZone?: boolean;
};

const ImageSwiper: React.FC<Props> = ({ imageUrls, isPrintZone }) => {
  return (
    <Container isPrintZone={isPrintZone ?? false}>
      <SwiperContainer
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
      >
        {imageUrls.map((imageUrl, index) => (
          <div key={index}>
            <SwiperSlide>
              <SwiperItem>
                <ContentImage
                  imageUrl={imageUrl}
                  isPrintZone={isPrintZone ?? false}
                />
              </SwiperItem>
            </SwiperSlide>
          </div>
        ))}
      </SwiperContainer>
    </Container>
  );
};

export default ImageSwiper;

ImageSwiper.defaultProps = {
  isPrintZone: false,
};

const Container = styled.div<{ isPrintZone: boolean }>`
  width: 100%;
  height: ${props => (props.isPrintZone ? '157px' : '324px')};
`;
const SwiperContainer = styled(Swiper)`
  width: 324px;
  height: 100%;
`;
const SwiperItem = styled(SwiperSlide)`
  width: 324px;
  height: 100%;
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const ContentImage = styled.div<{ imageUrl: string; isPrintZone: boolean }>`
  width: 100%;
  height: 100%;
  background: url('${props => props.imageUrl}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: ${props => (props.isPrintZone ? 'contain' : 'cover')};
  border: ${props => (props.isPrintZone ? 'none' : '0.5px solid #d2d2d2')};
  border-radius: ${props => (props.isPrintZone ? '4px' : '0')};
`;
