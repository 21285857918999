import React from 'react';
import { useNavigate } from 'react-router-dom';
import PageContainer from 'components/common/PageContainer';
import TopBar from 'components/common/top_bar/TopBar';
import List from 'components/common/list/List';
import styled from 'styled-components';

const Terms: React.FC = props => {
  const navigation = useNavigate();

  return (
    <PageContainer>
      <TopBar left="back" title="이용정책 안내" />
      <List
        title="보바 이용약관"
        action="arrow"
        onAllClick={() => navigation('/term1')}
      />
      <List
        title="개인정보 수집 및 이용 동의"
        action="arrow"
        onAllClick={() => navigation('/term2')}
      />
      <List
        title="위치기반서비스 이용약관"
        action="arrow"
        onAllClick={() => navigation('/term3')}
      />
      <List
        title="마케팅 정보 수신 동의 (선택)"
        action="arrow"
        onAllClick={() => navigation('/term4')}
      />
      <List
        title="개인정보 제3자 제공 동의 (선택)"
        action="arrow"
        onAllClick={() => navigation('/term5')}
      />
      <VersionInfo>{`v${process.env.REACT_APP_VERSION}`}</VersionInfo>
    </PageContainer>
  );
};

export default Terms;

const VersionInfo = styled.div`
  color: #525252;
  font-size: 12px;
  text-align: center;
  width: 100%;
  max-width: 780px;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 0;
  padding: 0 30px 24px 30px;
  background: white;
`;
