import React, { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from './stores/RootStore';
import { httpRequestService } from './services/http-request.service';
import Modal from './components/common/modal/Modal';
import FullScreenLoader from './components/common/FullScreenLoader';
import ErrorPage from './components/common/ErrorPage';
import { CheckJob } from './components/common/CheckJob';
import ScrollTop from './components/pages/ScrollTop';
import { ReloadAuth } from './components/pages/ReloadAuth';
import { getEnvironment, refreshEvery3hours } from './utils/utils';
import { RouterPaths } from './types/router';
import PathIndicatorForDebug from 'debugs/PathIndicatorForDebug';
import usePopstateListener from 'hooks/usePopStateListener';
import eruda from 'eruda';

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [maintenance, setMaintenance] = useState('');
  const { stateStore, userStore } = useStores();

  // fix webview view height bug
  let vh = 0;
  useEffect(() => {
    vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);

  useEffect(() => {
    // 점검 예정이 있는지 확인
    httpRequestService
      .create()
      .get('/maintenances')
      .then(res => {
        setMaintenance(res.data.start_at);
        if (res.data) {
          setIsOpen(true);
        }
      })
      .catch(err => console.warn(err));
  }, []);

  useEffect(() => {
    userStore.getUserLanguage();
    userStore.refresh();
    userStore.loadCurrentLocation();
    refreshEvery3hours();
  }, []);

  useEffect(() => {
    const environment = getEnvironment();
    if (environment !== 'Production') {
      eruda.init();
    }
  }, []);

  usePopstateListener();

  return (
    <BrowserRouter>
      <PathIndicatorForDebug />
      <ScrollTop />

      <Modal
        isOpen={isOpen}
        onRequestClose={() => {
          setIsOpen(false);
        }}
        title={'시스템 점검 공지'}
        description={`[ 점검일시 ]\n${maintenance}\n\n서비스 이용에 참고하시기 바라며,\n 안정적인 서비스 제공을 위하여\n최선을 다하겠습니다. 감사합니다.`}
        mainActionLabel={'확인'}
        onMainAction={() => {
          setIsOpen(false);
        }}
      />
      <Modal
        isOpen={userStore.errorModal}
        onRequestClose={() => window.location.reload()}
        description={`에러가 발생하였습니다.\n잠시 후 다시 시도해주세요.`}
        mainActionLabel="다시 시도"
        mainActionColor="NEGATIVE"
        onMainAction={() => window.location.reload()}
      />

      {stateStore.isLoading && <FullScreenLoader />}
      {stateStore.error && <ErrorPage />}

      <Routes>
        <Route element={<CheckJob />}>
          {/* 바로 접속할 수 없는 페이지들 */}
          {RouterPaths.filter(page => page.mustLogin === true).map(page => (
            <Route key={page.path} element={<ReloadAuth />}>
              <Route path={page.path} element={<page.element />} />
            </Route>
          ))}

          {/* 바로 접속할 수 있는 페이지들(새로고침해도 '/'으로 이동하지 않는 페이지들) */}
          {RouterPaths.filter(page => page.mustLogin === false).map(page => (
            <Route
              key={page.path}
              path={page.path}
              element={<page.element />}
            />
          ))}

          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default observer(App);
