import { ScanCopyJobStore } from './job/ScanCopyJobStore';
import { PrintJobBuilderStore } from './job/PrintJobBuilderStore';
import { CardStore } from './card/CardStore';
import { UserStore } from './user/UserStore';
import { AuthStore } from './AuthStore';
import React from 'react';
import { httpRequestService } from 'services/http-request.service';
import { UploadStore } from './file/UploadStore';
import { FileStore } from './file/FileStore';
import { ContentStore } from './content/ContentStore';
import { PrintJobStore } from './job/PrintJobStore';
import { KioskStore } from './kiosk/KioskStore';
import { KakaoStore } from './KakaoStore';
import { StateStore } from './state/StateStore';
import { JobPollingStore } from './job/JobPollingStore';
import { UpdateJobStore } from './job/UpdateJobStore';
import { ScanCopyJobBuilderStore } from './job/ScanCopyJobBuilderStore';

export type TStore = {
  stateStore: StateStore;
  userStore: UserStore;
  kakaoStore: KakaoStore;
  authStore: AuthStore;
  cardStore: CardStore;
  uploadStore: UploadStore;
  fileStore: FileStore;
  contentStore: ContentStore;
  kioskStore: KioskStore;
  printJobStore: PrintJobStore;
  printJobBuilderStore: PrintJobBuilderStore;
  scanCopyJobStore: ScanCopyJobStore;
  scanCopyJobBuilderStore: ScanCopyJobBuilderStore;
  jobPollingStore: JobPollingStore;
  updateJobStore: UpdateJobStore;
};

const stateStore = new StateStore();
const userStore = new UserStore(httpRequestService);
const kakaoStore = new KakaoStore(userStore, httpRequestService);
const authStore = new AuthStore(userStore, httpRequestService);
const cardStore = new CardStore(userStore, httpRequestService);
const uploadStore = new UploadStore(userStore, httpRequestService);
const fileStore = new FileStore(userStore, httpRequestService);
const contentStore = new ContentStore(userStore);
const kioskStore = new KioskStore(userStore, httpRequestService);
const printJobStore = new PrintJobStore(
  userStore,
  fileStore,
  httpRequestService
);
const printJobBuilderStore = new PrintJobBuilderStore(printJobStore);
const scanCopyJobStore = new ScanCopyJobStore(userStore, httpRequestService);
const scanCopyJobBuilderStore = new ScanCopyJobBuilderStore(
  scanCopyJobStore,
  stateStore
);
const jobPollingStore = new JobPollingStore(userStore, httpRequestService);
const updateJobStore = new UpdateJobStore(
  printJobStore,
  scanCopyJobStore,
  printJobBuilderStore,
  scanCopyJobBuilderStore
);

export const Store: TStore = {
  stateStore,
  userStore,
  kakaoStore,
  authStore,
  cardStore,
  uploadStore,
  fileStore,
  contentStore,
  kioskStore,
  printJobStore,
  printJobBuilderStore,
  scanCopyJobStore,
  scanCopyJobBuilderStore,
  jobPollingStore,
  updateJobStore,
};

export const StoreContext = React.createContext<TStore | null>(null);

export const useStores = () => {
  const stores = React.useContext(StoreContext);
  if (!stores) {
    throw new Error('useStores must be used within a StoreProvider');
  }
  return stores;
};
