import React, { useState } from 'react';
import styled from 'styled-components';
import { Spacing } from 'styles/styles';
import Badge from 'components/common/badge/Badge';
import Icon from 'components/common/icon/Icon';

export default function PrintZoneBadge({ isBoba }: { isBoba: boolean }) {
  const [tooltip, setTooltip] = useState<boolean>(false);

  if (isBoba) {
    return <Badge label="바로 인쇄" />;
  }

  return (
    <BadgeContainer>
      <Badge label="일반 출력업체" size="sm" badgeKind="gray" />
      <Spacing.Horizontal width={8} />
      <Icon
        icon="IconQuestion"
        size={16}
        themeColor="gray300"
        onMouseOver={() => setTooltip(true)}
        onMouseOut={() => setTooltip(false)}
      />
      {tooltip && (
        <Tooltip>
          <TooltipTitle>
            <Icon icon="IconInfo" size={16} />
            <Spacing.Horizontal width={8} />
            안내
          </TooltipTitle>
          <Spacing.Vertical height={8} />
          보바 앱에 등록되지 않은 출력 업체 입니다. 위치 및 정보를 참고할 수
          있으며 가격 정보가 다를 수 있습니다.
        </Tooltip>
      )}
    </BadgeContainer>
  );
}

const BadgeContainer = styled.div`
  position: relative;

  display: flex;
  align-items: center;
`;

const Tooltip = styled.div`
  position: absolute;
  top: 40px;

  padding: 16px 24px;

  width: 312px;

  background: ${({ theme }) => theme.color.white};
  box-shadow: 0px 4px 30px #00000026;
  border: 1px solid #f2f2f2;
  border-radius: 8px;

  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.gray900};
`;

const TooltipTitle = styled.div`
  display: flex;
  align-items: center;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.color.gray500};
`;
