import React from 'react';
import styled from 'styled-components';

interface Props {
  size: number;
}
const Border: React.FC<Props> = ({ size }) => {
  return (
    <Container size={size}>
      <Line />
    </Container>
  );
};

export default Border;

const Container = styled.div<Props>`
  width: 100%;
  height: ${props => props.size}px;
  display: flex;
  align-items: center;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #f5f5f5;
`;
