import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { useStores } from 'stores/RootStore';
import { PaperSize } from 'types/job/print-job/print-ticket/PaperSize';
import OptionButton from './EditOptionButton';

type Props = {
  printJobId: string;
};

function EditPaperSizeOption({ printJobId }: Props) {
  const { printJobStore, kioskStore } = useStores();

  const [option, setOption] = useState<PaperSize>(PaperSize.A4);

  const kioskPaperSizes = kioskStore.kiosks.find(
    kiosk =>
      kiosk.id ===
      printJobStore.printJobs.find(pj => pj.id === printJobId)?.kiosk.id
  )?.PaperSizes ?? [PaperSize.A4];

  const handleOption = (option: PaperSize) => {
    setOption(option);

    const printJob = printJobStore.printJobs.find(pj => pj.id === printJobId);
    if (!printJob) {
      return;
    }
    printJob.editTicket({
      ...printJob.ticket,
      paperSize: option,
    });
  };

  useEffect(() => {
    setOption(
      printJobStore.printJobs.find(pj => pj.id === printJobId)?.ticket
        .paperSize ?? PaperSize.A4
    );
  }, [printJobId]);

  return (
    <Container>
      {kioskPaperSizes.find(paperSize => paperSize === PaperSize.A4) && (
        <OptionButton
          onClick={() => handleOption(PaperSize.A4)}
          active={option === PaperSize.A4}
        >
          A4
        </OptionButton>
      )}

      {kioskPaperSizes.find(paperSize => paperSize === PaperSize.A3) && (
        <OptionButton
          onClick={() => handleOption(PaperSize.A3)}
          active={option === PaperSize.A3}
        >
          A3
        </OptionButton>
      )}

      {kioskPaperSizes.find(paperSize => paperSize === PaperSize.B4) && (
        <OptionButton
          onClick={() => handleOption(PaperSize.B4)}
          active={option === PaperSize.B4}
        >
          B4
        </OptionButton>
      )}
    </Container>
  );
}

export default observer(EditPaperSizeOption);

const Container = styled.div`
  display: flex;
  gap: 16px;

  padding: 16px 0;
`;
