import React, { useState } from 'react';
import styled from 'styled-components';
import Icon from '../icon/Icon';

interface Props {
  label?: string;
  checked?: boolean;
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

const CheckBox: React.FC<Props> = ({ label, checked, onClick }) => {
  return (
    <Container>
      {checked && (
        <Icon
          icon="IconChecked"
          size={20}
          themeColor="primary600"
          onClick={onClick}
          style={{ cursor: 'pointer' }}
        />
      )}
      {!checked && (
        <Icon
          icon="IconCheckNone"
          size={20}
          themeColor="white"
          onClick={onClick}
          style={{ cursor: 'pointer' }}
        />
      )}
      {label && <Label>{label}</Label>}
    </Container>
  );
};

export default CheckBox;

const Container = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
`;

const Label = styled.p`
  height: 24px;
  margin: 0 14px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #525252;
`;
