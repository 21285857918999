import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores/RootStore';
import trackUser from 'services/trackUser';
import PageContainer from 'components/common/PageContainer';
import TopBar from 'components/common/top_bar/TopBar';
import Modal from 'components/common/modal/Modal';
import Border from 'components/common/border/Border';
import TopInfo from 'components/common/top_info/TopInfo';
import List from 'components/common/list/List';
import ErrorPage from 'components/common/ErrorPage';
import SolidButton from 'components/common/solid_button/SolidButton';
import Icon from 'components/common/icon/Icon';
import { ampEvents } from 'amplitude-events';

const Accountsettings: React.FC = props => {
  const { userStore, kakaoStore } = useStores();
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState();
  const navigation = useNavigate();
  const location = useLocation();
  const KAKAO_CODE = location.search.split('=')[1];
  const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_JS_KEY}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=code`;

  const handleKakaoLogin = () => {
    window.location.href = KAKAO_AUTH_URL;
  };

  useEffect(() => {
    if (KAKAO_CODE === undefined) {
      return;
    }
    kakaoStore
      .kakaoRollBack(KAKAO_CODE)
      .then(() => {
        navigation('/login-kakao');
      })
      .catch(err => {
        setError(err);
      });
  }, [KAKAO_CODE]);

  useEffect(() => {
    if (!userStore.user) {
      userStore.loadUser();
    }
  }, []);

  if (error) return <ErrorPage />;

  return (
    <PageContainer>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(!isOpen)}
        description="로그아웃 하시겠습니까?"
        mainActionLabel="로그아웃"
        onMainAction={async () => {
          await userStore.logout();
        }}
        mainActionColor="NEGATIVE"
        subActionLabel="취소"
        onSubAction={() => setIsOpen(!isOpen)}
      />

      <TopBar title="계정 설정" />
      <TopInfo
        title={userStore.user?.name}
        rightContent={
          userStore.isKorea &&
          !userStore.user?.hasKakaoId && (
            <KakaoButton onClick={handleKakaoLogin}>
              <Icon icon="IconKakaoBallon" size={15} themeColor="black" />
              <KakaoButtonText>계정연동</KakaoButtonText>
            </KakaoButton>
          )
        }
      />
      <List title="이메일" text={userStore.user?.email} />
      <List title="휴대폰" text={userStore.user?.phoneNumber} />

      <Border size={24} />

      <List
        text="비밀번호 변경"
        action="arrow"
        onAllClick={() => {
          trackUser(ampEvents.Account_ChangePassword);
          navigation('/accountsettings/changepassword');
        }}
      />
      <List
        text="휴대폰 번호 변경"
        action="arrow"
        onAllClick={() => {
          trackUser(ampEvents.Account_ChangePhoneNumber);
          navigation('/accountsettings/changephone1');
        }}
      />
      {/* <List text="알림 설정" action="arrow" onAllClick={()=>{
        if (Notification.permission !== 'granted') {
          Notification.requestPermission().then(permission => {
          })
        } else {
        }
      }}/> */}

      <ButtonGroup>
        <Button>
          <SolidButton
            kind="lightGray"
            area="md"
            onClick={() => {
              trackUser(ampEvents.Account_Logout);
              setIsOpen(!isOpen);
            }}
          >
            로그아웃
          </SolidButton>
        </Button>
        <Button>
          <SolidButton
            kind="lightGray"
            area="md"
            onClick={() => {
              trackUser(ampEvents.Account_Quit);
              navigation('/withdrawal');
            }}
          >
            회원탈퇴
          </SolidButton>
        </Button>
      </ButtonGroup>
    </PageContainer>
  );
};

export default observer(Accountsettings);

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 780px;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 0;
  padding-bottom: 32px;
  background: white;
`;
const Button = styled.p`
  width: 89px;
  margin: 0 8px;
`;
const KakaoButton = styled.div`
  display: flex;
  align-items: center;
  background: #fee500;
  border-radius: 20px;
  padding: 8px 14px;
  gap: 4px;
`;
const KakaoButtonText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
`;
