import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import FullScreenLoader from 'components/common/FullScreenLoader';
import InputBox from 'components/common/input_box/InputBox';
import Modal from 'components/common/modal/Modal';
import PageContainer from 'components/common/PageContainer';
import SolidButton from 'components/common/solid_button/SolidButton';
import TopBar from 'components/common/top_bar/TopBar';
import FixedButtons from 'components/common/solid_button/FixedButtons';
import { httpRequestService } from 'services/http-request.service';
import { useStores } from 'stores/RootStore';
import { SubmitCouponDto } from 'types/coupon/SubmitCoupon.dto';
import { SubmitCouponResponseDto } from 'types/coupon/SubmitCouponResponse.dto';

const CouponCharge: React.FC = props => {
  const navigation = useNavigate();
  const { userStore } = useStores();

  const [isLoading, setIsLoading] = useState(false);
  const [coupon, setCoupon] = useState('');
  const [invalidCoupon, setInvalidCoupon] = useState(false);
  const [usedCoupon, setUsedCoupon] = useState(false);
  const [successCoupon, setSuccessCoupon] = useState(false);

  const handleCoupon = () => {
    setIsLoading(true);
    const dto: SubmitCouponDto = { couponId: coupon };
    httpRequestService
      .create(true)
      .post<SubmitCouponResponseDto>(
        `users/${userStore.user?.id}/coupon/submit`,
        dto
      )
      .then(res => {
        if (res.data.isSuccess) setSuccessCoupon(true);
        else setInvalidCoupon(true);
        userStore.loadUser();
      })
      .catch(() => setInvalidCoupon(true))
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      {isLoading && <FullScreenLoader message="쿠폰 등록중" />}
      <PageContainer>
        <TopBar title="쿠폰 등록" />
        <InputBox
          value={coupon}
          label="쿠폰 번호"
          placeholder="쿠폰 번호 16자리 입력"
          maxLength={16}
          onChange={e => setCoupon(e.target.value)}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              handleCoupon();
            }
          }}
        />

        <FixedButtons>
          <SolidButton disabled={coupon.length !== 16} onClick={handleCoupon}>
            쿠폰 등록하기
          </SolidButton>
        </FixedButtons>

        {/* 올바르지 않은 쿠폰 번호 */}
        <Modal
          isOpen={invalidCoupon}
          onRequestClose={() => {
            setInvalidCoupon(false);
          }}
          title="쿠폰 번호 오류"
          description="올바르지 않은 쿠폰 번호 입니다"
          mainActionLabel="확인"
          onMainAction={() => {
            setInvalidCoupon(false);
          }}
        />

        {/* 이미 입력된 쿠폰 번호 */}
        <Modal
          isOpen={usedCoupon}
          onRequestClose={() => {
            setUsedCoupon(false);
          }}
          title="쿠폰 번호 오류"
          description="이미 입력된 쿠폰 번호 입니다"
          mainActionLabel="확인"
          onMainAction={() => {
            setUsedCoupon(false);
          }}
        />

        {/* 쿠폰 등록 완료 */}
        <Modal
          isOpen={successCoupon}
          onRequestClose={() => navigation(-1)}
          title="쿠폰 등록 완료"
          description={`쿠폰 등록이 완료되었습니다\n잔여 포인트를 확인하세요`}
          mainActionLabel="확인"
          onMainAction={() => navigation(-1)}
        />
      </PageContainer>
    </>
  );
};

export default CouponCharge;
