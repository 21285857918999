import { makeAutoObservable } from 'mobx';

export class StateStore {
  isLoading = false;
  error = false;

  loadingTimer: NodeJS.Timer | undefined = undefined;
  loadingTimeout = (1 / 2) * 60_000;

  constructor() {
    makeAutoObservable(this, {});
  }

  startLoading() {
    this.isLoading = true;
  }

  endLoading() {
    this.isLoading = false;
  }

  setError(isError: boolean) {
    this.error = isError;
  }
}
