import { makeAutoObservable } from 'mobx';
import { HttpRequestService } from 'services/http-request.service';
import { jobService } from 'services/job.service';
import { PrintJobDto } from 'types/job/print-job/PrintJob.dto';
import { FileStore } from '../file/FileStore';
import { UserStore } from '../user/UserStore';
import { PrintJob } from './PrintJob';

/**
 * 개발 노트
 *
 * 필요한 기능
 * - load print job
 * - create print job => 빌더 패턴처럼?
 * - update print job => print ticket 변경
 */

export class PrintJobStore {
  isLoading = false;
  printJobs: PrintJob[] = [];

  constructor(
    public readonly userStore: UserStore,
    public readonly fileStore: FileStore,
    public readonly http: HttpRequestService
  ) {
    makeAutoObservable(this, {});
  }

  async getPrintJobs() {
    this.startLoading();

    return await jobService
      .getPrintJobs()
      .then(reponse => this.updatePrintJobsFromServer(reponse.data))
      .finally(() => this.endLoading());
  }

  async deletePrintJob(jobId: string) {
    this.startLoading();

    return await this.http
      .create(true)
      .delete(`users/${this.userStore.userId}/jobs/prints/${jobId}`)
      .then(() => this.getPrintJobs());
  }

  private updatePrintJobsFromServer(dtos: PrintJobDto[]) {
    // 1. DTO 에 없는 파일 삭제
    this.printJobs = this.printJobs.filter(
      (
        pj // dto 에 없는 파일을 필터링 한 새로운 배열을 return (요소의 참조는 유지 됨)
      ) => dtos.find(dto => pj.id === dto.id) // dto 에 id 에 해당하는 파일이 있는지
    );

    // 2. DTO 와 store 에 둘 다 있는 파일 업데이트
    dtos.map(dto => this.updateOnePrintJobFromServer(dto));

    // 3. DTO 에만 있는 파일 생성
    dtos
      .filter(dto => !this.printJobs.find(pj => pj.id === dto.id)) // file store 에서 일치하는 파일을 찾지 못한 dto 들만 filter
      .forEach(dto => this.createOneFileFromServer(dto)); // 각각의 dto 를 file store 에 push
  }

  /**
   * UI 상에서 키오스크(인증번호)별 묶음으로 보여주기 위한 메소드
   */
  get groupByKiosk() {
    const kioskIds = Array.from(new Set(this.printJobs.map(pj => pj.kiosk.id)));
    return kioskIds.map(id => this.printJobs.filter(pj => pj.kiosk.id === id));
  }

  /**
   * groupByKiosk 파일 변환 상태
   */
  isConverted(printjobs: PrintJob[]) {
    const convertingFile = printjobs.find(pj => pj.file.isConverting === true);
    const errorFile = printjobs.find(pj => pj.file.errorType !== null);
    //  * 우선순위: 1. 변환중 2. 변환 실패 3. 성공
    if (convertingFile) return 'converting';
    if (errorFile) return 'error';
    return 'success';
  }

  getPrintJobByPrintJobId(printJobId: string) {
    return this.printJobs.find(pjb => pjb.id === printJobId);
  }

  getPrintJobsExistingInThekiosk(kioskId: string) {
    return this.printJobs.filter(pj => pj.kiosk.id === kioskId);
  }

  private updateOnePrintJobFromServer(dto: PrintJobDto) {
    const file = this.printJobs.find(file => file.id === dto.id);
    if (!file) {
      console.warn('updateOneFileFromServer cannot find file!');
      return;
    }
    file.updatePrintJobFromDto(dto);
  }

  private createOneFileFromServer(dto: PrintJobDto) {
    this.printJobs.push(new PrintJob(this, dto));
  }

  startLoading() {
    this.isLoading = true;
  }

  endLoading() {
    this.isLoading = false;
  }
}
