import { NUp } from 'types/job/print-job/print-ticket/NUp';
import { PaperOrientation } from 'types/job/print-job/print-ticket/PaperOrientation';

export const showPortrait = ({
  filePortrait,
  pagePortrait,
  nup,
}: {
  filePortrait: boolean;
  pagePortrait: PaperOrientation;
  nup: NUp;
}) => {
  if (pagePortrait === 'AUTO') {
    if (filePortrait) {
      return [1, 4].includes(nup);
    }
    return [2, 6, 8].includes(nup);
  }
  const shouldShowPortrait = pagePortrait === 'PORTRAIT';
  return shouldShowPortrait;
};

export const getColumnFromOptions = ({
  filePortrait,
  pagePortrait,
  nup,
}: {
  filePortrait: boolean;
  pagePortrait: PaperOrientation;
  nup: NUp;
}) => {
  if (pagePortrait === 'AUTO') {
    if (filePortrait) {
      switch (nup) {
        case 1:
          return 1;
        case 2:
          return 2;
        case 4:
          return 2;
        case 6:
          return 3;
        case 8:
          return 4;
      }
    }
    switch (nup) {
      case 1:
        return 1;
      case 2:
        return 1;
      case 4:
        return 2;
      case 6:
        return 2;
      case 8:
        return 2;
    }
  }

  const isPortrait = pagePortrait === 'PORTRAIT';
  if (filePortrait) {
    // file portrait, page portrait
    if (isPortrait) {
      switch (nup) {
        case 1:
          return 1;
        case 2:
          return 1;
        case 4:
          return 2;
        case 6:
          return 2;
        case 8:
          return 2;
      }
    }

    // file portrait, page landscape
    switch (nup) {
      case 1:
        return 1;
      case 2:
        return 2;
      case 4:
        return 4;
      case 6:
        return 3;
      case 8:
        return 4;
    }
  }

  // file landscape, page portrait
  if (isPortrait) {
    switch (nup) {
      case 1:
        return 1;
      case 2:
        return 1;
      case 4:
        return 2;
      case 6:
        return 2;
      case 8:
        return 2;
    }
  }

  // file landscape, page landscape
  switch (nup) {
    case 1:
      return 1;
    case 2:
      return 1;
    case 4:
      return 2;
    case 6:
      return 3;
    case 8:
      return 2;
  }
};

export const getRowFromOptions = ({
  filePortrait,
  pagePortrait,
  nup,
}: {
  filePortrait: boolean;
  pagePortrait: PaperOrientation;
  nup: NUp;
}) => {
  if (pagePortrait === 'AUTO') {
    if (filePortrait) {
      switch (nup) {
        case 1:
          return 1;
        case 2:
          return 1;
        case 4:
          return 2;
        case 6:
          return 2;
        case 8:
          return 2;
      }
    }
    switch (nup) {
      case 1:
        return 1;
      case 2:
        return 2;
      case 4:
        return 2;
      case 6:
        return 3;
      case 8:
        return 4;
    }
  }

  const isPortrait = pagePortrait === 'PORTRAIT';
  if (filePortrait) {
    // file portrait, page portrait
    if (isPortrait) {
      switch (nup) {
        case 1:
          return 1;
        case 2:
          return 2;
        case 4:
          return 2;
        case 6:
          return 3;
        case 8:
          return 4;
      }
    }

    // file portrait, page landscape
    switch (nup) {
      case 1:
        return 1;
      case 2:
        return 1;
      case 4:
        return 1;
      case 6:
        return 2;
      case 8:
        return 2;
    }
  }

  // file landscape, page portrait
  if (isPortrait) {
    switch (nup) {
      case 1:
        return 1;
      case 2:
        return 2;
      case 4:
        return 2;
      case 6:
        return 3;
      case 8:
        return 4;
    }
  }

  // file landscape, page landscape
  switch (nup) {
    case 1:
      return 1;
    case 2:
      return 2;
    case 4:
      return 2;
    case 6:
      return 2;
    case 8:
      return 4;
  }
};
