import * as Sentry from '@sentry/react';
import { ampEvents } from 'amplitude-events';
import PageContainer from 'components/common/PageContainer';
import Icon from 'components/common/icon/Icon';
import InputBox from 'components/common/input_box/InputBox';
import Modal from 'components/common/modal/Modal';
import RadioButton from 'components/common/radio_button/RadioButton';
import RadioGroup from 'components/common/radio_button/RadioGroup';
import FixedButtons from 'components/common/solid_button/FixedButtons';
import SolidButton from 'components/common/solid_button/SolidButton';
import TopBar from 'components/common/top_bar/TopBar';
import {
  BIRTH_REGEX,
  EMAIL_REGEX,
  PASSWORD_REGEX,
  PHONE_REGEX,
  REG_NUMBER,
} from 'constants/Constants';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import trackUser from 'services/trackUser';
import { useStores } from 'stores/RootStore';
import styled from 'styled-components';
import { Spacing } from 'styles/styles';
import { Gender } from 'types/auth/Gender';
import { ApiError } from 'types/error/ApiError';

const Register2: React.FC = props => {
  const navigation = useNavigate();
  const { authStore } = useStores();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [code, setCode] = useState('');
  const [sentCode, setSentCode] = useState(false);
  const [reSentCode, setReSentCode] = useState(false);
  const [phoneInvalid, setPhoneInvalid] = useState(false);
  const [codeInvalid, setCodeInvalid] = useState(false);
  const [codeVerify, setCodeVerify] = useState(false);
  const [registeredNumberModal, setRegisteredNumberModal] = useState(false);
  const [time, setTime] = useState(180);

  const [email, setEmail] = useState('');
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [emailAvailable, setEmailAvailable] = useState(false);
  const [emailOverlap, setEmailOverlap] = useState(false);

  const [pwd, setPwd] = useState('');
  const [pwdInvalid, setPwdInvalid] = useState(false);

  const [pwdMatch, setPwdMatch] = useState('');
  const [pwdMatchInvalid, setPwdMatchInvalid] = useState(false);

  const [name, setName] = useState('');
  const [nameVaild, setNameValid] = useState(false);

  const [birth, setBirth] = useState('');
  const [birthInvalid, setBirthInvalid] = useState(false);

  const [gender, setGender] = useState<Gender | null>(null);

  const [failureSignUp, setFailureSignUp] = useState(false);

  const handleSendCode = () => {
    authStore
      .isExist(
        `+82${phoneNumber.length === 11 ? phoneNumber.slice(1) : phoneNumber}`
      )
      .then(res => {
        if (res.data.exists) throw new Error('이미 가입된 번호입니다');
        setSentCode(true);
        setReSentCode(false);
        setCodeVerify(false);
        setCode('');
        authStore.sendPhoneAuthMessage(
          `+82${phoneNumber.length === 11 ? phoneNumber.slice(1) : phoneNumber}`
        );
        trackUser(ampEvents.Register2_PhoneVerification_Send);
      })
      .catch(err => {
        setRegisteredNumberModal(true);
      });
  };

  const handleCodeVerify = () => {
    authStore
      .verifyPhone(code)
      .then(() => {
        trackUser(ampEvents.Register2_PhoneVerification_Success);
        setCodeVerify(true);
        setSentCode(false);
      })
      .catch(err => {
        setCodeVerify(false);
        // setSentCode(false);
        setReSentCode(true);
        // authStore.clear();
      });
  };

  /**
   * 이메일 중복확인
   */
  const handleEmailOverlap = () => {
    authStore.isExist(undefined, email).then(res => {
      if (res.data.exists) {
        setEmailAvailable(false);
        setEmailOverlap(true);
      } else {
        setEmailAvailable(true);
        setEmailOverlap(false);
      }
    });
  };

  /**
   * 회원가입
   */
  const handleSignUp = () => {
    authStore.setEmail(email);
    authStore.setPassword(pwd);
    authStore.setName(name);
    if (birth) {
      const year = Number(birth.slice(0, 4));
      const month = Number(birth.slice(4, 6));
      const day = Number(birth.slice(6, 8));
      authStore.setBirthday(new Date(year, month - 1, day + 1));
    }
    if (gender !== null) {
      authStore.setGender(gender);
    }

    authStore
      .signUp()
      .then(() => {
        trackUser(ampEvents.Register2_Complete);
        navigation('/register3', { replace: true });
      })
      .catch(err => {
        Sentry.captureException(new ApiError(err, 'signUpError'));
        setFailureSignUp(true);
      });
  };

  // 인증시간 초과 시
  useEffect(() => {
    if (time < 1) {
      setSentCode(false);
      setReSentCode(true);
    }
  }, [time]);

  // 인증시간 초과 후 time 재설정
  useEffect(() => {
    if (!sentCode) return;
    const countdown = setInterval(() => {
      setTime(t => t - 1);
    }, 1000);
    // eslint-disable-next-line consistent-return
    setTime(180);
    return () => clearInterval(countdown);
  }, [sentCode]);

  // phone number 형식 확인
  useEffect(() => {
    setPhoneInvalid(phoneNumber.match(PHONE_REGEX) === null);
  }, [phoneNumber]);

  // code number 형식 확인
  useEffect(() => {
    setCodeInvalid(code.match(/^[0-9]{5}$/) === null);
  }, [code]);

  // email 형식 확인
  useEffect(() => {
    setEmailInvalid(email.match(EMAIL_REGEX) === null);
  }, [email]);

  // password 형식 확인
  useEffect(() => {
    setPwdInvalid(pwd.match(PASSWORD_REGEX) === null);
  }, [pwd]);

  // password 확인
  useEffect(() => {
    setPwdMatchInvalid(pwdMatch === pwd);
  }, [pwd, pwdMatch]);

  // 이름 형식 확인
  useEffect(() => {
    setNameValid(name.length >= 2);
  }, [name]);

  // birth number 형식 확인
  useEffect(() => {
    setBirthInvalid(birth.match(BIRTH_REGEX) === null);
  }, [birth]);

  useEffect(() => {
    setPhoneInvalid(false);
    setCodeInvalid(false);
    setEmailInvalid(false);
    setPwdInvalid(false);
    setPwdMatchInvalid(false);
    setBirthInvalid(false);
  }, []);

  return (
    <PageContainer>
      <TopBar left="back" title="회원 가입" border={true} />
      <PhoneAuthContainer>
        <InputBox
          type="number"
          disabled={codeVerify}
          label="휴대폰 번호*"
          placeholder="01000000000"
          action={phoneInvalid ? 'error' : codeVerify ? 'correct' : 'default'}
          helpText={
            phoneInvalid
              ? '유효하지 않은 휴대폰 번호입니다'
              : codeVerify
              ? '인증되었습니다'
              : '휴대폰 번호를 입력해주세요'
          }
          onChange={e => {
            setPhoneNumber(e.target.value.replace(REG_NUMBER, ''));
          }}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              handleSendCode();
            }
          }}
        />

        <PhoneAuthSend>
          <Spacing.Vertical height={6} />
          <SolidButton
            disabled={phoneInvalid || phoneNumber === '' || codeVerify}
            kind="skyblue"
            area="md"
            onClick={() => {
              handleSendCode();
            }}
          >
            {reSentCode ? '인증번호 재전송' : '인증번호 전송'}
          </SolidButton>
        </PhoneAuthSend>
      </PhoneAuthContainer>

      <PhoneAuthContainer>
        {(sentCode || reSentCode!) && !codeVerify && (
          <InputConatiner>
            <InputBox
              value={code}
              label="인증번호*"
              placeholder="00000"
              action={codeInvalid || reSentCode ? 'error' : 'default'}
              helpText={
                !reSentCode
                  ? '문자로 받은 인증번호 5자리를 입력해주세요'
                  : !codeVerify
                  ? '인증번호가 일치하지 않습니다'
                  : '입력시간이 초과되었으니 다시 시도해주세요'
              }
              onChange={e => {
                setCode(e.target.value);
              }}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  handleCodeVerify();
                }
              }}
            />
            <RemainingTime>
              <Icon icon="IconClock" size={16} />
              <Spacing.Horizontal width={4} />
              {`${Math.floor(time / 60).toLocaleString(undefined, {
                minimumIntegerDigits: 2,
              })}:${
                (time % 60).toLocaleString(undefined, {
                  minimumIntegerDigits: 2,
                }) || '00'
              }`}
            </RemainingTime>
          </InputConatiner>
        )}

        {sentCode && (
          <PhoneAuthSend>
            <Spacing.Vertical height={6} />
            <SolidButton
              disabled={codeInvalid || code === ''}
              kind="skyblue"
              area="md"
              onClick={() => {
                handleCodeVerify();
              }}
            >
              인증하기
            </SolidButton>
          </PhoneAuthSend>
        )}
      </PhoneAuthContainer>

      {/* Modal: 이미 가입된 번호 */}
      <Modal
        isOpen={registeredNumberModal}
        onRequestClose={() => {
          navigation('/login', { replace: true });
        }}
        title="이미 가입된 번호"
        description={`휴대폰 번호를 다시 입력하거나\n로그인 해주세요\n\n문의 02-6959-2677`}
        mainActionLabel="로그인하기"
        onMainAction={() => {
          navigation('/login', { replace: true });
        }}
        subActionLabel="다시 입력"
        onSubAction={() => {
          setRegisteredNumberModal(false);
        }}
      />

      <InputBox
        label="이메일*"
        disabled={!codeVerify}
        value={email}
        placeholder="boba@bobaprint.com"
        action={
          emailInvalid || emailOverlap
            ? 'error'
            : email === ''
            ? 'default'
            : 'correct'
        }
        helpText={
          emailAvailable
            ? '가입 가능한 이메일입니다'
            : emailOverlap
            ? '이미 가입된 이메일입니다'
            : '이메일은 변경이 불가능하니 정확히 입력해주세요'
        }
        onChange={e => {
          setEmail(e.target.value);
          setEmailAvailable(false);
        }}
        onKeyPress={e => {
          if (e.key === 'Enter' && !emailInvalid && email !== '') {
            handleEmailOverlap();
          }
        }}
      />
      <SolidButton
        disabled={emailInvalid || email === ''}
        kind="skyblue"
        area="md"
        onClick={handleEmailOverlap}
      >
        중복 확인
      </SolidButton>

      <InputBox
        disabled={!emailAvailable}
        value={pwd}
        type="password"
        placeholder="• • • • • • • •"
        action={pwdInvalid ? 'error' : pwd === '' ? 'default' : 'correct'}
        label="비밀번호*"
        helpText="영문 + 숫자 조합 8자리 이상"
        onChange={e => {
          if (e.target.value.length > 100) return;
          setPwd(e.target.value);
        }}
      />

      <InputBox
        disabled={!emailAvailable}
        value={pwdMatch}
        type="password"
        placeholder="• • • • • • • •"
        action={
          pwdMatch === '' ? 'default' : pwdMatchInvalid ? 'correct' : 'error'
        }
        label="비밀번호 확인*"
        helpText={
          pwdMatch === ''
            ? '비밀번호를 한 번 더 입력해주세요'
            : pwdMatchInvalid
            ? '비밀번호가 일치합니다'
            : '비밀번호가 일치하지 않습니다'
        }
        onChange={e => {
          setPwdMatch(e.target.value);
        }}
      />

      <InputBox
        disabled={!emailAvailable}
        value={name}
        label="이름*"
        placeholder="김보바"
        helpText={
          name === ''
            ? '이름은 인쇄물을 찾아갈 때 본인 확인용입니다'
            : nameVaild
            ? '이름은 인쇄물을 찾아갈 때 본인 확인용입니다'
            : '2글자 이상 입력해주세요'
        }
        onChange={e => {
          if (e.target.value.length > 45) return;
          setName(e.target.value);
        }}
        onKeyPress={e => {
          if (
            e.key === 'Enter' &&
            emailAvailable &&
            pwdMatchInvalid &&
            name.length >= 2
          ) {
            handleSignUp();
          }
        }}
      />
      {/* 선택항목 추가: 생년월일, 성별  */}
      <InputBox
        type="number"
        disabled={!emailAvailable}
        value={birth}
        label="생년월일"
        action={birthInvalid ? 'error' : birth === '' ? 'default' : 'correct'}
        placeholder="20010101"
        helpText={
          birth === ''
            ? '생년월일 8자리를 입력해주세요'
            : !birthInvalid
            ? '생년월일 8자리를 입력해주세요'
            : '형식에 맞게 입력해주세요 (예시: 20010101)'
        }
        onChange={e => {
          e.preventDefault();
          if (e.target.value.length > 8) return;
          setBirth(e.target.value);
        }}
        onKeyPress={e => {
          if (e.key === 'Enter' && !birthInvalid) {
            handleSignUp();
          }
        }}
      />
      <Label>성별</Label>
      <Spacing.Vertical height={12} />
      <form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <RadioGroup direction="row">
          <RadioButton
            name="sex"
            value={Gender.Male}
            onChange={e => {
              setGender(Gender.Male);
            }}
          >
            남
          </RadioButton>
          <RadioButton
            name="sex"
            value={Gender.Female}
            onChange={e => {
              setGender(Gender.Female);
            }}
          >
            여
          </RadioButton>
        </RadioGroup>
      </form>

      <Spacing.Vertical height={140} />

      <FixedButtons>
        <SolidButton
          disabled={
            !emailAvailable ||
            !pwdMatchInvalid ||
            name.length < 2 ||
            (birth !== '' && birthInvalid)
          }
          onClick={handleSignUp}
        >
          회원가입 완료
        </SolidButton>
      </FixedButtons>

      {/* Modal - failure */}
      <Modal
        isOpen={failureSignUp}
        onRequestClose={() => {
          setFailureSignUp(false);
          navigation('/login', { replace: true });
        }}
        description="회원가입에 실패했습니다"
        mainActionLabel="확인"
        onMainAction={() => {
          setFailureSignUp(false);
          navigation('/boba', { replace: true });
        }}
      />
    </PageContainer>
  );
};
export default observer(Register2);

const PhoneAuthContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

const PhoneAuthSend = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: fit-content;
`;

const InputConatiner = styled.div`
  position: relative;
  width: 100%;
`;

const RemainingTime = styled.div`
  position: absolute;
  top: 16px;
  right: 10px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #737373;
`;

const Label = styled.label`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #737373;
`;
