import React from 'react';
import styled, { css } from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Spacing } from 'styles/styles';
import Badge, { BadgeProps } from '../badge/Badge';
import CheckBox from '../check_box/CheckBox';
import Icon from '../icon/Icon';
import SolidButton, { SolidButtonProps } from '../solid_button/SolidButton';
import PriceList, { PriceProps } from '../price_list/PriceList';
import { ReactComponent as IconFile } from 'assets/icons/files/IconFile.svg';
import { ReactComponent as IconErrorFile } from 'assets/icons/files/IconErrorFile.svg';
import { ImgOptionColor, ImgOptionMono } from 'assets/images';

export interface Props extends SolidButtonProps, PriceProps, BadgeProps {
  loading?: boolean;
  onAllClick?: (
    event:
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => void;
  active?: boolean;
  disabled?: boolean;
  // left options
  select?: 'none' | 'check' | 'radio';
  selectNode?: React.ReactNode;
  checked?: boolean;
  onSelected?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  onLeftClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  visual?: 'none' | 'image' | 'file' | 'color' | 'mono' | 'fileError' | 'error';
  imageUrl?: string | null;
  indicator?: boolean;
  title?: string;
  description?: string;
  text?: string;
  badge?: string;
  listBottom?: 'tags' | 'prices';
  tags?: Array<string>;
  // right options
  right?: React.ReactNode;
  actionText?: string;
  action?: 'button' | 'arrow' | 'more' | 'info' | 'none';
  actionLabel?: string;
  onActionClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const List: React.FC<Props> = ({
  loading,
  onAllClick,
  active,
  disabled,
  select,
  selectNode,
  checked,
  onSelected,
  onLeftClick,
  visual,
  imageUrl,
  indicator,
  title,
  description,
  text,
  badge,
  badgeKind,
  listBottom,
  tags,
  actionText,
  action,
  actionLabel,
  onActionClick,
  onClick,
  kind,
  children,
  status,
  priceMono,
  priceColor,
  right,
  ...props
}) => {
  return (
    <ListContainer onClick={onAllClick} active={active}>
      <ListLeft onClick={onLeftClick} disabled={disabled}>
        <Contents>
          {select && (
            <Select select={select}>
              {select === 'check' && (
                <CheckBox checked={checked} onClick={onSelected} />
              )}
            </Select>
          )}
          {selectNode && selectNode}
          {visual === 'image' && loading && (
            <Skeleton width={40} height={40} style={{ marginRight: '12px' }} />
          )}
          {visual === 'image' && !loading && (
            <Visual visual={visual} imageUrl={imageUrl}></Visual>
          )}
          {visual === 'file' && (
            <>
              <IconFile width={40} height={40} />
              <Spacing.Horizontal width={14} />
            </>
          )}
          {visual === 'color' && (
            <>
              <ColorOption src={ImgOptionColor} />
              <Spacing.Horizontal width={14} />
            </>
          )}
          {visual === 'mono' && (
            <>
              <ColorOption src={ImgOptionMono} />
              <Spacing.Horizontal width={14} />
            </>
          )}
          {visual === 'fileError' && (
            <>
              <IconErrorFile width={40} height={40} />
              <Spacing.Horizontal width={14} />
            </>
          )}
          {visual === 'error' && (
            <div style={{ display: 'flex', paddingTop: '6px' }}>
              <Icon icon="IconError" themeColor="destructive600" />
              <Spacing.Horizontal width={14} />
            </div>
          )}
          <Content>
            {badge && (
              <>
                <Badge
                  label={badge}
                  badgeKind={badgeKind ? badgeKind : 'gray'}
                  size="md"
                />
                <Spacing.Vertical height={8} />
              </>
            )}
            {title && (
              <TitleContainer>
                {indicator && <Indicator />}
                <Title>{!loading ? title : <Skeleton width={72} />}</Title>
              </TitleContainer>
            )}
            {description && (
              <Description>
                {!loading ? description : <Skeleton width={144} />}
              </Description>
            )}
            {text && <Text>{!loading ? text : <Skeleton width={120} />}</Text>}
          </Content>
        </Contents>
        {tags && (
          <ListBottom>
            {tags.map(tag => (
              <>
                <Badge
                  key={tag.toString()}
                  label={tag}
                  badgeKind={badgeKind ? badgeKind : 'gray'}
                  size="sm"
                />
                <Spacing.Horizontal width={8} />
              </>
            ))}
          </ListBottom>
        )}
        {listBottom === 'prices' && (
          <ListBottom>
            <PriceList
              status={status}
              priceMono={priceMono}
              priceColor={priceColor}
            />
          </ListBottom>
        )}
      </ListLeft>
      <Spacing.Horizontal width={10} />
      {!loading && (
        <ListRight>
          {right && right}
          {actionText && <ActionText>{actionText}</ActionText>}
          {action && (
            <Action onClick={onActionClick} action={action}>
              {action === 'button' && (
                <SolidButton kind={kind} area="sm" onClick={onClick}>
                  {children}
                </SolidButton>
              )}
              {action === 'arrow' && <Icon icon="IconArrowRight" />}
              {action === 'more' && <Icon icon="IconDotsVertical" />}
              {action === 'info' && <Icon icon="IconInfoCircle" />}
            </Action>
          )}
        </ListRight>
      )}
    </ListContainer>
  );
};

export default List;

List.defaultProps = {
  select: 'none',
  indicator: false,
};

const ListContainer = styled.div<Props>`
  width: 100%;
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${props => {
    if (props.active)
      return css`
        background: #e6f2fe;
        width: calc(100% + 48px);
        margin-left: -24px;
        padding: 16px 24px;
      `;
  }}
`;
const ListLeft = styled.div<Props>`
  width: 50%;
  flex-grow: 1;
  ${props => {
    if (props.disabled)
      return css`
        opacity: 0.5;
      `;
  }}
`;
const ListRight = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 0;
`;
const Contents = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
const Select = styled.div<Props>`
  ${props => {
    switch (props.select) {
      case 'check':
        return css`
          margin-right: 12px;
        `;
    }
  }}
`;
const Visual = styled.div<Props>`
  ${props => {
    switch (props.visual) {
      case 'image':
        return css`
          width: 40px;
          height: 40px;
          margin-right: 12px;
          border-radius: 8px;
          background: url('${props.imageUrl}');
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        `;
    }
  }}
`;
const Content = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;
const Indicator = styled.div`
  margin-right: 8px;
  width: 8px;
  height: 8px;
  background: ${({ theme }) => theme.color.primary600};
  opacity: 0.8;
  border-radius: 50%;
`;
const Title = styled.p`
  width: 80%;
  flex-grow: 1;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.color.gray700};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  word-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
const Description = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.color.gray500};
`;
const Text = styled.p`
  margin-top: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.color.gray700};
`;
const Action = styled.div<Props>`
  height: 18px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  ${props => {
    switch (props.action) {
      case 'button':
        return css`
          width: 85px;
        `;
    }
  }}
`;
const ActionText = styled.p`
  width: max-content;
  margin-right: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.gray600};
`;
const ListBottom = styled.div`
  display: flex;
  margin-top: 6px;
`;

const ColorOption = styled.img`
  width: 40px;
  height: 40px;
  background-position: center;
`;
