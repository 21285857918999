import { ampEvents } from 'amplitude-events';
import Icon from 'components/common/icon/Icon';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Map } from 'react-kakao-maps-sdk';
import trackUser from 'services/trackUser';
import { useStores } from 'stores/RootStore';
import styled from 'styled-components';
import PrintZoneMarker from './map/PrintZoneMarker';

const PrintZoneMap: React.FC = () => {
  const defaultLat = 37.56803229401946,
    defaultLng = 126.92519680992935,
    defaultLevel = 9; // 서울전체

  const { userStore } = useStores();
  const [map, setMap] = useState<kakao.maps.Map>();
  const [lat, setLat] = useState<number>(defaultLat);
  const [lng, setLng] = useState<number>(defaultLng);
  const [level, setLevel] = useState<number>();

  const handleCurrentLocation = () => {
    trackUser(ampEvents.Map_MyLocation);
    if (userStore.currentLocation === null) return;
    console.log(userStore.currentLocation);
    const moveLatLng = new kakao.maps.LatLng(
      userStore.currentLocation.lat,
      userStore.currentLocation.lng
    );
    map?.panTo(moveLatLng);
  };

  useEffect(() => {
    setLat(
      parseFloat(localStorage.getItem('centerLat') ?? defaultLat.toString())
    );
    setLng(
      parseFloat(localStorage.getItem('centerLng') ?? defaultLng.toString())
    );
    setLevel(
      parseInt(localStorage.getItem('level') ?? defaultLevel.toString())
    );

    setMap(map);
  }, []);

  return (
    <Container>
      <Map
        center={{ lat: lat, lng: lng }}
        isPanto
        style={{ width: '100%', height: 'calc(var(--vh, 1vh) * 100 - 126px)' }}
        level={level}
        onCreate={map => {
          setMap(map);
        }}
        onCenterChanged={target => {
          localStorage.setItem(
            'centerLat',
            target.getCenter().getLat().toString()
          );
          localStorage.setItem(
            'centerLng',
            target.getCenter().getLng().toString()
          );
        }}
        onZoomChanged={target => {
          localStorage.setItem('level', target.getLevel().toString());
        }}
      >
        <PrintZoneMarker />

        <FloatingButton>
          <Icon
            icon="IconCurrentLocation"
            themeColor="primary600"
            onClick={handleCurrentLocation}
          />
        </FloatingButton>
      </Map>
    </Container>
  );
};

export default observer(PrintZoneMap);

const Container = styled.div`
  position: relative;
`;
const FloatingButton = styled.div`
  z-index: 4;
  position: fixed;
  right: 20px;
  bottom: 92px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.gray200};
  border-radius: 50px;
  cursor: pointer;
`;
