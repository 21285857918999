import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import Lottie from 'react-lottie-player';
import { useStores } from 'stores/RootStore';
import trackUser from 'services/trackUser';
import { ampEvents } from 'amplitude-events';
import PageContainer from 'components/common/PageContainer';
import FixedButtons from 'components/common/solid_button/FixedButtons';
import SolidButton from 'components/common/solid_button/SolidButton';
import ErrorPage from 'components/common/ErrorPage';
import FullScreenLoader from 'components/common/FullScreenLoader';
import Icon from 'components/common/icon/Icon';
import { Spacing } from 'styles/styles';
import LottieError from 'assets/lottie/LottieError.json';
import LottieComplete from 'assets/lottie/LottieComplete.json';
import useHistoryBack from 'hooks/useHistoryBack';

export default observer(function JobError() {
  const navigation = useNavigate();
  const { updateJobStore, jobPollingStore } = useStores();
  const serviceName =
    jobPollingStore.serviceType === 'prints'
      ? '프린트'
      : jobPollingStore.serviceType === 'scans'
      ? '스캔'
      : '복사';
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [cancelJobsComplete, setCancelJobsComplete] = useState<boolean>(false);

  const handleCancelJob = async () => {
    trackUser(ampEvents.JobError_CancelTicket);
    setLoading(true);
    // TODO: 수정
    await updateJobStore
      .deleteAllJobs(jobPollingStore.serviceType!, jobPollingStore.kioskId!)
      .then(() => {
        setCancelJobsComplete(true);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleBack = () => {
    jobPollingStore.clearStore();
    navigation('/print', { replace: true });
  };

  useHistoryBack(async () => {
    await handleCancelJob();
    handleBack();
  });

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  if (error) {
    return <ErrorPage />;
  }

  if (cancelJobsComplete) {
    return (
      <PageContainer>
        <Main>
          <Lottie
            loop={false}
            animationData={LottieComplete}
            play
            style={{
              width: 200,
              height: 200,
            }}
          />
          <MainText>
            <CompleteTitle>
              {`${serviceName}`} 작업 취소가 완료되었습니다.
            </CompleteTitle>
          </MainText>
        </Main>

        <FixedButtons>
          <SolidButton onClick={handleBack}>확인</SolidButton>
        </FixedButtons>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      {loading && <FullScreenLoader message="작업 취소중" />}
      <Container>
        <Lottie
          loop={false}
          animationData={LottieError}
          play
          style={{
            width: 100,
            height: 100,
            marginRight: '4px',
            marginBottom: '2px',
            overflow: 'visible',
          }}
        />
        <Spacing.Vertical height={14} />
        <Title>{`${serviceName} 작업에 문제가 생겼습니다`}</Title>
        <Spacing.Vertical height={17} />
        <SubTitle>{`${serviceName} 작업이 중단되었습니다`}</SubTitle>
        {/* <SubTitle>용지가 걸리거나 부족할 수 있습니다</SubTitle> */}
        <SubTitle>관리자에게 문의해주세요</SubTitle>
      </Container>

      <FixedButtons>
        <InfoContainer>
          <Icon icon="IconPhone" themeColor="primary600" />
          <InfoText>
            <InfoTitle>카카오톡 문의</InfoTitle>
            AM 10:00 ~ PM 07:00
          </InfoText>
        </InfoContainer>
        <Row>
          <SolidButton
            onClick={() => {
              trackUser(ampEvents.JobError_CustomerService);
              window.open('http://pf.kakao.com/_BPfWT/chat', '_blank');
              // navigation('/job-error/report', { replace: true });
            }}
          >
            카카오톡 문의 바로가기
          </SolidButton>
          {/* <SolidButton onClick={handleCancelJob}>작업 취소</SolidButton> */}
          <SolidButton onClick={() => {
            navigation('/', { replace: true });
          }}>확인</SolidButton>
        </Row>
      </FixedButtons>
    </PageContainer>
  );
});

const Container = styled.section`
  height: 78vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Title = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.03em;
  color: ${({ theme }) => theme.color.gray900};
`;
const SubTitle = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.gray500};
`;
const InfoContainer = styled.div`
  width: 100%;
  background: ${props => props.theme.color.gray50};
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px 20px;
  border-radius: 8px;
`;
const InfoTitle = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.theme.color.gray700};
`;
const InfoText = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.theme.color.gray600};
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const Row = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
`;
const Main = styled.section`
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const MainText = styled.div`
  margin-top: -12px;
  padding: 16px 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const CompleteTitle = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.03em;
  color: #1d1d1d;
`;
