import React, { useState } from 'react';
import styled from 'styled-components';
import PageContainer from 'components/common/PageContainer';
import TopBar from 'components/common/top_bar/TopBar';
import Icon from 'components/common/icon/Icon';

const Faq: React.FC = props => {
  const [faqs] = useState([
    {
      key: 1,
      title: `파일을 업로드 후 계속 변환 중이라고 표시 됩니다`,
      body: '업로드한 파일이 ‘비밀번호가 걸려있는 PDF’, ‘손상된 파일’ 등인 경우 출력용 파일로 변환되지 않습니다. 해당 경우의 파일인지 확인하시고 정상 파일을 다시 업로드 해주셔야 합니다.',
    },
    {
      key: 2,
      title: '인쇄 요청 후 인증번호를 받아 놓고 잊어버렸습니다',
      body: '인쇄 요청이 완료된 작업의 인증번호는 홈화면 하단에서 지속적으로 확인 할 수 있습니다. 인증번호 화면에서 벗어나서 다른 작업을 진행하더라도 계속해서 확인 가능 합니다.',
    },
    {
      key: 3,
      title: `인쇄 설정을 마친 후, 프린터를 잘못 선택 했습니다`,
      body: '프린터를 잘 못 선택한 경우에는 인쇄 요청을 취소한 뒤, 새롭게 작업을 요청해야 합니다. 인쇄 요청의 인증번호는 선택한 프린터에서만 적용 되니 꼭 프린터 선택을 확인 하신 뒤 인쇄 요청을 보내주세요!',
    },
  ]);
  const [openIndices, setOpenIndices] = useState<number[]>([]);

  return (
    <PageContainer>
      <TopBar left="back" title="자주하는 질문" />
      {faqs.map((faq, index) => (
        <FaqItem
          key={faq.key}
          onClick={() => {
            if (openIndices.includes(index)) {
              setOpenIndices(openIndices.filter(it => it !== index));
            } else {
              setOpenIndices([...openIndices, index]);
            }
          }}
        >
          <Row>
            <FaqTitle>{faq.title}</FaqTitle>
            {openIndices.includes(index) ? (
              <Icon icon="IconArrowUp" />
            ) : (
              <Icon icon="IconArrowDown" />
            )}
          </Row>
          {openIndices.includes(index) && <FaqBody>{faq.body}</FaqBody>}
        </FaqItem>
      ))}
    </PageContainer>
  );
};

export default Faq;

const FaqItem = styled.li`
  width: 100%;
  padding: 16px 0;
`;
const Row = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const FaqTitle = styled.p`
  font-size: 16px;
  line-height: 24px;
`;
const FaqBody = styled.p`
  white-space: pre-wrap;
  margin-top: 16px;
  font-size: 14px;
  line-height: 158.22%;
  letter-spacing: -0.05em;
  color: ${props => props.theme.color.gray500};
`;
