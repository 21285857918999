import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Lottie from 'react-lottie-player';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStores } from 'stores/RootStore';
import { PrintJob } from 'stores/job/PrintJob';
import { jobService } from 'services/job.service';
import trackUser from 'services/trackUser';
import { ampEvents } from 'amplitude-events';
import List from 'components/common/list/List';
import PageContainer from 'components/common/PageContainer';
import TopBar from 'components/common/top_bar/TopBar';
import Icon from 'components/common/icon/Icon';
import ContextMenu from 'components/common/context_menu/ContextMenu';
import FixedButtons from 'components/common/solid_button/FixedButtons';
import SolidButton from 'components/common/solid_button/SolidButton';
import FullScreenLoader from 'components/common/FullScreenLoader';
import Modal from 'components/common/modal/Modal';
import PrintWaitingJob from 'components/pages/PrintWaitingJob';
import Spinner from 'assets/lottie/LottieSpinner.json';
import { Spacing } from 'styles/styles';
import useHistoryBack from 'hooks/useHistoryBack';

const PrintWaitingList: React.FC = props => {
  const {
    userStore,
    printJobStore,
    fileStore,
    updateJobStore,
    jobPollingStore,
  } = useStores();
  const { pathname } = useLocation();
  const id = pathname.split('/')[3];

  const [job, setJob] = useState<PrintJob[] | null>(null);
  const [printJobContextMenu, setPrintJobContextMenu] = useState(false);
  const [selectedPrintJob, setSelectedPrintJob] = useState<PrintJob>();
  const [deleteConfirmModal, setDeleteContirmModal] = useState(false);

  const navigation = useNavigate();

  /**
   * print wizard 3단계 edit ticket 페이지로 이동
   */
  const handleEditTicket = (printJobId: string) => {
    navigation(`/printwizard3edit/${printJobId}`);
  };

  const fileNameFormmated = (str: string | undefined) => {
    if (str == undefined) return str;
    if (str.length < 35) return str;

    const extension = str.match(/\.[^.]*$/);
    const truncated = str.slice(0, 30);

    return truncated + '…' + extension;
  };

  useEffect(() => {
    fileStore
      .loadFiles()
      .then(() => {
        setJob(printJobStore.getPrintJobsExistingInThekiosk(id));
      })
      .catch(() => {
        userStore.showErrorModal();
      });
  }, []);

  useHistoryBack(() => {
    navigation('/print', { replace: true });
  });

  if (job === null) {
    return <FullScreenLoader />;
  }

  return (
    <PageContainer>
      <TopBar title="작업 대기 목록" />
      {printJobStore.isLoading && <FullScreenLoader />}

      <>
        <PrintWaitingJob
          key={job[0].id}
          createdAt={job[0].createdAt}
          kiosk={job[0].kiosk}
          printJobs={job}
        />
        <List title="파일 목록" />
        {job.map(pj => (
          <FileContainer key={pj.id}>
            <FileStatusIcon>
              {pj.file.isConverting && (
                <Lottie
                  loop
                  animationData={Spinner}
                  play
                  style={{
                    width: 20,
                    height: 24,
                    marginLeft: 2,
                    marginRight: 2,
                  }}
                />
              )}
              {pj.file.errorType && (
                <Icon icon="IconErrorCircle" themeColor="destructive600" />
              )}
              {!pj.file.isConverting && !pj.file.errorType && (
                <Icon icon="IconCheckCircle" themeColor="primary600" />
              )}
            </FileStatusIcon>
            <Spacing.Horizontal width={12} style={{ order: 1 }} />
            <div style={{ width: '90%', order: 2 }}>
              <List
                title={pj.file.name}
                action="more"
                onActionClick={() => {
                  setSelectedPrintJob(pj);
                  setPrintJobContextMenu(true);
                }}
              />
            </div>
          </FileContainer>
        ))}

        <FixedButtons>
          <SolidButton
            disabled={printJobStore.isLoading}
            kind={
              printJobStore.isConverted(job) === 'error'
                ? 'lightRed'
                : 'primary'
            }
            onClick={() => {
              trackUser(ampEvents.WaitingList_Start);
              if (printJobStore.isConverted(job) === 'success') {
                jobPollingStore.setService('prints');
                const jobIds = job.map(job => job.id);
                jobPollingStore.setCurrentJobIds(jobIds);
                jobPollingStore.setKioskId(id);
                navigation('/verify-kiosk');
              } else if (printJobStore.isConverted(job) === 'error') {
                navigation(`/printjobfail/${job[0].kiosk.id}`);
              }
            }}
          >
            {printJobStore.isLoading ||
            printJobStore.isConverted(job) === 'converting'
              ? '파일 변환이 진행 중 입니다'
              : printJobStore.isConverted(job) === 'success'
              ? '시작하기'
              : '변환에 실패한 파일이 있습니다 >'}
          </SolidButton>
        </FixedButtons>
      </>

      {/* Context Menu: PrintJob */}
      <ContextMenu
        isOpen={printJobContextMenu}
        onRequestClose={() => {
          setPrintJobContextMenu(false);
          setSelectedPrintJob(undefined);
        }}
        title={fileNameFormmated(selectedPrintJob?.file.name) ?? ''}
      >
        <ul>
          {selectedPrintJob &&
            selectedPrintJob?.file.errorType === null &&
            !selectedPrintJob?.file.isConverting && (
              <>
                <CommandItem
                  onClick={() => {
                    trackUser(ampEvents.WaitingList_Preview);
                    navigation(`/printjobs/${selectedPrintJob.id}/preview`);
                    setSelectedPrintJob(undefined);
                  }}
                >
                  <div
                    style={{
                      marginLeft: -10,
                      width: 40,
                      height: 40,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Icon icon="IconVisual" />
                  </div>
                  <p>미리보기</p>
                </CommandItem>
              </>
            )}

          {/* 인쇄설정 수정 */}
          {selectedPrintJob?.file.errorType === null && (
            <CommandItem
              onClick={() => {
                trackUser(ampEvents.WaitingList_EditTicket);
                handleEditTicket(selectedPrintJob.id);
              }}
            >
              <div
                style={{
                  marginLeft: -10,
                  width: 40,
                  height: 40,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Icon icon="IconEdit" />
              </div>
              <p>인쇄설정 수정</p>
            </CommandItem>
          )}

          {/* 요청취소 */}
          <CommandItem
            onClick={() => {
              trackUser(ampEvents.WaitingList_CancelTicket);
              setPrintJobContextMenu(false);
              setDeleteContirmModal(true);
            }}
          >
            <div
              style={{
                marginLeft: -10,
                width: 40,
                height: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Icon icon="IconTrashBin" themeColor="destructive600" />
            </div>
            <p style={{ color: '#DD3D2A' }}>삭제</p>
          </CommandItem>
        </ul>
      </ContextMenu>

      {/* Modal: Confirm when delete file */}
      <Modal
        isOpen={deleteConfirmModal}
        onRequestClose={() => setDeleteContirmModal(false)}
        title="출력 목록 삭제"
        description={`선택한 파일을 출력 목록에서 삭제합니다\n정말 삭제하시겠습니까?`}
        mainActionLabel="삭제"
        mainActionColor="NEGATIVE"
        onMainAction={() => {
          // printjob 삭제
          if (selectedPrintJob?.id) {
            jobService
              .deleteOneJob('prints', selectedPrintJob.id)
              .then(() => {
                navigation('/waiting-list', { replace: true });
              })
              .finally(() => {
                setSelectedPrintJob(undefined);
                setDeleteContirmModal(false);
              });
          }
        }}
        subActionLabel="취소"
        onSubAction={() => setDeleteContirmModal(false)}
      />
    </PageContainer>
  );
};

export default observer(PrintWaitingList);

const CommandItem = styled.li`
  height: 50px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.05em;
  color: ${({ theme }) => theme.color.gray600};
  display: flex;
  align-items: center;
`;
const FileContainer = styled.div`
  display: flex;
  align-items: center;
`;
const FileStatusIcon = styled.div`
  order: 0;
`;
