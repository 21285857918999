import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import List from 'components/common/list/List';
import PageContainer from 'components/common/PageContainer';
import SolidButton from 'components/common/solid_button/SolidButton';
import TopBar from 'components/common/top_bar/TopBar';
import TopInfo from 'components/common/top_info/TopInfo';
import { Spacing } from 'styles/styles';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores/RootStore';
import FullScreenLoader from 'components/common/FullScreenLoader';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ImageSwiper from 'components/common/ImageSwiper';
import Modal from 'components/common/modal/Modal';
import HashTags from 'components/common/tag/HashTags';
import { SITE_URL } from 'constants/Constants';
import { ampEvents } from 'amplitude-events';
import trackUser from 'services/trackUser';
import Icon from 'components/common/icon/Icon';
import useToast from 'hooks/useToast';
import FixedButtons from 'components/common/solid_button/FixedButtons';
import useHistoryBack from 'hooks/useHistoryBack';

const ContentDetail: React.FC = props => {
  const navigation = useNavigate();
  const { id } = useParams();
  const { userStore, contentStore, cardStore, fileStore } = useStores();
  const { showToast } = useToast();

  const [errorPage, setErrorPage] = useState(false);

  const [loginRequestModal, setLoginRequestModal] = useState(false);
  const [paymentFailureModal, setPaymentFailureModal] = useState(false);
  const [noCardModal, setNoCardModal] = useState(false);
  const [pointPurchasedModal, setPointPurchasedModal] = useState(false);

  const handlePurchase = () => {
    if (contentStore.content === null) {
      setErrorPage(true);
      return;
    }
    if (!userStore.user) {
      setLoginRequestModal(true);
      return;
    }

    // 1. 무료 콘텐츠는 바로 구매 가능
    if (contentStore.content.price === 0) {
      purchaseContent().then(() => {
        navigation(`/contents/${id}/completed`);
      });
      return;
    }
    // 2. 포인트 >= 콘텐츠 가격 -> 바로 구매 -> '포인트로 결제되었습니다." 모달 띄우기
    if (userStore.user.points >= contentStore.content.price) {
      purchaseContent().then(() => {
        setPointPurchasedModal(true);
      });
      return;
    }
    // 3-1. (포인트 < 콘텐츠 가격) + 카드 미등록  -> 카드 등록 후 구매 가능
    if (cardStore.cards.length === 0) {
      setNoCardModal(true);
      return;
    }

    // 3-2. (포인트 < 콘텐츠 가격) + 카드 등록  -> 구매 페이지로 이동
    navigation(`/contents/${id}/purchase`);
  };

  async function purchaseContent() {
    if (!id) return;
    trackUser(ampEvents.ContentDetail_Purchase);

    return await contentStore
      .purchaseContent(id, null)
      .then(res => {
        if (res.data.isSuccess) {
          trackUser(ampEvents.ContentDetail_PurchaseSuccess);
          fileStore.loadFiles();
          userStore.loadUser();
          return;
        }
        if (res.data.errorCode) {
          setPaymentFailureModal(true);
        }
      })
      .catch(() => {
        setErrorPage(true);
      });
  }

  useHistoryBack(() => {
    contentStore.clear();
  });

  useEffect(() => {
    userStore.updateReload(false);
    cardStore.loadCards();
  }, []);

  useEffect(() => {
    if (id) {
      contentStore.loadContent(id).catch(err => {
        setErrorPage(true);
      });
      return;
    }
    setErrorPage(true);
  }, []);

  if (contentStore.isLoading || userStore.isLoading) {
    return <FullScreenLoader message="로딩중" />;
  }

  // 해당 url 콘텐츠 글 삭제되거나, 없는 content id 주소로 접근시
  if (errorPage) {
    return (
      <PageContainer>
        <TopBar />
        <ErrorContainer>
          <Icon icon="IconError" size={200} themeColor="destructive600" />
          <ErrorTitile>페이지를 찾을 수 없습니다.</ErrorTitile>
          <Spacing.Vertical height={20} />
          <ErrorDescription>
            {`방문하시려는 페이지의 주소가 잘못 입력되었거나,\n페이지의 주소가 변경 혹은 삭제되어\n요청하신 페이지를 찾을 수 없습니다.\n서비스 이용에 불편을 드려 죄송합니다.`}
          </ErrorDescription>
          <Spacing.Vertical height={40} />
          <div>
            <SolidButton
              onClick={() => navigation('/', { replace: true })}
              area="sm"
              kind="lightRed"
            >
              보바 홈으로
            </SolidButton>
          </div>
        </ErrorContainer>
      </PageContainer>
    );
  }

  // 정상 접근시
  return (
    <PageContainer>
      <TopBar
        rightNode={
          <CopyToClipboard
            text={`${SITE_URL.BOBA_APP}/contents/${id}`}
            onCopy={() => {
              trackUser(ampEvents.ContentDetail_Share);
              showToast('주소를 복사했습니다');
            }}
          >
            <Icon icon="IconShare" />
          </CopyToClipboard>
        }
      />
      <TopInfo title={contentStore.content?.title} />

      <HashTags
        tags={contentStore.content?.tags.map(tag => {
          return tag.name;
        })}
      />

      <Spacing.Vertical height={30} />

      {contentStore.content?.imageUrls && (
        <ImageSwiper imageUrls={contentStore.content?.imageUrls} />
      )}

      <List text="판매자" actionText={contentStore.content?.author} />
      <List
        text="가격"
        actionText={
          contentStore.content?.price === 0
            ? '무료'
            : `${contentStore.content?.price ?? 0}원`
        }
      />
      <List text="구매목록" />
      {contentStore.content?.files.map(file => (
        <FileList key={file.id}>{file.name}</FileList>
      ))}
      {contentStore.content?.description && (
        <>
          <List text="상세 설명" />
          <FileDescription
            dangerouslySetInnerHTML={{
              __html: contentStore.content?.description,
            }}
          ></FileDescription>
        </>
      )}

      <FixedButtons>
        {contentStore.content?.isPurchased ? (
          <SolidButton disabled>이미 구매한 콘텐츠입니다</SolidButton>
        ) : (
          <SolidButton
            onClick={() => {
              trackUser(ampEvents.ContentDetail_Purchase);
              handlePurchase();
            }}
          >
            구매하기
          </SolidButton>
        )}
      </FixedButtons>

      {/* Modals */}
      <Modal
        isOpen={loginRequestModal}
        onRequestClose={() => {
          setLoginRequestModal(false);
        }}
        description="로그인이 필요한 서비스입니다"
        mainActionLabel="로그인하기"
        mainActionColor="POSITIVE"
        onMainAction={() => {
          navigation('/boba');
        }}
        subActionLabel="취소"
        onSubAction={() => {
          setLoginRequestModal(false);
        }}
      />
      <Modal
        isOpen={paymentFailureModal}
        onRequestClose={() => setPaymentFailureModal(false)}
        title="결제 실패"
        description="카드 결제가 실패했습니다"
        mainActionLabel="확인"
        onMainAction={() => {
          setPaymentFailureModal(false);
        }}
      />
      <Modal
        isOpen={noCardModal}
        onRequestClose={() => setNoCardModal(false)}
        title="등록된 카드가 없습니다"
        description="카드를 먼저 등록해주세요."
        mainActionLabel="확인"
        onMainAction={() => {
          navigation('/cards');
        }}
      />
      <Modal
        isOpen={pointPurchasedModal}
        onRequestClose={() => setPointPurchasedModal(false)}
        title="결제 성공 🎉"
        description="포인트로 결제되었습니다."
        mainActionLabel="확인"
        onMainAction={() => {
          navigation(`/contents/${id}/completed`);
        }}
      />
    </PageContainer>
  );
};
export default observer(ContentDetail);

const FileList = styled.div`
  padding: 8px 0;
  width: 100%;
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #737373;
`;
const FileDescription = styled.div`
  width: 100%;
  max-width: 780px;
  padding: 12px 0;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #737373;
  & img {
    max-width: 100%;
  }
`;
const Fade = styled.div`
  position: fixed;
  left: 0;
  bottom: 80px;
  display: flex;
  justify-content: center;
  z-index: 2;
  width: 100%;
  height: 44px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
`;
const ErrorContainer = styled.div`
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const ErrorTitile = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #1d1d1d;
  line-height: 26px;
`;
const ErrorDescription = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: #767676;
  white-space: pre-line;
  text-align: center;
`;
