import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageContainer from 'components/common/PageContainer';
import TopBar from 'components/common/top_bar/TopBar';
import TopInfo from 'components/common/top_info/TopInfo';
import { NoticeDto } from 'types/notice/Notice.dto';
import { getRelativeTimestampString } from 'utils/time.util';
import styled from 'styled-components';
import { httpRequestService } from 'services/http-request.service';
import { useStores } from 'stores/RootStore';

const NoticeDetail: React.FC = props => {
  const { noticeId } = useParams();
  const { userStore } = useStores();

  const [notice, setNotice] = useState<NoticeDto>();

  useEffect(() => {
    httpRequestService
      .create()
      .get(`notices/${noticeId}`)
      .then(res => {
        setNotice(res.data);
      })
      .catch(() => {
        userStore.showErrorModal();
      });
  }, []);

  return (
    <PageContainer>
      <TopBar title="공지사항" />
      {notice && (
        <>
          <TopInfo
            title={notice?.title}
            subTitle={getRelativeTimestampString(notice?.createdAt.toString())}
            upTitle={true}
          />
          {notice?.imageUrl && (
            <BannerContainer>
              <Banner src={notice.imageUrl} />
            </BannerContainer>
          )}
          <Content>{notice?.content}</Content>
        </>
      )}
    </PageContainer>
  );
};
export default NoticeDetail;

const Content = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #737373;
  white-space: pre-wrap;
`;
const BannerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;
const Banner = styled.img`
  width: 100%;
  max-width: 312px;
  margin: 0 auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;
