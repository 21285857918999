import React, { useState } from 'react';
import styled from 'styled-components';
import Icon from 'components/common/icon/Icon';
import { ReactComponent as Icon1pageActive } from 'assets/icons/printSettings/icon_1page_active.svg';
import { ReactComponent as Icon2pageActive } from 'assets/icons/printSettings/icon_2page_active.svg';
import { ReactComponent as Icon4pageActive } from 'assets/icons/printSettings/icon_4page_active.svg';
import { ReactComponent as Icon6pageActive } from 'assets/icons/printSettings/icon_6page_active.svg';
import { ReactComponent as Icon8pageActive } from 'assets/icons/printSettings/icon_8page_active.svg';
import { ReactComponent as IconZActive } from 'assets/icons/printSettings/icon_Z_active.svg';
import { ReactComponent as IconNActive } from 'assets/icons/printSettings/icon_N_active.svg';
import { useStores } from 'stores/RootStore';

type Props = {
  fileId: string;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

const LayoutOption: React.FC<Props> = ({ fileId, onClick }) => {
  const { printJobBuilderStore } = useStores();
  const nUp =
    printJobBuilderStore.getPrintJobByFileId(fileId)?.ticket?.layout.nUp;
  const order =
    printJobBuilderStore.getPrintJobByFileId(fileId)?.ticket?.layout.order;
  return (
    <Container onClick={onClick}>
      {nUp === 1 && <Icon1pageActive />}
      {nUp === 2 && <Icon2pageActive />}
      {nUp === 4 && <Icon4pageActive />}
      {nUp === 6 && <Icon6pageActive />}
      {nUp === 8 && <Icon8pageActive />}
      <Label>{nUp}</Label>
      {order === 'RIGHT_TO_DOWN' ? <IconZActive /> : <IconNActive />}
      <Label>
        {order === 'RIGHT_TO_DOWN' ? '순서:가로 방향' : '순서: 세로 방향'}
      </Label>
      <Icon icon="IconArrowRight" />
    </Container>
  );
};

export default LayoutOption;

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 16px 0;
  padding: 8px 16px;
  height: 56px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #515151;
`;
