import { makeAutoObservable } from 'mobx';
import { jobService } from 'services/job.service';
import { PrintJobStore } from './PrintJobStore';
import { PrintJobBuilderStore } from './PrintJobBuilderStore';
import { ScanCopyJobStore } from './ScanCopyJobStore';
import { ScanCopyJobBuilderStore } from './ScanCopyJobBuilderStore';

type ServiceType = 'prints' | 'scans' | 'copies';

export class UpdateJobStore {
  builderKioskId: string | null = null;
  builderService: ServiceType | null = null;

  constructor(
    public readonly printJobStore: PrintJobStore,
    public readonly scanCopyJobStore: ScanCopyJobStore,
    public readonly printJobBuilderStore: PrintJobBuilderStore,
    public readonly scanCopyJobBuilderStore: ScanCopyJobBuilderStore
  ) {
    makeAutoObservable(this, {});
  }

  async getAllJobs() {
    await this.printJobStore.getPrintJobs();
    await this.scanCopyJobStore.getScanJobs();
    await this.scanCopyJobStore.getCopyJobs();
  }

  get jobLength() {
    return (
      this.printJobStore.groupByKiosk.length +
      this.scanCopyJobStore.getScanJobsGroupByKiosk().length +
      this.scanCopyJobStore.getCopyJobsGroupByKiosk().length
    );
  }

  async deleteAllJobs(serviceName: string, kioskId: string) {
    let jobIds: string[] | undefined;
    switch (serviceName) {
      case 'prints':
        jobIds = this.printJobStore
          .getPrintJobsExistingInThekiosk(kioskId)
          .map(printJob => printJob.id);
        break;
      case 'scans':
        jobIds = this.scanCopyJobStore
          .getScanJobsExistingInThekiosk(kioskId)
          .map(scanJob => scanJob.id);
        break;
      case 'copies':
        jobIds = this.scanCopyJobStore
          .getCopyJobsExistingInThekiosk(kioskId)
          .map(copyJob => copyJob.id);
        break;
    }

    if (!jobIds?.length) throw Error('not exist job id!!');

    return await Promise.all(
      jobIds.map(async jobId => {
        return jobService.deleteOneJob(serviceName, jobId);
      })
    ).finally(() => this.getAllJobs());
  }

  async deleteOneJob(serviceName: ServiceType, jobId: string) {
    await jobService.deleteOneJob(serviceName, jobId);

    switch (serviceName) {
      case 'prints':
        await this.printJobStore.getPrintJobs();
        break;
      case 'scans':
        await this.scanCopyJobStore.getScanJobs();
        break;
      case 'copies':
        await this.scanCopyJobStore.getCopyJobs();
        break;
    }
  }

  async patchJobStatus(serviceType: ServiceType, jobId: string, status: string) {
    await jobService.patchJobStatus(jobId, serviceType, status); 
        
    switch (serviceType) {
      case 'prints':
        await this.printJobStore.getPrintJobs();
        break;
      case 'scans':
        await this.scanCopyJobStore.getScanJobs();
        break;
      case 'copies':
        await this.scanCopyJobStore.getCopyJobs();
        break;
    }
  }

  setBuilderKioskId(kioskId: string | null) {
    this.builderKioskId = kioskId;

    switch (this.builderService) {
      case 'prints':
        this.printJobBuilderStore.setKiosk(kioskId ?? '');
        break;
      case 'scans':
        this.scanCopyJobBuilderStore.setKiosk(kioskId);
        break;
      case 'copies':
        this.scanCopyJobBuilderStore.setKiosk(kioskId);
        break;
    }
  }

  setBuilderService(serviceName: ServiceType | null) {
    this.builderService = serviceName;

    switch (serviceName) {
      case 'prints':
        break;
      case 'scans':
        this.scanCopyJobBuilderStore.setService('scans');
        break;
      case 'copies':
        this.scanCopyJobBuilderStore.setService('copies');
        break;
    }
  }

  clear() {
    this.setBuilderKioskId(null);
    this.setBuilderService(null);
  }
}
