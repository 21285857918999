import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import FullScreenLoader from 'components/common/FullScreenLoader';
import InputBox from 'components/common/input_box/InputBox';
import Modal from 'components/common/modal/Modal';
import PageContainer from 'components/common/PageContainer';
import SolidButton from 'components/common/solid_button/SolidButton';
import Icon from 'components/common/icon/Icon';
import TopBar from 'components/common/top_bar/TopBar';
import { useStores } from 'stores/RootStore';
import { Spacing } from 'styles/styles';
import { PASSWORD_REGEX } from 'constants/Constants';
import { observer } from 'mobx-react-lite';
import useToast from 'hooks/useToast';

const ChangePassword: React.FC = props => {
  const navigation = useNavigate();
  const { userStore, authStore } = useStores();
  const { showToast } = useToast();

  const [pwd, setPwd] = useState('');

  const [newPwd, setNewPwd] = useState('');
  const [pwdInvalid, setPwdInvalid] = useState(false);

  const [pwdMatch, setPwdMatch] = useState('');
  const [pwdMatchInvalid, setPwdMatchInvalid] = useState(false);

  const [completeChange, setCompleteChange] = useState(false);
  const [duplicatedPassword, setDuplicatedPassword] = useState(false);

  const handleChangePassword = () => {
    if (pwd === newPwd) {
      setDuplicatedPassword(true);
      return;
    }
    if (userStore.user && userStore.userId !== null) {
      authStore.isCurrentPassword(pwd).then(res => {
        if (res.data.result === true) {
          authStore
            .changePassword(userStore.userId!, newPwd)
            .then(() => {
              setCompleteChange(true);
            })
            .catch(() => {
              showToast('비밀번호 변경에 실패했습니다');
            })
            .finally(() => {
              return;
            });
        } else {
          showToast('현재 비밀번호를 다시 확인해주세요');
        }
      });
    }
  };

  // password 형식 확인
  useEffect(() => {
    setPwdInvalid(newPwd.match(PASSWORD_REGEX) === null);
  }, [newPwd]);

  // password 확인
  useEffect(() => {
    setPwdMatchInvalid(pwdMatch === newPwd);
  }, [newPwd, pwdMatch]);

  useEffect(() => {
    setPwdInvalid(false);
    setPwdMatchInvalid(false);
  }, []);

  return (
    <PageContainer>
      {authStore.isLoading && <FullScreenLoader message="비밀번호 변경중" />}
      <TopBar title="비밀번호 변경" />
      <InputBox
        value={pwd}
        type="password"
        placeholder="• • • • • • • •"
        label="현재 비밀번호"
        helpText="현재 사용중인 비밀번호를 입력하세요"
        onChange={e => {
          setPwd(e.target.value);
        }}
      />
      <InputBox
        disabled={!pwd}
        value={newPwd}
        type="password"
        placeholder="• • • • • • • •"
        action={pwdInvalid ? 'error' : !newPwd ? 'default' : 'correct'}
        label="새 비밀번호"
        helpText="영문 + 숫자 조합 8자리 이상"
        onChange={e => {
          setNewPwd(e.target.value);
        }}
      />

      <InputBox
        disabled={!pwd}
        value={pwdMatch}
        type="password"
        placeholder="• • • • • • • •"
        action={
          pwdMatch === '' ? 'default' : pwdMatchInvalid ? 'correct' : 'error'
        }
        label="새 비밀번호 확인"
        helpText={
          pwdMatch === ''
            ? '새로운 비밀번호를 한 번 더 입력해주세요'
            : pwdMatchInvalid
            ? '비밀번호가 일치합니다'
            : '비밀번호가 일치하지 않습니다'
        }
        onChange={e => {
          setPwdMatch(e.target.value);
        }}
        onKeyPress={e => {
          if (e.key === 'Enter' && pwd && newPwd && pwdMatchInvalid) {
            handleChangePassword();
          }
        }}
      />

      {userStore.user?.isOnlyKakao && (
        <InfoContainer>
          <KakaoBallon>
            <Icon icon="IconKakaoBallon" size={10} themeColor="black" />
          </KakaoBallon>
          <InfoText>{`기존 카카오로그인을 통해 가입하신 분들은 로그아웃 후, “비밀번호 찾기"를 통해 초기 비밀번호를 설정해주세요!`}</InfoText>
        </InfoContainer>
      )}

      <Spacing.Vertical height={140} />
      <ChangeButton>
        <Spacing.Vertical height={10} />
        <SolidButton
          disabled={!pwd || !newPwd || !pwdMatchInvalid}
          onClick={handleChangePassword}
        >
          비밀번호 변경
        </SolidButton>
      </ChangeButton>

      <Modal
        isOpen={duplicatedPassword}
        onRequestClose={() => setDuplicatedPassword(false)}
        title="비밀번호 중복"
        description={`새 비밀번호가 현재 비밀번호와 동일합니다.\n
        다른 비밀번호를 입력해주세요.`}
        mainActionLabel="확인"
        onMainAction={() => setDuplicatedPassword(false)}
      />

      <Modal
        isOpen={completeChange}
        onRequestClose={() => {
          setCompleteChange(false);
        }}
        title="비밀번호 변경 완료"
        description="비밀번호가 성공적으로 변경되었습니다!"
        mainActionLabel="확인"
        onMainAction={() => {
          navigation(-1);
        }}
      />
    </PageContainer>
  );
};
export default observer(ChangePassword);

const ChangeButton = styled.div`
  width: 100%;
  max-width: 780px;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 0;
  padding: 0 30px 24px 30px;
  background: white;
`;

const InfoContainer = styled.div`
  display: flex;
  gap: 6px;
`;
const KakaoBallon = styled.div`
  display: flex;
  align-items: center;
  height: fit-content;
  padding: 3px;
  background: #fee500;
  border-radius: 4px;
  margin-top: 6px;
`;
const InfoText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #737373;
`;
