/* eslint-disable no-extra-boolean-cast */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ampEvents } from 'amplitude-events';
import trackUser from 'services/trackUser';
import { kioskService } from 'services/kiosk.service';
import { useStores } from 'stores/RootStore';
import PageContainer from 'components/common/PageContainer';
import TopBar from 'components/common/top_bar/TopBar';
import SolidButton from 'components/common/solid_button/SolidButton';
import List from 'components/common/list/List';
import ListHeader from 'components/common/list_header/ListHeader';
import ContextMenu from 'components/common/context_menu/ContextMenu';
import Modal from 'components/common/modal/Modal';
import HashTags from 'components/common/tag/HashTags';
import TopInfo from 'components/common/top_info/TopInfo';
import ImageSwiper from 'components/common/ImageSwiper';
import FullScreenLoader from 'components/common/FullScreenLoader';
import Icon from 'components/common/icon/Icon';
import FixedButtons from 'components/common/solid_button/FixedButtons';
import PrintZoneBadge from 'components/pages/printzone-detail/PrintZoneBadge';
import PrintZoneColorBadge from 'components/pages/printzone-detail/PrintZoneColorBadge';
import PrintZoneServices from 'components/pages/printzone-detail/PrintZoneServices';
import { Spacing } from 'styles/styles';
import { PrintZoneDto } from 'types/kiosk/PrintZone.dto';
import { getServiceSet } from 'utils/utils';
import { SITE_URL } from 'constants/Constants';
import useToast from 'hooks/useToast';
import useHistoryBack from 'hooks/useHistoryBack';

const PrintZoneDetail: React.FC = props => {
  const navigation = useNavigate();
  const { showToast } = useToast();
  const { id } = useParams();
  const {
    stateStore,
    userStore,
    updateJobStore,
    printJobBuilderStore,
    scanCopyJobBuilderStore,
  } = useStores();

  const [printzone, setPrintzone] = useState<PrintZoneDto>();
  const [serviceSet, setServiceSet] = useState<string[]>();

  const [loginModal, setLoginModal] = useState<boolean>(false);
  const [serviceModal, setServiceModal] = useState<boolean>(false);
  const [changeModal, setChangeModal] = useState<boolean>(false);

  const handleCall = (phone: string | undefined) => {
    window.document.location.href = `tel:${phone}`;
  };

  const handleJob = (serviceName: string) => {
    if (!id) throw new Error('not found kiosk id');

    const service =
      serviceName === '인쇄'
        ? 'prints'
        : serviceName === '스캔'
        ? 'scans'
        : 'copies';

    switch (service) {
      case 'prints':
        updateJobStore.setBuilderService('prints');
        break;
      case 'scans':
        updateJobStore.setBuilderService('scans');
        break;
      case 'copies':
        updateJobStore.setBuilderService('copies');
        break;
    }
    moveToService(service);
    setServiceModal(false);
  };

  function moveToService(service: 'prints' | 'scans' | 'copies') {
    if (!id) {
      return stateStore.setError(true);
    }
    updateJobStore.setBuilderKioskId(id);
    if (scanCopyJobBuilderStore.changeKioskMode) {
      scanCopyJobBuilderStore
        .changeKioskToServer()
        .then(() => setChangeModal(true))
        .finally(() => {
          stateStore.endLoading();
        });
      return;
    }

    if (service === 'prints') {
      if (printJobBuilderStore.printJobBuilders.length) {
        navigation('/printwizard3');
      }
      navigation('/printwizard1'); // TODO: 1단계 했으면?
    } else {
      stateStore.startLoading();
      scanCopyJobBuilderStore
        .createToServer()
        .then(() => {
          navigation('/reservation-complete', { replace: true });
        })
        .finally(() => {
          stateStore.endLoading();
        });
    }
  }

  useEffect(() => {
    if (!printzone) return;

    if (printzone.services) {
      setServiceSet(getServiceSet(printzone.services));
    }
  }, [printzone]);

  useEffect(() => {
    if (!id) return;
    stateStore.startLoading();
    kioskService
      .getKioskDetails(id)
      .then(res => {
        setPrintzone(res.data);
      })
      .catch(() => {
        stateStore.setError(true);
      })
      .finally(() => {
        stateStore.endLoading();
      });
  }, []);

  useEffect(() => {
    userStore.updateReload(false);
  }, []);

  const setCenterLocation = () => {
    if (!printzone) return;

    localStorage.setItem('centerLat', printzone.latitude.toString());
    localStorage.setItem('centerLng', printzone.longitude.toString());
    localStorage.setItem('level', '3');
  };

  useHistoryBack(setCenterLocation);

  if (!printzone) {
    return <FullScreenLoader />;
  }

  return (
    <PageContainer>
      <TopBar
        left="back"
        rightNode={
          <>
            <Icon
              icon="IconMap"
              onClick={() => {
                setCenterLocation();
                navigation('/map');
              }}
              size={24}
            />
            <Spacing.Horizontal width={16} />
            <CopyToClipboard
              text={`${SITE_URL.SEARCH_IT}/print-zones/${printzone.id}`}
              onCopy={() => {
                trackUser(ampEvents.PrintZone_Share);
                showToast('주소를 복사했습니다');
              }}
            >
              <Icon icon="IconShare" />
            </CopyToClipboard>
          </>
        }
      />

      {printzone.status === 'MAINTENANCE' && (
        <>
          <MaintenanceBar>
            <Icon icon="IconErrorYellow" size={16} />
            <Spacing.Horizontal width={4} />
            소모품 부족
          </MaintenanceBar>
          <Spacing.Vertical height={56} />
        </>
      )}

      <TitleContainer>
        <TitleTop>
          <PrintZoneBadge isBoba={printzone.isBoba} />
          <PrintZoneColorBadge services={printzone.services} />
        </TitleTop>

        <TopInfo title={printzone.companyName}></TopInfo>

        <HashTags
          tags={printzone.tags.map(tag => {
            return tag.name;
          })}
        />
      </TitleContainer>

      {printzone.imageUrls[0] && (
        <ImageSwiper imageUrls={printzone.imageUrls} isPrintZone={true} />
      )}
      <Spacing.Vertical height={20} />

      <List
        title="주소"
        description={printzone.address || '주소 미제공'}
        right={
          printzone.address && (
            <CopyToClipboard
              text={`${printzone.address}`}
              onCopy={() => {
                if (!printzone.address) return;
                trackUser(ampEvents.PrintZone_CopyAddress);
                showToast('주소를 복사했습니다');
              }}
            >
              <SolidButton kind="lightGray" area="sm">
                주소 복사
              </SolidButton>
            </CopyToClipboard>
          )
        }
      />
      <List
        title="전화번호"
        description={printzone.phoneNumber || '전화번호 미제공'}
        right={
          printzone.phoneNumber && (
            <SolidButton
              kind="lightGray"
              area="sm"
              onClick={() => {
                trackUser(ampEvents.PrintZone_Call);
                handleCall(printzone.phoneNumber!);
              }}
            >
              전화 걸기
            </SolidButton>
          )
        }
      />

      <PrintZoneServices services={printzone.services} />

      {printzone.description?.substring(2) && <ListHeader title="장소 소개" />}

      <LocationInfo>{printzone.description}</LocationInfo>

      <Spacing.Vertical height={100} />

      {printzone.isBoba && (
        <>
          <FixedButtons>
            {printzone.status === 'MAINTENANCE' ? (
              <SolidButton disabled>소모품 부족</SolidButton>
            ) : (
              <SolidButton
                onClick={() => {
                  trackUser(ampEvents.PrintZone_GoToPrint);
                  if (userStore.user) {
                    if (!!updateJobStore.builderService) {
                      // FIXME
                      moveToService(updateJobStore.builderService);
                    } else {
                      setServiceModal(true);
                    }

                    return;
                  }
                  setLoginModal(true);
                }}
              >
                {!scanCopyJobBuilderStore.changeKioskMode
                  ? '프린터 사용하기'
                  : '프린터 변경하기'}
              </SolidButton>
            )}
          </FixedButtons>
        </>
      )}

      {/* Context Menu - select service type */}
      <ContextMenu
        isOpen={serviceModal}
        onRequestClose={() => {
          setServiceModal(false);
        }}
        title="작업 선택"
      >
        {serviceSet &&
          serviceSet.map(serviceName => (
            <ContextItem
              key={serviceName}
              onClick={() => handleJob(serviceName)}
            >
              <Spacing.Horizontal width={12} />
              <ContextLabel active={false}>{serviceName}</ContextLabel>
            </ContextItem>
          ))}
      </ContextMenu>

      {/* modal */}
      <Modal
        isOpen={loginModal}
        onRequestClose={() => {
          setLoginModal(false);
        }}
        description="로그인이 필요한 서비스입니다"
        mainActionLabel="로그인하기"
        mainActionColor="POSITIVE"
        onMainAction={() => {
          navigation('/boba');
        }}
        subActionLabel="취소"
        onSubAction={() => {
          setLoginModal(false);
        }}
      />

      <Modal
        isOpen={changeModal}
        onRequestClose={() => {
          setChangeModal(false);
          navigation('/waiting-list', { replace: true });
        }}
        description="프린터 변경이 완료되었습니다."
        mainActionLabel="확인"
        onMainAction={() => {
          setChangeModal(false);
          navigation('/waiting-list', { replace: true });
        }}
      />
    </PageContainer>
  );
};
export default observer(PrintZoneDetail);

const MaintenanceBar = styled.div`
  position: absolute;
  top: 56px;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 54px;

  background: ${({ theme }) => theme.color.gray100};

  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.gray700};
`;

const TitleContainer = styled.div`
  padding: 16px 0;
`;

const TitleTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LocationInfo = styled.p`
  padding: 12px 0;

  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.gray500};
  white-space: pre-wrap;
`;

const Fade = styled.div`
  z-index: 2;

  position: fixed;
  left: 0;
  bottom: 80px;

  display: flex;
  justify-content: center;

  width: 100%;
  height: 44px;

  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
`;

const ContextItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;

  width: 100%;

  cursor: pointer;
`;

const ContextLabel = styled.p<{ active: boolean }>`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props =>
    props.active ? props.theme.color.primary600 : props.theme.color.gray500};
`;
