import React from 'react';
import styled from 'styled-components';
import Lottie from 'react-lottie-player';
import { PrintJobStatus } from 'types/job/print-job/PrintJobStatus';
import Icon from 'components/common/icon/Icon';
import LottieUploading from 'assets/lottie/LottieUploading.json';
import LottiePrinting from 'assets/lottie/LottiePrinting.json';

interface PrintJobFileStatusProps {
  status: PrintJobStatus;
}

export default function PrintJobFileStatus({
  status,
}: PrintJobFileStatusProps) {
  return (
    <Container>
      {(status === PrintJobStatus.CheckoutCompleted ||
        status === PrintJobStatus.ConvertPending) && (
        <Lottie
          loop
          play
          animationData={LottieUploading}
          style={{
            width: 26,
            height: 26,
          }}
        />
      )}
      {status === PrintJobStatus.Printing && (
        <Lottie
          loop
          play
          animationData={LottiePrinting}
          style={{
            width: 24,
            height: 24,
          }}
        />
      )}
      {status === PrintJobStatus.Done && (
        <Icon icon="IconCheckCircle" themeColor="primary600" />
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 26px;
  height: 26px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
