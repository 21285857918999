import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useStores } from 'stores/RootStore';
import FullScreenLoader from '../common/FullScreenLoader';
import List from '../common/list/List';
import { observer } from 'mobx-react-lite';
import { getFileLabel } from 'utils/file.util';
import { Spacing } from 'styles/styles';
import ImageList from '../common/ImageList';
import Icon from 'components/common/icon/Icon';
import { FileDocument } from 'stores/file/File';
import { ampEvents } from 'amplitude-events';
import trackUser from 'services/trackUser';

type Props = {
  findText?: string;
};

const MyContents: React.FC<Props> = ({ findText }) => {
  const { fileStore, userStore } = useStores();

  const contents: FileDocument[] = fileStore.files
    .filter(file => file.origin === 'Market')
    .filter(file => {
      if (findText) {
        return file.name.includes(findText.trim());
      }
      return file;
    });

  useEffect(() => {
    fileStore
      .loadFiles()
      .then(() => {
        fileStore.setSortMode(fileStore.sortMode);
        fileStore.setSortDescending(fileStore.sortDescending);
      })
      .catch(() => {
        userStore.showErrorModal();
      });
    fileStore.setSelectMode(false);
  }, []);

  return (
    <>
      {fileStore.isLoading && <FullScreenLoader message="파일 로드중" />}

      {/* list view */}
      {fileStore.viewMode === 'list' &&
        (contents.length > 0 ? (
          contents.map(file => (
            <List
              key={file.id}
              onLeftClick={() => {
                {
                  !fileStore.selectMode &&
                    fileStore.setSelectMode(!fileStore.selectMode);
                }
                fileStore.updateSelectedFile(file.id);
              }}
              select={fileStore.selectMode ? 'check' : 'none'}
              checked={fileStore.selectedFile.includes(file.id)}
              onSelect={() => {}}
              visual="image"
              imageUrl={file.imageUrls && file.imageUrls[0]}
              title={file.name}
              description={getFileLabel(file, 'list')}
              action={fileStore.selectMode ? 'none' : 'more'}
              onActionClick={e => {
                fileStore.setOptionMode(true);
                fileStore.setOptionModeFile(
                  file.id,
                  file.name,
                  file.origin,
                  file.errorType
                );
              }}
            />
          ))
        ) : (
          <NoFilesView
            isFilesEmpty={
              fileStore.files.filter(file => file.origin === 'Market')
                .length === 0
            }
          />
        ))}

      {fileStore.viewMode === 'grid' &&
        (contents.length > 0 ? (
          <>
            <Spacing.Vertical height={40} />
            <ImageListContainer>
              {contents.map(file => (
                <ImageList
                  key={file.id}
                  onClick={() => {
                    trackUser(ampEvents.DocumentBox_SelectDocument);

                    {
                      !fileStore.selectMode &&
                        fileStore.setSelectMode(!fileStore.selectMode);
                    }
                    fileStore.updateSelectedFile(file.id);
                  }}
                  active={fileStore.selectedFile.includes(file.id)}
                  title={file.name}
                  subTitle={getFileLabel(file, 'image')}
                  imageUrl={file.imageUrls && file.imageUrls[0]}
                  onOptionClick={e => {
                    e.stopPropagation();
                    if (!fileStore.selectMode) fileStore.setOptionMode(true);
                    fileStore.setOptionModeFile(
                      file.id,
                      file.name,
                      file.origin,
                      file.errorType
                    );
                  }}
                />
              ))}
            </ImageListContainer>
          </>
        ) : (
          <NoFilesView
            isFilesEmpty={
              fileStore.files.filter(file => file.origin === 'Market')
                .length === 0
            }
          />
        ))}
      <Spacing.Vertical height={80} />
    </>
  );
};

const NoFilesView: React.FC<{ isFilesEmpty: boolean }> = ({ isFilesEmpty }) => {
  if (isFilesEmpty) {
    return (
      <>
        <LogoContainer>
          <Logo>
            <Icon icon="Icon3DEmptyFolder" size={200} />
          </Logo>
          <Title>구매한 콘텐츠가 없습니다</Title>
          <Spacing.Vertical height={57} />
        </LogoContainer>
      </>
    );
  }

  return (
    <NoSearchFile>
      <Icon icon="Icon3DNotFoundFile" size={200} />
      <Spacing.Vertical height={30} />
      <NoSearchFileText>검색 결과가 존재하지 않습니다</NoSearchFileText>
    </NoSearchFile>
  );
};

export default observer(MyContents);

const ImageListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  width: 100%;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75vh;
`;

const Logo = styled.div`
  margin: 41px 80px 32px 80px;
`;

const Title = styled.p`
  font-size: 18px;
  font-weight: 500;
  color: #1d1d1d;
  margin-bottom: 8px;
`;

const NoSearchFile = styled.div`
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const NoSearchFileText = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
`;
