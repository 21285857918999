import Alarms from '../pages/home/Alarms';
import Home from '../pages/home/Home';
import Accountsettings from '../pages/menu/Accountsettings';
import Boba from '../pages/login/Boba';
import Cards from '../pages/menu/Cards';
import ChangePassword from '../pages/menu/ChangePassword';
import ChangePhoneNumber1 from '../pages/menu/ChangePhoneNumber1';
import ChangePhoneNumber2 from '../pages/menu/ChangePhoneNumber2';
import Contents from '../pages/content/Contents';
import CouponCharge from '../pages/menu/CouponCharge';
import CustomerService from '../pages/menu/CustomerService';
import Faq from '../pages/menu/Faq';
import FindEmailPassword from '../pages/login/FindEmailPassword';
import Login from '../pages/login/Login';
import Map from '../pages/map/Map';
import Menu from '../pages/menu/Menu';
import Notices from '../pages/menu/Notices';
import PointCharge from '../pages/menu/PointCharge';
import Print from '../pages/print/Print';
import PrintHistoryDetail from '../pages/menu/PrintHistoryDetail';
import Register1 from '../pages/login/Register1';
import Register2 from '../pages/login/Register2';
import Register3 from '../pages/login/Register3';
import RegisterCard from '../pages/menu/RegisterCard';
import Term1 from '../pages/menu/Term1';
import Term2 from '../pages/menu/Term2';
import Term3 from '../pages/menu/Term3';
import Term4 from '../pages/menu/Term4';
import Term5 from '../pages/menu/Term5';
import Terms from '../pages/menu/Terms';
import UsageHistory from '../pages/menu/UsageHistory';
import Withdrawal from '../pages/menu/Withdrawal';
import ContentHistoryDetail from '../pages/menu/ContentHistoryDetail';
import NoticeDetail from '../pages/menu/NoticeDetail';
import PritnZoneDetail from '../pages/map/PrintZoneDetail';
import MyFileDocuments from '../pages/print/MyFileDocuments';
import ContentDetail from '../pages/content/ContentDetail';
import ContentPurchase from '../pages/content/ContentPurchase';
import ContentCompleted from '../pages/content/ContentCompleted';
import PrintWizard1 from '../pages/print/PrintWizard1';
import PrintWizard2 from '../pages/print/PrintWizard2';
import PrintWizard3 from '../pages/print/PrintWizard3';
import Preview from '../pages/print/Preview';
import PrintWaitingList from '../pages/print/PrintWaitingList';
import PrintWizardCompleted from '../pages/print/PrintWizardCompleted';
import PrintWizard3Edit from '../pages/print/PrintWizard3Edit';
import ErrorFileInfo from '../pages/print/ErrorFileInfo';
import PrintJobFail from '../pages/print/PrintJobFail';
import ResetPassword from '../pages/login/ResetPassword';
import KakaoLogin from '../pages/login/KakaoLogin';
import RegisterOption from '../pages/login/RegisterOption';
import SelectKiosk from '../pages/print/SelectKiosk';
import ReservationComplete from '../pages/print/ReservationComplete';
import VerifyKiosk from '../pages/print/VerifyKiosk';
import JobComplete from '../pages/print/JobComplete';
import JobInProgress from '../pages/print/JobInProgress';
import JobError from '../pages/print/JobError';
import JobErrorReport from '../pages/print/JobErrorReport';
import WaitingListDetail from '../pages/print/WaitingListDetail';
import WaitingList from '../pages/print/WaitingList';
import JobPayment from '../pages/print/JobPayment';
import JobWorking from '../pages/print/JobWorking';
import PrintZoneFinder from 'pages/map/PrintZoneFinder';

type RouterType = {
  path: string;
  element: React.FC;
  mustLogin: boolean;
};

export const RouterPaths: RouterType[] = [
  { path: '/', element: Home, mustLogin: false },
  { path: '/alarms', element: Alarms, mustLogin: true },
  { path: '/contents', element: Contents, mustLogin: false },
  { path: '/contents/:id', element: ContentDetail, mustLogin: false },
  {
    path: '/contents/:id/purchase',
    element: ContentPurchase,
    mustLogin: true,
  },
  {
    path: '/contents/:id/completed',
    element: ContentCompleted,
    mustLogin: true,
  },
  { path: '/print', element: Print, mustLogin: true },
  { path: '/printwizard1', element: PrintWizard1, mustLogin: true },
  { path: '/printwizard2', element: PrintWizard2, mustLogin: true },
  { path: '/printwizard3/:idx', element: PrintWizard3, mustLogin: true },
  {
    path: '/printwizard3edit/:id',
    element: PrintWizard3Edit,
    mustLogin: true,
  },
  {
    path: '/printwizardcompleted',
    element: PrintWizardCompleted,
    mustLogin: true,
  },
  {
    path: '/waiting-list/prints/:id',
    element: PrintWaitingList,
    mustLogin: true,
  },
  { path: '/printjobfail/:id', element: PrintJobFail, mustLogin: true },
  { path: '/myfiledocuments', element: MyFileDocuments, mustLogin: true },
  { path: '/errorfileinfo', element: ErrorFileInfo, mustLogin: true },
  { path: '/files/:id/preview', element: Preview, mustLogin: true },
  { path: '/printjobs/:id/preview', element: Preview, mustLogin: true },
  {
    path: '/printjobbuilders/:id/preview',
    element: Preview,
    mustLogin: true,
  },
  { path: '/map', element: Map, mustLogin: false },
  { path: '/printzone/search', element: PrintZoneFinder, mustLogin: false },
  { path: '/printzone/:id', element: PritnZoneDetail, mustLogin: false },
  { path: '/menu', element: Menu, mustLogin: true },
  { path: '/accountsettings', element: Accountsettings, mustLogin: true },
  {
    path: '/accountsettings/changepassword',
    element: ChangePassword,
    mustLogin: true,
  },
  {
    path: '/accountsettings/changephone1',
    element: ChangePhoneNumber1,
    mustLogin: true,
  },
  {
    path: '/accountsettings/changephone2',
    element: ChangePhoneNumber2,
    mustLogin: true,
  },
  { path: '/withdrawal', element: Withdrawal, mustLogin: true },
  { path: '/cards', element: Cards, mustLogin: true },
  { path: '/cards/register', element: RegisterCard, mustLogin: true },
  { path: '/point', element: PointCharge, mustLogin: true },
  { path: '/coupon', element: CouponCharge, mustLogin: true },
  { path: '/usagehistory', element: UsageHistory, mustLogin: true },
  {
    path: '/printhistory/:historyUid',
    element: PrintHistoryDetail,
    mustLogin: true,
  },
  {
    path: '/contenthistory/:historyUid',
    element: ContentHistoryDetail,
    mustLogin: true,
  },
  { path: '/customerservice', element: CustomerService, mustLogin: true },
  { path: '/notices', element: Notices, mustLogin: true },
  { path: '/notices/:noticeId', element: NoticeDetail, mustLogin: true },
  { path: '/faq', element: Faq, mustLogin: true },
  { path: '/terms', element: Terms, mustLogin: true },
  { path: '/term1', element: Term1, mustLogin: true },
  { path: '/term2', element: Term2, mustLogin: true },
  { path: '/term3', element: Term3, mustLogin: true },
  { path: '/term4', element: Term4, mustLogin: true },
  { path: '/term5', element: Term5, mustLogin: true },
  { path: '/boba', element: Boba, mustLogin: false },
  { path: '/login', element: Login, mustLogin: true },
  { path: '/login-kakao', element: KakaoLogin, mustLogin: false },
  { path: '/login/find', element: FindEmailPassword, mustLogin: true },
  { path: '/login/reset-password', element: ResetPassword, mustLogin: true },
  { path: '/register1', element: Register1, mustLogin: true },
  { path: '/register2', element: Register2, mustLogin: true },
  { path: '/register3', element: Register3, mustLogin: true },
  { path: '/register/option', element: RegisterOption, mustLogin: true },
  { path: '/select-kiosk', element: SelectKiosk, mustLogin: true },
  {
    path: '/reservation-complete',
    element: ReservationComplete,
    mustLogin: true,
  },
  { path: '/waiting-list', element: WaitingList, mustLogin: true },
  { path: '/waiting-list/:id', element: WaitingListDetail, mustLogin: true },
  { path: '/verify-kiosk', element: VerifyKiosk, mustLogin: true },
  { path: '/job-payment', element: JobPayment, mustLogin: false },
  { path: '/job-working', element: JobWorking, mustLogin: true },
  { path: '/job-in-progress', element: JobInProgress, mustLogin: true },
  { path: '/job-error', element: JobError, mustLogin: true },
  { path: '/job-error/report', element: JobErrorReport, mustLogin: true },
  { path: '/job-complete', element: JobComplete, mustLogin: true },
];
