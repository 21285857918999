import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Spacing } from 'styles/styles';

const UsageHistorySkeleton: React.FC = props => {
  return (
    <>
      <Spacing.Vertical height={20} />
      <Skeleton width={44} height={26} />
      <Spacing.Vertical height={8} />
      <Skeleton width={150} height={22} />
      <Spacing.Vertical height={4} />
      <Skeleton width={150} height={15} />
    </>
  );
};

export default UsageHistorySkeleton;
