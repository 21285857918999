import React, { useState } from 'react';
import styled from 'styled-components';
import FullScreenLoader from 'components/common/FullScreenLoader';
import InputBox from 'components/common/input_box/InputBox';
import List from 'components/common/list/List';
import Modal from 'components/common/modal/Modal';
import PageContainer from 'components/common/PageContainer';
import SolidButton from 'components/common/solid_button/SolidButton';
import TopBar from 'components/common/top_bar/TopBar';
import Icon from 'components/common/icon/Icon';
import TopInfo from 'components/common/top_info/TopInfo';
import { useStores } from 'stores/RootStore';
import { Spacing } from 'styles/styles';
import { observer } from 'mobx-react-lite';
import * as Sentry from '@sentry/react';
import { ApiError } from 'types/error/ApiError';
import useToast from 'hooks/useToast';
import useHistoryBack from 'hooks/useHistoryBack';

const Withdrawal: React.FC = props => {
  const { userStore, authStore } = useStores();
  const { showToast } = useToast();

  const [pwd, setPwd] = useState('');

  // 모달창 상태
  const [passwordWrong, setPasswordWrong] = useState(false);
  const [showWithdrawal, setShowWithdrawal] = useState(false);
  const [WithdrawalComplete, setWithdrawalComplete] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleCurrentPwd = () => {
    setIsLoading(true);
    authStore
      .isCurrentPassword(pwd)
      .then(res => {
        if (res.data.result) {
          setShowWithdrawal(true);
        } else {
          setPasswordWrong(true);
        }
      })
      .catch(() => {
        setPasswordWrong(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleWithdrawal = () => {
    setShowWithdrawal(false);
    authStore
      .withdrawal()
      .then(() => {
        setWithdrawalComplete(true);
      })
      .catch(err => {
        Sentry.captureException(new ApiError(err, 'withdrawalError'));
        showToast('회원탈퇴에 실패했습니다');
      });
  };

  const completeWithdrawal = async () => {
    userStore.logout();
    setWithdrawalComplete(false);
  };

  useHistoryBack(async () => {
    if (WithdrawalComplete) {
      await userStore.logout();
    }
  });

  return (
    <PageContainer>
      {authStore.isLoading && <FullScreenLoader />}
      {isLoading && <FullScreenLoader />}
      <TopBar title="회원탈퇴" />
      <TopInfo title={userStore.user?.name} />
      <List title="이메일" text={userStore.user?.email} />
      <InputBox
        type="password"
        label="비밀번호"
        value={pwd}
        helpText="현재 사용중인 비밀번호를 입력하세요"
        onChange={e => {
          setPwd(e.target.value);
        }}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            handleCurrentPwd();
          }
        }}
      />

      {userStore.user?.isOnlyKakao && (
        <InfoContainer>
          <KakaoBallon>
            <Icon icon="IconKakaoBallon" size={10} themeColor="black" />
          </KakaoBallon>
          <InfoText>{`기존 카카오로그인을 통해 가입하신 분들은 로그아웃 후, “비밀번호 찾기"를 통해 초기 비밀번호를 설정해주세요!`}</InfoText>
        </InfoContainer>
      )}

      <WithdrawalButton>
        <Slogan>회원 탈퇴시 내 문서에 업로드한 파일,</Slogan>
        <Slogan>이용 내역, 포인트 등 사용자 정보가</Slogan>
        <Slogan>모두 삭제됩니다</Slogan>
        <Spacing.Vertical height={22} />
        <SolidButton disabled={!pwd} kind="lightRed" onClick={handleCurrentPwd}>
          회원탈퇴
        </SolidButton>
      </WithdrawalButton>

      {/* Modal1: 비밀번호 오류 */}
      <Modal
        isOpen={passwordWrong}
        onRequestClose={() => {
          setPwd('');
          setPasswordWrong(false);
        }}
        description="비밀번호를 다시 확인해주세요"
        mainActionLabel="확인"
        onMainAction={() => {
          setPwd('');
          setPasswordWrong(false);
        }}
      />

      {/* Modal2: 회원탈퇴 */}
      <Modal
        isOpen={showWithdrawal}
        onRequestClose={() => {
          setShowWithdrawal(false);
        }}
        title="회원 탈퇴"
        description="정말 회원탈퇴 하시겠습니까?"
        mainActionLabel="탈퇴하기"
        mainActionColor="NEGATIVE"
        onMainAction={handleWithdrawal}
        subActionLabel="취소"
        onSubAction={() => {
          setShowWithdrawal(false);
        }}
      />

      {/* Modal3: 회원탈퇴 완료 */}
      <Modal
        isOpen={WithdrawalComplete}
        onRequestClose={completeWithdrawal}
        description={`회원 탈퇴가 완료되었습니다\n그동안 보바를 이용해주셔서 감사합니다`}
        mainActionLabel="확인"
        onMainAction={completeWithdrawal}
      />
    </PageContainer>
  );
};

export default observer(Withdrawal);

const WithdrawalButton = styled.div`
  width: 100%;
  max-width: 780px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 0;
  padding: 0 30px 24px 30px;
  background: white;
`;
const Slogan = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #404040;
`;
const InfoContainer = styled.div`
  display: flex;
  gap: 6px;
`;
const KakaoBallon = styled.div`
  display: flex;
  align-items: center;
  height: fit-content;
  padding: 3px;
  background: #fee500;
  border-radius: 4px;
  margin-top: 6px;
`;
const InfoText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #737373;
`;
