import React, { useEffect } from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useInView } from 'react-intersection-observer';
import { ampEvents } from 'amplitude-events';
import trackUser from 'services/trackUser';
import { CONTENT_API } from 'services/contents.service';
import { Spacing } from 'styles/styles';
import { useStores } from 'stores/RootStore';
import { ContentLineDto } from 'types/contents/ContentLine.dto';
import BottomNavbar from 'components/common/BottomNavbar';
import PageContainer from 'components/common/PageContainer';
import TopInfo from 'components/common/top_info/TopInfo';
import Icon from 'components/common/icon/Icon';
import CustomTagList from 'components/common/CustomTagList';
import ErrorPage from 'components/common/ErrorPage';
import ContentList from 'components/pages/contents/ContentList';
import ContentListSkeleton from 'components/pages/contents/ContentListSkeleton';
import SearchContents from 'components/pages/contents/SearchContents';
import useHistoryBack from 'hooks/useHistoryBack';

// TODO: 1회 출력, 평생소장

const Contents: React.FC = props => {
  const navigation = useNavigate();
  const queryClient = useQueryClient();
  const [ref, inView] = useInView();
  const { userStore, contentStore } = useStores();
  const { state } = useLocation() as { state: string };

  const LIMIT = 8;

  async function getContents({ pageParam = 0 }) {
    const params = {
      q: contentStore.findText ?? '',
      offset: pageParam,
      limit: LIMIT,
    };
    const res = await CONTENT_API.getContents(params);
    return res.data;
  }

  const {
    data: contents,
    error,
    fetchNextPage,
    isLoading,
  } = useInfiniteQuery('contents', getContents, {
    getNextPageParam: (_, allPages) => {
      return allPages.length * LIMIT;
    },
    staleTime: 5000,
  });

  useEffect(() => {
    queryClient.resetQueries('contents');
  }, [contentStore.findText]);

  useEffect(() => {
    if (inView) fetchNextPage();
  }, [inView]);

  useEffect(() => {
    userStore.updateReload(false);
  }, []);

  useHistoryBack(() => {
    if (state == null && contentStore.findText.length) {
      navigation('/contents');
    }
    contentStore.setFindText('');
  });

  if (error) {
    return <ErrorPage />;
  }

  return (
    <>
      <PageContainer>
        <TopContainer>
          <TopInfo
            leftNode={
              contentStore.findText && (
                <>
                  <Icon icon="IconArrowLeft" onClick={() => navigation(-1)} />
                  <Spacing.Horizontal width={16} />
                </>
              )
            }
            title="콘텐츠"
          />
        </TopContainer>
        <Spacing.Vertical height={64} />

        <SearchContents />

        {!contentStore.findText && (
          <>
            <CustomTagList beforePage="CONTENTS" />
            <Spacing.Vertical height={21} />
            <MonthlyTitle>이번 주 추천 콘텐츠</MonthlyTitle>
          </>
        )}

        {!isLoading && contents?.pages[0].length === 0 && (
          <InfoContainer>
            <Slogan>검색결과가 없습니다</Slogan>
            <Spacing.Vertical height={20} />

            <Title>태그검색</Title>
            <Example>#모의고사 #종이접기</Example>
          </InfoContainer>
        )}

        <>
          <ContentsContainer>
            {isLoading ? (
              <>
                <ContentListSkeleton />
                <ContentListSkeleton />
              </>
            ) : (
              contents &&
              contents?.pages.map((group, i) => (
                <React.Fragment key={i}>
                  {group.map((content: ContentLineDto) => (
                    <ContentList
                      key={content.id}
                      id={content.id}
                      title={content.title}
                      author={content.author}
                      tags={content.tags}
                      imageUrl={content.imageUrl}
                      totalPrice={content.totalPrice}
                    />
                  ))}
                </React.Fragment>
              ))
            )}
          </ContentsContainer>

          {/* 무한스크롤: 아래 영역이 보일시 추가 데이터 요청 */}
          <div ref={ref} />
        </>
        <Spacing.Vertical height={50} />
      </PageContainer>
      <BottomNavbar />
    </>
  );
};

export default observer(Contents);

const TopContainer = styled.div`
  z-index: 10;

  position: fixed;
  top: 0;

  padding-right: 48px;

  width: 100%;
  max-width: 780px;

  background: white;
`;

const MonthlyTitle = styled.p`
  padding: 16px 0;
  height: 22px;

  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  color: #515151;
`;

const ContentsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  flex-wrap: wrap;
  gap: 20px;

  padding: 24px 0;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 40vh;
`;

const Title = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  color: #646464;
`;

const Slogan = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 160%;
  color: #484848;
`;

const Example = styled.p`
  margin-top: 2px;

  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #9c9c9c;
`;
