import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores/RootStore';
import trackUser from 'services/trackUser';
import { ampEvents } from 'amplitude-events';
import InputBox from 'components/common/input_box/InputBox';
import PageContainer from 'components/common/PageContainer';
import FixedButtons from 'components/common/solid_button/FixedButtons';
import SolidButton from 'components/common/solid_button/SolidButton';
import FullScreenLoader from 'components/common/FullScreenLoader';
import Modal from 'components/common/modal/Modal';
import Icon from 'components/common/icon/Icon';
import { ErrorType } from 'types/job/ErrorType.enum';

export default observer(function JobErrorReport() {
  const navigation = useNavigate();
  const { stateStore, jobPollingStore } = useStores();

  const [type, setType] = useState<ErrorType | undefined>(undefined);
  const [open, setOpen] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleValue = (key: ErrorType) => {
    setType(key);
    setOpen(false);
  };

  const handleReportError = () => {
    trackUser(ampEvents.JobError_Submit);
    jobPollingStore
      .reportError(
        jobPollingStore.jobIds!,
        jobPollingStore.serviceType!,
        type,
        message
      )
      .then(() => {
        setOpenModal(true);
      })
      .catch(() => {
        stateStore.setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <PageContainer>
      {loading && <FullScreenLoader message="오류 상황 제출중" />}
      <Title>
        <p>오류 상황이 발생했나요?</p>
      </Title>

      <Label>오류 상황 선택</Label>
      <SelectWrapper onClick={() => setOpen(!open)}>
        <Select open={open}>
          {type ?? '선택'}
          <Options open={open}>
            {Object.values(ErrorType).map(errorType => (
              <Option
                key={errorType}
                selected={type === errorType}
                onClick={() => handleValue(errorType)}
              >
                {errorType}
              </Option>
            ))}
          </Options>
        </Select>
        <StyledIcon icon="IconArrowDown" size={20} open={open} />
      </SelectWrapper>

      <InputBox
        label="무슨 오류가 발생했나요? (선택)"
        value={message}
        placeholder="메시지 입력"
        onChange={e => {
          setMessage(e.target.value);
        }}
        onKeyPress={e => {
          if (e.key === 'Enter' && type) {
            handleReportError;
          }
        }}
      />

      <FixedButtons>
        <div>
          <HelpText>이용에 불편을 드려 죄송합니다</HelpText>
          <HelpText> 문제를 신속히 파악하고 해결해 드리겠습니다</HelpText>
        </div>
        <SolidButton disabled={!type} onClick={handleReportError}>
          제출하기
        </SolidButton>
      </FixedButtons>

      <Modal
        isOpen={openModal}
        onRequestClose={() => {
          navigation('/', { replace: true });
          setOpenModal(false);
          jobPollingStore.clearStore();
        }}
        description="제출이 완료되었습니다."
        mainActionLabel="확인"
        onMainAction={() => {
          navigation('/', { replace: true });
          setOpenModal(false);
          jobPollingStore.clearStore();
        }}
      />
    </PageContainer>
  );
});

const Title = styled.div`
  padding: 65px 0 46px 0;
  p {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.03em;
    color: ${({ theme }) => theme.color.gray900};
  }
`;
const Label = styled.label`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.gray500};
`;
const SelectWrapper = styled.div`
  position: relative;
  display: flex;
`;
const StyledIcon = styled(Icon)<{ open: boolean }>`
  position: absolute;
  top: 34px;
  right: 16px;
  ${props => props.open && 'transform: rotate(180deg);'}
`;
const Select = styled.div<{ open: boolean }>`
  position: relative;
  width: 100%;
  margin: 16px 0;
  padding: 12px 16px;
  border-radius: ${props => (props.open ? '10px 10px 0 0' : '10px')};
  border: 1px solid #ebebeb;
  outline: none;
  font-size: 16px;
  line-height: 24px;
  ${props => props.open && `color: ${props.theme.color.primary500};`};
  cursor: pointer;
  &:focus {
    border: 1px solid ${({ theme }) => theme.color.primary600};
    box-shadow: 0px 0px 0px 2px #e6f2fe;
  }
`;
const Options = styled.div<{ open: boolean }>`
  ${props => !props.open && `visibility: hidden;`}
  position: absolute;
  left: -1px;
  top: 32px;
  padding: 14px 16px;
  width: calc(100% + 2px);
  background: ${({ theme }) => theme.color.white};
  display: flex;
  flex-direction: column;
  border: 1px solid #ebebeb;
  border-top: none;
  border-radius: 0 0 10px 10px;
`;
const Option = styled.div<{ selected: boolean }>`
  ${props => props.selected && 'display: none;'}
  color: ${({ theme }) => theme.color.gray900};
  padding: 8px 0;
`;
const HelpText = styled.p`
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.gray500};
`;
