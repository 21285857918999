import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import FullScreenLoader from 'components/common/FullScreenLoader';
import PageContainer from 'components/common/PageContainer';
import Icon from 'components/common/icon/Icon';
import ErrorPage from 'components/common/ErrorPage';
import FixedButtons from 'components/common/solid_button/FixedButtons';
import FullPageContainer from 'components/common/FullPageContainer';
import TopBar from 'components/common/top_bar/TopBar';
import KakaoLoginButton from 'components/pages/login/KakaoLoginButton';
import ButtonToMoveLogin from 'components/pages/login/ButtonToMoveLoginPage';
import { useStores } from 'stores/RootStore';
import { Spacing } from 'styles/styles';
import useHistoryBack from 'hooks/useHistoryBack';

const Boba: React.FC = () => {
  const navigation = useNavigate();
  const { userStore, kakaoStore } = useStores();
  const location = useLocation();
  const KAKAO_CODE = location.search.split('=')[1];
  const [error, setError] = useState();

  useHistoryBack(() => {
    navigation('/menu', { replace: true });
  });

  useEffect(() => {
    // userStore.user 로 이미 로그인되어있는지 확인
    if (userStore.user || KAKAO_CODE === undefined) {
      return;
    }
    kakaoStore
      .signUpOrConnect(KAKAO_CODE)
      .then(() => {
        navigation('/login-kakao');
      })
      .catch(err => {
        setError(err);
      });

    return () => {
      window.removeEventListener('message', () => {});
    };
  }, [KAKAO_CODE]);

  useEffect(() => {
    userStore.updateReload(false);
    if (userStore.user) navigation('/', { replace: true });
  }, []);

  if (userStore.isLoading || kakaoStore.isLoading) {
    return (
      <PageContainer>
        <FullScreenLoader />
      </PageContainer>
    );
  }

  if (error) {
    return <ErrorPage />;
  }

  return (
    <FullPageContainer>
      <TopBar />
      <LogoContainer>
        <Logo>
          <Icon icon="Icon3DBoba" size={200} />
        </Logo>
        <Title>항상 곁에 있는 프린트 - 보바</Title>
        <Slogan>간단하게 터치 몇 번으로</Slogan>
        <Slogan>내 주변 어디서든 프린트를 해보세요</Slogan>
        <Spacing.Vertical height={57} />
      </LogoContainer>

      <FixedButtons>
        {userStore.isKorea && <KakaoLoginButton />}
        <ButtonToMoveLogin>로그인 및 회원가입</ButtonToMoveLogin>
      </FixedButtons>
    </FullPageContainer>
  );
};

export default observer(Boba);

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75vh;
`;

const Logo = styled.div`
  margin: 41px 80px 32px 80px;
`;

const Title = styled.p`
  font-size: 18px;
  font-weight: 500;
  color: #1d1d1d;
  margin-bottom: 8px;
`;

const Slogan = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: #767676;
`;
