import styled from 'styled-components';

export const Spacing = {
  Vertical: styled.div<{ height: number }>`
    height: ${({ height }) => height}px;
  `,
  Horizontal: styled.div<{ width: number }>`
    width: ${({ width }) => width}px;
  `,
};
