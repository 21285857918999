import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ampEvents } from 'amplitude-events';
import trackUser from 'services/trackUser';
import { ContentLineDto } from 'types/contents/ContentLine.dto';
import { Spacing } from 'styles/styles';

const ContentList: React.FC<ContentLineDto> = ({
  id,
  title,
  author,
  tags,
  imageUrl,
  totalPrice,
}) => {
  const navigation = useNavigate();

  return (
    <ContentListContainer
      onClick={() => {
        trackUser(ampEvents.Contents_Item);
        navigation(`/contents/${id}`);
      }}
    >
      <ContentImg imageUrl={imageUrl} />
      <Spacing.Vertical height={12} />

      <BadgeContainer>
        {tags.map(tag => (
          <HashTag key={tag.id}>{tag.name}</HashTag>
        ))}
      </BadgeContainer>
      <Spacing.Vertical height={8} />

      <ContentTitle>{title}</ContentTitle>

      <ContentAuthor>판매자 : {author}</ContentAuthor>
      <Spacing.Vertical height={4} />

      <ContentPrice>
        {totalPrice === 0 ? '무료' : `${totalPrice}원`}
      </ContentPrice>
      <Spacing.Vertical height={12} />
    </ContentListContainer>
  );
};

export default ContentList;

const ContentListContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 16px;

  width: 144px;
`;

const ContentImg = styled.div<{ imageUrl: string }>`
  margin-bottom: 4px;

  width: 100%;
  height: 144px;

  background: url('${props => props.imageUrl}');
  background-size: cover;
  background-position: center;

  border-radius: 8px;
  box-shadow: 0px 0px 30px #00000014;
`;

const ContentTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const ContentAuthor = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #737373;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const ContentPrice = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
`;

const BadgeContainer = styled.div`
  display: flex;
  gap: 8px;

  line-height: 24px;

  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const HashTag = styled.div`
  padding: 2px 8px;

  height: 24px;

  background: #f5f5f5;
  border-radius: 20px;

  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #737373;
  white-space: nowrap;
`;
