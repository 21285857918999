import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ContextMenu from 'components/common/context_menu/ContextMenu';
import { ReactComponent as Icon1pageActive } from 'assets/icons/printSettings/icon_1page_active.svg';
import { ReactComponent as Icon1pageInActive } from 'assets/icons/printSettings/icon_1page_inactive.svg';
import { ReactComponent as Icon2pageActive } from 'assets/icons/printSettings/icon_2page_active.svg';
import { ReactComponent as Icon2pageInActive } from 'assets/icons/printSettings/icon_2page_inactive.svg';
import { ReactComponent as Icon4pageActive } from 'assets/icons/printSettings/icon_4page_active.svg';
import { ReactComponent as Icon4pageInActive } from 'assets/icons/printSettings/icon_4page_inactive.svg';
import { ReactComponent as Icon6pageActive } from 'assets/icons/printSettings/icon_6page_active.svg';
import { ReactComponent as Icon6pageInActive } from 'assets/icons/printSettings/icon_6page_inactive.svg';
import { ReactComponent as Icon8pageActive } from 'assets/icons/printSettings/icon_8page_active.svg';
import { ReactComponent as Icon8pageInActive } from 'assets/icons/printSettings/icon_8page_inactive.svg';
import { ReactComponent as IconZActive } from 'assets/icons/printSettings/icon_Z_active.svg';
import { ReactComponent as IconZInActive } from 'assets/icons/printSettings/icon_Z_inactive.svg';
import { ReactComponent as IconNActive } from 'assets/icons/printSettings/icon_N_active.svg';
import { ReactComponent as IconNInActive } from 'assets/icons/printSettings/icon_N_inactive.svg';
import OptionButton from './OptionButton';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores/RootStore';
import { NUp } from 'types/job/print-job/print-ticket/NUp';
import { LayoutOrder } from 'types/job/print-job/print-ticket/LayoutOrder';
import SolidButton from 'components/common/solid_button/SolidButton';
import { Spacing } from 'styles/styles';

type Props = {
  fileId: string;
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const LayoutContextMenu: React.FC<Props> = ({ fileId, isOpen, setOpen }) => {
  const { printJobBuilderStore } = useStores();

  const [NUp, setNUp] = useState<NUp>(1);
  const [order, setOrder] = useState<LayoutOrder>(LayoutOrder.RIGHT_TO_DOWN);

  const handleNUp = (option: NUp) => {
    setNUp(option);
  };

  const handleOrder = (option: LayoutOrder) => {
    setOrder(option);
  };

  const handleLayout = (order: LayoutOrder, nUp: NUp) => {
    const printOption =
      printJobBuilderStore.getPrintJobByFileId(fileId)?.ticket;
    if (printOption) {
      printJobBuilderStore.getPrintJobByFileId(fileId)?.setTicket({
        ...printOption,
        layout: { order: order, nUp: nUp },
      });
      setOpen(!isOpen);
    }
  };

  useEffect(() => {
    setNUp(
      printJobBuilderStore.getPrintJobByFileId(fileId)?.ticket?.layout.nUp ?? 1
    );
    setOrder(
      printJobBuilderStore.getPrintJobByFileId(fileId)?.ticket?.layout.order ??
        LayoutOrder.RIGHT_TO_DOWN
    );
  }, [fileId]);

  return (
    <ContextMenu
      isOpen={isOpen}
      onRequestClose={() => {
        setOpen(!isOpen);
      }}
      title=""
    >
      <SettingLabel>모아찍기</SettingLabel>
      <SettingOptions>
        <OptionButton onClick={() => handleNUp(1)} active={NUp === 1} label="1">
          {NUp === 1 && <Icon1pageActive />}
          {NUp !== 1 && <Icon1pageInActive />}
        </OptionButton>
        <OptionButton onClick={() => handleNUp(2)} active={NUp === 2} label="2">
          {NUp === 2 && <Icon2pageActive />}
          {NUp !== 2 && <Icon2pageInActive />}
        </OptionButton>
        <OptionButton onClick={() => handleNUp(4)} active={NUp === 4} label="4">
          {NUp === 4 && <Icon4pageActive />}
          {NUp !== 4 && <Icon4pageInActive />}
        </OptionButton>
        <OptionButton onClick={() => handleNUp(6)} active={NUp === 6} label="6">
          {NUp === 6 && <Icon6pageActive />}
          {NUp !== 6 && <Icon6pageInActive />}
        </OptionButton>
        <OptionButton onClick={() => handleNUp(8)} active={NUp === 8} label="8">
          {NUp === 8 && <Icon8pageActive />}
          {NUp !== 8 && <Icon8pageInActive />}
        </OptionButton>
      </SettingOptions>

      <SettingLabel>모아찍기 방향</SettingLabel>
      <SettingOptions>
        <OptionButton
          onClick={() => handleOrder(LayoutOrder.RIGHT_TO_DOWN)}
          active={order === LayoutOrder.RIGHT_TO_DOWN}
          label="가로"
        >
          {order === LayoutOrder.RIGHT_TO_DOWN && <IconZActive />}
          {order !== LayoutOrder.RIGHT_TO_DOWN && <IconZInActive />}
        </OptionButton>
        <OptionButton
          onClick={() => handleOrder(LayoutOrder.DOWN_TO_RIGHT)}
          active={order === LayoutOrder.DOWN_TO_RIGHT}
          label="세로"
        >
          {order === LayoutOrder.DOWN_TO_RIGHT && <IconNActive />}
          {order !== LayoutOrder.DOWN_TO_RIGHT && <IconNInActive />}
        </OptionButton>
      </SettingOptions>

      <Spacing.Vertical height={20} />
      <SolidButton onClick={() => handleLayout(order, NUp)}>확인</SolidButton>
    </ContextMenu>
  );
};
export default observer(LayoutContextMenu);

const SettingLabel = styled.p`
  margin: 20px 0 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.05em;
  color: #646464;
`;
const SettingOptions = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 12px;
`;
