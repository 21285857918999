import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { ampEvents } from 'amplitude-events';
import trackUser from 'services/trackUser';
import { Spacing } from 'styles/styles';
import { useStores } from 'stores/RootStore';
import Badge from 'components/common/badge/Badge';
import PageContainer from 'components/common/PageContainer';
import SolidButton from 'components/common/solid_button/SolidButton';
import TopBar from 'components/common/top_bar/TopBar';
import FullScreenLoader from 'components/common/FullScreenLoader';
import PreviewButton from 'components/pages/print/PreviewButton';
import CopiesOption from 'components/pages/printJobOptions/CopiesOption';
import DuplexOption from 'components/pages/printJobOptions/DuplexOption';
import FitToOptionOption from 'components/pages/printJobOptions/FitToPageOption';
import IsColorOption from 'components/pages/printJobOptions/IsColorOption';
import LayoutContextMenu from 'components/pages/printJobOptions/LayoutContextMenu';
import LayoutOption from 'components/pages/printJobOptions/LayoutOption';
import PageRangeContextMenu from 'components/pages/printJobOptions/PageRangeContextMenu';
import PaperOrientationOption from 'components/pages/printJobOptions/PaperOrientationOption';
import PrintWizardProgressBar from 'components/pages/PrintWizardProgressBar';
import PaperSizeOption from 'components/pages/printJobOptions/PaperSizeOption';

const PrintWizard3: React.FC = props => {
  const navigation = useNavigate();
  const { userStore, printJobBuilderStore, fileStore } = useStores();

  const { idx } = useParams();
  const [fileId, setFileId] = useState<string>('');
  const [openLayoutContextMenu, setOpenLayoutContextMenu] =
    useState<boolean>(false);

  const handleFinalRequest = () => {
    trackUser(ampEvents.PrintWizard3_Request);
    printJobBuilderStore
      .createToServer()
      .then(() => navigation('/printwizardcompleted', { replace: true }))
      .catch(() => {
        userStore.showErrorModal();
      })
      .finally(() => fileStore.setSelectMode(false));
  };

  useEffect(() => {
    setFileId(fileStore.selectedFile[Number(idx)]);
  }, [idx]);

  if (printJobBuilderStore.isLoading) {
    return <FullScreenLoader message="인쇄 요청 중" />;
  }

  return (
    <PageContainer>
      <PrintWizardProgressBar step={3} />
      <TopBar
        title=""
        rightNode={<PreviewButton isEdit={false} id={fileId} />}
      />
      <NavTitle>인쇄 옵션을 설정해주세요</NavTitle>

      <Spacing.Vertical height={16} />

      {/* title */}
      <TitleLabel>파일명</TitleLabel>
      <TitleContainer>
        {fileStore.selectedFile.length !== 1 && (
          <StyledBadge
            label={`${Number(idx) + 1} / ${fileStore.selectedFile.length}`}
            size="xs"
          />
        )}
        <Title>
          {printJobBuilderStore.getPrintJobByFileId(fileId)?.file.name}
        </Title>
      </TitleContainer>

      <OptionLabel>색상 설정</OptionLabel>
      <IsColorOption fileId={fileId} />

      {/* TODO: printzone dto로 설정가능한 용지 크기 보여주기 */}
      <OptionLabel>용지 크기</OptionLabel>
      <PaperSizeOption fileId={fileId} />

      <OptionLabel>용지 방향</OptionLabel>
      <PaperOrientationOption fileId={fileId} />

      <OptionLabel>모아찍기</OptionLabel>
      <LayoutOption
        fileId={fileId}
        onClick={() => {
          setOpenLayoutContextMenu(true);
        }}
      />
      <LayoutContextMenu
        fileId={fileId}
        isOpen={openLayoutContextMenu}
        setOpen={setOpenLayoutContextMenu}
      />

      <OptionLabel>양단면</OptionLabel>
      <DuplexOption fileId={fileId} />

      <OptionLabel>부 수</OptionLabel>
      <CopiesOption fileId={fileId} />

      {/* <OptionLabel>페이지 크기</OptionLabel>
      <FitToOptionOption /> */}

      <OptionLabel>범위 지정</OptionLabel>
      <PageRangeContextMenu fileId={fileId} />

      <Spacing.Vertical height={120} />
      <Fade />

      {/* button: case1 - 파일 한개만 존재 */}
      {fileStore.selectedFile.length === 1 && (
        <FixedButton>
          <SolidButton onClick={handleFinalRequest}>다음 단계</SolidButton>
        </FixedButton>
      )}

      {/* button: case2 - 파일이 여러개 존재 + 처음문서 */}
      {fileStore.selectedFile.length !== 1 && Number(idx) === 0 && (
        <FixedButton>
          <SolidButton
            onClick={() => {
              navigation(`/printwizard3/${Number(idx) + 1}`, { replace: true });
            }}
          >
            다음 문서
          </SolidButton>
        </FixedButton>
      )}

      {/* button: case3 - 파일이 여러개 존재 + 중간문서 */}
      {fileStore.selectedFile.length !== 1 &&
        Number(idx) !== 0 &&
        Number(idx) !== fileStore.selectedFile.length - 1 && (
          <FixedButton>
            <PreviousButton
              onClick={() => {
                navigation(`/printwizard3/${Number(idx) - 1}`, {
                  replace: true,
                });
              }}
            >
              이전 문서
            </PreviousButton>
            <SolidButton
              onClick={() => {
                navigation(`/printwizard3/${Number(idx) + 1}`, {
                  replace: true,
                });
              }}
            >
              다음 문서
            </SolidButton>
          </FixedButton>
        )}

      {/* button: case4 - 파일이 여러개 존재 + 마지막문서 */}
      {fileStore.selectedFile.length !== 1 &&
        Number(idx) === fileStore.selectedFile.length - 1 && (
          <FixedButton>
            <PreviousButton
              onClick={() => {
                navigation(`/printwizard3/${Number(idx) - 1}`, {
                  replace: true,
                });
              }}
            >
              이전 문서
            </PreviousButton>
            <SolidButton onClick={handleFinalRequest}>다음 단계</SolidButton>
          </FixedButton>
        )}
    </PageContainer>
  );
};

export default observer(PrintWizard3);

const NavTitle = styled.h1`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.color.gray700};
  margin-top: 12px;
  margin-bottom: 16px;
`;

const TitleLabel = styled.div`
  margin-bottom: 4px;

  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #c2c2c2;
`;

const StyledBadge = styled(Badge)`
  width: max-content;
`;
const Title = styled.div`
  width: 80%;

  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #515151;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  width: 100%;

  margin-bottom: 16px;
`;

const OptionLabel = styled.div`
  padding-top: 16px;

  font-weight: 400;
  font-size: 12px;
  line-height: 165%;
  color: #c2c2c2;
`;

const FixedButton = styled.div`
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 0;

  display: flex;
  gap: 10px;

  padding: 0 30px 24px 30px;

  width: 100%;
  max-width: 780px;

  background: white;
`;

const Fade = styled.div`
  z-index: 2;

  position: fixed;
  left: 0;
  bottom: 80px;

  display: flex;
  justify-content: center;

  width: 100%;
  height: 44px;

  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
`;

const PreviousButton = styled.button`
  width: 80px;

  background: #f4f4f4;
  border-radius: 8px;
  border-color: transparent;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #666666;
`;
