import { PropsWithChildren, createContext, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import ToastMessage from './ToastMessage';

const toastContext: {
  showToast: (message: string) => void;
} = {
  showToast: () => {},
};

export const ToastContext = createContext(toastContext);

export const ToastProvider = ({ children }: PropsWithChildren) => {
  const root = document.getElementById('modal-root');

  const [message, setMessage] = useState<string>('');
  const [isOpenToast, setIsOpenToast] = useState<boolean>(false);
  const toastTimer = useRef<NodeJS.Timeout>();

  const showToast = (message: string) => {
    setIsOpenToast(true);
    setMessage(message);

    // 이전 알림과 겹치는 것 방지
    if (toastTimer.current) {
      clearTimeout(toastTimer.current);
    }

    const timer = setTimeout(() => {
      setIsOpenToast(false);
      setMessage('');
    }, 3000);
    toastTimer.current = timer;
  };

  if (!root) return null;

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {isOpenToast &&
        ReactDOM.createPortal(<ToastMessage message={message} />, root)}
    </ToastContext.Provider>
  );
};
