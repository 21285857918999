import { action, makeAutoObservable } from 'mobx';
import { HttpRequestService } from 'services/http-request.service';
import { kioskService } from 'services/kiosk.service';
import { PrintZoneLineDto } from 'types/kiosk/PrintZoneLine.dto';
import { UserStore } from '../user/UserStore';
import { Kiosk } from './Kiosk';

export class KioskStore {
  isLoading = false;
  kiosks: Kiosk[] = [];

  constructor(
    public readonly userStore: UserStore,
    public readonly http: HttpRequestService
  ) {
    makeAutoObservable(this, {});
  }

  async getKiosks() {
    this.startLoading();

    return await kioskService
      .getKiosks(true, true, undefined, undefined)
      .then(
        action('fetchKiosk_Success', res =>
          this.updateManyKiosksFromServer(res)
        )
      )
      .finally(() => this.endLoading());
  }

  private updateManyKiosksFromServer(dtos: PrintZoneLineDto[]) {
    // 1. DTO 에 없는 요소 삭제
    this.kiosks = this.kiosks.filter(kiosk =>
      dtos.find(dto => kiosk.id === dto.id)
    );

    // 2. DTO 와 store 에 둘 다 있는 요소 업데이트
    dtos.map(dto => this.updateOneKioskFromServer(dto));

    // 3. DTO 에만 있는 파일 생성
    dtos
      .filter(dto => !this.kiosks.find(kiosk => kiosk.id === dto.id)) // kiosk store 에서 일치하는 요소를 찾지 못한 dto 들만 filter
      .forEach(dto => this.createOneKioskFromServer(dto)); // 각각의 dto 를 kiosk store 에 push

    this.endLoading();
  }

  /**
   * @param dto
   * @returns
   */
  private updateOneKioskFromServer(dto: PrintZoneLineDto) {
    const kiosk = this.kiosks.find(kiosk => kiosk.id === dto.id);
    if (!kiosk) {
      console.warn('updateOneKioskFromServer cannot find kiosk!');
      return;
    }
    kiosk.updateKioskFromDto(dto);
  }

  /**
   * Store 에 새로운 파일을 dto 로 부터 추가하기 위함.
   * @param dto
   */
  private createOneKioskFromServer(dto: PrintZoneLineDto) {
    this.kiosks.push(new Kiosk(dto));
  }

  startLoading() {
    this.isLoading = true;
  }

  endLoading() {
    this.isLoading = false;
  }
}
