import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import 'react-loading-skeleton';
import Border from 'components/common/border/Border';
import List from 'components/common/list/List';
import PageContainer from 'components/common/PageContainer';
import SolidButton from 'components/common/solid_button/SolidButton';
import TopBar from 'components/common/top_bar/TopBar';
import TopInfo from 'components/common/top_info/TopInfo';
import FullScreenLoader from 'components/common/FullScreenLoader';
import Modal from 'components/common/modal/Modal';
import ContextMenu from 'components/common/context_menu/ContextMenu';
import Icon from 'components/common/icon/Icon';
import { Spacing } from 'styles/styles';
import { useStores } from 'stores/RootStore';
import { observer } from 'mobx-react-lite';
import { Card } from 'stores/card/Card';
import ErrorPage from 'components/common/ErrorPage';
import * as Sentry from '@sentry/react';
import { ampEvents } from 'amplitude-events';
import trackUser from 'services/trackUser';
import { Img3DCard } from 'assets/images';
import useToast from 'hooks/useToast';
import FixedButtons from 'components/common/solid_button/FixedButtons';
import useHistoryBack from 'hooks/useHistoryBack';

const Cards: React.FC = () => {
  const navigation = useNavigate();
  const location = useLocation().state as { beforePage: 'REGISTER' };

  const { cardStore } = useStores();
  const { showToast } = useToast();

  const [error, setError] = useState<boolean>(false);
  const [selectedCard, setSelectedCard] = useState<Card>();

  const [openContextMenu, setOpenContextMenu] = useState(false);

  const [successChangePriority, setSuccessChangePriority] = useState(false);
  const [clickedDeleteCard, setClickedDeleteCard] = useState(false);
  const [clickedErrorCard, setClickedErrorCard] = useState(false);
  const [afterRegister, setAfterRegister] = useState(false);

  const handleDeleteCard = async () => {
    try {
      if (!selectedCard) {
        throw new Error('no selected card!');
      }
      await cardStore.deleteCard(selectedCard);
      setClickedDeleteCard(false);
      navigation('/cards', { replace: true });
    } catch (err) {
      Sentry.captureException(err);
      setError(true);
    } finally {
      setSelectedCard(undefined);
    }
  };

  const handleResetPriority = async () => {
    setOpenContextMenu(false);

    try {
      if (!selectedCard) {
        throw new Error('no selected card!');
      }
      await cardStore.setPriority(selectedCard);
      setSuccessChangePriority(true);
    } catch (err) {
      Sentry.captureException(err);
      setError(true);
    }
  };

  useEffect(() => {
    if (cardStore.errors.setPriorityError) {
      showToast('주결제 카드 변경에 실패했습니다');
      cardStore.clearError();
    } else if (cardStore.errors.deleteCardError) {
      showToast('카드를 삭제하는데 실패했습니다');
      cardStore.clearError();
    }
  }, [cardStore.errors]);

  useEffect(() => {
    cardStore.loadCards();
    return () => {
      cardStore.clearError();
    };
  }, []);

  useEffect(() => {
    if (!location) return;
    setAfterRegister(true);
  }, []);

  useHistoryBack(() => {
    if (afterRegister) {
      navigation('/', { replace: true, state: { beforePage: 'REGISTER' } });
    } else {
      navigation('/menu', { replace: true });
    }
  });

  if (cardStore.errors.loadCardError || error) {
    return <ErrorPage />;
  }

  return (
    <PageContainer>
      {cardStore.isLoading && <FullScreenLoader />}

      <TopBar title="카드 관리" border={true} />

      {/* empty card 확인 */}
      {cardStore.cards.length === 0 ? (
        <LogoContainer>
          <StyledImg src={Img3DCard} />
          <Title>카드를 등록해주세요</Title>
          <Slogan>하단 카드 추가하기 버튼을 눌러 카드를 추가하세요</Slogan>
          <Spacing.Vertical height={57} />
        </LogoContainer>
      ) : (
        <>
          <TopInfo
            subTitle="현재 주결제 카드"
            title={
              cardStore.cards.filter(card => card.isPriority === false)[0]?.name
            }
          />
          <Border size={16} />

          {cardStore.cards.map(card => (
            <CardContainer key={card.id}>
              {!card.isPriority && (
                <>
                  {card.rejectionMessage !== null ? (
                    <Icon icon="IconErrorCircle" themeColor="destructive600" />
                  ) : (
                    <Icon icon="IconStar" themeColor="primary600" />
                  )}
                  <Spacing.Horizontal width={12} />
                </>
              )}
              <List
                title={card.name}
                action={card.rejectionMessage !== null ? 'arrow' : 'more'}
                actionText={card.rejectionMessage !== null ? '오류 확인' : ''}
                onActionClick={() => {
                  setSelectedCard(card);
                  if (card.rejectionMessage !== null) setClickedErrorCard(true);
                  else setOpenContextMenu(true);
                }}
              />
            </CardContainer>
          ))}
        </>
      )}

      {/* 하단 button, toastmessage */}
      <FixedButtons>
        <SolidButton
          onClick={() => {
            trackUser(ampEvents.CardManagement_Add);
            if (cardStore.cards.length >= 3) {
              showToast('카드는 최대 3장까지 등록이 가능합니다');
            } else navigation('/cards/register');
          }}
        >
          카드 추가하기
        </SolidButton>
      </FixedButtons>

      {/* Context Menu */}
      <ContextMenu
        isOpen={openContextMenu}
        onRequestClose={() => {
          setOpenContextMenu(false);
        }}
        title="카드 관리"
      >
        <ContextItem onClick={handleResetPriority}>
          <Icon icon="IconStar" themeColor="gray200" />
          <Spacing.Horizontal width={12} />
          <ContextLabel>주결제 카드 변경</ContextLabel>
        </ContextItem>

        <ContextItem
          onClick={() => {
            setOpenContextMenu(false);
            setClickedDeleteCard(true);
          }}
        >
          <Icon icon="IconTrashBin" themeColor="destructive600" />
          <Spacing.Horizontal width={12} />
          <ContextLabel style={{ color: '#dd3d2a' }}>카드 삭제</ContextLabel>
        </ContextItem>
      </ContextMenu>

      {/* Reset priority card */}
      <Modal
        isOpen={successChangePriority}
        onRequestClose={() => {
          setSuccessChangePriority(false);
        }}
        title="주결제 카드 변경"
        description={`주결제 카드가 ${selectedCard?.name}로\n 변경 되었습니다`}
        mainActionLabel="확인"
        onMainAction={() => {
          setSuccessChangePriority(false);
        }}
      />

      {/* Delete card */}
      <Modal
        isOpen={clickedDeleteCard}
        onRequestClose={() => {
          setClickedDeleteCard(false);
        }}
        title="카드 삭제"
        description={`${selectedCard?.name}을 삭제하시겠습니까?`}
        mainActionLabel="삭제"
        mainActionColor="NEGATIVE"
        onMainAction={() => {
          handleDeleteCard();
          setClickedDeleteCard(false);
        }}
        subActionLabel="취소"
        onSubAction={() => {
          setClickedDeleteCard(false);
        }}
      />

      {/* Payment failure card */}
      <Modal
        isOpen={clickedErrorCard}
        onRequestClose={() => {
          setClickedErrorCard(false);
        }}
        title="결제 오류"
        description={`${selectedCard?.rejectionMessage}\n결제오류를 확인하고 카드를 삭제하거나\n다시 결제를 시도해주세요`}
        mainActionLabel="카드 삭제"
        mainActionColor="NEGATIVE"
        onMainAction={() => {
          handleDeleteCard();
          setClickedErrorCard(false);
        }}
        subActionLabel="확인"
        onSubAction={() => {
          setClickedErrorCard(false);
        }}
      />
    </PageContainer>
  );
};

export default observer(Cards);

const CardContainer = styled.div`
  display: flex;
  align-items: center;
`;
const ContextItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
`;
const ContextLabel = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #767676;
`;
const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75vh;
`;
const Logo = styled(Icon)`
  margin: 41px 80px 32px 80px;
`;
const Title = styled.p`
  font-size: 18px;
  font-weight: 500;
  color: #1d1d1d;
  margin-bottom: 8px;
`;
const Slogan = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: #767676;
`;
const StyledImg = styled.img`
  width: 242px;
  height: 242px;
`;
