/**
 * Amplitude 데이터 분석을 위해
 * 페이지별로 만들어진 각종 이벤트들
 */
export const ampEvents = {
  Home_Alarm: 'Home_Alarm',
  Home_ContentTag: 'Home_ContentTag',
  Home_NearPrinter: 'Home_NearPrinter',
  Home_GoToPrint: 'Home_GoToPrint',
  Home_GoToDocumentBox: 'Home_GoToDocumentBox',
  Home_GoToFindPrinter: 'Home_GoToFindPrinter',
  Home_NeedLoginModal_Okay: 'Home_NeedLoginModal_Ok',
  Home_NeedLoginModal_Cancel: 'Home_NeedLoginModal_Cancel',
  Home_Banner: 'Home_Banner',

  NavBar_Home: 'NavBar_Home',
  NavBar_Content: 'NavBar_Content',
  NavBar_Print: 'NavBar_Print',
  NavBar_Map: 'NavBar_Map',
  NavBar_Menu: 'NavBar_Menu',

  Boba_LoginOrSignUp: 'Boba_LoginOrSignUp',
  Boba_KakaoLogin: 'Boba_KakaoLogin',

  Login_SignUp: 'Login_SignUp',
  Login_FindEmailPassword: 'Login_FindEmailPassword',
  Login_LoginSubmit: 'Login_LoginSubmit',
  Login_LoginSuccess: 'Login_LoginSuccess',

  FindEmailPassword_Tab_FindEmail: 'FindEmailPassword_Tab_FindEmail',
  FindEmailPassword_Tab_FindPassword: 'FindEmailPassword_Tab_FindPassword',
  FindEmailPassword_SendVerification_Submit:
    'FindEmailPassword_SendVerification_Submit',
  FindEmailPassword_SendVerification_VerifySuccess:
    'FindEmailPassword_SendVerification_VerifySuccess',
  FindEmailPassword_FindPassword_Submit:
    'FindEmailPassword_FindPassword_Submit',
  FindEmailPassword_FindPassword_Success:
    'FindEmailPassword_FindPassword_Success',

  ResetPassword_Code_Send: 'ResetPassword_Code_Send',
  ResetPassword_Code_Verify: 'ResetPassword_Code_Verify',
  ResetPassword_Submit: 'ResetPassword_Submit',
  ResetPassword_Success: 'ResetPassword_Success',

  Register1_Next: 'Register1_Next',
  Register2_Complete: 'Register2_Complete',
  Register2_PhoneVerification_Send: 'Register2_PhoneVerification_Send',
  Register2_PhoneVerification_Success: 'Register2_PhoneVerification_Success',
  Register3_Pass: 'Register3_Pass',
  Register3_RegisterCard: 'Register3_RegisterCard',

  RegisterCard_Submit: 'RegisterCard_Submit',
  RegisterCard_Success: 'RegisterCard_Success',

  RegisterOption_Submit: 'RegisterOption_Submit', // 카카오 계정에서 받을 수 없는 추가적인 정보를 받기 위함

  Menu_AccountSetting: 'Menu_AccountSetting',
  Menu_CardManagement: 'Menu_CardManagement',
  Menu_PointRecharge: 'Menu_PointRecharge',
  Menu_UsageHistory: 'Menu_UsageHistory',
  Menu_CustomerSupport: 'Menu_CustomerSupport',

  Account_ChangePassword: 'Account_ChangePassword',
  Account_ChangePhoneNumber: 'Account_ChangePhoneNumber',
  Account_AlarmSetting: 'Account_AlarmSetting',
  Account_Logout: 'Account_Logout',
  Account_Quit: 'Account_Quit',

  CustomerService_Call: 'CustomerService_Call',
  CustomerService_Kakao: 'CustomerService_Kakao',
  CustomerService_Notice: 'CustomerService_Notice',
  CustomerService_Faq: 'CustomerService_Faq',
  CustomerService_Policy: 'CustomerService_Policy',

  CardManagement_Add: 'CardManagement_Add',

  Contents_Map: 'Contents_Map',
  Contents_SearchBox: 'Contents_SearchBox',
  Contents_Item: 'Contents_Item',

  ContentDetail_Share: 'ContentDetail_Share',
  ContentDetail_Purchase: 'ContentDetail_Purchase',

  ContentDetail_PurchaseSubmit: 'ContentDetail_PurchaseSubmit',
  ContentDetail_PurchaseSuccess: 'ContentDetail_PurchaseSuccess',
  ContentDetail_RegisterCard: 'ContentDetail_RegisterCard',

  ContentCompleted_Close: 'ContentCompleted_Close',
  ContentCompleted_GoToPrint: 'ContentCompleted_GoToPrint',
  ContentCompleted_CheckContent: 'ContentCompleted_CheckContent',

  Print_GoToPrint: 'Print_GoToPrint',
  Print_DocumentBox: 'Print_DocumentBox',
  Print_GoToReserved: 'Print_GoToReserved',
  Print_RecentDocument: 'Print_RecentDocument',
  Print_PrintRecentDocument: 'Print_PrintRecentDocument',
  Print_DownloadRecentDocument: 'Print_DownloadRecentDocument',

  PrintWizard_LoadFile: 'PrintWizard_LoadFile',
  PrintWizard_DocumentBox: 'PrintWizard_DocumentBox',

  PrintWizard2_SearchBox: 'PrintWizard2_SearchBox',
  PrintWizard2_Map: 'PrintWizard2_Map',
  PrintWizard2_PrinterDetail: 'PrintWizard2_PrinterDetail',
  PrintWizard2_Next: 'PrintWizard2_Next',
  PrintWizard2_MyLocation: 'PrintWizard2_MyLocation',

  PrintWizard3_Preview: 'PrintWizard3_Preview',
  PrintWizard3_Request: 'PrintWizard3_Request',

  PrintWizardComplete_GoToPrint: 'PrintWizardComplete_GoToPrint',
  PrintWizardComplete_Complete: 'PrintWizardComplete_Complete',

  WaitingList_SelectTicket: 'WaitingList_SelectTicket',
  WaitingList_PrinterDetail: 'WaitingList_PrinterDetail',
  WaitingList_CancelTicket: 'WaitingList_CancelTicket',
  WaitingList_Start: 'WaitingList_Start',
  WaitingList_Preview: 'WaitingList_Preview',
  WaitingList_EditTicket: 'WaitingList_EditTicket',

  VerifyKiosk_Guide: 'VerifyKiosk_Guide',

  JobPayment_RegisterCard: 'JobPayment_RegisterCard',
  JobPayment_PurchaseSubmit: 'JobPayment_PurchaseSubmit',
  JobPayment_PurchaseSuccess: 'JobPayment_PurchaseSuccess',

  JobComplete_Close: 'JobComplete_Close',
  JobComplete_SubmitReview: 'JobComplete_SubmitReview',

  JobError_CustomerService: 'JobError_CustomerService',
  JobError_CancelTicket: 'JobError_CancelTicket',
  JobError_Submit: 'JobError_Submit',

  DocumentBox_Search: 'DocumentBox_Search',
  DocumentBox_ChangeLayout: 'DocumentBox_ChangeLayout',
  DocumentBox_Tab_Uploaded: 'DocumentBox_Tab_Uploaded',
  DocumentBox_Tab_PurchasedContent: 'DocumentBox_Tab_PurchasedContent',
  DocumentBox_SelectDocument: 'DocumentBox_SelectDocument',
  DocumentBox_Upload: 'DocumentBox_Upload',
  DocumentBox_Download: 'DocumentBox_Download',
  DocumentBox_Print: 'DocumentBox_Print',
  DocumentBox_Preview: 'DocumentBox_Preview',

  Map_SearchBox: 'Map_SearchBox',
  Map_SelectFromSearch: 'Map_SelectFromSearch',
  Map_SelectAll: 'Map_SelectAll',
  Map_SelectPrint: 'Map_SelectPrint',
  Map_SelectScan: 'Map_SelectScan',
  Map_SelectCopy: 'Map_SelectCopy',
  Map_Select: 'Map_Select',
  Map_MyLocation: 'Map_MyLocation',

  PrintZone_Share: 'PrintZone_Share',
  PrintZone_CopyAddress: 'PrintZone_CopyAddress',
  PrintZone_Call: 'PrintZone_Call',
  PrintZone_SelectPaperSize: 'PrintZone_SelectPaperSize',
  PrintZone_GoToPrint: 'PrintZone_GoToPrint',
};
