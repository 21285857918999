import React from 'react';
import styled from 'styled-components';
import { Spacing } from 'styles/styles';

type Props = {
  step: 1 | 2 | 3;
};

const PrintWizardProgressBar: React.FC<Props> = ({ step }) => {
  return (
    <Container>
      {step === 1 && (
        <>
          <ActiveButton>1</ActiveButton>
          <ActiveLabel>문서 선택</ActiveLabel>
          <Spacing.Horizontal width={16} />
          <Line />
          <Spacing.Horizontal width={16} />
          <InActiveButton>2</InActiveButton>
          <Spacing.Horizontal width={16} />
          <InActiveButton>3</InActiveButton>
        </>
      )}
      {step === 2 && (
        <>
          <ClearButton>1</ClearButton>
          <Spacing.Horizontal width={16} />
          <Line />
          <Spacing.Horizontal width={16} />
          <ActiveButton>2</ActiveButton>
          <ActiveLabel>프린터 선택</ActiveLabel>
          <Spacing.Horizontal width={16} />
          <Line />
          <Spacing.Horizontal width={16} />
          <InActiveButton>3</InActiveButton>
        </>
      )}
      {step === 3 && (
        <>
          <ClearButton>1</ClearButton>
          <Spacing.Horizontal width={16} />
          <ClearButton>2</ClearButton>
          <Spacing.Horizontal width={16} />
          <Line />
          <Spacing.Horizontal width={16} />
          <ActiveButton>3</ActiveButton>
          <ActiveLabel>인쇄 설정</ActiveLabel>
        </>
      )}
    </Container>
  );
};

export default PrintWizardProgressBar;

const Container = styled.div`
  width: calc(100% + 48px);
  height: 54px;
  display: flex;
  align-items: center;
  padding: 0 24px;
  margin-left: -24px;
  background: white;
  position: sticky;
  /* top: 56px; */
  z-index: 4;
`;

const ActiveButton = styled.div`
  margin-right: 8px;
  width: 20px;
  height: 20px;
  background: ${({ theme }) => theme.color.primary50};
  border-radius: 50px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }) => theme.color.primary500};
`;

const ActiveLabel = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #4483f7;
`;

const InActiveButton = styled.div`
  width: 20px;
  height: 20px;
  background: #fafafa;
  border-radius: 50px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #a3a3a3;
`;

const Line = styled.div`
  flex-grow: 1;
  height: 1px;
  background: #f5f5f5;
`;

const ClearButton = styled.div`
  margin-right: 8px;
  width: 20px;
  height: 20px;
  background: ${({ theme }) => theme.color.primary600};
  border-radius: 50px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: white
`;