import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores/RootStore';
import { PrintJob } from 'stores/job/PrintJob';
import trackUser from 'services/trackUser';
import { ampEvents } from 'amplitude-events';
import List from '../common/list/List';
import SolidButton from '../common/solid_button/SolidButton';
import TopInfo from '../common/top_info/TopInfo';
import FullScreenLoader from '../common/FullScreenLoader';
import Modal from '../common/modal/Modal';
import { JobKiosk } from 'types/job/JobKiosk';
import { getDatetimeKoreanString, getExpireTimeString } from 'utils/time.util';
import { set } from 'mobx';

type Props = {
  createdAt: Date;
  kiosk: JobKiosk;
  printJobs: PrintJob[];
};


// TODO: WaitingListDetail과 통합 필요
const PrintWaitingJob: React.FC<Props> = ({ createdAt, kiosk, printJobs }) => {
  const { printJobStore, stateStore, updateJobStore } = useStores();
  const navigation = useNavigate();

  const [cancelConfirmModal, setCancelConfirmModal] = useState<boolean>(false);
  const [cancelModal, setCancelModal] = useState<boolean>(false);

  const calculateTotalPrice = (printjobs: PrintJob[]) => {
    if (printJobStore.isConverted(printjobs) === 'converting') return '계산중';
    else if (printJobStore.isConverted(printjobs) === 'error') return '-';

    let answer = 0;
    printjobs.map(pj => (answer += pj.price ?? 0));
    return `${answer}원`;
  };

  const calculateRemainingTime = (printJobs: PrintJob[]) => {
    if (printJobStore.isConverted(printJobs) === 'converting') return '발급중';
    else if (printJobStore.isConverted(printJobs) === 'error') return '';
    else return getExpireTimeString(printJobs[0].expireAt);
  };

  const handleDeleteJob = () => {
    trackUser(ampEvents.WaitingList_CancelTicket);
    stateStore.startLoading();
    updateJobStore
      .deleteAllJobs('prints', kiosk.id)
      .then(() => setCancelModal(true))
      .catch(() => {
        stateStore.setError(true);
      })
      .finally(() => {
        stateStore.endLoading();
        setCancelConfirmModal(false);
      });
  };

  return (
    <>
      {printJobStore.isLoading && <FullScreenLoader />}
      <TopInfo
        title="인쇄"
        rightContent={
          <div>
            <SolidButton onClick={() => setCancelConfirmModal(true)} kind="lightRed" area="sm">
              요청 취소
            </SolidButton>
          </div>
        }
      />
      <List
        title="요청 일시"
        actionText={getDatetimeKoreanString(createdAt.toString()) ?? '발급중'}
      />
      <List title="만료시간" actionText={calculateRemainingTime(printJobs)} />
      <Border />
      <List title="결제 금액" actionText={calculateTotalPrice(printJobs)} />
      <Border />

      <List title="프린터" />
      <List
        title={kiosk.name}
        description={kiosk.description}
        action="info"
        onActionClick={() => {
          trackUser(ampEvents.WaitingList_PrinterDetail);
          navigation(`/printzone/${kiosk.id}`);
        }}
      />
      <Border />

      <Modal
        isOpen={cancelModal}
        onRequestClose={() => {
          setCancelModal(false);
          navigation('/waiting-list', { replace: true });
        }}
        description="요청을 취소하였습니다."
        mainActionLabel="확인"
        onMainAction={() => {
          setCancelModal(false);
          navigation('/waiting-list', { replace: true });
        }}
      />

      {/* Modal: 인쇄 대기 중인 문서 삭제 시도시 */}
      <Modal
        isOpen={cancelConfirmModal}
        onRequestClose={() => setCancelConfirmModal(false)}
        title="요청 취소"
        description={`정말 요청을 취소하시겠습니까?`}
        mainActionLabel="요청 취소"
        mainActionColor="NEGATIVE"
        onMainAction={handleDeleteJob}
        subActionLabel="아니오"
        onSubAction={() => setCancelConfirmModal(false)}
      />
    </>
  );
};
export default observer(PrintWaitingJob);

const Border = styled.div`
  margin-left: -24px;
  width: calc(100% + 48px);
  height: 8px;
  background: ${({ theme }) => theme.color.gray100};
`;
