import React from 'react';
import styled from 'styled-components';
import PageContainer from 'components/common/PageContainer';
import TopBar from 'components/common/top_bar/TopBar';

const Term2: React.FC = props => {
  return (
    <PageContainer>
      <TopBar left="back" title="개인정보 수집 및 이용 동의" />

      <Term>
        {TERM2.split('\n').map((text, index) => (
          <p key={String(index)}>{text}</p>
        ))}
      </Term>
    </PageContainer>
  );
};
export default Term2;

const Term = styled.div`
  width: 100%;
  padding: 0 0 20px 0;
  font-size: 14px;
  line-height: 165.3%;
  letter-spacing: -0.05em;
  color: #494949;
`;

const TERM2 = `■ 총 칙

주식회사 보바/ (이하 ”보바”이라 합니다.) 보바 이용자(이하 '이용자')의 개인정보를 중요시하고, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법 등 관련 법령상의 개인정보보호 규정을 준수하며, 또한 관련 법령에 의거한 개인정보취급방침을 정하여 이용자 권익 보호를 위하여 노력하고 있습니다. 본 개인정보취급방침은 회사가 제공하는 모바일 및 무인 키오스크 관련 제반 서비스(모바일 웹/앱 포함) 이용에 적용되며 다음과 같은 내용을 담고 있습니다.



■ 개인정보의 수집 항목 및 수집 방법

1. 수집 항목

  1) 보바는 회원가입, 비회원 주문시 주문조회 용 정보, 원활한 고객상담, 각종 서비스의 제공을 위해 아래와 같은 개인정보를 수집하고 있습니다.

- 필수 항목 : 희망 ID, 비밀번호, 성명, 전화번호, 이메일 주소       



  2) 서비스 이용과정이나 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.

    - IP Address, 쿠키, 방문 일시, OS종류, 브라우져 종류, 서비스 이용 기록, 불량 이용 기록

    - 모바일 서비스 이용 시 : 단말기 모델, 하드웨어 ID, 단말기 OS 종류, 모델종류

    

  3) 부가 서비스 및 맞춤식 서비스 이용 또는 이벤트 응모 과정에서 해당 서비스의 이용자, 개인정보 추가 수집에 대해 동의를 받는 경우에 한해서만, 아래와 같은 정보들이 수집될 수 있습니다.

    - 경품 등 배송 또는 계정 등록 후 사용가능 쿠폰경품 등의 배송 등을 위한 배송지, 수령자명, 유선전화번호, 휴대전화번호, E-Mail 등

  4) 유료 서비스 이용 과정에서 아래와 같은 정보들이 수집될 수 있습니다.

    - 신용카드 결제 시 : 카드사명, 카드번호 등

    - 휴대전화 결제 시 : 이동전화번호, 통신사, 결제승인번호, 이메일주소 등

    - 계좌이체 시 : 은행명, 계좌번호 등

    - 상품권(포인트) 이용 시 : 상품권 번호

    - 환불 시 : 환불계좌정보(은행명, 계좌번호, 예금주명)



2. 수집 방법 : 

보바는 다음과 같은 방법으로 개인정보를 수집합니다.

- 홈페이지, 서면양식, 전화, 팩스, 상담 게시판, 이메일, 이벤트 응모, (비회원 구매 포함)

- 제휴회사로부터의 제공

- 생성정보 수집 툴을 통한 수집





■ 개인정보 수집에 대한 동의

보바는 이용자께서 보바의 개인정보취급방침 또는 이용약관의 내용에 대해 “동의한다“버튼 또는 ”동의하지 않는다“ 버튼을 클릭할 수 있는 절차를 마련하여, ”동의한다“ 버튼을 클릭하면 개인정보 수집에 대해 동의한 것으로 간주합니다.





■ 개인정보의 수집 및 이용목적

1. 보바는 수집한 개인정보를 다음의 목적으로 활용합니다. 이용자가 제공한 모든 정보는 하기 목적에 필요한 용도 이외로는 사용되지 않으며, 이용 목적이 변경될 시에는 사전에 동의를 구하고 있습니다.



- 성명, 아이디, 비밀번호 : 회원제 서비스 이용에 따른 본인 식별 절차에 이용 

- 이메일주소, 전화번호 : 고지사항 전달, 본인 의사 확인, 불만 처리 등 원활한 의사소통 경로의 확보, 

     새로운 서비스/신상품이나 이벤트 정보의 안내 

- 은행계좌정보, 신용카드정보 : 유료정보 이용에 대한 요금 결제용

- 주소, 전화번호 : 청구서, 경품과 쇼핑 물품 배송에 대한 정확한 배송지의 확보 

- 그 외 선택항목 : 개인맞춤 서비스를 제공하기 위한 자료



2. 단, 이용자의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록, 건강상태 및 성생활 등)는 수집하지 않습니다.



■ 쿠키에 의한 개인정보 수집

1. 쿠키(cookie)란?

  보바는 이용자에 대한 정보를 저장하고 수시로 찾아내는 쿠키(cookie)를 사용합니다. 쿠키는 웹사이트가 이용자의 컴퓨터 브라우저(넷스케이프, 인터넷 익스플로러 등)로 전송하는 일반적인 소량의 정보입니다. 이용자께서 웹사이트에 접속을 하면  보바의 컴퓨터는 이용자의 브라우저에 있는 쿠키의 내용을 읽고, 이용자의 추가정보를 이용자의 컴퓨터에서 찾아 접속 시 추가 입력 없이 기본적인 개인화 서비스를 제공할 수 있습니다. 쿠키는 이용자의 컴퓨터는 식별하지만 이용자를 개인적으로 식별하지는 않습니다. 또한 이용자는 쿠키에 대한 선택권이 있습니다. 웹 브라우저 상단의 도구 > 인터넷 옵션 탭(option tab)에서 모든 쿠키를 다 받아들이거나, 쿠키가 설치될 때 통지를 보내도록 하거나, 아니면 모든 쿠키를 거부할 수 있는 선택권을 가질 수 있습니다. 다만 쿠키의 저장을 거부하는 경우에는 쿠키를 이용하여 제공하는 일부 서비스를 이용할 수 없습니다.



2. 쿠키(cookie) 운용

  보바는 이용자의 편의를 위하여 쿠키를 운영합니다. 보바가 쿠키를 통해 수집하는 정보는 이용자의 계정을 식별하는 e-mail에 한하며, 그 외의 다른 정보는 수집하지 않습니다. 보바가 쿠키(cookie)를 통해 수집한 이용자 e-mail은 다음의 목적을 위해 사용됩니다.

  - 개인의 관심 분야에 따라 차별화된 정보를 제공

  - 게시판 글 등록

쿠키는 브라우저의 종료시나 로그아웃시 만료됩니다.



■ 목적 외 사용 및 제3자에 대한 제공 및 공유

1. 보바는 공개된 정보를 제외하고는 이용자의 개인정보를 보바가 제공하는 인터넷 서비스 외의 용도로 사용하거나, 이용자의 동의 없이 제3자에게 제공하지 않습니다. 다만, 다음 각 호의 경우에는 예외로 합니다.

  1) 금융실명거래 및 비밀보장에 관한 법률, 신용정보의 이용 및 보호에 관한 법률, 전기통신기본법, 전기통신사업법, 지방세법, 소비자보호법, 한국은행법, 형사소송법등 법령에 특별한 규정이 있는 경우 

     그러나 예외 사항에서도 관계법령에 의하거나 수사기관의 요청에 의해 정보를 제공한 경우에는 이를 당사자에게 고지하는 것을 원칙으로 운영하고 있습니다. 법률상의 근거에 의해 부득이하게 고지를 하지 못할 수도 있습니다.

  2) 서비스제공에 따른 요금정산을 위하여 필요한 경우 

  3) 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로 특정개인을 식별할 수 없는 형태로 제공하는 경우



2. 보바는 이용자에게 보다 더 나은 서비스를 제공하기 위하여 개인정보를 제휴사에 제공하거나 제휴사 등과 공유할 수 있습니다. 개인정보를 제공하거나 공유할 경우에는 사전에 이용자에 제휴사, 제공 또는 공유되는 개인정보항목, 개인정보의 제공 또는 공유 목적, 개인정보 보호 및 관리 방법 및 기간에 대해 개별적으로 고지하여 동의를 구하는 절차를 거치게 되며, 이용자가 동의하지 않은 경우에는 제휴사 등에 제공 및 공유하지 않습니다. 또한 이용자가 일단 개인정보의 제공에 동의하더라도 언제든지 그 동의를 철회할 수 있습니다.



■ 개인정보의 열람, 정정

1. 이용자는 등록되어 있는 이용자의 개인정보를 언제든지 열람하거나 정정할 수 있습니다. 개인정보를 열람 및 정정하고자 할 경우에는 "마이페이지>나의계정" 클릭 후 열람,  "회원정보관리>개인정보수정" 클릭 후 직접 정정할 수 있습니다.



2. 이용자가 개인정보의 오류에 대한 정정을 요청한 경우, 정정을 완료하기 전까지 당해 개인 정보를 이용 또는 제공하지 않습니다.



3. 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정하도록 조치하겠습니다.



■ 개인정보 수집, 이용, 제공에 대한 동의철회

1. 회원가입 등을 통해 개인정보의 수집, 이용, 제공에 대해 동의한 내용을 이용자는 언제든지 철회할 수 있습니다. 동의철회는 홈페이지 첫 화면의 "나의계정"을 클릭 후 회원정보관리>회원탈회 또는 개인정보보호관리자 및 담당자에게 서면, 전화, E-mail 등으로 연락하면 즉시 개인정보의 삭제 등에 필요한 조치를 하겠습니다. 단, 회원가입 시 서비스 이용에 필수적인 동의 항목 중 일부항목의 동의철회는 서비스 제공 특성상 불가능하므로 탈회를 통한 전체 동의취소만이 유일합니다. 동의 철회를 하고 개인정보를 파기하는 등의 조치를 취한 경우에는 그 사실을 이용자에게 즉시 통지하도록 하겠습니다.



2. 보바는 개인정보의 수집에 대한 동의철회(계정 삭제)를 개인정보를 수집하는 방법보다 용이하도록 필요한 조치를 취하겠습니다.





■ 개인정보의 보유 및 이용기간

1. 이용자의 개인정보는 다음과 같이 개인정보의 수집목적 또는 제공받은 목적이 달성되면 파기됩니다.

  단, 상법 등 법령의 규정에 의하여 보존할 필요성이 있는 경우에는 예외로 합니다.

  1) 계정정보의 경우, 계정을 삭제하거나 제명된 때 

  2) 대금지급정보의 경우, 대금의 완제일 또는 채권소멸시효기간이 만료된 때 



  또한 회원이 마지막 로그인 기간으로부터 1년 동안 서비스를 이용하지 않았을 경우 ’개인정보 유효기간제’에 따른 회원 개인정보 관리를 위해 회사는 파기 예정일로부터 30일 전 개인정보 파기 안내문을 이메일로 통지합니다.

  이후 회원이 서비스에 로그인 하는 것으로 마지막 서비스 이용 기간이 갱신되며, 회원에게 통지하였음에도 30일간 회원의 마지막 서비스 이용기간이 갱신되지 않았을 경우, 회원의 개인정보(개인 신상정보, 이메일, 연락처)는 분리 저장ㆍ관리(또는 파기)합니다. 재이용을 위해서는 별도의 본인인증 후 로그인을 거쳐야 합니다.



2. 위 개인정보 수집목적 달성 시 즉시 파기 원칙에도 불구하고 다음과 같이 거래 관련 권리 의무 관계의 확인 등을 이유로 일정기간 보유하여야 할 필요가 있는 경우에는 “전자상거래등 에서의 소비자보호에 관한 법률” 등에 근거하여 일정기간 보유합니다.

- 회원가입정보의 경우, 회원가입을 탈퇴하거나 회원에서 제명된 경우 등 일정한 사전에 보유목적, 기간 및 

보유하는 개인 정보 항목을 명시하여 동의를 구합니다. 

- 계약 또는 청약철회 등에 관한 기록 : 5년 

- 대금결제 및 재화 등의 공급에 관한 기록 : 5년 

- 소비자의 불만 또는 분쟁 처리에 관한 기록 : 3년

3. 이용자의 동의를 받아 보유하고 있는 거래정보 등을 이용자가 열람을 요구하는 경우 보바는 즉시 열람할 수 있도록 조치합니다.



■ 개인정보의 파기 절차 및 방법

이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기하며, 보바의 파기절차 및 방법은 다음과 같습니다.



1. 파기절차

  - 이용자가 회원가입 등을 위해 입력한 정보는 이용목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기됩니다. 

  - 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다. 



2. 파기방법

  - 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.

  - 전자 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다. 



■ 이용자의 권리와 의무

1. 이용자의 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고에 대비해 주시기 바랍니다. 이용자가 입력한 부정확한 정보로 인해 발생하는 사고의 책임은 이용자 자신에게 있으며 타인 정보의 도용 등 허위정보를 입력할 경우 회원자격이 상실될 수 있습니다.



2. 이용자는 개인정보를 보호받을 권리와 함께 스스로를 보호하고 타인의 정보를 침해하지 않을 의무도 가집니다. 비밀번호를 포함한 이용자의 개인정보가 유출되지 않도록 조심하고 게시물을 포함한 타인의 개인정보를 훼손하지 않도록 유의해 주십시오. 만약 이 같은 책임을 다하지 못하고 타인의 정보 및 존엄성을 훼손할 시에는 “정보통신망 이용촉진 및 정보보호등에 관한 법률” 등에 의해 처벌받을 수 있습니다.





■ 고지의 의무

현 개인정보처리방침은 2014 년 7 월 22일에 제정되었으며 정부의 정책 또는 보안기술의 변경에 따라 내용의 추가/삭제 및 수정이 있을 시에는 개정 최소 10일 전부터 홈페이지의 '공지'란을 통해 고지할 것입니다.



이용자 개인정보 보호를 위한 보안 대책



■ 개인정보보호를 위한 기술 및 관리적 대책

1. 기술적 대책

  보바는 이용자의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적 대책을 강구하고 있습니다.

  1) 이용자의 개인정보는 비밀번호에 의해 보호되며, 파일 및 전송 데이터를 암호화하여 일반사용자 및 관리자가 접근할 수 없습니다.

  2) 보바는 백신프로그램을 이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다. 백신프로그램은 주기적으로 업데이트하여 바이러스에 대처하고 있습니다.

  3) 보바는 암호알고리즘을 이용하여 네트워크 상의 개인정보를 안전하게 전송할 수 있는 보안장치(SSL)를 채택하고 있습니다.

  4) 해킹 등 외부 침입에 대비하여 이용자가 입력한 일체 정보를 인터넷으로부터 완전히 분리된 별도의 서버 컴퓨터에 저장함으로써, 해킹의 위협으로부터 원천적으로 차단된 100% 보안체제를 구축하고 있습니다. 또한 각 서버마다 침입차단시스템 및 취약점 분석 시스템 등을 이용하여 보안에 만전을 기하고 있습니다.



2. 관리적 대책

  1) 보바는 이용자의 개인정보에 대한 접근권한을 최소한의 인원으로 제한하고 있습니다. 그 최소한의 인원에 해당하는 자는 다음과 같습니다. 

    (1) 이용자를 직접 상대로 하여 마케팅 업무를 수행하는 자 

    (2) 개인정보보호 책임자, 개인정보보호 관리자 및 담당자 등 개인정보관보호 업무를 수행하는 자 

    (3) 기타 업무상 개인정보의 취급이 불가피한 자

  2) 개인정보를 취급하는 직원을 대상으로 새로운 보안 기술 습득 및 개인정보 보호 의무 등에 관해 정기적인 사내 교육 및 외부 위탁교육을 실시하고 있습니다.

  3) 입사 시 전 직원이 보안서약서를 통하여 사람에 의한 정보유출을 사전에 방지하고 개인정보취급방침에 대한 이행사항 및 직원의 준수여부를 감사하기 위한 내부절차를 마련하고 있습니다.

  4) 개인정보 관련 취급자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이뤄지고 있으며 입사 및 퇴사 후 개인정보 사고에 대한 책임을 명확화하고 있습니다.

  5) 서버실 등을 통제구역으로 설정하여 출입을 통제하고 있습니다.

  6) 보바는 이용자 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니다.

     회원 개개인이 본인의 개인정보를 보호하기 위해서 자신의 e-mail 과 비밀번호를 적절하게 관리하고 이에 대한 책임을 져야 합니다.

  7) 그 외 내부 관리자의 실수나 기술관리 상의 사고로 인해 개인정보의 상실, 유출, 변조, 훼손이 유발될 경우 보바는 즉각 이용자께 사실을 알리고 적절한 대책과 보상을 강구할 것입니다.





이용자 개인정보 보호 문의처



■ 의견수렴 및 불만처리

1. 당사는 이용자의 의견을 소중하게 생각하며, 이용자는 의문사항에 대해 성실한 답변을 받을 권리가 있습니다.



2. 당사는 이용자와의 원활한 의사소통을 위해 고객센터를 운영하고 있으며, 고객센터의 연락처는 다음과 같습니다.

【고객센터】

- 1:1 고객상담 바로가기

- 전화번호: 02-6959-2677

- 주 소: 서울특별시 광진구 광나루로 360, CS빌딩 3층



3. 전화상담은 평일(월-금, 단, 공휴일 휴무) 오전10시에서 오후 7시까지입니다.(12시~오후 1시까지는 점심시간으로 제외됩니다)



4. 전자우편이나 팩스 및 우편을 이용한 상담은 접수 후 24시간 내에 성실하게 답변 드리겠습니다. 단, 근무시간 이후 또는 주말 및 공휴일에는 다음 정상근무일 처리하는 것을 원칙으로 합니다.



5. 기타 개인정보에 관한 상담이 필요한 경우에는 개인정보침해신고센터, 정보보호마크 인증위원회, 대검찰청 인터넷범죄수사센터, 경찰청 사이버범죄수사대 등으로 문의하실 수 있습니다.





이용자 개인정보 관리 책임자



■ 이용자 개인정보 안전을 위한 최선

1. 개인정보를 보호하는데 있어 이용자께 고지한 사항들에 반하는 사고가 발생할 시에 개인정보관리책임자가 모든 책임을 집니다.



2. 기술적인 보완조치를 했음에도 불구하고, 해킹 등 기본적 또는 일반적인 네트워크상의 위험성에 의해 발생하는 예기치 못한 사고로 인한 정보의 훼손 및 방문자가 작성한 게시물에 의한 각종 분쟁에 관해서는 책임이 없습니다.



3. 이용자의 개인정보를 취급하는 책임자 및 담당자는 다음과 같으며 개인정보 관련 문의사항에 신속하고 성실하게 답변해드리고 있습니다.



   개인정보보호 책임자 

  - 성명: 이규원

- 직책: 개발 책임자
  - 010-3389-1886


   개인정보보호 관리자 

  - 성명: 박기호

  - 소속: 개발자

  - 전화: 010-4176-4928



기타 개인정보침해에 대한 신고 또는 상담이 필요하신 경우에는 아래 기관으로 문의하시기 바랍니다. 





개인정보 침해신고센터(한국인터넷진흥원 운영) 

- 소관업무 : 개인정보 침해사실 신고, 상담 신청

- 홈페이지 : privacy.kisa.or.kr

- 전화 : (국번없이) 118

- 주소 : (138-950) 서울시 송파구 중대로 135 



한국인터넷진흥원 개인정보침해신고센터 

- 소관업무 : 개인정보개인정보 분쟁조정위원회(한국인터넷진흥원 운영) 분쟁조정신청, 집단분쟁조정(민사적 해결)

- 홈페이지 : privacy.kisa.or.kr



- 전화 : (국번없이) 118

- 주소 : (138-950) 서울시 송파구 중대로 135 한국인터넷진흥원 개인정보침해신고센터



개인정보침해신고센터 (www.118.or.kr / 118)

정보보호마크인증위원회 (www.eprivacy.or.kr / 02-550-9531~2) 

대검찰청 첨단범죄수사과 (http://www.spo.go.kr / 02-3480-2000)

경찰청 사이버테러대응센터 (www.ctrc.go.kr / 02-393-9112) 



개인정보취급방침 최초제정일자: 2019-09-17

개인정보취급방침 관련 최종변경일자 : 2019-09-17

개인정보정보취급방침 시행일자: 2019-09-17
`;
