import React, { useEffect } from 'react';
import styled from 'styled-components';
import Lottie from 'react-lottie-player';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores/RootStore';
import PageContainer from 'components/common/PageContainer';
import List from 'components/common/list/List';
import PrintJobFileStatus from 'components/pages/job-working/PrintJobFileStatus';
import LottieLoadingRing from 'assets/lottie/LottieLoadingRing.json';
import { Spacing } from 'styles/styles';
import useStatusOnBackground from 'hooks/useStatusOnBackground';

/**
 * 접근 경로
 * 1. printJob 결제 성공 -> PrintJobStatus = checkout-complete, converting-pending, printing
 * 2. copyJob 결제 성공 -> CopyJobStatus = printing
 * 3. local storage에 scan job이 있을 시, 잠깐 접속 후 상태에 맞는 페이지로 이동됨
 */

export default observer(function JobWorking() {
  const { jobPollingStore } = useStores();
  useStatusOnBackground();

  useEffect(() => {
    if (jobPollingStore.serviceType === 'prints') {
      return jobPollingStore.startPrintJobPollingTimer();
    }
  }, []);

  return (
    <PageContainer>
      <Wrapper>
        <div>
          <InfoTitle>
            {`${
              jobPollingStore.serviceType === 'prints'
                ? '인쇄'
                : jobPollingStore.serviceType === 'scans'
                ? '스캔'
                : '복사'
            }`}
            작업이 진행 중입니다
          </InfoTitle>
          <Spacing.Vertical height={8} />
          <InfoDescription>잠시만 기다려주세요...</InfoDescription>
        </div>

        <Lottie loop play animationData={LottieLoadingRing} />

        {/* print-job일시, 파일별 status 보여줘야 함 */}
        {jobPollingStore.serviceType === 'prints' &&
          jobPollingStore.pollingPrintJobDtos !== null &&
          jobPollingStore.pollingPrintJobDtos.map(pj => (
            <List
              key={pj.id}
              selectNode={<PrintJobFileStatus status={pj.status} />}
              title={pj.file.name}
            />
          ))}
      </Wrapper>
    </PageContainer>
  );
});

const Wrapper = styled.div`
  height: 84vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const InfoTitle = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: ${({ theme }) => theme.color.gray900};
  text-align: center;
`;
const InfoDescription = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.gray500};
  text-align: center;
`;
