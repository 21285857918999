import { action, makeAutoObservable } from 'mobx';
import { CONTENT_API } from 'services/contents.service';
import { UserStore } from './../user/UserStore';
import { ContentPurchaseRequestDto } from 'types/contents/ContentPurchaseRequest.dto';
import { ContentDto } from 'types/contents/Content.dto';

export class ContentStore {
  isLoading = false;
  content: ContentDto | null = null;
  findText: string = '';

  constructor(private readonly userStore: UserStore) {
    makeAutoObservable(this, {});
  }

  async loadContent(contentId: string) {
    this.startLoading();

    return await CONTENT_API.getContentDetail(
      !!this.userStore.userId,
      contentId
    )
      .then(
        action('fetchContent_Success', res => {
          this.content = res.data;
        })
      )
      .finally(() => {
        this.endLoading();
      });
  }

  async purchaseContent(contentId: string, cardId: string | null) {
    this.startLoading();

    if (!this.userStore.userId) {
      this.endLoading();
      throw new Error('no users');
    }

    const dto: ContentPurchaseRequestDto = {
      cardId: cardId,
      userId: this.userStore.userId,
    };
    return await CONTENT_API.postPurchaseContent(contentId, dto).finally(() =>
      this.endLoading()
    );
  }

  setFindText(findText: string) {
    this.findText = findText;
  }

  clear() {
    this.content = null;
  }

  startLoading() {
    this.isLoading = true;
  }

  endLoading() {
    this.isLoading = false;
  }
}
