import 'dayjs/locale/ko';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.locale('ko');
dayjs.extend(LocalizedFormat);
dayjs.extend(relativeTime);

export const getRelativeTimestampString = (input: Date | number | string) => {
  const date = input instanceof Date ? input : new Date(input);
  if (new Date().getTime() - date.getTime() < 1000 * 60) {
    return '방금 전';
  }
  if (
    new Date().getTime() - date.getTime() > 1000 * 3600 &&
    new Date().getTime() - date.getTime() < 1000 * 3600 * 2
  ) {
    return '1시간 전';
  }
  if (new Date().getTime() - date.getTime() < 1000 * 3600 * 24) {
    return dayjs(date).fromNow();
  }
  return dayjs(date).format('YYYY/MM/DD A HH:mm');
};

export const getDatetimeString = (input: Date | number | string) => {
  const date = input instanceof Date ? input : new Date(input);
  return dayjs(date).format('YYYY/MM/DD A HH:mm');
};

export const getRemainingTimeStringOfJob = (input: Date | number | string) => {
  const date = input instanceof Date ? input : new Date(input);
  const remainingMinutes = Math.round(
    (date.getTime() + 48 * 3600 * 1000 - new Date().getTime()) / 1000 / 60
  );
  if (remainingMinutes < 0) return '만료된 요청입니다';

  const hours = Math.floor(remainingMinutes / 60);
  const minutes = Math.round(remainingMinutes - hours * 60);
  return `${hours}시간 ${minutes.toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  })}분 후 만료`;
};

export const getExpireTimeString = (input: Date) => {
  const date = new Date(input);
  const remainingMinutes =
    Math.round(date.getTime() - new Date().getTime()) / 1000 / 60;
  const hours = Math.floor(remainingMinutes / 60);
  const minutes = Math.floor(remainingMinutes - hours * 60);

  if (remainingMinutes < 0) return '만료된 요청입니다';

  return `${hours}시간 ${minutes.toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  })}분 후 만료`;
};

export const getDatetimeKoreanString = (input: Date | number | string) => {
  const date = input instanceof Date ? input : new Date(input);
  return dayjs(date).format('YYYY년 MM월 DD일 A hh:mm');
};
