import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import Highlighter from 'react-highlight-words';
import styled from 'styled-components';
import { Uploadable } from 'stores/file/Uploadable';
import { TextUtils } from 'utils/text.util';
import Icon from 'components/common/icon/Icon';
import { ReactComponent as IconFile } from 'assets/icons/files/IconFile.svg';
import { Spacing } from 'styles/styles';
import Lottie from 'react-lottie-player';
import Spinner from 'assets/lottie/LottieSpinner.json';
interface Props {
  files: Uploadable[];
}
const UploadProgress: React.FC<Props> = ({ files }) => {
  const [showDetail, setShowDetail] = useState(false);
  const filenameRef = useRef<HTMLParagraphElement>(null);
  const [filenameWidth, setFilenameWidth] = useState(10000);
  const currentProgress =
    (files.reduce(
      (acc, cur) => acc + (cur.transferred ? cur.transferred : 0),
      0
    ) /
      files.reduce((acc, cur) => acc + cur.file.size, 1)) *
    100;

  useEffect(() => {
    if (filenameRef.current) {
      setFilenameWidth(filenameRef.current?.clientWidth);
    }
    if (files.length === 0) {
      setShowDetail(false);
    }
  }, [files]);

  return (
    <>
      <Container>
        <Summary>
          <Lottie
            loop
            animationData={Spinner}
            play
            style={{
              width: 12,
              height: 12,
              marginRight: '4px',
              marginBottom: '2px',
              overflow: 'visible',
            }}
          />
          <Main>
            <DescriptionRow>
              <Description>{`${files.length}개 파일 업로드 중`}</Description>
              <ProgressValue>{`${Math.round(currentProgress)}%`}</ProgressValue>
            </DescriptionRow>
            <ProgressBarBackground>
              <ProgressBar progress={currentProgress} />
            </ProgressBarBackground>
          </Main>
          <IconWrapper onClick={() => setShowDetail(!showDetail)}>
            {showDetail ? (
              <Icon icon="IconArrowUp" />
            ) : (
              <Icon icon="IconArrowDown" />
            )}
          </IconWrapper>
        </Summary>

        {showDetail && (
          <Detail>
            {files.map(file => (
              <ItemContainer key={file.id}>
                <IconFile width={40} height={40} />
                <Spacing.Horizontal width={10} />
                {/* <ThumbnailContainer>
                  <ThumbnailFromImgsPath
                    imgsPath=""
                    hasError={false}
                    filetype={file.file.name?.split('.')?.pop() ?? ''}
                    fileUid={''}
                  />
                </ThumbnailContainer> */}
                <LabelContainer>
                  <Filename ref={filenameRef}>
                    <Highlighter
                      highlightStyle={{
                        fontWeight: 700,
                        backgroundColor: 'transparent',
                        color: '#0266CA',
                      }}
                      searchWords={['']}
                      autoEscape
                      textToHighlight={TextUtils.getAbbreviatedText(
                        file.file.name,
                        filenameWidth
                      )}
                    />
                  </Filename>
                  <Lottie
                    loop
                    animationData={Spinner}
                    play
                    style={{
                      width: 12,
                      height: 12,
                      marginRight: '4px',
                      marginBottom: '2px',
                      overflow: 'visible',
                    }}
                  />
                </LabelContainer>
              </ItemContainer>
            ))}
          </Detail>
        )}
      </Container>
    </>
  );
};

export default observer(UploadProgress);

const Container = styled.div`
  width: 100%;
  background-color: white;
  z-index: 4;
  border-bottom: 1px solid #ebebeb;
`;
const Summary = styled.div`
  width: 100%;
  padding: 16px 0;
  display: flex;
  align-items: center;
`;
const Main = styled.div`
  flex: 1;
  margin: 0 14px;
`;
const DescriptionRow = styled.div`
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Description = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
`;
const ProgressValue = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
`;
const ProgressBarBackground = styled.div`
  width: 100%;
  height: 2px;
  background: #d6d6d6;
  border-radius: 20px;
`;
const ProgressBar = styled.div<{ progress: number }>`
  width: ${({ progress }) => `${progress}%`};
  height: 100%;
  background: linear-gradient(315deg, #2784ff 0%, #218ffd 100%);
  border-radius: 13px;
`;
const IconWrapper = styled.button`
  padding-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-width: 0;
`;
const Detail = styled.ul`
  background: #ffffff;
  padding: 16px 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
`;
const ItemContainer = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
`;
const ThumbnailContainer = styled.div`
  margin-right: 10px;
  width: 20px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const LabelContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Filename = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
`;
