import React from 'react';
import { TabPanel } from 'react-tabs';
import PageContainer from 'components/common/PageContainer';
import TabMenu from 'components/common/tab_menu/TabMenu';
import TopBar from 'components/common/top_bar/TopBar';
import FindEmail from 'components/pages/FindEmail';
import FindPassword from 'components/pages/FindPassword';
import trackUser from 'services/trackUser';
import { ampEvents } from 'amplitude-events';

const FindEmailPassword: React.FC = props => {
  return (
    <PageContainer>
      <TopBar left="back" />

      {/* Tab */}
      <TabMenu names={['이메일 찾기', '비밀번호 찾기']}>
        <TabPanel
          onClick={() => {
            trackUser(ampEvents.FindEmailPassword_Tab_FindEmail);
          }}
        >
          <FindEmail />
        </TabPanel>
        <TabPanel
          onClick={() => {
            trackUser(ampEvents.FindEmailPassword_Tab_FindPassword);
          }}
        >
          <FindPassword />
        </TabPanel>
      </TabMenu>
    </PageContainer>
  );
};

export default FindEmailPassword;
