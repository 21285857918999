import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores/RootStore';
import trackUser from 'services/trackUser';
import { ampEvents } from 'amplitude-events';
import PageContainer from 'components/common/PageContainer';
import TopBar from 'components/common/top_bar/TopBar';
import InputBox from 'components/common/input_box/InputBox';
import SolidButton from 'components/common/solid_button/SolidButton';
import BottomFixedButton from 'components/common/solid_button/FixedButtons';
import Modal from 'components/common/modal/Modal';
import Icon from 'components/common/icon/Icon';
import { Spacing } from 'styles/styles';
import useHistoryBack from 'hooks/useHistoryBack';

export default observer(function JobComplete() {
  const navigation = useNavigate();
  const { stateStore, jobPollingStore, fileStore } = useStores();
  const [stars, setStars] = useState<number>(0);
  const [message, setMessage] = useState<string>('');
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const serviceName =
    jobPollingStore.serviceType === 'prints'
      ? '프린트'
      : jobPollingStore.serviceType === 'scans'
      ? '스캔'
      : '복사';

  const handleReview = () => {
    trackUser(ampEvents.JobComplete_SubmitReview);
    stateStore.startLoading();
    jobPollingStore
      .writeReview(stars, message)
      .then(() => {
        setSuccessModal(true);
      })
      .catch(() => {
        stateStore.setError(true);
      })
      .finally(() => {
        stateStore.endLoading();
      });
  };

  const handleBack = () => {
    setSuccessModal(false);
    fileStore.loadFiles();
    navigation('/print', { replace: true });
  };

  useHistoryBack(handleBack);

  return (
    <PageContainer>
      <TopBar
        left="none"
        right="exit"
        onRightClick={() => {
          trackUser(ampEvents.JobComplete_Close);
          navigation('/print', { replace: true });
        }}
      />
      <Title>{`${serviceName}`} 작업이 완료되었습니다</Title>

      <Buttons>
        <Button clicked={stars === 1} onClick={() => setStars(1)}>
          <Icon icon="IconDislike" />
          <p>별로였어요</p>
        </Button>
        <Button clicked={stars === 2} onClick={() => setStars(2)}>
          <Icon icon="IconLike" />
          <p>좋았어요</p>
        </Button>
        <Button clicked={stars === 3} onClick={() => setStars(3)}>
          <Icon icon="IconHeart" themeColor="primary600" />
          <p>최고였어요</p>
        </Button>
      </Buttons>

      <Spacing.Vertical height={53} />

      <InputBox
        value={message}
        label="이용 후기를 남겨주세요"
        placeholder="메시지 입력"
        onChange={e => setMessage(e.target.value)}
      />

      <BottomFixedButton>
        <SolidButton disabled={stars === 0} onClick={handleReview}>
          확인
        </SolidButton>
      </BottomFixedButton>

      <Modal
        isOpen={successModal}
        onRequestClose={handleBack}
        description="리뷰가 등록되었습니다."
        mainActionLabel="확인"
        onMainAction={handleBack}
      />
    </PageContainer>
  );
});

const Title = styled.p`
  padding: 53px 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.03em;
  color: ${({ theme }) => theme.color.gray900};
  text-align: center;
`;
const Buttons = styled.div`
  display: flex;
  gap: 12px;
`;
const Button = styled.button<{ clicked: boolean }>`
  width: 100%;
  background: ${props =>
    props.clicked ? props.theme.color.primary50 : props.theme.color.gray50};
  border-radius: 8px;
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.color.gray200};
  }
  &:active {
    background: ${({ theme }) => theme.color.gray100};
  }
  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: ${({ theme }) => theme.color.gray600};
  }
`;
