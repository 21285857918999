import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import FullScreenLoader from 'components/common/FullScreenLoader';
import List from 'components/common/list/List';
import ListFooter from 'components/common/list_footer/ListFooter';
import PageContainer from 'components/common/PageContainer';
import SolidButton from 'components/common/solid_button/SolidButton';
import TopBar from 'components/common/top_bar/TopBar';
import TopInfo from 'components/common/top_info/TopInfo';
import { FileDocument } from 'stores/file/File';
import { useStores } from 'stores/RootStore';
import { Spacing } from 'styles/styles';
import trackUser from 'services/trackUser';
import { ampEvents } from 'amplitude-events';
import FixedButtons from 'components/common/solid_button/FixedButtons';

const ContentCompleted: React.FC = props => {
  const navigation = useNavigate();
  const { userStore, contentStore, fileStore, printJobBuilderStore } =
    useStores();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <PageContainer>
      {isLoading && <FullScreenLoader />}
      <TopBar
        left="none"
        title="구매 완료"
        right="exit"
        onRightClick={() => {
          trackUser(ampEvents.ContentCompleted_Close);
          navigation(-1);
        }}
      />
      <AlarmContainer>
        <MainAlarm>구매가 완료되었습니다</MainAlarm>
        <Spacing.Vertical height={16} />
        <SubAlarm>구매한 콘텐츠는 ‘문서함’에서 확인할 수 있습니다</SubAlarm>
      </AlarmContainer>

      <TopInfo
        title={contentStore.content?.title}
        subTitle={`판매자: ${contentStore.content?.author}`}
        upTitle={true}
      />
      <List text="구매 목록" />
      {contentStore.content?.files.map(file => (
        <FileList key={file.id}>{file.name}</FileList>
      ))}
      <ListFooter
        leftTitle="가격"
        rightTitle={
          contentStore.content?.price === 0
            ? '무료'
            : `${contentStore.content?.price}원`
        }
      />

      <FixedButtons>
        <SolidButton
          onClick={() => {
            trackUser(ampEvents.ContentCompleted_GoToPrint);
            setIsLoading(true);
            fileStore
              .loadFiles()
              .then(() => {
                const selectedContentFile: FileDocument[] = [];
                contentStore.content?.files.map(content => {
                  fileStore.files.map(file => {
                    if (file.id === content.id) {
                      selectedContentFile.push(file);
                      fileStore.updateSelectedFile(file.id);
                    }
                  });
                });
                printJobBuilderStore.createBuilders(selectedContentFile);
                contentStore.clear();
                navigation('/printwizard2', { replace: true });
              })
              .catch(() => {
                userStore.showErrorModal();
              })
              .finally(() => setIsLoading(false));
          }}
        >
          프린트하러 가기
        </SolidButton>
        <SolidButton
          onClick={() => {
            trackUser(ampEvents.ContentCompleted_CheckContent);
            contentStore.clear();
            navigation('/myfiledocuments', {
              replace: true,
              state: {
                type: 'CONTENTS',
              },
            });
          }}
        >
          구매한 콘텐츠 확인하기
        </SolidButton>
      </FixedButtons>
    </PageContainer>
  );
};

export default ContentCompleted;

const AlarmContainer = styled.div`
  padding: 57.5px 0;
  height: 179px;
`;

const MainAlarm = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #404040;
`;

const SubAlarm = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #737373;
`;

const FileList = styled.div`
  padding: 8px 0;
  width: 100%;
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #737373;
`;
