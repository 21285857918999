import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Spacing } from 'styles/styles';
import { Service } from 'types/kiosk/Service';
import { getSortedPaperSize } from 'utils/utils';
import trackUser from 'services/trackUser';
import { ampEvents } from 'amplitude-events';
import ContextMenu from 'components/common/context_menu/ContextMenu';
import Icon from 'components/common/icon/Icon';
import PrintOptions from 'components/common/PrintOptions';

export default function PrintZoneServices({
  services,
}: {
  services: Service[];
}) {
  const [paperSizeSet, setPaperSizeSet] = useState<string[]>();
  const [selectedPaperSize, setSelectedPaperSize] = useState<string>('A4');
  const [paperSizeModal, setPaperSizeModal] = useState<boolean>(false);

  useEffect(() => {
    setPaperSizeSet(getSortedPaperSize(services));
  }, [services]);

  if (!services[0]) {
    return null;
  }

  return (
    <>
      <SelectContainer>
        <SelectTitle>이용 가격</SelectTitle>
        <SelectBox
          onClick={() => {
            trackUser(ampEvents.PrintZone_SelectPaperSize);
            setPaperSizeModal(true);
          }}
        >
          {selectedPaperSize}
          <Spacing.Horizontal width={8} />
          <Icon icon="IconArrowDown" size={20} />
        </SelectBox>
      </SelectContainer>

      <PrintOptions
        services={services}
        serviceName="인쇄"
        sizeName={selectedPaperSize}
      />

      <PrintOptions
        services={services}
        serviceName="복사"
        sizeName={selectedPaperSize}
      />

      <PrintOptions
        services={services}
        serviceName="스캔"
        sizeName={selectedPaperSize}
      />

      {/* Context Menu - select paper size */}
      <ContextMenu
        isOpen={paperSizeModal}
        onRequestClose={() => {
          setPaperSizeModal(false);
        }}
        title="용지 사이즈"
      >
        {paperSizeSet &&
          paperSizeSet.map(paperSize => (
            <ContextItem
              key={paperSize}
              onClick={() => {
                trackUser(ampEvents.PrintZone_SelectPaperSize);
                setSelectedPaperSize(paperSize);
                setPaperSizeModal(false);
              }}
            >
              <Spacing.Horizontal width={12} />
              <ContextLabel active={selectedPaperSize === paperSize}>
                {paperSize}
              </ContextLabel>
            </ContextItem>
          ))}
      </ContextMenu>
    </>
  );
}

const SelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 16px 0;

  width: 100%;
`;

const SelectTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.gray700};
`;

const SelectBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  padding: 8px 12px;

  width: 73px;

  background: #fafafa;
  border: 1px solid ${({ theme }) => theme.color.gray200};
  border-radius: 28px;

  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.gray500};

  &:focus {
    background: #e5e5e590;
  }
`;

const ContextItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;

  width: 100%;

  cursor: pointer;
`;

const ContextLabel = styled.p<{ active: boolean }>`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props =>
    props.active ? props.theme.color.primary600 : props.theme.color.gray500};
`;
