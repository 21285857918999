import { Bridge } from 'bridges';

export default class implements Bridge {
  downloadFiles(files: { url: string; name: string }[]) {
    const data = JSON.stringify(files);
    (window as any)?.webkit?.messageHandlers?.downloadFiles.postMessage(data);
  }

  // TODO: 추후 정식으로 브릿지를 통한 기능 개발시 IOS 혹은 프론트 코드 수정 필요
  getVersionName() {
    return (window as any)?.webkit?.messageHandlers?.iOS.postMessage(
      'getVersionName'
    ) as string;
  }

  toString() {
    return 'APP';
  }
}
