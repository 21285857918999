import { useCallback, useState } from 'react';
import { ColorType } from 'types/kiosk/ColorType';
import { PrintZoneLineDto } from 'types/kiosk/PrintZoneLine.dto';
import { Service } from 'types/kiosk/Service';

export default function () {
  // key - kiosk id, value - 해당 서비스(Print, Scan, Copy)의 컬러, 흑백 가격
  const [kioskIdToServicePrice, setKioskIdToServicePrice] = useState<
    Map<
      string,
      {
        color?: number;
        mono?: number;
      }
    >
  >(new Map());

  const parseKioskServicePrice = useCallback(
    (
      by: PrintZoneLineDto[],
      serviceName: 'Print' | 'Scan' | 'Copy',
      paper: 'A3' | 'A4' | 'B3' = 'A4'
    ) => {
      const table = new Map<
        string,
        {
          color?: number;
          mono?: number;
        }
      >();

      by.forEach(kiosk => {
        const mono = kiosk.services.find(
          s =>
            areSameServiceNameAndSize(s, serviceName, paper) &&
            s.colorType === ColorType.Mono
        )?.price;

        const color = kiosk.services.find(
          s =>
            areSameServiceNameAndSize(s, serviceName, paper) &&
            s.colorType === ColorType.Color
        )?.price;

        table.set(kiosk.id, {
          mono,
          color,
        });
      });

      setKioskIdToServicePrice(table);
    },
    []
  );

  return [kioskIdToServicePrice, parseKioskServicePrice] as const;
}

function areSameServiceNameAndSize(
  service: Service,
  serviceName: string,
  size: string
) {
  return service.serviceNameEn === serviceName && service.sizeNameEn === size;
}
