import { HistoryContext } from 'contexts/HistoryBackContext';
import { useContext, useEffect } from 'react';

/**
 *
 * @param handleHistory android, web에서 뒤로 가기 버튼 클릭시, 수행할 작업을 정의합니다.
 */

const useHistoryBack = (handleHistory: () => void) => {
  const { registerHistoryHandler } = useContext(HistoryContext);

  useEffect(() => {
    registerHistoryHandler([handleHistory]);

    return () => {
      registerHistoryHandler([]);
    };
  }, []);
};

export default useHistoryBack;
