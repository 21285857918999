import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { ampEvents } from 'amplitude-events';
import trackUser from 'services/trackUser';
import { useStores } from 'stores/RootStore';
import PageContainer from 'components/common/PageContainer';
import SolidButton from 'components/common/solid_button/SolidButton';
import TopBar from 'components/common/top_bar/TopBar';
import ListHeader from 'components/common/list_header/ListHeader';
import List from 'components/common/list/List';
import Border from 'components/common/border/Border';
import FixedButtons from 'components/common/solid_button/FixedButtons';

const Register1: React.FC = props => {
  const navigation = useNavigate();
  const { authStore } = useStores();

  const [checkedTermAll, setCheckedTermAll] = useState(false);
  const [checkedTerm1, setCheckedTerm1] = useState(false);
  const [checkedTerm2, setCheckedTerm2] = useState(false);
  const [checkedTerm3, setCheckedTerm3] = useState(false);
  const [checkedTerm4, setCheckedTerm4] = useState(false);
  const [checkedTerm5, setCheckedTerm5] = useState(false);

  const [buttonActive, setButtonActive] = useState(false);

  const handleCheckedAll = () => {
    if (!checkedTermAll) {
      setCheckedTermAll(true);
      setCheckedTerm1(true);
      setCheckedTerm2(true);
      setCheckedTerm3(true);
      setCheckedTerm4(true);
      setCheckedTerm5(true);
    } else {
      setCheckedTermAll(false);
      setCheckedTerm1(false);
      setCheckedTerm2(false);
      setCheckedTerm3(false);
      setCheckedTerm4(false);
      setCheckedTerm5(false);
    }
  };

  useEffect(() => {
    if (
      checkedTerm1 &&
      checkedTerm2 &&
      checkedTerm3 &&
      checkedTerm4 &&
      checkedTerm5
    ) {
      setCheckedTermAll(true);
    } else {
      setCheckedTermAll(false);
    }
  }, [checkedTerm1, checkedTerm2, checkedTerm3, checkedTerm4, checkedTerm5]);

  useEffect(() => {
    if (checkedTerm1 && checkedTerm2 && checkedTerm3) {
      setButtonActive(true);
    } else {
      setButtonActive(false);
    }
  }, [checkedTerm1, checkedTerm2, checkedTerm3]);

  return (
    <PageContainer>
      <TopBar left="back" title="약관동의" border={true} />
      <ListHeader
        check={true}
        checked={checkedTermAll}
        onChecked={handleCheckedAll}
        title="전체 약관에 동의합니다"
      />
      <Border size={16} />
      <List
        select="check"
        checked={checkedTerm1}
        onSelected={() => {
          setCheckedTerm1(!checkedTerm1);
        }}
        description="(필수)보바 이용약관"
        action="arrow"
        onActionClick={() => navigation('/term1')}
      />
      <List
        select="check"
        checked={checkedTerm2}
        onSelected={() => {
          setCheckedTerm2(!checkedTerm2);
        }}
        description="(필수)개인정보 수집 및 이용 동의"
        action="arrow"
        onActionClick={() => navigation('/term2')}
      />
      <List
        select="check"
        checked={checkedTerm3}
        onSelected={() => {
          setCheckedTerm3(!checkedTerm3);
        }}
        description="(필수)위치기반서비스 이용약관"
        action="arrow"
        onActionClick={() => navigation('/term3')}
      />
      <List
        select="check"
        checked={checkedTerm4}
        onSelected={() => {
          setCheckedTerm4(!checkedTerm4);
        }}
        description="(선택)마케팅 정보 수신 동의"
        action="arrow"
        onActionClick={() => navigation('/term4')}
      />
      <List
        select="check"
        checked={checkedTerm5}
        onSelected={() => {
          setCheckedTerm5(!checkedTerm5);
        }}
        description="(선택)개인정보 제 3자 제공 동의"
        action="arrow"
        onActionClick={() => navigation('/term5')}
      />{' '}
      <FixedButtons>
        <SolidButton
          disabled={!buttonActive}
          onClick={() => {
            trackUser(ampEvents.Register1_Next);
            authStore.setAgreements(checkedTerm4, checkedTerm5);
            navigation('/register2', { replace: true });
          }}
        >
          가입 계속하기
        </SolidButton>
      </FixedButtons>
    </PageContainer>
  );
};
export default observer(Register1);
