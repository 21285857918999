import React from 'react';
import styled from 'styled-components';
import PageContainer from 'components/common/PageContainer';
import TopBar from 'components/common/top_bar/TopBar';

const Term5: React.FC = props => {
  return (
    <PageContainer>
      <TopBar left="back" title="개인정보 제 3자 제공 동의" />

      <Term>
        {TERM5.split('\n').map((text, index) => (
          <p key={String(index)}>{text}</p>
        ))}
      </Term>
    </PageContainer>
  );
};
export default Term5;

const Term = styled.div`
  width: 100%;
  padding: 0 0 20px 0;
  font-size: 14px;
  line-height: 165.3%;
  letter-spacing: -0.05em;
  color: #494949;
`;

const TERM5 = `개인정보 제 3자 제공 동의
①	 개인정보를 제공받는 자 : 모집위탁계약을 체결한 자
②	 개인정보를 제공받는 자의 개인정보 이용 목적 : 고객관리 및 마케팅 활용, 이벤트 행사 안내, 시장 조사 및 상품 서비스 개발연구
③	 제공하는 개인정보의 항목 : 성명, 전화번호, 생년월일
④	개인정보를 제공받는 자의 개인정보 보유 및 이용 기간 : 제공 후 1년
⑤	동의를 거부할 수 있으며, 동의 거부시 포인트 적립 서비스가 제공되지 않습니다.

`;
