import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
:root {
   --vh: 100%;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*,
*::before,
*::after {
  font-family: SUIT, sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
ul,
li {
  list-style: none;
}
a,
a:hover,
a:active {
  text-decoration: none;
}
h1,
h2,
h3,
p {
  letter-spacing: -0.03em;
}
input {
  border: none;
  outline: none;
  border-width: 0;
    &:focus {
    outline: none;
  }
}
button {
  background: transparent;
  border: none;
  cursor: pointer;
}
.ReactModal__Overlay {
  opacity: 0;
  transition: all 300ms linear;
}
.ReactModal__Content {
  transform: translateY(100%);
  transition: all 300ms linear;
}
.ReactModal__Content--after-open {
  opacity: 1;
  transform: translateY(0);
}
.ReactModal__Content--before-close {
  opacity: 0;
  transform: translateY(100%);
}
.ReactModal__Overlay--after-open {
  opacity: 1;
}
.ReactModal__Overlay--before-close {
  opacity: 0;
}
`;
