import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie-player';
import { ampEvents } from 'amplitude-events';
import trackUser from 'services/trackUser';
import { useStores } from 'stores/RootStore';
import SolidButton from 'components/common/solid_button/SolidButton';
import Spinner from 'assets/lottie/LottieSpinner.json';

function PreviewButton({ isEdit, id }: { isEdit: boolean; id: string }) {
  const navigation = useNavigate();
  const { fileStore, printJobBuilderStore, printJobStore } = useStores();

  const isConverting =
    fileStore.files.find(file => file.id === id)?.isConverting ||
    fileStore.files.find(file => file.id === id)?.errorType !== null;

  const isEditConverting = printJobStore.printJobs.find(pj => pj.id === id)
    ?.file.isConverting;

  const handleClick = () => {
    trackUser(ampEvents.PrintWizard3_Preview);
    if (isConverting) return;
    navigation(
      `/printjobbuilders/${
        printJobBuilderStore.getPrintJobByFileId(id)?.builderId
      }/preview`
    );
  };

  const handleEditClick = () => {
    if (isEditConverting) return;
    navigation(`/printjobs/${id}/preview`);
  };

  return (
    <StyledButton
      kind="skyblue"
      area="sm"
      disabled={!isEdit ? isConverting : isEditConverting}
      onClick={!isEdit ? handleClick : handleEditClick}
    >
      {!isEdit && isConverting && (
        <StyledLottie loop animationData={Spinner} play />
      )}
      {isEdit && isEditConverting && (
        <StyledLottie loop animationData={Spinner} play />
      )}
      미리보기
    </StyledButton>
  );
}

export default observer(PreviewButton);

const StyledButton = styled(SolidButton)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;

  display: flex;
  align-items: center;
  gap: 4px;

  width: max-content;
`;

const StyledLottie = styled(Lottie)`
  width: 12px;
`;
