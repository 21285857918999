import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import OptionButton from './OptionButton';
import { ReactComponent as IconAutoActive } from 'assets/icons/printSettings/icon_auto_active.svg';
import { ReactComponent as IconAutoInActive } from 'assets/icons/printSettings/icon_auto_inactive.svg';
import { ReactComponent as IconPortraitActive } from 'assets/icons/printSettings/icon_portrait_active.svg';
import { ReactComponent as IconPortraitInActive } from 'assets/icons/printSettings/icon_portrait_inactive.svg';
import { ReactComponent as IconLandscapeActive } from 'assets/icons/printSettings/icon_landscape_active.svg';
import { ReactComponent as IconLandscapeInActive } from 'assets/icons/printSettings/icon_landscape_inactive.svg';
import { useStores } from 'stores/RootStore';
import { observer } from 'mobx-react-lite';
import { PaperOrientation } from 'types/job/print-job/print-ticket/PaperOrientation';

type Props = {
  fileId: string;
};

const PaperOrientationOption: React.FC<Props> = ({ fileId }) => {
  const { printJobBuilderStore } = useStores();

  const [option, setOption] = useState<PaperOrientation>();

  const handleOption = (option: PaperOrientation) => {
    const printOption =
      printJobBuilderStore.getPrintJobByFileId(fileId)?.ticket;
    if (printOption) {
      printJobBuilderStore.getPrintJobByFileId(fileId)?.setTicket({
        ...printOption,
        paperOrientation: option,
      });
      setOption(option);
    }
  };

  useEffect(() => {
    setOption(
      printJobBuilderStore.getPrintJobByFileId(fileId)?.ticket
        ?.paperOrientation ?? PaperOrientation.AUTO
    );
  }, [fileId]);

  return (
    <Container>
      <OptionButton
        onClick={() => handleOption(PaperOrientation.AUTO)}
        active={option === PaperOrientation.AUTO}
        label="자동"
      >
        {option === PaperOrientation.AUTO && <IconAutoActive />}
        {option !== PaperOrientation.AUTO && <IconAutoInActive />}
      </OptionButton>
      <OptionButton
        onClick={() => handleOption(PaperOrientation.LANDSCAPE)}
        active={option === PaperOrientation.LANDSCAPE}
        label="가로"
      >
        {option === PaperOrientation.LANDSCAPE && <IconLandscapeActive />}
        {option !== PaperOrientation.LANDSCAPE && <IconLandscapeInActive />}
      </OptionButton>
      <OptionButton
        onClick={() => handleOption(PaperOrientation.PORTRAIT)}
        active={option === PaperOrientation.PORTRAIT}
        label="세로"
      >
        {option === PaperOrientation.PORTRAIT && <IconPortraitActive />}
        {option !== PaperOrientation.PORTRAIT && <IconPortraitInActive />}
      </OptionButton>
    </Container>
  );
};

export default observer(PaperOrientationOption);

const Container = styled.div`
  gap: 16px;
  padding: 16px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
