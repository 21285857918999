import { HistoryContext } from 'contexts/HistoryBackContext';
import { useContext, useEffect } from 'react';

const usePopstateListener = () => {
  const { historyHandler } = useContext(HistoryContext);

  useEffect(() => {
    window.addEventListener('popstate', historyHandler[0]);

    return () => {
      window.removeEventListener('popstate', historyHandler[0]);
    };
  }, [historyHandler]);
};

export default usePopstateListener;
