import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores/RootStore';

type ServiceType = 'prints' | 'scans' | 'copies';

export function CheckJob() {
  const { userStore, jobPollingStore } = useStores();
  const [serviceType, setServiceType] = useState<ServiceType | null>(null);
  const [jobIds, setJobIds] = useState<string[] | null>(null);
  const [orderId, setOrderId] = useState<string | null>(null);
  const navigation = useNavigate();

  useEffect(() => {
    if (!jobIds || !orderId) {
      return;
    }

    if (jobIds && orderId) {
      jobPollingStore.setService(serviceType);
      jobPollingStore.setCurrentJobIds(jobIds);
      jobPollingStore.setOrderId(orderId);

      if (serviceType === 'prints') {
        jobPollingStore.startPrintJobPollingTimer();
      } else {
        jobPollingStore.startScanCopyJobPollingTimer();
      }
      navigation('/job-working'); // TODO scan, copy는어디로..?
    }
  }, [jobIds, orderId]);

  useEffect(() => {
    if (userStore.userId) {
      const localServiceType = localStorage.getItem(
        'serviceType'
      ) as ServiceType;
      const localJobIds = localStorage.getItem('jobIds');
      const localOrderId = localStorage.getItem('orderId');

      setServiceType(localServiceType);
      setJobIds(JSON.parse(localJobIds!));
      setOrderId(localOrderId);
    }
  }, [userStore.userId]);

  return <Outlet />;
}

export default observer(CheckJob);
