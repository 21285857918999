import React, { useState } from 'react';
import { Duplex } from 'types/job/print-job/print-ticket/Duplex';
import { LayoutOrder } from 'types/job/print-job/print-ticket/LayoutOrder';
import { PageFitting } from 'types/job/print-job/print-ticket/PageFitting';
import { PaperOrientation } from 'types/job/print-job/print-ticket/PaperOrientation';
import { PrintTicket } from 'types/job/print-job/print-ticket/PrintTicket';
import { PageRange } from 'types/job/print-job/print-ticket/PageRange';
import { PaperSize } from 'types/job/print-job/print-ticket/PaperSize';

const defaultTicket: PrintTicket = {
  version: 'v2.0',
  layout: { order: LayoutOrder.RIGHT_TO_DOWN, nUp: 1 },
  copies: 1,
  duplex: Duplex.ONE_SIDED,
  fitToPage: PageFitting.FIT_TO_PAGE,
  isColor: true,
  pageRanges: [],
  paperOrientation: PaperOrientation.AUTO,
  paperSize: PaperSize.A4,
};

export function usePreview() {
  const [groupedUrls, setGroupedUrls] = useState<string[][]>([]);
  const [nbspNum, setNbspNum] = useState<number>(0);
  const [ticket, setTicket] = useState<PrintTicket>(defaultTicket);

  const nbspArr = [0, 2, 1];

  const makeFilter = (pageRanges: PageRange[]) => {
    if (pageRanges.length === 0) {
      return null;
    }

    return Array.from(
      new Set(
        pageRanges.flatMap(pr =>
          Array(pr.end - pr.start + 1)
            .fill(undefined)
            .map((_, idx) => pr.start + idx)
        )
      )
    );
  };

  const updatePreview = (
    urls: string[],
    ticket: PrintTicket = defaultTicket
  ) => {
    const pageRangeFilter = makeFilter(ticket.pageRanges);

    const filtered = urls.filter((url, idx) => {
      if (!pageRangeFilter) return true; // 설정된 page range 가 없으면

      const curPage = idx + 1;
      return pageRangeFilter.includes(curPage);
    });

    const grouped: string[][] = [];
    const { nUp } = ticket.layout;
    for (let i = 0; i < filtered.length; i += nUp) {
      grouped.push(filtered.slice(i, i + nUp));
    }

    setGroupedUrls(grouped);
    setTicket(ticket);
    setNbspNum(nbspArr[filtered.length]);
  };

  return { groupedUrls, updatePreview, ticket, nbspNum };
}
