import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStores } from 'stores/RootStore';
import { PrintJobStatus } from 'types/job/print-job/PrintJobStatus';
import { ScanJobStatus } from 'types/job/scan-job/ScanJobStatus';
import { CopyJobStatus } from 'types/job/copy-job/CopyJobStatus';

// TODO: stopped에 관한 상황을 아직 처리하지 않았음

export default function useStatusOnBackground() {
  const navigation = useNavigate();
  const [jobStatus, _] = useState('');
  const { jobPollingStore } = useStores();

  useEffect(() => {
    switch (jobPollingStore.serviceType) {
      case 'prints':
        switch (jobPollingStore.status) {
          // case PrintJobStatus.Requested:
          //   navigation('/waiting-list', { replace: true });
          //   break;
          case PrintJobStatus.CheckoutCompleted:
            navigation('/job-working', { replace: true });
            break;
          case PrintJobStatus.ConvertPending:
            navigation('/job-working', { replace: true });
            break;
          case PrintJobStatus.Printing:
            navigation('/job-working', { replace: true });
            break;
          case PrintJobStatus.Done:
            navigation('/job-complete', { replace: true });
            break;
          case PrintJobStatus.Error:
            navigation('/job-error', { replace: true });
            break;
          case PrintJobStatus.Expired:
            navigation('/waiting-list', { replace: true });
            break;
          case PrintJobStatus.Deleted:
            navigation('/waiting-list', { replace: true });
            break;
        }
        break;
      case 'scans':
        switch (jobPollingStore.status) {
          case ScanJobStatus.Requested:
            navigation('/waiting-list', { replace: true });
            break;
          case ScanJobStatus.SeletingOption:
            navigation('/job-in-progress', { replace: true });
            break;
          case ScanJobStatus.Scanning:
            navigation('/job-working', { replace: true });
            break;
          case ScanJobStatus.WaitingCheckout:
            navigation('/job-payment', { replace: true });
            break;
          case ScanJobStatus.Done:
            navigation('/job-complete', { replace: true });
            break;
          case ScanJobStatus.Error:
            navigation('/job-error', { replace: true });
            break;
          case ScanJobStatus.Expired:
            navigation('/waiting-list', { replace: true });
            break;
          case ScanJobStatus.Canceled:
            navigation('/waiting-list', { replace: true });
            break;
        }
        break;
      case 'copies':
        switch (jobPollingStore.status) {
          case CopyJobStatus.Requested:
            navigation('/waiting-list', { replace: true });
            break;
          case CopyJobStatus.SeletingOption:
            navigation('/job-in-progress', { replace: true });
            break;
          case CopyJobStatus.Scanning:
            navigation('/job-working', { replace: true });
            break;
          case CopyJobStatus.WaitingCheckout:
            navigation('/job-payment', { replace: true });
            break;
          case CopyJobStatus.Printing:
            navigation('/job-working', { replace: true });
            break;
          case CopyJobStatus.Done:
            navigation('/job-complete', { replace: true });
            break;
          case CopyJobStatus.Error:
            navigation('/job-error', { replace: true });
            break;
          case CopyJobStatus.Expired:
            navigation('/waiting-list', { replace: true });
            break;
          case CopyJobStatus.Canceled:
            navigation('/waiting-list', { replace: true });
            break;
        }
        break;
      default:
        navigation('/waiting-list', { replace: true });
    }
  }, [jobPollingStore.status]);

  return jobStatus;
}
