import { makeAutoObservable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

export class Uploadable {
  id: string;
  uploadUrl: string;
  objectName: string;
  file: File;
  transferred = 0;
  isUploaded = false;
  isCreated = false;

  constructor(id: string, file: File, uploadUrl: string, objectName: string) {
    makeAutoObservable(this, {});

    this.id = id;
    this.file = file;
    this.uploadUrl = uploadUrl;
    this.objectName = objectName;
  }

  setIsUploaded() {
    this.isUploaded = true;
  }

  setIsCreated() {
    this.isCreated = true;
  }

  setFileTransferred(bytes: number) {
    this.transferred = bytes;
  }
}
