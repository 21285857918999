import AndroidBridge from './Android';
import IOSBridge from './Ios';
import WebBridge from './Web';

export interface Bridge {
  downloadFiles: (files: { url: string; name: string }[]) => void;
  getVersionName: () => string;
  toString: () => string;
}

function createBridgeByPlatform(): Bridge {
  if (isAndroid()) return new AndroidBridge();
  else if (isIOS()) return new IOSBridge();
  return new WebBridge();
}

function isAndroid() {
  if ((window as any)?.Android) return true;
  return false;
}

function isIOS() {
  if ((window as any)?.webkit && (window as any)?.webkit?.messageHandlers) {
    return true;
  }
  return false;
}

export default createBridgeByPlatform();
