import { AxiosError, AxiosResponse } from 'axios';
import { makeAutoObservable } from 'mobx';
import { NotAuthorizedError } from 'errors';
import { HttpRequestService } from 'services/http-request.service';
import { CardDto } from 'types/card/Card.dto';
import { CreateCardDto } from 'types/card/CreateCard.dto';
import { UserStore } from '../user/UserStore';
import { Card } from './Card';
import * as Sentry from '@sentry/react';
import { ApiError } from 'types/error/ApiError';

type CardStoreErrors = {
  loadCardError?: Error;
  deleteCardError?: Error;
  setPriorityError?: Error;
  createCardError?: string;
};

export class CardStore {
  isLoading = false;
  cards: Card[] = [];
  errors: CardStoreErrors = {};

  constructor(
    private readonly userStore: UserStore,
    private readonly http: HttpRequestService
  ) {
    makeAutoObservable(this, {});
  }

  async loadCards() {
    this.startLoading();

    if (!this.userStore.user) {
      this.errors.loadCardError = new NotAuthorizedError();
      this.endLoading();
      return;
    }

    try {
      const res = await this.http
        .create(true)
        .get<CardDto[]>(`users/${this.userStore.userId}/cards`);

      this.updateFromServer(res.data);
    } catch (err) {
      this.errors.loadCardError = err as AxiosError;
    } finally {
      this.endLoading();
    }
  }

  async deleteCard(card: Card) {
    this.startLoading();

    try {
      await this.http
        .create(true)
        .delete(`users/${this.userStore.userId}/cards/${card.id}`);
      await this.loadCards();
    } catch (err) {
      this.errors.deleteCardError = err as AxiosError;
      this.endLoading();

      Sentry.captureException(
        new ApiError(err as AxiosError, 'card delete 에러 발생')
      );
    }
  }

  async setPriority(card: Card) {
    this.startLoading();

    try {
      await this.http
        .create(true)
        .post(`users/${this.userStore.userId}/cards/${card.id}/set-priority`);
      await this.loadCards();
    } catch (err) {
      this.errors.setPriorityError = err as AxiosError;
      this.endLoading();

      Sentry.captureException(
        new ApiError(err as AxiosError, 'card set-priority 에러 발생')
      );
    }
  }

  async createCard(cardDto: CreateCardDto) {
    this.startLoading();

    try {
      await this.http
        .create(true)
        .post(`users/${this.userStore.userId}/cards/create`, cardDto);
      await this.loadCards();
    } catch (err) {
      const { response } = err as AxiosError;
      const { data } = response as AxiosResponse;
      this.errors.createCardError = data.message;
      this.endLoading();
    }
  }

  updateFromServer(dtos: CardDto[]) {
    // mobx 문서에는 이렇게 나와있지 않은데,
    // boba v3 에서 이렇게 해도 상관 없었음
    this.cards = dtos.map(dto => new Card(dto));
  }

  get priorityCard(): string | null {
    if (this.cards.length) {
      return this.cards.filter(card => !card.isPriority)[0].id;
    }
    return null;
  }

  clearError() {
    this.errors = {};
  }

  startLoading() {
    this.isLoading = true;
  }

  endLoading() {
    this.isLoading = false;
  }
}
