import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ReactModal from 'react-modal';
import { TextUtils } from 'utils/text.util';

const overlayStyle: React.CSSProperties = {
  zIndex: 16,
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(38, 38, 38, 0.5)',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
};

const contentStyle: React.CSSProperties = {
  position: 'static',
  width: '100%',
  maxWidth: '780px',
  border: 'none',
  background: '#fff',
  outline: 'none',
  overflow: 'auto',
  WebkitOverflowScrolling: 'touch',
  borderRadius: '16px 16px 0px 0px',
  padding: '25px 30px',
};

const TitleWrapper = styled.div``;

const Title = styled.p`
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid #e1e1e1;

  font-weight: normal;
  color: #767676;
  font-size: 16px;
  line-height: 24px;
`;

const MIN_WIDTH = 300;

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  title: string;
  children: React.ReactNode;
}

const ContextMenu: React.FC<Props> = ({
  isOpen,
  onRequestClose,
  title,
  children,
}) => {
  const titleRef = useRef<HTMLDivElement>(null);
  const [titleAreaWidth, setTitleAreaWidth] = useState<number>(MIN_WIDTH);
  const [adjustedTitle, setAdjustedTitle] = useState<string>(title);

  useEffect(() => {
    if (titleRef.current == null) return;

    setTitleAreaWidth(titleRef.current.clientWidth);
  }, [titleRef.current]);

  useEffect(() => {
    const titleLength = TextUtils.getTextWidth(title) ?? 0;

    if (titleLength <= titleAreaWidth) {
      setAdjustedTitle(title);
    } else {
      setAdjustedTitle(TextUtils.getAbbreviatedText(title, titleAreaWidth));
    }
  }, [title]);

  if (isOpen == null) return null;

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      closeTimeoutMS={300}
      overlayClassName="ContextMenu__Overlay"
      className="ContextMenu__Content"
      style={{
        overlay: overlayStyle,
        content: contentStyle,
      }}
      contentLabel="Modal"
    >
      <TitleWrapper ref={titleRef}>
        <Title>{adjustedTitle}</Title>
      </TitleWrapper>
      {children}
    </ReactModal>
  );
};

export default ContextMenu;
