import { Store } from 'stores/RootStore';
import { httpRequestService } from './http-request.service';
import { PrintJobDto } from 'types/job/print-job/PrintJob.dto';
import { ScanJobDto } from 'types/job/scan-job/ScanJob.dto';
import { CopyJobDto } from 'types/job/copy-job/CopyJob.dto';
import { PatchJobDto } from 'types/job/PatchJob.dto';
import { PatchJobStatusDto } from 'types/job/PatchJobStatus.dto';
import { CreateScanCopyJobDto } from 'types/job/CreateScanCopyJob.dto';
import { VerifyRequestDto } from 'types/job/VerifyRequest.dto';
import { VerifyResponseDto } from 'types/job/VerifyResponse.dto';
import { ReviewDto } from 'types/job/Review.dto';
import { ReviewType } from 'types/job/ReviewType.enum';
import { ErrorType } from 'types/job/ErrorType.enum';
import { ReportErrorDto } from 'types/job/ReportError.dto';

export class JobService {
  constructor() {}

  async getPrintJobs() {
    return await httpRequestService
      .create(true)
      .get<PrintJobDto[]>(`users/${Store.userStore.userId}/jobs/prints`);
  }

  async getOnePrintJob(printJobId: string) {
    return await httpRequestService
      .create(true)
      .get<PrintJobDto>(
        `users/${Store.userStore.userId}/jobs/prints/${printJobId}`
      );
  }

  async getScanJobs() {
    return await httpRequestService
      .create(true)
      .get<ScanJobDto[]>(`users/${Store.userStore.userId}/jobs/scans`)
      .then(res => {
        const sorted_list = res.data.sort((a, b) => {
          return (
            new Date(b.modifiedAt).getTime() - new Date(a.modifiedAt).getTime()
          );
        });
        return sorted_list;
      });
  }

  async getCopyJobs() {
    return await httpRequestService
      .create(true)
      .get<CopyJobDto[]>(`users/${Store.userStore.userId}/jobs/copies`)
      .then(res => {
        const sorted_list = res.data.sort((a, b) => {
          return (
            new Date(b.modifiedAt).getTime() - new Date(a.modifiedAt).getTime()
          );
        });
        return sorted_list;
      });
  }

  async createScanCopyJob(serviceType: string, kioskId: string) {
    const dto: CreateScanCopyJobDto = {
      kioskId,
    };
    return await httpRequestService
      .create(true)
      .post(`users/${Store.userStore.userId}/jobs/${serviceType}/create`, dto);
  }

  async changeKiosk(jobIds: string[], serviceType: string, kioskId: string) {
    const dto: PatchJobDto = { printZoneId: kioskId };

    return await Promise.all(
      jobIds.map(async jobId => {
        return await httpRequestService
          .create(true)
          .patch(
            `users/${Store.userStore.userId}/jobs/${serviceType}/${jobId}`,
            dto
          );
      })
    );
  }

  async patchJobStatus(jobId: string, serviceType: string, status: string){
    const dto: PatchJobStatusDto = { status }
    return await httpRequestService
    .create(true)
    .patch(
      `users/${Store.userStore.userId}/jobs/${serviceType}/${jobId}`,
      dto
    );
  }

  async deleteOneJob(serviceName: string, jobId: string) {
    return await httpRequestService
      .create(true)
      .delete(`users/${Store.userStore.userId}/jobs/${serviceName}/${jobId}`);
  }

  async verifyNumber(
    serviceType: string,
    verifyNum: string,
    printzoneId: string
  ) {
    const dto: VerifyRequestDto = {
      verificationNumber: verifyNum,
      printzoneId: printzoneId,
    };
    return await httpRequestService
      .create(true)
      .post<VerifyResponseDto>(
        `users/${Store.userStore.userId}/jobs/${serviceType}/verify`,
        dto
      );
  }

  async getOneScanCopyJob(serviceType: string, jobId: string) {
    return await httpRequestService
      .create(true)
      .get<ScanJobDto | CopyJobDto>(
        `users/${Store.userStore.userId}/jobs/${serviceType}/${jobId}`
      );
  }

  async writeReview(orderId: string, stars: number, message: string) {
    const dto: ReviewDto = {
      stars: stars,
      type: ReviewType.Review,
      message: message,
    };
    return await httpRequestService
      .create(true)
      .post(
        `users/${Store.userStore.userId}/orders/jobs/${orderId}/review`,
        dto
      );
  }

  async reportError(
    jobIds: string[],
    serviceType: string,
    type: ErrorType | undefined,
    message: string | null
  ) {
    const dto: ReportErrorDto = {
      type: type ?? '',
      message: message,
    };

    return await httpRequestService
      .create(true)
      .post(
        `users/${Store.userStore.userId}/jobs/${serviceType}/${jobIds[0]}/report-error`,
        dto
      );
  }
}

export const jobService = new JobService();
