import React, { useEffect } from 'react';
import styled from 'styled-components';
import BottomNavbar from 'components/common/BottomNavbar';
import PageContainer from 'components/common/PageContainer';
import TopBar from 'components/common/top_bar/TopBar';
import Icon from 'components/common/icon/Icon';
import PrintZoneMap from 'components/pages/PrintZoneMap';
import { useNavigate } from 'react-router-dom';
import { Spacing } from 'styles/styles';
import { useStores } from 'stores/RootStore';
import { observer } from 'mobx-react-lite';
import { ampEvents } from 'amplitude-events';
import trackUser from 'services/trackUser';

const Map: React.FC = props => {
  const navigation = useNavigate();
  const { userStore } = useStores();

  useEffect(() => {
    userStore.updateReload(false);
  }, []);

  return (
    <>
      <PageContainer>
        <TopBar title="프린터 찾기" />

        {/* searching + input */}
        <SearchContainer isSearching={false}>
          <InputContainer
            isSearching={false}
            onClick={() => {
              trackUser(ampEvents.Map_SearchBox);
              navigation('/printzone/search');
            }}
          >
            <Icon icon="IconSearch" size={20} />
            <Spacing.Horizontal width={8} />
            <Input placeholder="장소를 검색하세요" />
          </InputContainer>
        </SearchContainer>
      </PageContainer>

      <PrintZoneMap />
      <BottomNavbar />
    </>
  );
};

export default observer(Map);

const SearchContainer = styled.div<{ isSearching: boolean }>`
  z-index: 2;

  position: absolute;
  top: 56px;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  justify-content: center;

  width: 100%;
  max-width: 732px;
  padding: 20px 0;
  background: ${props => (props.isSearching ? '#ffffff' : 'transparent')};
`;

const InputContainer = styled.div<{ isSearching: boolean }>`
  top: 76px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  width: calc(100% - 48px);
  max-width: 732px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
`;

const Input = styled.input`
  z-index: 3;
  background: transparent;
  width: 100%;
  height: 100%;
  outline: none;
  border-width: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #404040;

  ::placeholder,
  ::-webkit-input-placeholder {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #a3a3a3;
  }
`;
