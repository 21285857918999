import { FileConvertedErrorType } from 'types/file/FileConvertedErrorType.enum';
import { makeAutoObservable } from 'mobx';
import { HttpRequestService } from 'services/http-request.service';
import { FileDocumentDto } from 'types/file/FileDocument.dto';
import { FileDocument } from './File';
import axios from 'axios';
import { DownLoadUrlDto } from 'types/file/DownLoadUrl.dto';
import { UserStore } from '../user/UserStore';

export type SortMode = 'filename' | 'size' | 'recent' | 'extension';
type ViewMode = 'list' | 'grid';
export class FileStore {
  isLoading = false;
  timer: NodeJS.Timer | null = null;
  interval = 3000;

  files: FileDocument[] = [];

  constructor(
    private readonly userStore: UserStore,
    private readonly http: HttpRequestService
  ) {
    makeAutoObservable(this, {});
    this.timer = setInterval(() => this.watchFilesConverting(), this.interval); // 주기적으로 변환 완료되지 않은 파일들의 상태를 재 조회한다.
  }

  async loadFiles() {
    this.startLoading();

    return await this.http
      .create(true)
      .get<FileDocumentDto[]>(`users/${this.userStore.userId}/files`)
      .then(response => this.updateManyFilesFromServer(response.data))
      .finally(() => {
        this.endLoading();
      });
  }

  async deleteFile(id: string) {
    this.startLoading();
    return await this.http
      .create(true)
      .delete(`users/${this.userStore.userId}/files/${id}`)
      .then(() => this.loadFiles())
      .finally(() => this.setSelectMode(false));
  }

  findOneWithId(id: string) {
    return this.files.find(f => f.id === id);
  }

  /**
   * load files 이후 store 의 파일 배열을 업데이트한다.
   * job store 에서 file 을 참조하고 있기 때문에 card store 과는 다르게 SSOT 원칙을 지켜야 함.
   * @param dtos
   */
  private updateManyFilesFromServer(dtos: FileDocumentDto[]) {
    // 1. DTO 에 없는 파일 삭제
    this.files = this.files.filter(
      (
        file // dto 에 없는 파일을 필터링 한 새로운 배열을 return (요소의 참조는 유지 됨)
      ) => dtos.find(dto => file.id === dto.id) // dto 에 id 에 해당하는 파일이 있는지
    );

    // 2. DTO 와 store 에 둘 다 있는 파일 업데이트
    dtos.map(dto => this.updateOneFileFromServer(dto));

    // 3. DTO 에만 있는 파일 생성
    dtos
      .filter(dto => !this.files.find(file => file.id === dto.id)) // file store 에서 일치하는 파일을 찾지 못한 dto 들만 filter
      .forEach(dto => this.createOneFileFromServer(dto)); // 각각의 dto 를 file store 에 push
  }

  /**
   * fileStore 에 존재하는 파일의 정보를 업데이트 한다.
   * @param dto
   * @returns
   */
  private updateOneFileFromServer(dto: FileDocumentDto) {
    const file = this.files.find(file => file.id === dto.id);
    if (!file) {
      console.warn('updateOneFileFromServer cannot find file!');
      return;
    }
    file.updateFileFromDto(dto);
  }

  /**
   * fileStore 에 새로운 파일을 dto 로 부터 추가하기 위함.
   * @param dto
   */
  private createOneFileFromServer(dto: FileDocumentDto) {
    this.files.push(new FileDocument(dto, this.userStore, this.http));
  }

  /**
   * 변환이 완료되지 않은 파일이 있으면 해당 파일의 상태를 업데이트 하는 로직. 생성자의 setInterval 에서 주기적으로 호출됨.
   * @returns
   */
  private watchFilesConverting() {
    if (this.isLoading) return;

    const filesConvertedYet = this.files.filter(file => file.isConverting);
    if (filesConvertedYet.length === 0) return; // 변환이 되지 않은 파일이 없으면 종료함.

    filesConvertedYet.map(file => file.sync(this.interval));
  }

  startLoading() {
    this.isLoading = true;
  }

  endLoading() {
    this.isLoading = false;
  }

  /**
   * UI 관련
   */

  sortDescending = true;
  sortMode: SortMode = 'recent';
  viewMode: ViewMode = 'list';

  selectMode = false;
  selectedFile: FileDocument['id'][] = [];

  optionMode: boolean = false;

  optionModeFile: {
    fileId: string;
    fileName: string;
    fileOrigin: string;
    errorType: FileConvertedErrorType | null;
  } | null = null;

  /**
   * 선택모드
   * 중복되지 않게 selectedFile에 fileId추가
   * 같은 file클릭시 selectedFile에서 제거
   * 제거 후, selectedFile의 길이가 0일시 선택모드(selectmode) 종료
   */
  updateSelectedFile(fileId: string) {
    if (this.selectedFile.includes(fileId)) {
      const idx = this.selectedFile.indexOf(fileId);
      this.selectedFile.splice(idx, 1);
      if (this.selectedFile.length === 0) {
        this.setSelectMode(false);
      }
      return;
    }
    this.selectedFile.push(fileId);
  }

  setSelectMode(isSelect: boolean) {
    if (!isSelect) {
      this.selectedFile = [];
    }
    this.selectMode = isSelect;
  }

  setSortMode(mode: SortMode) {
    this.sortMode = mode;
    this.files = this.sortedFiles();
  }

  setSortDescending(isDescending: boolean) {
    this.sortDescending = isDescending;
    this.files = this.sortedFiles();
  }

  setOptionMode(isOption: boolean) {
    this.optionMode = isOption;
  }

  sortedFiles() {
    const sorted = this.files
      // .filter(file => !file.errorType)
      .sort((a: FileDocument, b: FileDocument) => {
        switch (this.sortMode) {
          case 'filename':
            return a.name
              .toLocaleLowerCase()
              .localeCompare(b.name.toLocaleLowerCase());
          case 'extension': {
            const aSplit = a.name.split('.');
            const aExt = aSplit[aSplit.length - 1];
            const bSplit = b.name.split('.');
            const bExt = bSplit[bSplit.length - 1];
            return aExt
              .toLocaleLowerCase()
              .localeCompare(bExt.toLocaleLowerCase());
          }
          case 'size':
            return a.size - b.size;
          case 'recent':
            if (!new Date(a.createdAt).getTime()) {
              return -1;
            }
            if (!new Date(b.createdAt).getTime()) {
              return 1;
            }
            return (
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            );

          default:
            return a.name
              .toLocaleLowerCase()
              .localeCompare(b.name.toLocaleLowerCase());
        }
      });
    return this.sortDescending ? sorted : sorted.reverse();
  }

  setViewMode(mode: ViewMode) {
    this.viewMode = mode;
  }

  // 더보기 버튼 클릭시
  setOptionModeFile(
    fileId: string,
    fileName: string,
    fileOrigin: string,
    errorType: FileConvertedErrorType | null
  ) {
    this.optionModeFile = { fileId, fileName, fileOrigin, errorType };
  }

  clearOptionModeFile() {
    this.optionModeFile = null;
  }
}
