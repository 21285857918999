import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router';
import {
  getColumnFromOptions,
  getRowFromOptions,
  showPortrait,
} from 'utils/print-setting.util';
import { observer } from 'mobx-react';
import { NUp } from 'types/job/print-job/print-ticket/NUp';
import { PaperOrientation } from 'types/job/print-job/print-ticket/PaperOrientation';
import PageContainer from 'components/common/PageContainer';
import Topbar from 'components/common/top_bar/TopBar';
import Icon from 'components/common/icon/Icon';
import styled, { css } from 'styled-components';
import { useStores } from 'stores/RootStore';
import { FileOrientation } from 'types/file/FileOrientation.enum';
import { LayoutOrder } from 'types/job/print-job/print-ticket/LayoutOrder';
import { FileDocument } from 'stores/file/File';
import { usePreview } from 'hooks/usePreview';
import { Spacing } from 'styles/styles';

// TODO: File
const PrintPreview: React.FC = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const { fileStore, printJobStore, printJobBuilderStore } = useStores();
  const { id } = useParams();

  const [isGridMode, setIsGridMode] = useState(true);
  const [fileOrientation, setFileOrientation] = useState(
    FileOrientation.PORTRAIT
  );
  const { groupedUrls, updatePreview, nbspNum, ticket } = usePreview();

  useEffect(() => {
    const { pathname } = location;
    const resource = pathname.split('/')[1];

    if (!resource || !id) {
      return navigation('/');
    }

    // TODO: typecheck 때문에 코드가 드러워 짐. 수정 필요
    let file: FileDocument | undefined;
    if (resource === 'files') {
      file = fileStore.files.find(f => f.id === id);
      if (!file) return navigation('/');

      file.fetchThumbnailsIfNotFilled().then(() => {
        if (!file || !file.orientaion || !file.imageUrls)
          return navigation('/');
        setFileOrientation(file.orientaion);
        updatePreview(file.imageUrls);
      });
    } else if (resource === 'printjobs') {
      const pj = printJobStore.printJobs.find(pj => pj.id === id);
      if (!pj || !pj.file || !pj.file.orientaion || !pj.file.imageUrls)
        return navigation('/');
      pj.file.fetchThumbnailsIfNotFilled().then(() => {
        if (!pj.file.orientaion || !pj.file.imageUrls) return navigation('/');

        setFileOrientation(pj.file.orientaion);
        updatePreview(pj.file.imageUrls, pj.ticket);
      });
    } else if (resource === 'printjobbuilders') {
      const pjb = printJobBuilderStore.printJobBuilders.find(
        pjb => pjb.builderId === id
      );
      if (!pjb || !pjb.file || !pjb.ticket) return navigation('/');
      // 썸네일 업데이트
      pjb.file.fetchThumbnailsIfNotFilled().then(() => {
        if (!pjb.file.orientaion || !pjb.file.imageUrls || !pjb.ticket)
          return navigation('/');

        setFileOrientation(pjb.file.orientaion);
        updatePreview(pjb.file.imageUrls, pjb.ticket);
      });
    } else {
      return navigation('/');
    }
  }, []);

  return (
    <PageContainer>
      <Topbar
        left="back"
        title="미리보기"
        border={true}
        rightNode={
          <>
            <Icon
              icon="IconGrid"
              themeColor={isGridMode ? 'primary600' : 'gray400'}
              onClick={() => setIsGridMode(true)}
            />
            <Spacing.Horizontal width={25} />
            <Icon
              icon="IconFit"
              themeColor={!isGridMode ? 'primary600' : 'gray400'}
              onClick={() => setIsGridMode(false)}
            />
          </>
        }
      />
      <Comment>
        {`"미리보기는 출력 결과물의 이해를 돕기위한 예시입니다."`}
      </Comment>
      <Main grid={isGridMode}>
        {groupedUrls.map((urls, index) => (
          <PageOuter
            key={`${urls[0]}-${index}`}
            filePortrait={fileOrientation === FileOrientation.PORTRAIT}
            pagePortrait={ticket.paperOrientation}
            orderRight={ticket.layout.order === LayoutOrder.RIGHT_TO_DOWN}
            nup={ticket.layout.nUp}
          >
            <PageInner
              filePortrait={fileOrientation === FileOrientation.PORTRAIT}
              pagePortrait={ticket.paperOrientation}
              orderRight={ticket.layout.order === LayoutOrder.RIGHT_TO_DOWN}
              nup={ticket.layout.nUp}
            >
              {urls.map((url, idx) => (
                // required to prevent overflow
                <div
                  onClick={() => setIsGridMode(!isGridMode)}
                  key={url}
                  style={{
                    height: '100%',
                    width: '100%',
                    position: 'relative',
                  }}
                >
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <PageImg src={url} isColor={ticket.isColor} />
                  </div>
                </div>
              ))}
            </PageInner>
            <PageIndex>{index + 1}</PageIndex>
          </PageOuter>
        ))}
        {isGridMode &&
          Array(nbspNum)
            .fill(true)
            .map((_, i) => <div key={i}>&nbsp;</div>)}
        &nbsp;
      </Main>
    </PageContainer>
  );
};

const Comment = styled.div`
  margin-top: 14px;
  font-size: 12px;
  line-height: 14px;
  color: #767676;
  text-align: center;
`;

const Main = styled.main<{ grid: boolean }>`
  position: relative;
  padding: ${({ grid }) => (grid ? '15px' : '45px')};
  flex: 1;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: ${({ grid }) => (grid ? '1fr 1fr 1fr' : '1fr')};
  gap: ${({ grid }) => (grid ? '15px' : '20px')};
  align-content: flex-start;
  justify-items: center;
  background: white;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const PageOuter = styled.div<{
  filePortrait: boolean;
  pagePortrait: PaperOrientation;
  nup: NUp;
  orderRight: boolean;
}>`
  margin: 0 6px;
  width: 100%;
  height: 0px;
  padding-top: ${({ filePortrait, pagePortrait, nup }) =>
    showPortrait({
      filePortrait,
      pagePortrait,
      nup,
    })
      ? '141.4%'
      : '70.7%'};
  position: relative;
  background: #dadada;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
`;

const PageIndex = styled.div`
  position: absolute;
  bottom: 10px;
  height: 22px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 8px;
  background: rgba(10, 10, 10, 0.3);
  border-radius: 5px;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PageInner = styled.div<{
  filePortrait: boolean;
  pagePortrait: PaperOrientation;
  nup: NUp;
  orderRight: boolean;
}>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  display: grid;
  grid-auto-flow: ${props => (props.orderRight ? 'row' : 'column')};
  grid-template-columns: ${props =>
    `repeat(${getColumnFromOptions({
      filePortrait: props.filePortrait,
      pagePortrait: props.pagePortrait,
      nup: props.nup,
    })}, 1fr)`};
  grid-template-rows: ${props =>
    `repeat(${getRowFromOptions({
      filePortrait: props.filePortrait,
      pagePortrait: props.pagePortrait,
      nup: props.nup,
    })}, 1fr)`};
  gap: 2px;
  align-items: center;
  justify-items: center;
  color: #ffffff;
`;

const PageImg = styled.img<{ isColor: boolean }>`
  /* required to prevent overflow */
  max-height: 90%;
  max-width: 85%;
  object-fit: contain;
  ${props => {
    if (!props.isColor) {
      return css`
        filter: gray;
        -webkit-filter: grayscale(100%);
      `;
    }
  }}
`;

export default observer(PrintPreview);
