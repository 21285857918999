import React from 'react';
import styled from 'styled-components';
import Icon from './icon/Icon';

interface Props {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const FloatingButton: React.FC<Props> = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      <Icon icon="IconPlus" themeColor="gray50" />
    </Button>
  );
};

export default FloatingButton;

const Button = styled.button`
  position: fixed;
  right: 24px;
  bottom: 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 56px;
  height: 56px;

  background: ${({ theme }) => theme.color.primary600};
  border-radius: 50%;
  border-width: 0;
`;
