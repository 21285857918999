import { useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import { ampEvents } from 'amplitude-events';
import trackUser from 'services/trackUser';
import { paymentService } from 'services/payment.service';
import { PayCardResponseDto } from 'types/payment/PayCardResponse.dto';
import { PrintJob } from 'stores/job/PrintJob';
import { ScanJobDto } from 'types/job/scan-job/ScanJob.dto';
import { CopyJobDto } from 'types/job/copy-job/CopyJob.dto';
import { ServiceDomain } from 'types/job/ServiceDomain';

type ConvertStatus = 'converting' | 'error' | 'success' | null;
type ScanCopyJobDTO = ScanJobDto | CopyJobDto | null;

export default function usePayment({
  serviceType,
  kioskId,
  jobIds,
  printJobs,
  scanCopyJob,
  convertStatus,
  userPoint,
}: {
  serviceType: ServiceDomain | null;
  kioskId: string | null;
  jobIds: string[] | null;
  printJobs: PrintJob[] | null;
  scanCopyJob: ScanCopyJobDTO;
  convertStatus: ConvertStatus;
  userPoint: number | undefined;
}) {
  const navigation = useNavigate();

  const getServiceTotalPrice = () => {
    switch (serviceType) {
      case 'prints':
        return calculatePrintPrice(printJobs, convertStatus);
      case 'scans':
      case 'copies':
        return calculateScanCopyPrice(scanCopyJob);
      default:
        return '';
    }
  };

  const calculateNumPages = () => {
    if (convertStatus === 'converting') return '계산중';
    else if (convertStatus === 'error') return '-';

    let answer = 0;
    printJobs?.map(pj => (answer += pj.numPrintPages ?? 0));
    return `${answer}페이지`;
  };

  /**
   * printjob file이 converting이거나 error일 경우
   * scan,copyjob의 가격정보가 없을 경우 결제 못하도록 함
   */
  const isNotPrepared = (): boolean => {
    if (printJobs) {
      if (convertStatus === 'converting') {
        return true;
      } else if (convertStatus === 'error') {
        return true;
      }
    } else if (scanCopyJob) {
      if (!scanCopyJob.price) {
        return true;
      }
    }

    return false;
  };

  /**
   * 카드가 등록되어 있지 않은 상태에서 user의 point상태가 price보다 적을 경우 결제 못하도록 함
   */
  const isPointlimitExcess = (cardId: string | null): boolean => {
    if (cardId) {
      return false;
    }
    if (printJobs) {
      let answer = 0;
      printJobs?.map(pj => (answer += pj.price ?? 0));
      if (answer > (userPoint ?? 0)) {
        return true;
      }
    } else if (scanCopyJob) {
      if ((scanCopyJob.price ?? 0) > (userPoint ?? 0)) {
        return true;
      }
    }
    return false;
  };

  const pageNavigation = (res: AxiosResponse<PayCardResponseDto>) => {
    if (res.data.isSuccess) {
      trackUser(ampEvents.JobPayment_PurchaseSuccess);
      switch (serviceType) {
        case 'prints':
        case 'copies':
          navigation('/job-working', { replace: true });
          break;
        case 'scans':
          navigation('/job-complete', { replace: true });
          break;
      }
    }
  };

  const cardPayment = async (
    cardId: string | null
  ): Promise<PayCardResponseDto> => {
    let res: AxiosResponse<PayCardResponseDto>;

    let { isSuccess, errorMsg, orderId }: PayCardResponseDto = {
      isSuccess: false,
      errorMsg: null,
      orderId: null,
    };

    try {
      if (serviceType === 'prints') {
        res = await paymentService.payPrintWithMyCard(kioskId, jobIds, cardId);
      } else {
        res = await paymentService.payScanCopyWithMyCard(
          serviceType,
          jobIds,
          cardId
        );
      }

      isSuccess = res.data.isSuccess;
      errorMsg = res.data.errorMsg;
      orderId = res.data.orderId;
      pageNavigation(res);
    } catch (err) {
      navigation('/job-error', { replace: true });
    }

    return { isSuccess, errorMsg, orderId };
  };

  useEffect(() => {
    isNotPrepared();
  }, [convertStatus]);

  return {
    getServiceTotalPrice,
    calculateNumPages,
    isNotPrepared,
    isPointlimitExcess: (cardId: string | null) => isPointlimitExcess(cardId),
    cardPayment: (cardId: string | null) => cardPayment(cardId),
  };
}

function calculatePrintPrice(
  printJobs: PrintJob[] | null,
  convertStatus: ConvertStatus
) {
  if (!printJobs || convertStatus === 'error') return '-';
  else if (convertStatus === 'converting') return '계산중';

  const price = printJobs.reduce((acc, cur) => (acc += cur.price ?? 0), 0);
  return `${price}원`;
}

function calculateScanCopyPrice(scanCopyJob: ScanCopyJobDTO) {
  if (!scanCopyJob) return '-';
  const price = scanCopyJob!.price ?? 0;
  return `${price}원`;
}
