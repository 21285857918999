export enum PaperSize {
  A4 = 'A4',
  A3 = 'A3',
  A5 = 'A5',
  B5 = 'B5',
  B4 = 'B4',
  Letter = 'Letter',
  Ledger = 'Ledger',
  Statement = 'Statement',
  Legal = 'Legal',
  Executive = 'Executive',
}
