import { subtle } from 'crypto';
import React from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import { Spacing } from 'styles/styles';
import Badge from '../badge/Badge';
import SolidButton, { SolidButtonProps } from '../solid_button/SolidButton';

interface Props extends SolidButtonProps {
  loading?: boolean;
  badge?: string;
  leftNode?: React.ReactNode;
  title?: string;
  subTitle?: string;
  upTitle?: boolean;
  text?: string;
  right?: 'button' | 'subTitle' | 'title';
  rightLabel?: string;
  rightContent?: React.ReactNode;
}
const TopInfo: React.FC<Props> = ({
  loading,
  badge,
  leftNode,
  title,
  subTitle,
  upTitle,
  text,
  right,
  rightLabel,
  rightContent,
  kind,
  onClick,
}) => {
  return (
    <Container>
      <TobLeft>
        {badge && (
          <>
            <Badge loading={loading} label={badge} />
            <Spacing.Horizontal width={16} />
          </>
        )}
        {leftNode && leftNode}
        <Contents>
          {upTitle && title && (
            <>
              <Title>{!loading ? title : <Skeleton width={120} />}</Title>
              {subTitle && <Spacing.Vertical height={4} />}
            </>
          )}
          {subTitle && (
            <SubTitle>{!loading ? subTitle : <Skeleton width={72} />}</SubTitle>
          )}
          {!upTitle && title && (
            <>
              {subTitle && <Spacing.Vertical height={4} />}
              <Title>{!loading ? title : <Skeleton width={120} />}</Title>
            </>
          )}
          {text && <Text>{!loading ? text : <Skeleton width={90} />}</Text>}
        </Contents>
      </TobLeft>
      {!loading && (
        <TobRight>
          {rightContent && rightContent}
          {right === 'subTitle' && <SubTitle>{rightLabel}</SubTitle>}
          {right === 'title' && <Title>{rightLabel}</Title>}
          {right === 'button' && (
            <SolidButton onClick={onClick} kind={kind} area="sm">
              {rightLabel}
            </SolidButton>
          )}
        </TobRight>
      )}
    </Container>
  );
};

export default TopInfo;

const Container = styled.div`
  width: 100%;
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TobLeft = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const Contents = styled.div``;

const Title = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #404040;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  display: -webkit-box;
  word-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const SubTitle = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #a3a3a3;
`;

const Text = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
`;

const TobRight = styled.div`
  width: max-content;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
