import { makeAutoObservable, runInAction } from 'mobx';
import { HttpRequestService } from 'services/http-request.service';
import { FileConverted } from 'types/file/FileConverted';
import { FileConvertedErrorType } from 'types/file/FileConvertedErrorType.enum';
import { FileDocumentDto } from 'types/file/FileDocument.dto';
import { FileOrientation } from 'types/file/FileOrientation.enum';
import { FileOrigin } from 'types/file/FileOrigin.enum';
import { UpdateFileDto } from 'types/file/UpdateFile.dto';
import { UserStore } from '../user/UserStore';

export class FileDocument {
  id: string;
  name: string;
  createdAt: Date;
  size: number;
  imageUrls: string[] | null;
  orientaion: FileOrientation | null;
  origin: FileOrigin;
  errorType: FileConvertedErrorType | null;

  private DEFAULT_TIMEOUT = 10000;

  constructor(
    dto: FileDocumentDto,
    private readonly userStore: UserStore,
    private readonly http: HttpRequestService
  ) {
    makeAutoObservable(this, {});

    this.id = dto.id;
    this.name = dto.name;
    this.createdAt = dto.createdAt;
    this.size = dto.size;
    this.imageUrls = null;
    this.orientaion = null;
    if (dto.fileConverted) {
      this.orientaion = dto.fileConverted.orientation;
      this.setFirstImageUrl(dto.fileConverted);
    }
    this.origin = dto.origin;
    this.errorType = dto.errorType;
  }

  rename(name: string) {
    const dto: UpdateFileDto = {
      name,
    };

    return this.http
      .create(true)
      .patch(`users/${this.userStore.userId}/files/${this.id}`, dto)
      .then(() => this.sync());
  }

  sync(timeout: number = this.DEFAULT_TIMEOUT) {
    return this.http
      .create(true)
      .get<FileDocumentDto>(`users/${this.userStore.userId}/files/${this.id}`, {
        timeout,
      })
      .then(response => this.updateFileFromDto(response.data))
      .catch(err => {
        console.warn('File sync failed', this, err);
        throw err;
      });
  }

  fetchThumbnailsIfNotFilled() {
    if (this.isAllImageUrlsFetched) {
      return Promise.resolve();
    }

    return this.http
      .create(true)
      .get<{ [idx: number]: string }>(
        `users/${this.userStore.userId}/files/${this.id}/images`
      )
      .then(response => {
        Object.entries(response.data).forEach(entry => {
          const [key, value] = entry;
          this.setImageUrl(parseInt(key), value);
        });
      });
  }

  updateFileFromDto(dto: FileDocumentDto) {
    this.id = dto.id;
    this.name = dto.name;
    this.createdAt = dto.createdAt;
    this.size = dto.size;
    if (!!dto.fileConverted && !this.imageUrls) {
      // dto 에서 새로운 이미지가 들어오고,
      // 로컬에는 이미지가 없는 상황에서 작동
      this.setFirstImageUrl(dto.fileConverted);
    }
    if (dto.fileConverted) {
      this.orientaion = dto.fileConverted.orientation;
    }
    this.errorType = dto.errorType;
  }

  get isConverting() {
    return !this.orientaion && !this.errorType;
  }

  get numPages() {
    if (!this.imageUrls) {
      return null;
    }
    return this.imageUrls.length;
  }

  get isAllImageUrlsFetched() {
    if (!this.imageUrls || this.imageUrls.length === 0) return false;

    return this.imageUrls.every(url => !!url); // 모든 필드가 null 이 아니라면 모든 image 가 fetch 된 것이다.
  }

  private setFirstImageUrl(fileConverted: FileConverted) {
    this.imageUrls = new Array(fileConverted.pageCount);
    this.imageUrls[0] = fileConverted.imageUrl;
  }

  private setImageUrl(idx: number, url: string) {
    if (!this.imageUrls) return;
    this.imageUrls[idx] = url;
  }
}
