import React from 'react';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
}
const PageContainer: React.FC<Props> = ({ children }) => {
  return <Container>{children}</Container>;
};

export default PageContainer;

const Container = styled.div`
  width: 100%;
  max-width: 780px;
  margin: 0 auto;
  padding-left: 24px;
  padding-right: 24px;
`;
