import { Gender } from './Gender';
export interface SignUpDto {
  email: string;
  password: string;
  name: string;
  phoneNumber: string;
  phoneAuthSessionKey: string;
  birthday: Date | null;
  gender: Gender | null;
  agreements: Agreement[];
}

// 노션 페이지에 이용약관 버전 기록해둠
export interface Agreement {
  name: AgreementType;
  version: number; // 1, 2, 3, ...
}

export enum AgreementType {
  Boba = 'Boba',
  Privacy = 'Privacy',
  Location = 'Location',
  Marketing = 'Marketing',
  ThildPartyProvision = 'ThildPartyProvision',
}
