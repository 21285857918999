import { ampEvents } from 'amplitude-events';
import IconColoringbook from 'assets/icons/contentTags/icon_coloringbook.png';
import IconMockExam from 'assets/icons/contentTags/icon_mock_exam.png';
import IconOrigami from 'assets/icons/contentTags/icon_origami.png';
import IconSemtlei from 'assets/icons/contentTags/icon_semtlei.png';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import trackUser from 'services/trackUser';
import { useStores } from 'stores/RootStore';
import styled from 'styled-components';
import TagList from './TagList';

/**
 * Contents 추천 태그(tag) 관리하는 곳 (여기서만 수정하면 됨)
 */

interface CustomTagListProps {
  beforePage: 'HOME' | 'CONTENTS';
}

function CustomTagList({ beforePage }: CustomTagListProps) {
  const { contentStore } = useStores();
  const navigation = useNavigate();

  const handleClick = (label: string) => {
    trackUser(ampEvents.Home_ContentTag);
    if (beforePage === 'HOME') {
      navigation('/contents', { state: 'home' });
    }
    contentStore.setFindText(label);
  };

  return (
    <TagContainer>
      <TagList
        name="프린트학습지"
        iconSrc={IconSemtlei}
        onClick={() => handleClick('프린트학습지')}
      />
      <TagList
        name="기출문제"
        iconSrc={IconMockExam}
        onClick={() => handleClick('수능,모의고사,기출문제')}
      />
      <TagList
        name="입체아트"
        iconSrc={IconOrigami}
        onClick={() => handleClick('입체아트')}
      />
      <TagList
        name="색칠놀이"
        iconSrc={IconColoringbook}
        onClick={() => handleClick('색칠놀이')}
      />
    </TagContainer>
  );
}
export default observer(CustomTagList);

const TagContainer = styled.div`
  width: calc(100% + 24px);
  margin-left: -24px;
  padding: 16px 0;
  padding-left: 24px;
  overflow-x: scroll;
  display: flex;
  align-items: center;

  ::-webkit-scrollbar {
    display: none;
  }

  position: relative;
  right: 0;
`;
