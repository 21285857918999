import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Service } from 'types/kiosk/Service';
import { ImgOptionColor, ImgOptionMono } from 'assets/images';

type Props = {
  services: Service[] | undefined;
  serviceName: '인쇄' | '복사' | '스캔';
  sizeName: string;
};

const PrintOptions: React.FC<Props> = ({ services, serviceName, sizeName }) => {
  const [monoPrice, setMonoPrice] = useState<number | undefined | null>();
  const [colorPrice, setColorPrice] = useState<number | undefined | null>();

  useEffect(() => {
    if (services) {
      const res = services
        .filter(service => service.serviceName === `${serviceName}`)
        .filter(service => service.colorType === 'mono')
        .filter(service => service.sizeName === `${sizeName}`);
      if (res[0]) setMonoPrice(res[0].price);
      else setMonoPrice(undefined);
    }
  }, [services, sizeName]);

  useEffect(() => {
    if (services) {
      const res = services
        .filter(service => service.serviceName === `${serviceName}`)
        .filter(service => service.colorType === 'color')
        .filter(service => service.sizeName === `${sizeName}`);
      if (res[0]) setColorPrice(res[0].price);
      else setColorPrice(undefined);
    }
  }, [services, sizeName]);

  return monoPrice === undefined && colorPrice === undefined ? (
    <></>
  ) : (
    <PrintDetailContainer>
      <PrintInfoLabel>{`${serviceName} 가격`}</PrintInfoLabel>
      <PrintDetailTitle>단면 • 한 페이지 당</PrintDetailTitle>
      <PrintOptionLine>
        {colorPrice !== undefined && colorPrice !== null && (
          <PrintOption>
            <ColorOption src={ImgOptionColor} />
            <PrintOptionContent>
              <PrintOptionLabel>{`컬러 ${serviceName}`}</PrintOptionLabel>
              <PrintOptionPrice>{`${colorPrice}원`}</PrintOptionPrice>
            </PrintOptionContent>
          </PrintOption>
        )}
        {monoPrice !== undefined && monoPrice !== null && (
          <PrintOption>
            <ColorOption src={ImgOptionMono} />
            <PrintOptionContent>
              <PrintOptionLabel>{`흑백 ${serviceName}`}</PrintOptionLabel>
              <PrintOptionPrice>{`${monoPrice}원`}</PrintOptionPrice>
            </PrintOptionContent>
          </PrintOption>
        )}
      </PrintOptionLine>
    </PrintDetailContainer>
  );
};

export default PrintOptions;

const PrintInfoLabel = styled.p`
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  color: #525252;
`;

const PrintDetailContainer = styled.div`
  padding: 12px 0;
`;

const PrintDetailTitle = styled.p`
  margin: 12px 0;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #a3a3a3;
`;

const PrintOptionLine = styled.div`
  display: flex;
  align-items: center;
`;

const PrintOption = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: 70px;
  padding: 12px 0;
`;

const PrintOptionContent = styled.div`
  margin-left: 16px;
`;

const PrintOptionLabel = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #a3a3a3;
`;

const PrintOptionPrice = styled.p`
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  color: #404040;
`;

const ColorOption = styled.img`
  width: 36px;
  height: 36px;
  background-position: center;
`;
