import React from 'react';
import styled, { css } from 'styled-components';

type Props = {
  active: boolean;
  children?: React.ReactNode;
  label?: string;
  onClick?: () => void;
};

const OptionButton: React.FC<Props> = ({
  active,
  children,
  label,
  onClick,
}) => {
  return (
    <Container onClick={onClick} active={active}>
      <OptionButtonContainer active={active}>{children}</OptionButtonContainer>
      {label && <Label active={active}>{label}</Label>}
    </Container>
  );
};

export default OptionButton;

const Container = styled.div<{ active: boolean }>`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #c2c2c2;
  ${props => {
    if (props.active)
      return css`
        color: #2784ff;
      `;
  }}
`;

const OptionButtonContainer = styled.div<{ active: boolean }>`
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  ${props => {
    if (props.active)
      return css`
        background: #ebf3ff;
        border: 1px solid #2784ff;
      `;
  }}
`;

const Label = styled.div<{ active: boolean }>`
  margin-top: 8px;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #c2c2c2;
  white-space: pre-wrap;
  ${props => {
    if (props.active)
      return css`
        color: #2784ff;
      `;
  }}
`;
