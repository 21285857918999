import * as Sentry from '@sentry/react';
import { ampEvents } from 'amplitude-events';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import Icon from 'components/common/icon/Icon';
import trackUser from 'services/trackUser';
import { useStores } from 'stores/RootStore';
import { compareVersion, isIOS } from 'utils/utils';

interface Props {}

const KakaoLoginButton: React.FC<Props> = () => {
  const { kakaoStore } = useStores();
  const handleClickKakaoLogin = () => {
    kakaoStore.startLoading();
    try {
      (async () => {
        /**
         * 카카오 로그인 기능이 개선된 버전 이상일 경우 카카오 로그인 SDK가 사용되며,
         * 업데이트 되지 않은 버전일 경우 기존 방식을 사용합니다.
         * iOS 2.2.9+
         * Android 2.3.3+
         */
        const kakaoLogin = (currentVersionCode: string) => {
          if (
            compareVersion(currentVersionCode, isIOS() ? '2.2.9' : '2.3.3') ===
            -1
          ) {
            // 기존 방식
            const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_JS_KEY}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=code`;
            window.location.href = KAKAO_AUTH_URL;
          } else {
            // 카카오 SDK 사용
            (window as any).Kakao.Auth.authorize({
              redirectUri: process.env.REACT_APP_REDIRECT_URI,
            });
          }
        };

        if (isIOS()) {
          // WebView가 아닐 때 기존 방식으로 로그인
          if (
            !(window as any)?.webkit ||
            !(window as any)?.webkit?.messageHandlers
          ) {
            kakaoLogin('0.0.0');
            return;
          }

          // 앱이 업데이트 되지 않았을 경우 대응
          const timer = setTimeout(() => {
            kakaoLogin('0.0.0');
          }, 2000);

          window.addEventListener('message', (event: any) => {
            try {
              const data = JSON.parse(event.data) as {
                type: string;
                data: string;
              };
              if (data?.type === 'getVersionName') {
                clearTimeout(timer);
                kakaoLogin(data.data);
              }
            } catch (e) {
              clearTimeout(timer);
              kakaoLogin('0.0.0');
            }
          });
          (window as any)?.webkit?.messageHandlers?.iOS?.postMessage(
            'getVersionName'
          );
        } else {
          try {
            const versionName: string =
              (await (window as any)?.Android?.getVersionName()) ?? '0.0.0';
            kakaoLogin(versionName);
          } catch (e) {
            kakaoLogin('0.0.0');
          }
        }
      })();
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  return (
    <KakaoButton
      onClick={() => {
        trackUser(ampEvents.Boba_KakaoLogin);
        handleClickKakaoLogin();
      }}
    >
      <Icon icon="IconKakaoBallon" themeColor="black" />
      <KakaoLoginText>카카오 로그인</KakaoLoginText>
    </KakaoButton>
  );
};

const KakaoButton = styled.div`
  background: #fee500;
  border-radius: 12px;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const KakaoLoginText = styled.div`
  font-size: 16px;
  line-height: 16px;
  color: #00000085;
`;

export default observer(KakaoLoginButton);
