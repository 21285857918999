import React from 'react';
import { useNavigate } from 'react-router';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { ampEvents } from 'amplitude-events';
import trackUser from 'services/trackUser';
import { useStores } from 'stores/RootStore';
import Icon from 'components/common/icon/Icon';
import { Spacing } from 'styles/styles';

// TODO: AlarmButtonType 상태 처리 관련 리팩토링 필요

type AlarmButtonType = {
  type: 'Reserved' | 'ErrorFile';
};

function AlarmButton({ type }: AlarmButtonType) {
  const navigation = useNavigate();
  const { printJobStore, scanCopyJobStore, fileStore, updateJobStore } = useStores();

  const hasErrorFile = fileStore.files.find(file => file.errorType);

  const handleReserved = () => {
    trackUser(ampEvents.Print_GoToReserved);
    if (updateJobStore.jobLength === 1) {
      //print
      if(printJobStore.groupByKiosk.length === 1){
        return navigation(
          `/waiting-list/prints/${printJobStore.printJobs[0].kiosk.id}`
        );
      }
      //scan
      if (scanCopyJobStore.getScanJobsGroupByKiosk().length === 1) {
        return navigation(
          `/waiting-list/${scanCopyJobStore.scanJobs[0].kiosk.id}`,{
            state: {
              service: '스캔',
            }
          }
        );
      }
      //copy
      if (scanCopyJobStore.getCopyJobsGroupByKiosk().length === 1) {
        return navigation(
          `/waiting-list/${scanCopyJobStore.copyJobs[0].kiosk.id}`,{
            state: {
              service: '복사',
            }
          }
        );
      }
    } 
    navigation('/waiting-list');
  };

  const handleErrorFile = () => {
    navigation('/errorfileinfo');
  };

const getSingleJobInfo = () => {
    const { printJobStore, scanCopyJobStore } = updateJobStore;

    const { printJobs } = printJobStore;
    const { scanJobs, copyJobs } = scanCopyJobStore;

    let kioskName = "";
    let isColorMode: boolean = false;
    let jobType: "print" | "scan" | "copy" = "print";

    if (printJobs.length > 0) {
      const printJob = printJobs[0];
      kioskName = printJob.kiosk.name;
      isColorMode = printJob.ticket.isColor;
      jobType = "print";
    } else if (scanJobs.length > 0) {
      const scanJob = scanJobs[0];
      kioskName = scanJob.kiosk.name;
      jobType = "scan";
    } else if (copyJobs.length > 0) {
      const copyJob = copyJobs[0];
      kioskName = copyJob.kiosk.name;
      jobType = "copy";
    }

    return {
      kioskName,
      isColorMode,
      jobType
    }
  };

  if (type === 'ErrorFile' && !hasErrorFile) {
    return null;
  }

  if (type === "ErrorFile") {
    return (
      <Alarm onClick={handleErrorFile}>
        <Icon icon="IconError" size={24} themeColor="destructive600" />
        <p>변환 실패한 파일이 있습니다</p>
        <Icon icon="IconArrowRight" />
      </Alarm>
    )
  }

  if (type === 'Reserved' && updateJobStore.jobLength === 0) {
    return null;
  }

  if (type === "Reserved" && updateJobStore.jobLength > 1) {
    return (
      <Alarm onClick={handleReserved}>
        <JobInfoStack>
          <RemainingJobs>
            <span>{updateJobStore.jobLength}</span>
          </RemainingJobs>
          <Spacing.Horizontal width={12} />
          <Title>예약된 작업보러가기</Title>
        </JobInfoStack>
        <Icon icon="IconArrowRight" />
      </Alarm>
    );
  }

  const { kioskName, jobType } = getSingleJobInfo();

  return (
    <Alarm onClick={type === 'Reserved' ? handleReserved : handleErrorFile}>
      {type === 'Reserved' && (
        <Stack>
          <Title>현재 진행중인 작업</Title>
          <Spacing.Vertical height={8} />
          <JobInfoStack>
            {
              jobType === "print"
                ? <ColorChip>인쇄</ColorChip>
                : jobType === "scan" ? <ColorChip>스캔</ColorChip> : <ColorChip>복사</ColorChip>
            }
            <SubTitle>{kioskName}</SubTitle>
          </JobInfoStack>
        </Stack>
      )}
      <Icon icon="IconArrowRight" />
    </Alarm>
  );
}

export default observer(AlarmButton);

const Alarm = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;

  margin: 16px 0;
  padding: 24px;

  background: ${({ theme }) => theme.color.gray100};
  border-radius: 16px;
  cursor: pointer;

  p {
    flex: 1;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.color.gray700};
  }
`;

const RemainingJobs = styled.div`
  width: 28px;
  height: 28px;

  background: ${({ theme }) => theme.color.primary600};
  border-radius: 50%;

  text-align: center;

  span {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: ${({ theme }) => theme.color.white};
  }
`;
/**
 * Layout Stacks
 */

const Stack = styled.div`
  display: flex;
  flex-direction: column;
`;

const JobInfoStack = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

/**
 * Text Components
 */
const Title = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: ${({ theme }) => theme.color.gray700};
  display: inline-block;
`;

const SubTitle = styled.p`
  display: inline-block;
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
  color: ${({ theme }) => theme.color.gray500};
`;

/**
 * Chips
 */
const ChipBase = styled.span`
  display: inline-block;
  border-radius: 4px;
  padding: 2px 6px;
  font-weight: bold;
  color: white;
  font-size: 14px;
  line-height: 22px;

  margin-right: 8px;
`;

const ColorChip = styled(ChipBase)`
  background: ${({ theme }) => theme.color.primary500};
`;