import { makeAutoObservable } from 'mobx';
import { PaperSize } from 'types/job/print-job/print-ticket/PaperSize';
import { PrintZoneLineDto } from 'types/kiosk/PrintZoneLine.dto';
import { Service } from 'types/kiosk/Service';
import { Status } from 'types/kiosk/Status';

export class Kiosk {
  id: string;
  companyName: string;
  latitude: number;
  longitude: number;
  address: string;
  priceA4Color: number | null;
  priceA4Mono: number | null;
  status: Status;
  services: Service[];

  constructor(dto: PrintZoneLineDto) {
    makeAutoObservable(this, {});

    this.id = dto.id;
    this.companyName = dto.companyName;
    this.latitude = dto.latitude;
    this.longitude = dto.longitude;
    this.address = dto.address;
    this.priceA4Mono = dto.priceA4Mono;
    this.priceA4Color = dto.priceA4Color;
    this.status = dto.status;
    this.services = dto.services;
  }

  updateKioskFromDto(dto: PrintZoneLineDto) {
    this.id = dto.id;
    this.companyName = dto.companyName;
    this.latitude = dto.latitude;
    this.longitude = dto.longitude;
    this.address = dto.address;
    this.priceA4Mono = dto.priceA4Mono;
    this.priceA4Color = dto.priceA4Color;
    this.status = dto.status;
  }

  get isColor() {
    return this.priceA4Color !== null;
  }

  get isMono() {
    return this.priceA4Mono !== null;
  }

  get PaperSizes() {
    const set = new Set(this.services?.map(service => service.sizeName));
    const arr = Array.from(set) as PaperSize[];

    return arr;
  }
}
