export enum CopyJobStatus {
  Requested = 'requested',
  SeletingOption = 'selecting-option',
  Scanning = 'scanning',
  WaitingCheckout = 'waiting-checkout',
  Printing = 'printing',
  Done = 'done',
  Error = 'error',
  StoppedPrinting = 'stopped-printing',
  StoppedScanning = 'stopped-scanning',
  Expired = 'expired',
  Canceled = 'canceled',
  Deleted = 'deleted',
}

/**
 * 각 상태별 있어야 하는 페이지
 * selecting-option, scanning => '/job-in-progress' page
 * waiting-chekcout => '/job-payment'
 * printing => '/job-working'
 * done => '/job-complete'
 * error => '/job-error'
 */
