import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useStores } from 'stores/RootStore';
import { observer } from 'mobx-react-lite';
import FullScreenLoader from 'components/common/FullScreenLoader';
import InputBox from 'components/common/input_box/InputBox';
import List from 'components/common/list/List';
import Modal from 'components/common/modal/Modal';
import PageContainer from 'components/common/PageContainer';
import SolidButton from 'components/common/solid_button/SolidButton';
import TopBar from 'components/common/top_bar/TopBar';
import TopInfo from 'components/common/top_info/TopInfo';
import Icon from 'components/common/icon/Icon';
import FixedButtons from 'components/common/solid_button/FixedButtons';

const ChangePhoneNumber1: React.FC = props => {
  const navigation = useNavigate();
  const { userStore, authStore } = useStores();

  const [isLoading, setIsLoading] = useState(false);
  const [pwd, setPwd] = useState('');
  const [pwdErrorModal, setPwdErrorModal] = useState(false);

  const handleVerifyPwd = () => {
    setIsLoading(true);
    authStore
      .isCurrentPassword(pwd)
      .then(res => {
        if (res.data.result) {
          navigation('/accountsettings/changephone2', { replace: true });
        } else {
          setPwdErrorModal(true);
        }
      })
      .catch(() => {
        setPwdErrorModal(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <PageContainer>
      {isLoading && <FullScreenLoader message="비밀번호 확인중" />}
      <TopBar title="휴대폰 번호 변경" />
      <TopInfo title={userStore.user?.name} />
      <List title="이메일" text={userStore.user?.email} />
      <InputBox
        value={pwd}
        type="password"
        placeholder="• • • • • • • •"
        label="비밀번호"
        helpText="현재 사용중인 비밀번호를 입력하세요"
        onChange={e => {
          setPwd(e.target.value);
        }}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            handleVerifyPwd();
          }
        }}
      />

      {userStore.user?.isOnlyKakao && (
        <InfoContainer>
          <KakaoBallon>
            <Icon icon="IconKakaoBallon" size={10} themeColor="black" />
          </KakaoBallon>
          <InfoText>{`기존 카카오로그인을 통해 가입하신 분들은 로그아웃 후, “비밀번호 찾기"를 통해 초기 비밀번호를 설정해주세요!`}</InfoText>
        </InfoContainer>
      )}

      <FixedButtons>
        <SolidButton disabled={!pwd} onClick={handleVerifyPwd}>
          휴대폰 번호 변경
        </SolidButton>
      </FixedButtons>

      {/* modal */}
      <Modal
        isOpen={pwdErrorModal}
        onRequestClose={() => setPwdErrorModal(false)}
        description="비밀번호를 다시 확인해주세요"
        mainActionLabel="확인"
        onMainAction={() => setPwdErrorModal(false)}
      />
    </PageContainer>
  );
};
export default observer(ChangePhoneNumber1);

const InfoContainer = styled.div`
  display: flex;
  gap: 6px;
`;
const KakaoBallon = styled.div`
  display: flex;
  align-items: center;
  height: fit-content;
  padding: 3px;
  background: #fee500;
  border-radius: 4px;
  margin-top: 6px;
`;
const InfoText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #737373;
`;
