import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useStores } from 'stores/RootStore';
import { track } from '@amplitude/analytics-browser';
import { ampEvents } from 'amplitude-events';
import FullScreenLoader from 'components/common/FullScreenLoader';
import InputBox from 'components/common/input_box/InputBox';
import Modal from 'components/common/modal/Modal';
import PageContainer from 'components/common/PageContainer';
import SolidButton from 'components/common/solid_button/SolidButton';
import TopBar from 'components/common/top_bar/TopBar';
import useHistoryBack from 'hooks/useHistoryBack';
import { Spacing } from 'styles/styles';
import FixedButtons from 'components/common/solid_button/FixedButtons';
import { CardFormData, cardSchema } from 'types/InputSchema';
import { CreateCardDto } from 'types/card/CreateCard.dto';

const RegisterCard: React.FC = () => {
  const navigation = useNavigate();
  const location = useLocation().state as { beforePage: 'REGISTER' };
  const { cardStore, jobPollingStore } = useStores();

  const [isLoading, setIsLoading] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [errorMsgModal, setErrorMsgModal] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [afterRegister, setAfterRegister] = useState(false);

  const handleCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const v = e.target.value.replace(/\s+/g, '');
    const numericValue = v.replace(/\D/g, '');
    const matches = numericValue.match(/\d{4,16}/g);
    const match = (matches && matches[0]) || '';
    const parts = [];
    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }
    if (parts.length) {
      e.target.value = parts.join(' ');
    } else {
      e.target.value = v;
    }
  };

  const handleExpiryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
    let formattedValue = rawValue;

    if (rawValue.length > 2) {
      formattedValue = `${rawValue.slice(0, 2)} / ${rawValue.slice(2, 4)}`;
    }

    e.target.value = formattedValue;
  };

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<CardFormData>({
    resolver: yupResolver(cardSchema),
    mode: 'onChange',
  });

  const handleRegisterCard = (data: CreateCardDto) => {
    cardStore.createCard({
      cardNumber: data.cardNumber.split(' ').join(''),
      expiry: `20${data.expiry.slice(-2)}-${data.expiry.slice(0, 2)}`,
      birth: data.birth,
      pwd2digit: data.pwd2digit,
    });
    setIsLoading(true);
  };

  useEffect(() => {
    /**
      ## 카드 등록 상황별 오류 메시지 모음 (서버에서 오는 오류를 그대로 적었습니다)
      > iamport 서버에서 오는 오류 메시지 그대로 보여주고 있으며 20230531 기준으로 아래와 같이 반환하고 있습니다.
      > iamport 서버에서 오는 오류 메시지에 기반해서 정적으로 메시지를 변경하고 있음

      카드번호 불일치 -> "카드정보 인증 및 빌키 발급에 실패하였습니다. [F113]카드번호틀림”
      비밀번호 불일치 -> "카드정보 인증 및 빌키 발급에 실패하였습니다. [F113]비밀번호틀림"
      유효기간 불일치 -> "카드정보 인증 및 빌키 발급에 실패하였습니다. [F113]유효기간오류”
      생년월일 불일치 -> "카드정보 인증 및 빌키 발급에 실패하였습니다. [F113]주민OR사업자등록번호오류" OR "주민등록OR사업자번호등록번호오류"
     */
    if (errorMessage.includes('비밀번호틀림'))
      setErrorMessage(`비밀번호 앞 2자리를 다시 확인해주세요`);
    else if (errorMessage.includes('회수초과'))
      setErrorMessage(
        `카드 비밀번호 오류 횟수 초과로 사용이 제한됩니다\n카드사를 통해 비밀번호 오류를 해제해주세요`
      );
    else if (errorMessage.includes('유효기간오류'))
      setErrorMessage(`유효기간을 다시 확인해주세요`);
    else if (errorMessage.includes('번호상이'))
      setErrorMessage(`생년월일 6자리를 다시 확인해주세요`);
    else if (
      errorMessage.includes('주민등록OR사업자번호등록번호오류') ||
      errorMessage.includes('주민OR사업자등록번호오류')
    )
      setErrorMessage(`생년월일 6자리를 다시 확인해주세요`);
    else if (errorMessage.includes('카드번호틀림'))
      setErrorMessage(`카드 번호를 다시 확인해주세요`);
    else if (errorMessage.includes(`이미 등록된 카드 정보 입니다`))
      // 이미 등록된 카드 정보 입니다
      setErrorMessage(`이미 등록된 카드 정보 입니다`);
  }, [errorMessage]);

  useEffect(() => {
    if (!cardStore.isLoading) {
      if (cardStore.errors.createCardError) {
        setIsLoading(false);
        setErrorMessage(`${cardStore.errors.createCardError}`);
        setErrorMsgModal(true);
        cardStore.clearError();
        return;
      }
    }
    if (isLoading && !cardStore.isLoading) {
      setIsLoading(false);
      setSuccessModal(true);
      track(ampEvents.RegisterCard_Success);
    }
  }, [cardStore.isLoading]);

  useEffect(() => {
    if (!location) return;
    setAfterRegister(true);
  }, []);

  const handleHistoryBack = () => {
    if (afterRegister) {
      navigation('/', { replace: true, state: { beforePage: 'REGISTER' } });
    } else if (jobPollingStore.jobIds === null) {
      navigation('/cards', { replace: true });
    }
  };

  useHistoryBack(handleHistoryBack);

  return (
    <PageContainer>
      {isLoading && <FullScreenLoader message="카드 정보 확인중" />}

      <Modal
        isOpen={successModal}
        onRequestClose={handleHistoryBack}
        description={'카드를 등록했습니다'}
        mainActionLabel="확인"
        onMainAction={handleHistoryBack}
      />

      <Modal
        isOpen={errorMsgModal}
        onRequestClose={() => {
          setErrorMsgModal(false);
        }}
        description={errorMessage}
        mainActionLabel="확인"
        onMainAction={() => {
          setErrorMsgModal(false);
        }}
      />

      <TopBar title="카드 등록" border={true} />

      <form onSubmit={handleSubmit(data => handleRegisterCard(data))}>
        <InputBox
          label="카드 번호"
          inputMode="numeric"
          placeholder="1234 5678 1234 5678"
          fieldName="cardNumber"
          register={register}
          rules={{ onChange: handleCardNumberChange }}
          action={errors?.cardNumber?.message ? 'error' : 'default'}
          helpText={errors?.cardNumber?.message}
        />
        <InputColumn>
          <InputBox
            label="비밀번호 앞 2자리"
            type="password"
            inputMode="numeric"
            placeholder=" • •"
            fieldName="pwd2digit"
            register={register}
            action={errors?.pwd2digit?.message ? 'error' : 'default'}
            helpText={errors?.pwd2digit?.message}
          />
          <Spacing.Horizontal width={16} />
          <InputBox
            label="유효기간"
            inputMode="numeric"
            placeholder="MM/YY"
            fieldName="expiry"
            register={register}
            rules={{ onChange: handleExpiryChange }}
            action={errors?.expiry?.message ? 'error' : 'default'}
            helpText={errors?.expiry?.message}
          />
        </InputColumn>
        <InputBox
          label="생년월일 6자리"
          inputMode="numeric"
          placeholder="YYMMDD"
          fieldName="birth"
          register={register}
          action={errors?.birth?.message ? 'error' : 'default'}
          helpText={errors?.birth?.message}
        />
        <FixedButtons>
          <SolidButton
            disabled={!isValid}
            onClick={() => {
              track(ampEvents.RegisterCard_Submit);
            }}
          >
            카드 등록하기
          </SolidButton>
        </FixedButtons>
      </form>
    </PageContainer>
  );
};

export default observer(RegisterCard);

const InputColumn = styled.div`
  display: flex;
  align-items: flex-start;
`;
