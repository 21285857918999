import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import FullScreenLoader from 'components/common/FullScreenLoader';
import InputBox from 'components/common/input_box/InputBox';
import PageContainer from 'components/common/PageContainer';
import RadioButton from 'components/common/radio_button/RadioButton';
import RadioGroup from 'components/common/radio_button/RadioGroup';
import SolidButton from 'components/common/solid_button/SolidButton';
import TopBar from 'components/common/top_bar/TopBar';
import { useStores } from 'stores/RootStore';
import { Spacing } from 'styles/styles';
import { Gender } from 'types/auth/Gender';
import { BIRTH_REGEX } from 'constants/Constants';
import { observer } from 'mobx-react-lite';
import ErrorPage from 'components/common/ErrorPage';
import { ampEvents } from 'amplitude-events';
import { track } from '@amplitude/analytics-browser';
import FixedButtons from 'components/common/solid_button/FixedButtons';

const RegisterOption: React.FC = props => {
  const { kakaoStore, authStore, userStore } = useStores();
  const navigation = useNavigate();
  const [name, setName] = useState<string>('');
  const [nameVaild, setNameValid] = useState<boolean>(false);
  const [birth, setBirth] = useState<string>('');
  const [birthInvalid, setBirthInvalid] = useState<boolean>(false);
  const [gender, setGender] = useState<Gender | null>(null);
  const [error, setError] = useState();

  const handleSignUp = () => {
    track(ampEvents.RegisterOption_Submit);

    const year = Number(birth.slice(0, 4));
    const month = Number(birth.slice(4, 6));
    const day = Number(birth.slice(6, 8));

    authStore
      .updateUser(
        kakaoStore.valueToInsert?.name ? name : undefined,
        undefined,
        undefined,
        kakaoStore.valueToInsert?.birthday
          ? new Date(year, month - 1, day + 1)
          : undefined,
        kakaoStore.valueToInsert?.gender ? gender : undefined
      )
      .then(() => {
        userStore.loadUser();
      })
      .then(() => {
        if (kakaoStore.isRegistered) {
          navigation('/register3', { replace: true });
          return;
        }
        navigation('/', { replace: true });
      })
      .catch(err => {
        setError(err);
      });
  };

  useEffect(() => {
    setNameValid(name.length >= 2);
  }, [name]);

  useEffect(() => {
    setBirthInvalid(birth.match(BIRTH_REGEX) === null);
  }, [birth]);

  useEffect(() => {
    setBirthInvalid(false);
  }, []);

  useEffect(() => {
    if (kakaoStore.isLoading || kakaoStore.valueToInsert === null) {
      return;
    }

    const { name, birthday, gender } = kakaoStore.valueToInsert;
    if (!name && !birthday && !gender) {
      if (kakaoStore.isRegistered) {
        navigation('/register3', { replace: true });
        return;
      }
      navigation('/', { replace: true });
    }
    return () => {
      kakaoStore.clearValueToInsert();
    };
  }, [kakaoStore.isLoading, kakaoStore.valueToInsert]);

  if (kakaoStore.isLoading || authStore.isLoading)
    return (
      <PageContainer>
        <FullScreenLoader message="로그인 중" />
      </PageContainer>
    );

  if (error) return <ErrorPage />;

  return (
    <PageContainer>
      <TopBar title="회원가입" left="none" />

      {kakaoStore.valueToInsert?.name && (
        <InputBox
          value={name}
          label="이름*"
          placeholder="김보바"
          helpText={
            name === ''
              ? '이름은 인쇄물을 찾아갈 때 본인 확인용입니다'
              : nameVaild
              ? '이름은 인쇄물을 찾아갈 때 본인 확인용입니다'
              : '2글자 이상 입력해주세요'
          }
          onChange={e => {
            if (e.target.value.length > 45) return;
            setName(e.target.value);
          }}
        />
      )}

      {kakaoStore.valueToInsert?.birthday && (
        <InputBox
          value={birth}
          label="생년월일"
          action={birthInvalid ? 'error' : birth === '' ? 'default' : 'correct'}
          placeholder="20010101"
          helpText={
            birth === ''
              ? '생년월일 8자리를 입력해주세요'
              : !birthInvalid
              ? '생년월일 8자리를 입력해주세요'
              : '형식에 맞게 입력해주세요 (예시: 20010101)'
          }
          onChange={e => {
            e.preventDefault();
            if (e.target.value.length > 8) return;
            setBirth(e.target.value);
          }}
        />
      )}

      {kakaoStore.valueToInsert?.gender && (
        <>
          <Label>성별</Label>
          <Spacing.Vertical height={12} />
          <RadioGroup direction="row">
            <RadioButton
              name="sex"
              value={Gender.Male}
              onChange={e => {
                setGender(Gender.Male);
              }}
            >
              남
            </RadioButton>
            <RadioButton
              name="sex"
              value={Gender.Female}
              onChange={e => {
                setGender(Gender.Female);
              }}
            >
              여
            </RadioButton>
          </RadioGroup>
        </>
      )}

      <FixedButtons>
        <SolidButton
          disabled={
            (kakaoStore.valueToInsert?.name && name.length < 2) ||
            (birth !== '' && birthInvalid)
          }
          onClick={handleSignUp}
        >
          회원가입 완료
        </SolidButton>
      </FixedButtons>
    </PageContainer>
  );
};

export default observer(RegisterOption);

const Label = styled.label`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #737373;
`;
