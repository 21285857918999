import LottieLoading from 'assets/lottie/LottieLoading.json';
import PageContainer from 'components/common/PageContainer';
import Border from 'components/common/border/Border';
import Icon from 'components/common/icon/Icon';
import InputBox from 'components/common/input_box/InputBox';
import List from 'components/common/list/List';
import ListHeader from 'components/common/list_header/ListHeader';
import Modal from 'components/common/modal/Modal';
import BottomFixedButton from 'components/common/solid_button/FixedButtons';
import SolidButton from 'components/common/solid_button/SolidButton';
import TopBar from 'components/common/top_bar/TopBar';
import TopInfo from 'components/common/top_info/TopInfo';
import KioskMap from 'components/pages/KioskMap';
import useHistoryBack from 'hooks/useHistoryBack';
import useKioskService from 'hooks/useKioskService';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import Lottie from 'react-lottie-player';
import { useNavigate } from 'react-router-dom';
import { kioskService } from 'services/kiosk.service';
import { useStores } from 'stores/RootStore';
import styled from 'styled-components';
import { Spacing } from 'styles/styles';
import { PrintZoneLineDto } from 'types/kiosk/PrintZoneLine.dto';
import { calcDistance, sortKiosksByDistance } from 'utils/utils';

export default observer(function SelectScan() {
  const navigation = useNavigate();
  const {
    stateStore,
    userStore,
    scanCopyJobBuilderStore,
    updateJobStore,
    scanCopyJobStore,
  } = useStores();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [kiosks, setKiosks] = useState<PrintZoneLineDto[]>([]);
  const [latestKiosks, setLatestKiosks] = useState<PrintZoneLineDto[]>([]);

  const [searchWord, setSearchWord] = useState<string>('');
  const [searchMode, setSearchMode] = useState<boolean>(true);
  const [changeModal, setChangeModal] = useState<boolean>(false);
  const [jobExistModal, setJobExistModal] = useState<boolean>(false);
  const [kioskIdToServicePrice, parseKioskServicePrice] = useKioskService();
  const [latestKioskIdToServicePrice, parseLatestKioskServicePrice] =
    useKioskService();

  const handleCreateJob = () => {
    stateStore.startLoading();
    if (
      (updateJobStore.builderService == 'scans' &&
        scanCopyJobStore.scanJobs.length > 0) ||
      (updateJobStore.builderService == 'copies' &&
        scanCopyJobStore.copyJobs.length > 0)
    ) {
      setJobExistModal(true);
      stateStore.endLoading();
      return;
    }

    scanCopyJobBuilderStore
      .createToServer()
      .then(() => {
        navigation('/reservation-complete', { replace: true });
      })
      .finally(() => {
        stateStore.endLoading();
      });
  };

  const handleChangeJob = () => {
    stateStore.startLoading();
    scanCopyJobBuilderStore
      .changeKioskToServer()
      .then(() => setChangeModal(true))
      .finally(() => {
        stateStore.endLoading();
      });
  };

  useEffect(() => {
    setIsLoading(true);
    kioskService
      .getKiosks(
        true,
        false,
        updateJobStore.builderService === 'scans',
        updateJobStore.builderService === 'copies',
        searchWord
      )
      .then(res => {
        const serviceName =
          updateJobStore.builderService === 'scans' ? 'Scan' : 'Copy';

        parseKioskServicePrice(res, serviceName, 'A4');
        setKiosks(res);
      })
      .catch(() => {
        stateStore.setError(true);
        updateJobStore.clear();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [searchWord]);

  useEffect(() => {
    const serviceName =
      updateJobStore.builderService === 'scans' ? 'Scan' : 'Copy';

    kioskService
      .getRecentlyUsedKiosks(updateJobStore.builderService)
      .then(({ data }) => {
        // scan or copy 지원하는 개소인지 확인
        const printZoneLines = data.filter(
          printZoneLines =>
            printZoneLines.services.filter(
              sv => sv.serviceNameEn === serviceName
            ).length >= 1
        );
        parseLatestKioskServicePrice(printZoneLines, serviceName, 'A4');
        setLatestKiosks(printZoneLines);
      })
      .catch(() => {
        stateStore.setError(true);
        updateJobStore.clear();
      });
  }, []);

  useHistoryBack(() => {
    updateJobStore.clear();
  });

  return (
    <PageContainer>
      <TopBar
        right="map"
        onLeftClick={() => {
          if (!searchMode) setSearchMode(true);
          else navigation(-1);
          updateJobStore.clear();
        }}
        onRightClick={() => {
          setSearchMode(false);
          updateJobStore;
        }}
      />
      <TopInfo
        title={`${
          updateJobStore.builderService === 'scans' ? '스캔' : '복사'
        }할 프린터를 선택하세요.`}
      />

      {/* search printer */}
      <InputBox
        placeholder="프린터 검색"
        searchMode={true}
        value={searchWord}
        setValue={setSearchWord}
        onChange={e => setSearchWord(e.target.value)}
        onClick={() => {
          setSearchMode(true);
        }}
      />

      <InfoContainer>
        <Icon icon="IconInfo" size={20} />
        <Info>{`${
          updateJobStore.builderService === 'scans' ? '스캔이' : '복사가'
        } 가능한 프린터 목록 입니다`}</Info>
      </InfoContainer>
      <Spacing.Vertical height={17} />

      {/* list mode */}
      {searchMode && (
        <>
          {latestKiosks.length > 0 && (
            <>
              <ListHeader title="최근 사용 프린터" />
              <List
                key={latestKiosks[0].id}
                onLeftClick={() => {
                  if (latestKiosks[0].status === 'MAINTENANCE') {
                    return;
                  }
                  updateJobStore.setBuilderKioskId(latestKiosks[0].id);
                }}
                active={latestKiosks[0].id === updateJobStore.builderKioskId}
                disabled={latestKiosks[0].status === 'MAINTENANCE'}
                title={latestKiosks[0].companyName}
                description={`${calcDistance(
                  userStore.currentLocation?.lat,
                  userStore.currentLocation?.lng,
                  latestKiosks[0].latitude,
                  latestKiosks[0].longitude
                )} ${latestKiosks[0].address}`}
                listBottom="prices"
                status={latestKiosks[0].status}
                priceMono={
                  latestKioskIdToServicePrice.get(latestKiosks[0].id)?.mono
                }
                priceColor={
                  latestKioskIdToServicePrice.get(latestKiosks[0].id)?.color
                }
                action="info"
                onActionClick={() => {
                  navigation(`/printzone/${latestKiosks[0].id}`);
                }}
              />
              <Border size={16} />
            </>
          )}

          {isLoading ? (
            <LoadingContainer>
              <Lottie loop play animationData={LottieLoading} />
            </LoadingContainer>
          ) : kiosks.length === 0 ? (
            searchWord ? (
              <NoSearchFile>
                <Icon icon="Icon3DNotFoundFile" size={200} />
                <Spacing.Vertical height={30} />
                <NoSearchFileText>
                  검색 결과가 존재하지 않습니다
                </NoSearchFileText>
              </NoSearchFile>
            ) : (
              <HelpText>
                <Slogan>이렇게 검색해보세요</Slogan>
                <Spacing.Vertical height={20} />

                <Title>지역명 + 번지</Title>
                <Example>도곡동 12-1</Example>
                <Spacing.Vertical height={12} />

                <Title>태그 검색</Title>
                <Example>#한국대학교 #스터디카페</Example>
                <Spacing.Vertical height={12} />

                <Title>매장 검색</Title>
                <Example>스타벅스 대치점</Example>
              </HelpText>
            )
          ) : (
            <>
              {sortKiosksByDistance(kiosks).map(kiosk => (
                <List
                  key={kiosk.id}
                  onLeftClick={() => {
                    if (kiosk.status === 'MAINTENANCE') {
                      return;
                    }
                    updateJobStore.setBuilderKioskId(kiosk.id);
                  }}
                  active={kiosk.id === updateJobStore.builderKioskId}
                  disabled={kiosk.status === 'MAINTENANCE'}
                  title={kiosk.companyName}
                  description={`${calcDistance(
                    userStore.currentLocation?.lat,
                    userStore.currentLocation?.lng,
                    kiosk.latitude,
                    kiosk.longitude
                  )} ${kiosk.address}`}
                  listBottom="prices"
                  status={kiosk.status}
                  priceMono={kioskIdToServicePrice.get(kiosk.id)?.mono ?? null}
                  priceColor={
                    kioskIdToServicePrice.get(kiosk.id)?.color ?? null
                  }
                  action="info"
                  onActionClick={() => {
                    navigation(`/printzone/${kiosk.id}`);
                  }}
                />
              ))}
            </>
          )}

          <BottomFixedButton>
            {!scanCopyJobBuilderStore.changeKioskMode ? (
              <SolidButton
                disabled={updateJobStore.builderKioskId === null}
                onClick={handleCreateJob}
              >
                {`${
                  updateJobStore.builderService === 'scans' ? '스캔' : '복사'
                } 예약`}
              </SolidButton>
            ) : (
              <SolidButton
                disabled={!updateJobStore.builderKioskId}
                onClick={handleChangeJob}
              >
                {`프린터 변경`}
              </SolidButton>
            )}
          </BottomFixedButton>
        </>
      )}

      {/* map mode */}
      {!searchMode && <KioskMap />}

      <Modal
        isOpen={changeModal}
        onRequestClose={() => {
          setChangeModal(false);
          navigation('/waiting-list', { replace: true });
        }}
        description="프린터 변경이 완료되었습니다."
        mainActionLabel="확인"
        onMainAction={() => {
          setChangeModal(false);
          navigation('/waiting-list', { replace: true });
        }}
      />
      <Modal
        isOpen={jobExistModal}
        onRequestClose={() => navigation('/waiting-list')}
        description={`이미 예약된 ${
          updateJobStore.builderService === 'scans' ? '스캔' : '복사'
        }작업이 있습니다.`}
        mainActionLabel="확인"
        mainActionColor="NEGATIVE"
        onMainAction={() => navigation('/waiting-list')}
      />
    </PageContainer>
  );
});

const Info = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: ${({ theme }) => theme.color.gray500};
`;
const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 60px 0 60px 0;
`;
const HelpText = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Title = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  color: ${({ theme }) => theme.color.gray600};
`;
const Slogan = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 160%;
  color: ${({ theme }) => theme.color.gray700};
`;
const Example = styled.p`
  margin-top: 2px;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: ${({ theme }) => theme.color.gray500};
`;

const NoSearchFile = styled.div`
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const NoSearchFileText = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
`;
