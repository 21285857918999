import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

const FullPageContainer = ({ children }: PropsWithChildren) => {
  return <Container>{children}</Container>;
};

export default FullPageContainer;

const Container = styled.div`
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);

  width: 100%;
  max-width: 780px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  overflow: hidden;

  margin: 0 auto;
  padding-left: 24px;
  padding-right: 24px;

  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
`;
