import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores/RootStore';
import { httpRequestService } from 'services/http-request.service';
import Border from 'components/common/border/Border';
import FullScreenLoader from 'components/common/FullScreenLoader';
import List from 'components/common/list/List';
import ListFooter from 'components/common/list_footer/ListFooter';
import PageContainer from 'components/common/PageContainer';
import TopBar from 'components/common/top_bar/TopBar';
import TopInfo from 'components/common/top_info/TopInfo';
import { Spacing } from 'styles/styles';
import { getDatetimeKoreanString } from 'utils/time.util';
import { IContentOrderDto } from 'types/user/orders/ContentOrder.dto';

const ContentHistoryDetail: React.FC = props => {
  const { historyUid } = useParams();
  const { userStore } = useStores();

  const [isLoading, setIsLoading] = useState(true);
  const [contentOrder, setContentOrder] = useState<IContentOrderDto>();

  useEffect(() => {
    httpRequestService
      .create(true)
      .get<IContentOrderDto>(
        `users/${userStore.userId}/orders/contents/${historyUid}`
      )
      .then(res => {
        setContentOrder(res.data);
      })
      .catch(() => {
        userStore.showErrorModal();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <PageContainer>
      {isLoading && <FullScreenLoader message="로딩중" />}
      <TopBar title="이용 내역" />

      {!isLoading && contentOrder && (
        <>
          <TopInfo title={contentOrder.title} />
          <List title="판매자 ID" actionText={contentOrder.author} />
          <List
            title="요청 일시"
            actionText={getDatetimeKoreanString(contentOrder.createdAt)}
          />
          <Spacing.Vertical height={32} />
          <List
            title="카드 결제"
            actionText={`${contentOrder.cardPayment} 원`}
          />
          <List
            title="포인트 차감"
            actionText={`${contentOrder.pointPayment} P`}
          />
          <Border size={24} />
          <ListFooter
            leftTitle="총 결제 금액"
            rightTitle={`${
              contentOrder.cardPayment + contentOrder.pointPayment
            } 원`}
          />
        </>
      )}
    </PageContainer>
  );
};

export default observer(ContentHistoryDetail);
