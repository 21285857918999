import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { MapMarker, MarkerClusterer } from 'react-kakao-maps-sdk';
import { ampEvents } from 'amplitude-events';
import trackUser from 'services/trackUser';
import { kioskService } from 'services/kiosk.service';
import { useStores } from 'stores/RootStore';
import { PrintZoneLocationDto } from 'types/kiosk/PrintZoneLocation.dto';
import {
  ImgCurrentLocationMarker,
  ImgPrinterBobaMarker,
  ImgPrinterMarker,
} from 'assets/images';

export type SearchFilter = 'all' | 'print' | 'scan' | 'copy';

const PrintZoneMarker: React.FC = () => {
  const navigation = useNavigate();
  const { userStore, stateStore } = useStores();
  const [printZones, setPrintZones] = useState<PrintZoneLocationDto[] | null>(
    null
  );
  const [filter, setFilter] = useState<SearchFilter>('all');

  const handleService = (filter: SearchFilter) => {
    localStorage.setItem('map-filter', filter);
    setFilter(filter);
  };

  const iconType = (t: number) => {
    if (t === 1) return ImgPrinterBobaMarker;
    else return ImgPrinterMarker;
  };

  const iconSize = (t: number) => {
    if (t === 1) return { width: 63, height: 78 };
    else return { width: 28, height: 32 };
  };

  useEffect(() => {
    const savedFilter = localStorage.getItem('map-filter');
    if (savedFilter !== null) {
      const filter: SearchFilter = savedFilter as SearchFilter;
      setFilter(filter);
    }
  }, []);

  useEffect(() => {
    stateStore.startLoading();
    // FIXME: 보바 개소만 뜨게 해둠
    kioskService
      .getKiosksLocation(
        true,
        filter === 'all' || filter === 'print',
        filter === 'all' || filter === 'scan',
        filter === 'all' || filter === 'copy'
      )
      .then(res => {
        setPrintZones(res.data);
      })
      .catch(() => {
        stateStore.setError(true);
      })
      .finally(() => {
        stateStore.endLoading();
      });
  }, [filter]);

  useEffect(() => {
    stateStore.startLoading();
    // FIXME: 보바 개소만 뜨게 해둠
    kioskService
      .getKiosksLocation(true, true, true, true)
      .then(res => {
        setPrintZones(res.data);
      })
      .catch(() => {
        stateStore.setError(true);
      })
      .finally(() => {
        stateStore.endLoading();
      });
  }, []);

  return (
    <>
      <MarkerClusterer
        averageCenter={true}
        minLevel={8}
        styles={[MarkerClusterCss]}
      >
        {printZones &&
          printZones.map(printZone => (
            <MapMarker
              onClick={() => {
                trackUser(ampEvents.Map_Select);
                navigation(`/printzone/${printZone.i}`, {
                  state: {
                    prevPath: '/map',
                  },
                });
              }}
              key={printZone.i}
              image={{
                src: iconType(printZone.t),
                size: iconSize(printZone.t),
              }}
              position={{ lat: printZone.y, lng: printZone.x }}
            />
          ))}
      </MarkerClusterer>

      {!!userStore.currentLocation && (
        <MapMarker
          image={{
            src: ImgCurrentLocationMarker,
            size: { width: 18, height: 18 },
          }}
          position={{
            lat: userStore.currentLocation.lat,
            lng: userStore.currentLocation.lng,
          }}
        />
      )}

      <ServiceSelection>
        <ServiceButton
          clicked={filter === 'all'}
          onClick={() => {
            trackUser(ampEvents.Map_SelectAll);
            handleService('all');
          }}
        >
          전체
        </ServiceButton>
        <ServiceButton
          clicked={filter === 'print'}
          onClick={() => {
            trackUser(ampEvents.Map_SelectPrint);
            handleService('print');
          }}
        >
          인쇄
        </ServiceButton>
        <ServiceButton
          clicked={filter === 'scan'}
          onClick={() => {
            trackUser(ampEvents.Map_SelectScan);
            handleService('scan');
          }}
        >
          스캔
        </ServiceButton>
        <ServiceButton
          clicked={filter === 'copy'}
          onClick={() => {
            trackUser(ampEvents.Map_SelectCopy);
            handleService('copy');
          }}
        >
          복사
        </ServiceButton>
      </ServiceSelection>
    </>
  );
};

export default observer(PrintZoneMarker);

const MarkerClusterCss: React.CSSProperties = {
  width: '50px',
  height: '50px',
  background: '#2784FF',
  borderRadius: '50%',
  color: 'white',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const ServiceSelection = styled.div`
  position: absolute;
  z-index: 10;
  top: 88px;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  padding: 8px;
  background: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.gray200};
  border-radius: 50px;
`;
const ServiceButton = styled.button<{ clicked: boolean }>`
  border-radius: 50px;
  padding: 8px 24px;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  background: ${props =>
    props.clicked ? props.theme.color.primary500 : props.theme.color.white};
  color: ${props =>
    props.clicked ? props.theme.color.white : props.theme.color.gray400};
`;
