import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styled, { css } from 'styled-components';
export interface BadgeProps {
  loading?: boolean;
  label?: string;
  badgeKind?: 'blue' | 'gray';
  size?: 'xs' | 'sm' | 'md' | 'lg';
}

const Badge: React.FC<BadgeProps> = ({ loading, label, badgeKind, size }) => {
  return !loading ? (
    <BadgeContainer badgeKind={badgeKind} size={size}>
      <Label badgeKind={badgeKind} size={size}>
        {label}
      </Label>
    </BadgeContainer>
  ) : (
    <Skeleton width={40} height={20} />
  );
};
export default Badge;

Badge.defaultProps = {
  badgeKind: 'blue',
  size: 'sm',
};

const BadgeContainer = styled.div<BadgeProps>`
  display: flex;
  align-items: center;
  width: max-content;
  border-radius: 4px;
  ${props => {
    switch (props.size) {
      case 'xs':
        return css`
          height: 24px;
          padding: 2px 8px;
        `;
      case 'sm':
        return css`
          height: 28px;
          padding: 4px 10px;
        `;
      case 'md':
        return css`
          height: 28px;
          padding: 4px 10px;
        `;
      case 'lg':
        return css`
          height: 36px;
          padding: 6px 12px;
        `;
    }
  }}
  ${props => {
    switch (props.badgeKind) {
      case 'blue':
        return css`
          background: #e6f2fe;
        `;
      case 'gray':
        return css`
          background: #f5f5f5;
        `;
    }
  }}
`;

const Label = styled.p<BadgeProps>`
  margin: 0;
  text-align: center;
  font-weight: 600;
  ${props => {
    switch (props.size) {
      case 'xs':
        return css`
          height: 20px;
          font-size: 14px;
          line-height: 20px;
        `;
      case 'sm':
        return css`
          height: 20px;
          font-size: 12px;
          line-height: 20px;
        `;
      case 'md':
        return css`
          height: 20px;
          font-size: 14px;
          line-height: 20px;
        `;
      case 'lg':
        return css`
          height: 24px;
          font-size: 16px;
          line-height: 24px;
        `;
    }
  }}
  ${props => {
    switch (props.badgeKind) {
      case 'blue':
        return css`
          color: #4483f7;
        `;
      case 'gray':
        return css`
          color: #737373;
        `;
    }
  }}
`;
