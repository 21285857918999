import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie-player';
import { useStores } from 'stores/RootStore';
import PageContainer from 'components/common/PageContainer';
import SolidButton from 'components/common/solid_button/SolidButton';
import FixedButtons from 'components/common/solid_button/FixedButtons';
import LottieComplete from 'assets/lottie/LottieComplete.json';
import useHistoryBack from 'hooks/useHistoryBack';

// TODO: location state로 받기
export default observer(function RequestedScan() {
  const navigation = useNavigate();
  const { updateJobStore, jobPollingStore } = useStores();
  const serviceName =
    updateJobStore.builderService === 'scans' ? '스캔' : '복사';

  const handleBack = () => {
    navigation('/print', { replace: true });
    updateJobStore.clear();
  };

  useHistoryBack(handleBack);

  return (
    <PageContainer>
      <Main>
        <Lottie
          loop={false}
          animationData={LottieComplete}
          play
          style={{
            width: 200,
            height: 200,
          }}
        />
        <MainText>
          <Title>{`${serviceName}`} 예약이 완료되었습니다</Title>
          <SubTitle>프린터 화면의 인증번호를 입력해 주세요.</SubTitle>
        </MainText>
      </Main>

      <FixedButtons>
        <SolidButton
          onClick={() => {
            jobPollingStore.setKioskId(updateJobStore.builderKioskId);
            jobPollingStore.setService(updateJobStore.builderService);
            navigation('/verify-kiosk', { replace: true });
          }}
        >
          바로 시작하기
        </SolidButton>
        <SolidButton kind="skyblue" onClick={handleBack}>
          나중에 하기
        </SolidButton>
      </FixedButtons>
    </PageContainer>
  );
});

const Main = styled.section`
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const MainText = styled.div`
  margin-top: -12px;
  padding: 16px 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const Title = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.03em;
  color: #1d1d1d;
`;
const SubTitle = styled.p`
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.03em;
  color: #767676;
`;
const InfoContainer = styled.div`
  width: 100%;
  background: ${props => props.theme.color.gray50};
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px 20px;
  border-radius: 8px;
`;
const InfoTitle = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.theme.color.gray700};
`;
const InfoText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.theme.color.gray600};
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
