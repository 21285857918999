import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie-player';
import { useStores } from 'stores/RootStore';
import { track } from '@amplitude/analytics-browser';
import { ampEvents } from 'amplitude-events';
import SolidButton from 'components/common/solid_button/SolidButton';
import TopInfo from 'components/common/top_info/TopInfo';
import FixedButtons from 'components/common/solid_button/FixedButtons';
import { Spacing } from 'styles/styles';
import LottieCongrats from 'assets/lottie/LottieCongrats.json';
import { Img3DRaccoon } from 'assets/images';
import FullPageContainer from 'components/common/FullPageContainer';

const Register3: React.FC = props => {
  const navigation = useNavigate();
  const { userStore } = useStores();

  useEffect(() => {
    if (!userStore.user) {
      userStore.loadUser();
    }
  }, []);

  return (
    <FullPageContainer>
      <Lottie
        loop={false}
        animationData={LottieCongrats}
        play
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -76%)',
          width: '390px',
          opacity: 0.5,
          pointerEvents: 'none',
        }}
      />
      <TopInfo
        right="button"
        rightLabel="건너뛰기"
        kind="lightGray"
        onClick={() => {
          track(ampEvents.Register3_Pass);
          navigation('/', {replace: true, state: {beforePage:'REGISTER'}});
        }}
      />
      <LogoContainer>
        <Logo>
          <StyledImg src={Img3DRaccoon} />
        </Logo>
        <Title>회원등록 되었습니다.</Title>
        <Title>계속해서 결제 수단을 등록해주세요.</Title>
        <Slogan>카드 등록을 해놓으면 간편하게 결제할 수 있습니다</Slogan>
        <Spacing.Vertical height={57} />
      </LogoContainer>

      <FixedButtons>
        <SolidButton
          onClick={() => {
            track(ampEvents.Register3_RegisterCard);
            navigation('/cards/register', {replace: true, state: {beforePage:'REGISTER'}});
          }}
        >
          카드 등록하기
        </SolidButton>
      </FixedButtons>
    </FullPageContainer>
  );
};

export default Register3;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 78vh;
`;
const Logo = styled.div`
  margin: 40px 80px 38px 80px;
`;
const Title = styled.p`
  font-size: 18px;
  font-weight: 500;
  color: #1d1d1d;
  margin-bottom: 8px;
`;
const Slogan = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: #767676;
`;
const StyledImg = styled.img`
  width: 227px;
  height: 227px;
`;
