import React from 'react';
import styled, { css } from 'styled-components';

interface TagProps {
  kind?: 'blue' | 'gray';
  size?: 'sm' | 'md';
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const Tag: React.FC<TagProps> = ({ kind, size, children, onClick }) => {
  return (
    <Container kind={kind} size={size} onClick={onClick}>
      {children}
    </Container>
  );
};

export default Tag;

Tag.defaultProps = {
  kind: 'gray',
  size: 'md',
};

const Container = styled.div<TagProps>`
  width: fit-content;
  max-width: 100%;
  border-radius: 20px;
  font-weight: 600;
  word-wrap: break-word;
  ${props => {
    switch (props.size) {
      case 'sm':
        return css`
          font-size: 12px;
          line-height: 20px;
          padding: 2px 8px;
        `;
      case 'md':
        return css`
          font-size: 14px;
          line-height: 22px;
          padding: 4px 12px;
        `;
    }
  }}
  ${props => {
    switch (props.kind) {
      case 'blue':
        return css`
          background: #e6f2fe;
          color: #4483f7;
        `;
      case 'gray':
        return css`
          background: #f5f5f5;
          color: #737373;
        `;
    }
  }}
`;
