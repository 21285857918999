import React, { useState } from 'react';
import PageContainer from 'components/common/PageContainer';
import SolidButton from 'components/common/solid_button/SolidButton';
import styled from 'styled-components';
import ListHeader from 'components/common/list_header/ListHeader';
import List from 'components/common/list/List';
import { Spacing } from 'styles/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores/RootStore';
import FullScreenLoader from 'components/common/FullScreenLoader';
import Modal from 'components/common/modal/Modal';
import Icon from 'components/common/icon/Icon';

const PrintJobFail: React.FC = props => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const navigation = useNavigate();
  const { printJobStore, userStore } = useStores();

  const failedFiles = printJobStore.printJobs
    .filter(pj => pj.kiosk.id === id)
    .filter(pj => pj.file.errorType !== null);

  const handleDelete = () => {
    setIsLoading(true);
    failedFiles.map(pj =>
      printJobStore.deletePrintJob(pj.id).catch(() => {
        userStore.showErrorModal();
      })
    );
    setIsLoading(false);
    navigation('/print', { replace: true });
  };

  return (
    <PageContainer>
      {isLoading && <FullScreenLoader message="삭제중" />}
      <Container>
        <Icon icon="Icon3DErrorFile" size={200} />
        <Spacing.Vertical height={19} />
        <Title>파일 변환에 실패했습니다</Title>
        <Spacing.Vertical height={16} />
        <Description>
          {`암호화된 PDF 파일이거나 손상된 파일인 경우
        파일 변환에 실패할 수 있습니다.
        해당 파일을 삭제하고 인쇄가 진행됩니다.`}
        </Description>
        <Spacing.Vertical height={16} />
      </Container>
      <Spacing.Vertical height={19} />

      <ListHeader title="파일 목록"></ListHeader>
      {failedFiles.map(pj => (
        <FileList key={pj.file.id}>
          <Icon icon="IconErrorCircle" themeColor="destructive600" />
          <Spacing.Horizontal width={12} />
          <List title={pj.file.name} />
        </FileList>
      ))}

      <Spacing.Vertical height={80} />
      <BottomButton>
        <SolidButton
          onClick={() => setDeleteConfirmModal(true)}
          kind="lightRed"
        >
          변환 실패 파일 삭제
        </SolidButton>
      </BottomButton>

      {/* Modal: Confirm when delete file */}
      <Modal
        isOpen={deleteConfirmModal}
        onRequestClose={() => setDeleteConfirmModal(false)}
        title="출력 목록 삭제"
        description={`선택한 파일을 출력 목록에서 삭제합니다\n정말 삭제하시겠습니까?`}
        mainActionLabel="삭제"
        mainActionColor="NEGATIVE"
        onMainAction={handleDelete}
        subActionLabel="취소"
        onSubAction={() => setDeleteConfirmModal(false)}
      />
    </PageContainer>
  );
};
export default observer(PrintJobFail);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 50vh;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #404040;
`;

const Description = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #737373;
  white-space: pre-wrap;
`;

const FileList = styled.div`
  display: flex;
  align-items: center;
`;

const BottomButton = styled.div`
  width: 100%;
  max-width: 780px;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 0;
  padding: 0 30px 24px 30px;
  background: white;
`;
