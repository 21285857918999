import React, {
  createContext,
  useState,
  useMemo,
  PropsWithChildren,
} from 'react';

interface HistoryContextValue {
  historyHandler: Array<() => void>;
  registerHistoryHandler: (handler: Array<() => void>) => void;
}

export const HistoryContext = createContext<HistoryContextValue>({
  historyHandler: [],
  registerHistoryHandler: () => {},
});

export default function HistoryProvider({ children }: PropsWithChildren) {
  const [historyHandler, setHistoryHandler] = useState<Array<() => void>>([]);

  const registerHistoryHandler = (handler: Array<() => void>): void => {
    return setHistoryHandler(handler);
  };

  const value = useMemo(() => {
    return {
      historyHandler,
      registerHistoryHandler,
    };
  }, [historyHandler]);

  return (
    <HistoryContext.Provider value={value}>{children}</HistoryContext.Provider>
  );
}
