import axios, { AxiosRequestConfig } from 'axios';
import { NotAuthorizedError } from '../errors';

export class HttpRequestService {
  accessToken: string | undefined;

  constructor() {}

  create(auth?: boolean) {
    const config: AxiosRequestConfig = {};

    if (auth) {
      const token = this.accessToken;

      if (!token) {
        throw new NotAuthorizedError('not find accessToken!!');
      }

      config['headers'] = {
        Authorization: `Bearer ${token}`,
      };
    }

    return axios.create(config);
  }

  setAccessToken(token: string) {
    this.accessToken = token;
  }
}

export const httpRequestService = new HttpRequestService();
