import { useLocation } from 'react-router-dom';
import { getEnvironment } from 'utils/utils';

interface Props {
  show?: boolean;
}

const PathIndicatorForDebug: React.FC<Props> = ({ show = false }) => {
  const location = useLocation();
  const env = getEnvironment();

  if (show && env === 'Development') {
    return (
      <div style={{ position: 'relative', zIndex: '10000' }}>
        {location.pathname}
      </div>
    );
  }
  return <></>;
};

export default PathIndicatorForDebug;
