import React, { ButtonHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

export interface SolidButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  kind?: 'primary' | 'skyblue' | 'lightGray' | 'ghost' | 'danger' | 'lightRed';
  area?: 'sm' | 'md' | 'lg';
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
}

const SolidButton: React.FC<SolidButtonProps> = ({
  children,
  kind,
  area,
  onClick,
  disabled,
  ...props
}) => {
  return (
    <>
      <Container
        disabled={disabled}
        kind={kind}
        area={area}
        onClick={onClick}
        {...props}
      >
        {children}
      </Container>
    </>
  );
};

export default SolidButton;

SolidButton.defaultProps = {
  kind: 'primary',
  area: 'lg',
};

const Container = styled.button<SolidButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 0 10px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  letter-spacing: -0.05em;
  // set button kinds
  ${props => {
    switch (props.kind) {
      case 'primary':
        return css`
          background: #4483f7;
          color: #ffffff;
          border-color: transparent;
          &:hover {
            background: #3f70e2;
          }
          &:active {
            background: #395fd0;
          }
          &:disabled {
            background: #4483f7;
            opacity: 0.3;
          }
        `;
      case 'skyblue':
        return css`
          background: #e6f2fe;
          color: #4483f7;
          border-color: transparent;
          &:hover {
            background: #c3ddfc;
          }
          &:active {
            background: #9cc8fa;
          }
          &:disabled {
            background: #e6f2fe;
            opacity: 0.3;
          }
        `;
      case 'lightGray':
        return css`
          background: #fafafa;
          color: #737373;
          border-color: transparent;
          &:hover {
            background: #f5f5f5;
          }
          &:active {
            background: #e5e5e5;
          }
          &:disabled {
            background: #fafafa;
            opacity: 0.3;
          }
        `;
      case 'danger':
        return css`
          background: #cc4a36;
          color: #ffffff;
          border-color: transparent;
          &:hover {
            background: #bb4030;
          }
          &:active {
            background: #af3929;
          }
          &:disabled {
            background: #cc4a36;
            opacity: 0.3;
          }
        `;
      case 'ghost':
        return css`
          background: white;
          color: #4483f7;
          border-color: #4483f7;
          &:hover {
            background: #e6f2fe;
          }
          &:active {
            background: #c3ddfc;
          }
          &:disabled {
            opacity: 0.3;
          }
        `;
      case 'lightRed':
        return css`
          background: #fceced;
          color: #cc4a36;
          border-color: transparent;
          &:hover {
            background: #f6ced0;
          }
          &:active {
            background: #e09d96;
          }
          &:disabled {
            background: #fceced;
            opacity: 0.3;
          }
        `;
    }
  }}
  // set button sizes
  ${props => {
    switch (props.area) {
      case 'sm':
        return css`
          width: 100%;
          height: 36px;
          font-size: 14px;
        `;
      case 'md':
        return css`
          width: 100%;
          height: 44px;
          font-size: 14px;
        `;
      case 'lg':
        return css`
          width: 100%;
          height: 56px;
        `;
    }
  }}
`;
