import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import FullScreenLoader from 'components/common/FullScreenLoader';
import InputBox from 'components/common/input_box/InputBox';
import Modal from 'components/common/modal/Modal';
import PageContainer from 'components/common/PageContainer';
import SolidButton from 'components/common/solid_button/SolidButton';
import TopBar from 'components/common/top_bar/TopBar';
import { useStores } from 'stores/RootStore';
import { PASSWORD_REGEX } from 'constants/Constants';
import { observer } from 'mobx-react-lite';
import trackUser from 'services/trackUser';
import { ampEvents } from 'amplitude-events';
import useToast from 'hooks/useToast';

const ResetPassword: React.FC = props => {
  const navigation = useNavigate();
  const { authStore } = useStores();
  const { showToast } = useToast();

  const [newPwd, setNewPwd] = useState('');
  const [pwdInvalid, setPwdInvalid] = useState(false);

  const [pwdMatch, setPwdMatch] = useState('');
  const [pwdMatchInvalid, setPwdMatchInvalid] = useState(false);

  const [completeChange, setCompleteChange] = useState(false);

  const handleChangePassword = () => {
    if (authStore.userIdforFindPassword === null) return;
    authStore
      .changePassword(authStore.userIdforFindPassword, newPwd)
      .then(() => {
        trackUser(ampEvents.ResetPassword_Success);
        setCompleteChange(true);
      })
      .catch(() => {
        showToast('비밀번호 변경에 실패했습니다');
      });
  };

  // password 형식 확인
  useEffect(() => {
    setPwdInvalid(newPwd.match(PASSWORD_REGEX) === null);
  }, [newPwd]);

  // password 확인
  useEffect(() => {
    setPwdMatchInvalid(pwdMatch === newPwd);
  }, [newPwd, pwdMatch]);

  useEffect(() => {
    setPwdInvalid(false);
    setPwdMatchInvalid(false);
  }, []);

  return (
    <PageContainer>
      {authStore.isLoading && <FullScreenLoader message="비밀번호 변경중" />}
      <TopBar title="비밀번호 초기화" />
      <InputBox
        value={newPwd}
        type="password"
        placeholder="• • • • • • • •"
        action={pwdInvalid ? 'error' : !newPwd ? 'default' : 'correct'}
        label="새 비밀번호"
        helpText="영문 + 숫자 조합 8자리 이상"
        onChange={e => {
          setNewPwd(e.target.value);
        }}
      />

      <InputBox
        value={pwdMatch}
        type="password"
        placeholder="• • • • • • • •"
        action={
          pwdMatch === '' ? 'default' : pwdMatchInvalid ? 'correct' : 'error'
        }
        label="새 비밀번호 확인"
        helpText={
          pwdMatch === ''
            ? '새로운 비밀번호를 한 번 더 입력해주세요'
            : pwdMatchInvalid
            ? '비밀번호가 일치합니다'
            : '비밀번호가 일치하지 않습니다'
        }
        onChange={e => {
          setPwdMatch(e.target.value);
        }}
        onKeyPress={e => {
          if (e.key === 'Enter' && newPwd && pwdMatchInvalid) {
            handleChangePassword();
          }
        }}
      />
      <ChangeButton>
        <SolidButton
          disabled={!newPwd || !pwdMatchInvalid}
          onClick={() => {
            trackUser(ampEvents.ResetPassword_Submit);
            handleChangePassword();
          }}
        >
          비밀번호 변경
        </SolidButton>
      </ChangeButton>

      <Modal
        isOpen={completeChange}
        onRequestClose={() => {
          setCompleteChange(false);
        }}
        title="비밀번호 변경 완료"
        description="비밀번호가 성공적으로 변경되었습니다!"
        mainActionLabel="확인"
        onMainAction={() => {
          setCompleteChange(false);
          navigation('/login', { replace: true });
        }}
      />
    </PageContainer>
  );
};
export default observer(ResetPassword);

const ChangeButton = styled.div`
  width: 100%;
  max-width: 780px;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 0;
  padding: 0 30px 24px 30px;
  background: white;
`;
