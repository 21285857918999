import { v4 as uuidv4 } from 'uuid';
import { PageRange } from 'types/job/print-job/print-ticket/PageRange';
import { ParamError } from 'errors';
import { CreatePrintJobDto } from 'types/print-job/CreatePrintJob.dto';
import { Duplex } from 'types/job/print-job/print-ticket/Duplex';
import { LayoutOrder } from 'types/job/print-job/print-ticket/LayoutOrder';
import { PageFitting } from 'types/job/print-job/print-ticket/PageFitting';
import { PaperOrientation } from 'types/job/print-job/print-ticket/PaperOrientation';
import { PrintTicket } from 'types/job/print-job/print-ticket/PrintTicket';
import { FileDocument } from '../file/File';
import { PrintJobStore } from './PrintJobStore';
import { PaperSize } from 'types/job/print-job/print-ticket/PaperSize';

export class PrintJobBuilder {
  builderId: string;
  kioskId: string | null = null;
  ticket: PrintTicket | null = {
    version: 'v2.0',
    layout: { order: LayoutOrder.RIGHT_TO_DOWN, nUp: 1 },
    copies: 1,
    duplex: Duplex.ONE_SIDED,
    fitToPage: PageFitting.FIT_TO_PAGE,
    isColor: true,
    pageRanges: [],
    paperOrientation: PaperOrientation.AUTO,
    paperSize: PaperSize.A4,
  };

  constructor(
    private readonly store: PrintJobStore,
    readonly file: FileDocument
  ) {
    this.builderId = uuidv4();
  }

  setKiosk(kioskId: string) {
    this.kioskId = kioskId;
  }

  setTicket(ticket: PrintTicket) {
    this.ticket = ticket;
  }

  createToServer() {
    if (!this.file || !this.kioskId || !this.ticket) {
      throw new ParamError();
    }

    const dto: CreatePrintJobDto = {
      kioskId: this.kioskId,
      fileId: this.file.id,
      ticket: this.ticket,
    };

    return this.store.http
      .create(true)
      .post(`users/${this.store.userStore.userId}/jobs/prints/create`, dto);
  }
}
