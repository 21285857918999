import { Store } from 'stores/RootStore';
import { PrintZoneLineDto } from 'types/kiosk/PrintZoneLine.dto';
import { Service } from 'types/kiosk/Service';

export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts: any = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return '';
};

export const calcDistance = (
  currentLat: number | undefined,
  currentLng: number | undefined,
  lat: number,
  lng: number
) => {
  if (currentLat && currentLng) {
    const meter = Math.floor(
      Math.sqrt(Math.pow(currentLat - lat, 2) + Math.pow(currentLng - lng, 2)) *
        100000
    );
    if (meter >= 1000) return `${Math.floor(meter / 1000)}km · `;
    return `${meter}m · `;
  }
  return '';
};

export const calcNearby = (
  currentLat: number | undefined,
  currentLng: number | undefined,
  lat: number,
  lng: number
) => {
  if (currentLat && currentLng) {
    const meter = Math.floor(
      Math.sqrt(Math.pow(currentLat - lat, 2) + Math.pow(currentLng - lng, 2)) *
        100000
    );
    return meter;
  }
  return 0;
};

export const refreshEvery3hours = () =>
  window.addEventListener(
    'blur',
    function () {
      const timeout = setTimeout(() => {
        if (this.document.hasFocus()) {
          clearTimeout(timeout);
        } else {
          window.addEventListener(
            'focus',
            function () {
              window.location.reload();
            },
            false
          );
        }
      }, 3 * 60 * 60000);
    },
    false
  );

// 인쇄, 복합 서비스만 해당
export const getPrintCopyColorSet = (services: Service[]) => {
  const printAndCopyServices = services.filter(s => s.serviceNameEn === 'Print' || s.serviceName === 'Copy');
  const set = new Set(printAndCopyServices.map(service => service.colorType));
  if (Array.from(set).length === 2) {
    return 'both';
  } else if (Array.from(set).length === 0) {
    return 'none';
  } else if (Array.from(set)[0] === 'mono') {
    return 'mono';
  }
  return 'color';
};

export const getServiceSet = (services: Service[]) => {
  const set = new Set(services.map(service => service.serviceName));
  const arr = Array.from(set).sort((a, b) => {
    return a > b ? -1 : a < b ? 1 : 0;
  });

  return arr;
};

export const getSortedPaperSize = (services: Service[]) => {
  const set = new Set(services.map(service => service.sizeName));
  // A4 -> A3 -> B4 -> ...
  return Array.from(set).sort((a, b) => {
    if (a[0] === b[0]) {
      return a[1] > b[1] ? -1 : 1;
    }
    return a > b ? 1 : -1;
  });
};

export const sortKiosksByDistance = (kiosks: PrintZoneLineDto[]) => {
  if (Store.userStore.currentLocation?.lat == undefined){
    return kiosks.sort((a,b) => {return a.address < b.address ? -1 : a.address > b.address ? 1 : 0;});
  }
  return kiosks.sort(
    (a, b) =>
      calcNearby(
        Store.userStore.currentLocation?.lat,
        Store.userStore.currentLocation?.lng,
        a.latitude,
        a.longitude
      ) -
      calcNearby(
        Store.userStore.currentLocation?.lat,
        Store.userStore.currentLocation?.lng,
        b.latitude,
        b.longitude
      )
  );
};

/**
 * 두 개의 버전 문자열을 비교하고, 첫 번째 버전이 더 높은 경우 1을, 두 번째 버전이 더 높은 경우 -1을, 두 버전이 같은 경우 0을 반환합니다. 이 함수는 버전 문자열을 .으로 구분된 세 개의 숫자로 구성된 버전 형식으로 가정합니다.
 * @param version1 ex) 3.3.1
 * @param version2 ex) 3.3.0
 * @returns
 */
export const compareVersion = (version1: string, version2: string) => {
  const parts1 = version1.split('.');
  const parts2 = version2.split('.');

  for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
    const part1 = parseInt(parts1[i] || '0');
    const part2 = parseInt(parts2[i] || '0');

    if (part1 > part2) {
      return 1;
    } else if (part1 < part2) {
      return -1;
    }
  }

  return 0;
};

export const isIOS = () => {
  const userAgent = window.navigator.userAgent;
  return /iP(hone|od|ad)/.test(userAgent);
};

export const getEnvironment = () => {
  switch (window.location.hostname) {
    case 'app.bobaprint.com':
      return 'Production';
    case 'staging-app.bobaprint.com':
    case '192.168.0.181':
      return 'Staging';
    default:
      return 'Development';
  }
};
