import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import BottomNavbar from 'components/common/BottomNavbar';
import PageContainer from 'components/common/PageContainer';
import TopBar from 'components/common/top_bar/TopBar';
import Icon from 'components/common/icon/Icon';
import FindPrintZone from 'components/pages/FindPrintZone';
import { useNavigate } from 'react-router-dom';
import { Spacing } from 'styles/styles';
import { useStores } from 'stores/RootStore';
import { observer } from 'mobx-react-lite';
import useDebounce from 'hooks/useDebounce';

const PrintZoneFinder: React.FC = props => {
  const navigation = useNavigate();
  const { userStore } = useStores();

  const [findText, setFindText] = useState<string>('');
  const debouncedSearchText = useDebounce<string>(findText, 200);

  const handleBack = () => {
    localStorage.setItem('findText', '');
    navigation(-1);
  };

  useEffect(() => {
    userStore.updateReload(false);
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem('findText') != '' &&
      localStorage.getItem('findText') != null
    ) {
      setFindText(localStorage.getItem('findText') ?? '');
    }
  }, []);

  return (
    <>
      <PageContainer>
        <TopBar title={'전체 검색'} onLeftClick={handleBack} />

        {/* searching + input */}
        <SearchContainer isSearching>
          <InputContainer isSearching>
            <Icon icon="IconSearch" size={20} />
            <Spacing.Horizontal width={8} />
            <Input
              value={findText}
              placeholder="장소를 검색하세요"
              onChange={e => setFindText(e.target.value)}
            />
            {findText && (
              <Icon
                icon="IconXCircle"
                size={20}
                onClick={() => setFindText('')}
              />
            )}
          </InputContainer>
        </SearchContainer>

        {/* searching + result */}
        <FindPrintZone findText={debouncedSearchText} />
      </PageContainer>

      <BottomNavbar />
    </>
  );
};

export default observer(PrintZoneFinder);

const SearchContainer = styled.div<{ isSearching: boolean }>`
  z-index: 2;

  position: absolute;
  top: 56px;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  justify-content: center;

  width: 100%;
  padding: 20px 0;
  background: ${props => (props.isSearching ? '#ffffff' : 'transparent')};
`;

const InputContainer = styled.div<{ isSearching: boolean }>`
  top: 76px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  width: calc(100% - 48px);
  height: 732px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
`;

const Input = styled.input`
  z-index: 3;
  background: transparent;
  width: 100%;
  height: 100%;
  outline: none;
  border-width: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #404040;

  ::placeholder,
  ::-webkit-input-placeholder {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #a3a3a3;
  }
`;
