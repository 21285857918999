import { DefaultTheme } from 'styled-components';

const myTheme: DefaultTheme = {
  color: {
    white: 'white',
    black: 'black',
    primary50: '#E6F2FE',
    primary100: '#C3DDFC',
    primary200: '#9CC8FA',
    primary300: '#75B2F9',
    primary400: '#5AA1F8',
    primary500: '#4791F7',
    primary600: '#4483F7',
    primary700: '#3F70E2',
    primary800: '#395FD0',
    primary900: '#303DB1',
    destructive50: '#FCECED',
    destructive100: '#F6CED0',
    destructive200: '#E09D96',
    destructive300: '#D27870',
    destructive400: '#D75E4E',
    destructive500: '#D95338',
    destructive600: '#CC4A36',
    destructive700: '#BB4030',
    destructive800: '#AF3929',
    destructive900: '#A12F1F',
    gray50: '#FAFAFA',
    gray100: '#F5F5F5',
    gray200: '#E5E5E5',
    gray300: '#D4D4D4',
    gray400: '#A3A3A3',
    gray500: '#737373',
    gray600: '#525252',
    gray700: '#404040',
    gray800: '#262626',
    gray900: '#171717',
  },
};

export default myTheme;
