import React from 'react';
import styled from 'styled-components';
import { Spacing } from 'styles/styles';
import Icon from 'components/common/icon/Icon';

type ContactType = {
  type: 'Call' | 'Kakao';
};

export default function ContactButton({ type }: ContactType) {
  if (type === 'Call')
    return (
      <Container href="tel:0269592677">
        <Icon icon="IconPhone" size={40} />
        <Spacing.Horizontal width={20} />
        <Text>
          <HelpTitle>전화 문의</HelpTitle>
          <HelpLabel>평일 10:00 ~ 19:00</HelpLabel>
        </Text>
      </Container>
    );

  if (type === 'Kakao') {
    return (
      <Container
        href="https://pf.kakao.com/_BPfWT"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon icon="IconKakao" size={40} />
        <Spacing.Horizontal width={20} />
        <Text>
          <HelpTitle>카톡 문의</HelpTitle>
          <HelpLabel>평일/주말 10:00 ~ 19:00</HelpLabel>
        </Text>
      </Container>
    );
  }

  return null;
}

const Container = styled.a`
  display: flex;
  align-items: center;

  width: 100%;

  padding: 16px 24px;

  background-color: #fafafa;
  border-radius: 16px;
`;

const Text = styled.div``;

const HelpTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: ${props => props.theme.color.gray700};
  line-height: 24px;
`;

const HelpLabel = styled.p`
  margin-top: 4px;

  font-weight: 500;
  font-size: 14px;
  color: ${props => props.theme.color.gray500};
`;
