import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Spacing } from 'styles/styles';

interface FixedButtonProps {
  children: React.ReactNode;
}

export default function FixedButtons({ children }: FixedButtonProps) {
  const [height, setHeight] = useState<number>(0);
  const buttonsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (buttonsRef.current === null) {
      return;
    }
    setHeight(buttonsRef.current.clientHeight + 44);
  }, [buttonsRef]);

  return (
    <>
      <Spacing.Vertical height={height} />

      <RegisterButton>
        <Fade />
        <Buttons ref={buttonsRef}>{children}</Buttons>
      </RegisterButton>
    </>
  );
}

const RegisterButton = styled.div`
  z-index: 3;
  margin-left: -24px;
  width: calc(100% + 48px);
  max-width: 780px;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 0;
  padding: 0 24px 0 24px;
  display: flex;
  flex-direction: column;
`;
const Fade = styled.div`
  display: flex;
  justify-content: center;
  z-index: 4;
  width: calc(100% + 48px);
  height: 44px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
`;
const Buttons = styled.div`
  z-index: 4;
  width: calc(100% + 48px);
  padding: 0 48px 24px 48px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;
