import axios, { AxiosRequestConfig } from 'axios';
import { makeAutoObservable } from 'mobx';
import { HttpRequestService } from 'services/http-request.service';
import { KakaoLogInRequestDto } from 'types/kakao/KakaoLogInRequest.dto';
import {
  KakaoLogInResponseDto,
  ValueToInsert,
} from 'types/kakao/KakaoLoginResponse.dto';
import { KakaoRollBackRequestDto } from 'types/kakao/KakaoRollBackRequest.dto';
import {
  KakaoRollBackResponseDto,
  ValueToChoice,
} from 'types/kakao/KakaoRollBackResponse.dto';
import { UserStore } from './user/UserStore';
import * as Sentry from '@sentry/react';
import { ApiError } from 'types/error/ApiError';

export class KakaoStore {
  isLoading = false;
  isRegistered = true;
  userId: string | null = null;
  valueToChoice: ValueToChoice | null = null;
  valueToInsert: ValueToInsert | null = null;

  kakaoAccessToken: string = '';

  constructor(
    private readonly userStore: UserStore,
    private readonly http: HttpRequestService
  ) {
    makeAutoObservable(this, {});
  }

  /**
   * @deprecated
   */
  async kakaoRollBack(code: string) {
    this.startLoading();
    const dto: KakaoRollBackRequestDto = {
      code,
    };

    try {
      const res = await this.http
        .create()
        .post<KakaoRollBackResponseDto>('auth/kakao-auth/roll-back', dto);

      this.userId = res.data.userId;
      this.valueToChoice = res.data.valueToChoice;
      this.isRegistered = res.data.isRegistered;
      this.kakaoAccessToken = res.data?.kakaoAccessToken;
    } catch (err: any) {
      Sentry.captureException(new ApiError(err, 'kakaoRollBackError'));
      throw new Error('kakao rollback error!!');
    } finally {
      this.endLoading();
    }
  }

  async signUpOrConnect(code: string) {
    this.startLoading();
    const dto: KakaoRollBackRequestDto = {
      code,
    };

    try {
      const res = await this.http
        .create()
        .post<KakaoRollBackResponseDto>(
          'auth/kakao-auth/signup-or-connect',
          dto
        );

      this.userId = res.data.userId;
      this.valueToChoice = res.data.valueToChoice;
      this.isRegistered = res.data.isRegistered;
      this.kakaoAccessToken = res.data?.kakaoAccessToken;
    } catch (err: any) {
      Sentry.captureException(new ApiError(err, 'kakaoRollBackError'));
      throw new Error('kakao rollback error!!');
    } finally {
      this.endLoading();
    }
  }

  async kakaoLogin(
    userId: string,
    email?: string | null,
    phoneNumber?: string | null,
    password?: string | null
  ) {
    this.startLoading();
    // kakaoAccessToken은 ""이 될 수 있다.
    const dto: KakaoLogInRequestDto = {
      userId: userId,
      email: email ?? null,
      phoneNumber: phoneNumber ?? null,
      password: password ?? null,
      kakaoAccessToken: this.kakaoAccessToken,
    };

    try {
      const config: AxiosRequestConfig = {
        headers: {
          'Access-Control-Allow-Origin': process.env
            .REACT_APP_SERVER_V4_URL as string,
        },
        withCredentials: true,
      };

      const res = await axios
        .create(config)
        .post<KakaoLogInResponseDto>('auth/kakao-auth/log-in', dto);

      this.userStore.setUserId(res.data.userId);
      this.userStore.setAccessToken(res.data.accessToken);
      this.http.setAccessToken(res.data.accessToken);

      this.kakaoAccessToken = ''; // 혹여 탈취당하지 않게 초기화
      this.valueToInsert = res.data.valueToInsert;

      await this.userStore.loadUser();
    } catch (err) {
      throw new Error('kakao login error!!');
    } finally {
      this.endLoading();
    }
  }

  clearUserId() {
    this.userId = null;
  }

  clearValueToChoice() {
    this.valueToChoice = null;
  }

  clearValueToInsert() {
    this.valueToInsert = null;
  }

  startLoading() {
    this.isLoading = true;
  }

  endLoading() {
    this.isLoading = false;
  }
}
