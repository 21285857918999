import React, { useEffect } from 'react';
import styled from 'styled-components';
import Lottie from 'react-lottie-player';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores/RootStore';
import trackUser from 'services/trackUser';
import { ampEvents } from 'amplitude-events';
import SolidButton from 'components/common/solid_button/SolidButton';
import Icon from 'components/common/icon/Icon';
import FixedButtons from 'components/common/solid_button/FixedButtons';
import FullPageContainer from 'components/common/FullPageContainer';
import LottieComplete from 'assets/lottie/LottieComplete.json';
import { Spacing } from 'styles/styles';
import useHistoryBack from 'hooks/useHistoryBack';

const PrintWizardCompleted: React.FC = props => {
  const navigation = useNavigate();
  const { updateJobStore, stateStore, printJobBuilderStore, jobPollingStore } =
    useStores();

  function clearStore() {
    printJobBuilderStore.clear();
    updateJobStore.clear();
  }

  const handleStartNow = async () => {
    if (
      updateJobStore.builderKioskId == null ||
      updateJobStore.builderService == null
    ) {
      navigation(`/waiting-list/`, { replace: true });
    }
    trackUser(ampEvents.PrintWizardComplete_GoToPrint);
    jobPollingStore.setKioskId(updateJobStore.builderKioskId);
    jobPollingStore.setService(updateJobStore.builderService);
    stateStore.startLoading();
    await updateJobStore.getAllJobs();
    stateStore.endLoading();
    navigation(
      `/waiting-list/prints/${printJobBuilderStore.printJobBuilders[0].kioskId}`,
      {
        replace: true,
      }
    );
    clearStore();
  };

  useHistoryBack(handleStartNow);

  return (
    <FullPageContainer>
      <Lottie
        loop={false}
        animationData={LottieComplete}
        play
        style={{
          width: 200,
          height: 200,
        }}
      />
      <Title>인쇄 요청이 완료되었습니다</Title>
      <Slogan>
        인쇄 대기 목록에서 작업을 시작해보세요
        <br />
        출력이 되고나서 결제가 진행됩니다
      </Slogan>

      <FixedButtons>
        <Alarm>
          <StackRow>
            <Icon icon="IconInfoFilledBlue" size={32} />
            <Spacing.Horizontal width={16} />
            <div>
              <AlarmText>안내</AlarmText>
              <Spacing.Vertical height={4} />
              <AlarmText>
                100원 미만 금액 결제 시 100원이 결제되고 나머지 금액이 부분 취소
                됩니다
              </AlarmText>
            </div>
          </StackRow>
        </Alarm>
        <SolidButton onClick={handleStartNow}>확인</SolidButton>
      </FixedButtons>
    </FullPageContainer>
  );
};

export default observer(PrintWizardCompleted);

const Title = styled.p`
  font-size: 18px;
  font-weight: 500;
  color: #1d1d1d;
  margin-bottom: 8px;
`;
const Slogan = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: #767676;
  text-align: center;
`;

const Alarm = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;

  margin: 16px 0;
  padding: 24px;

  background: ${({ theme }) => theme.color.gray50};
  border-radius: 16px;
  cursor: pointer;
`;

const StackRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

const AlarmText = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.gray600};
  font-weight: bold;
`;
