import React from 'react';
import styled from 'styled-components';

interface Props {
  leftTitle?: string;
  rightTitle?: string;
}

const ListFooter: React.FC<Props> = ({ leftTitle, rightTitle }) => {
  return (
    <Container>
      <Title>{leftTitle}</Title>
      <Title>{rightTitle}</Title>
    </Container>
  );
};

export default ListFooter;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
`;

const Title = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
`;
