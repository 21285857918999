import { action, makeAutoObservable } from 'mobx';
import { HttpRequestService } from 'services/http-request.service';
import { jobService } from 'services/job.service';
import { CopyJobDto } from 'types/job/copy-job/CopyJob.dto';
import { ScanJobDto } from 'types/job/scan-job/ScanJob.dto';
import { UserStore } from '../user/UserStore';

export class ScanCopyJobStore {
  isLoading: boolean = false;
  scanJobs: ScanJobDto[] = [];
  copyJobs: CopyJobDto[] = [];

  constructor(
    public readonly userStore: UserStore,
    public readonly http: HttpRequestService
  ) {
    makeAutoObservable(this, {});
  }

  async getScanJobs() {
    this.startLoading();
    await jobService.getScanJobs().then(
      action('fetchScanJobs', data => {
        this.scanJobs = data;
      })
    );
    this.endLoading();
  }

  async getCopyJobs() {
    this.startLoading();
    await jobService.getCopyJobs().then(
      action('fetchCopyJobs', data => {
        this.copyJobs = data;
      })
    );
    this.endLoading();
  }

  getScanJobsGroupByKiosk() {
    const kioskIds = Array.from(new Set(this.scanJobs.map(pj => pj.kiosk.id)));
    return kioskIds.map(id => this.scanJobs.filter(pj => pj.kiosk.id === id));
  }

  getCopyJobsGroupByKiosk() {
    const kioskIds = Array.from(new Set(this.copyJobs.map(pj => pj.kiosk.id)));
    return kioskIds.map(id => this.copyJobs.filter(pj => pj.kiosk.id === id));
  }

  getScanJobsExistingInThekiosk(kioskId: string) {
    return this.scanJobs.filter(scanJob => scanJob.kiosk.id === kioskId);
  }

  getCopyJobsExistingInThekiosk(kioskId: string) {
    return this.copyJobs.filter(copyJob => copyJob.kiosk.id === kioskId);
  }

  startLoading() {
    this.isLoading = true;
  }

  endLoading() {
    this.isLoading = false;
  }
}
