import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Spacing } from 'styles/styles';

const ContentListSkeleton: React.FC = props => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Skeleton width={144} height={144} borderRadius={8} />
      <Spacing.Vertical height={12} />
      <Skeleton width={40} height={24} borderRadius={4} />
      <Spacing.Vertical height={8} />
      <Skeleton width={90} height={20} borderRadius={4} />
      <Spacing.Vertical height={7} />
      <Skeleton width={70} height={18} borderRadius={4} />
      <Spacing.Vertical height={8} />
      <Skeleton width={40} height={20} borderRadius={4} />
      <Spacing.Vertical height={16} />
    </div>
  );
};

export default ContentListSkeleton;
