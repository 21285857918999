import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { ampEvents } from 'amplitude-events';
import trackUser from 'services/trackUser';
import { useStores } from 'stores/RootStore';
import Icon from 'components/common/icon/Icon';
import { Spacing } from 'styles/styles';

function SearchContents() {
  const { contentStore } = useStores();

  return (
    <SearchContainer>
      <InputContainer>
        <Icon icon="IconSearch" size={20} />
        <Input
          value={contentStore.findText}
          placeholder="검색어를 입력하세요"
          onChange={e => {
            contentStore.setFindText(e.target.value);
          }}
          onClick={() => {
            trackUser(ampEvents.Contents_SearchBox);
          }}
        />
        {contentStore.findText && (
          <>
            <Icon
              icon="IconXCircle"
              size={20}
              onClick={() => {
                contentStore.setFindText('');
              }}
            />
            <Spacing.Horizontal width={10} />
          </>
        )}
      </InputContainer>
    </SearchContainer>
  );
}

export default observer(SearchContents);

const SearchContainer = styled.div`
  display: flex;

  padding: 16px 0;

  width: 100%;
  height: 80px;

  background: #ffffff;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;

  padding: 3px 8px 3px 16px;

  width: 100%;
  height: 48px;

  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
`;

const Input = styled.input`
  z-index: 3;

  padding: 12px 4px;

  width: 100%;
  height: 100%;

  background: transparent;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #404040;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #a3a3a3;
  }
`;
