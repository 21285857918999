import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { useStores } from 'stores/RootStore';
import List from '../../common/list/List';
import UsageHistorySkeleton from '../UsageHistorySkeleton';
import { JobOrderLineDto } from 'types/user/orders/JobOrderLine.dto';
import { ContentOrderLineDto } from 'types/user/ContentOrderLine.dto';
import { USER_API } from 'services/user.service';
import { getRelativeTimestampString } from 'utils/time.util';

// TODO: 스캔, 복사, 인쇄 표시
// TODO: 1회 소장, 평생 출력 여부

type Props = {
  kind: 'PRINTS' | 'CONTENTS';
};

const HistoryLine = ({ kind }: Props) => {
  const navigation = useNavigate();
  const { stateStore, userStore } = useStores();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [printOrderLine, setPrintOrderLine] = useState<JobOrderLineDto[]>();
  const [contentOrderLine, setContentOrderLine] =
    useState<ContentOrderLineDto[]>();

  const getLineDto = async () => {
    try {
      if (kind == 'PRINTS') {
        const res = await USER_API.getPrintsHistory(userStore.userId ?? '');
        setPrintOrderLine(res.data);
      } else {
        const res = await USER_API.getContentsHistory(userStore.userId ?? '');
        setContentOrderLine(res.data);
      }
    } catch (e) {
      stateStore.setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!kind) return;
    getLineDto();
  }, [kind]);

  if (isLoading) {
    return (
      <>
        <UsageHistorySkeleton />
        <UsageHistorySkeleton />
      </>
    );
  }

  if (kind === 'CONTENTS' && !contentOrderLine?.length) {
    return <Empty>콘텐츠 구매 내역이 없습니다</Empty>;
  }

  if (kind === 'CONTENTS') {
    return (
      <>
        {contentOrderLine &&
          contentOrderLine
            .sort(
              (a, b) =>
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
            )
            .map(contentOrder => (
              <List
                key={contentOrder.id}
                onAllClick={() =>
                  navigation(`/contenthistory/${contentOrder.id}`)
                }
                badge={contentOrder.author}
                title={contentOrder.title}
                description={getRelativeTimestampString(contentOrder.createdAt)}
                actionText={`${contentOrder.amount}원`}
              />
            ))}
      </>
    );
  }

  if (kind === 'PRINTS' && !printOrderLine?.length) {
    return <Empty>인쇄 이용 내역이 없습니다</Empty>;
  }

  return (
    <>
      {printOrderLine &&
        printOrderLine
          .sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          )
          .map(printOrder => (
            <List
              key={printOrder.id}
              onAllClick={() => navigation(`/printhistory/${printOrder.id}`)}
              badge={printOrder.service}
              title={printOrder.printZone}
              description={getRelativeTimestampString(
                printOrder.createdAt.toString()
              )}
              actionText={`${printOrder.amount}원`}
            />
          ))}
    </>
  );
};

export default observer(HistoryLine);

const Empty = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3D(-50%, -50%, 0);

  font-weight: 600;
  font-size: 16px;
  color: ${({ theme }) => theme.color.gray700};
`;
