import React from 'react';
import styled from 'styled-components';
import Lottie from 'react-lottie-player';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores/RootStore';
import PageContainer from './PageContainer';
import TopBar from './top_bar/TopBar';
import SolidButton from './solid_button/SolidButton';
import { Spacing } from 'styles/styles';
import LottieError from 'assets/lottie/LottieError.json';
import useHistoryBack from 'hooks/useHistoryBack';

export default observer(function ErrorPage() {
  const navigation = useNavigate();
  const { stateStore } = useStores();

  useHistoryBack(() => {
    stateStore.setError(false);
  });

  return (
    <PageContainer>
      <ErrorContainer>
        <TopBar
          style={{
            zIndex: 20,
            position: 'fixed',
            top: 0,
            left: 0,
            margin: '0 4px',
          }}
        />
        <Lottie
          loop={false}
          animationData={LottieError}
          play
          style={{
            width: 200,
            height: 200,
            marginBottom: '2px',
            overflow: 'visible',
          }}
        />
        <ErrorTitile>에러가 발생했습니다.</ErrorTitile>
        <Spacing.Vertical height={20} />
        <ErrorDescription>
          {`요청하신 페이지를 찾을 수 없습니다.\n서비스 이용에 불편을 드려 죄송합니다.`}
        </ErrorDescription>
        <Spacing.Vertical height={40} />
        <div>
          <SolidButton
            onClick={() => {
              navigation('/');
              stateStore.setError(false);
            }}
            area="sm"
            kind="lightRed"
          >
            보바 홈으로
          </SolidButton>
        </div>
      </ErrorContainer>
    </PageContainer>
  );
});

const ErrorContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 16;
  background: ${({ theme }) => theme.color.white};
`;
const ErrorTitile = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.gray900};
  line-height: 26px;
`;
const ErrorDescription = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.gray500};
  white-space: pre-line;
  text-align: center;
`;
