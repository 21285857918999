import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores/RootStore';
import OptionButton from './OptionButton';
import IconMonoOnly from 'assets/icons/printSettings/icon_mono_only.png';
import IconColorOnly from 'assets/icons/printSettings/icon_color_only.png';
import { ImgOptionColor, ImgOptionMono } from 'assets/images';

type Props = {
  fileId: string;
};

const IsColorOption: React.FC<Props> = ({ fileId }) => {
  const { kioskStore, printJobBuilderStore } = useStores();

  const [kioskIsMono, setKioskIsMono] = useState<boolean>();
  const [kioskIsColor, setKioskIsColor] = useState<boolean>();

  const [isColor, setIsColor] = useState(true);

  const handleOption = (option: boolean) => {
    const printOption =
      printJobBuilderStore.getPrintJobByFileId(fileId)?.ticket;
    if (printOption) {
      printJobBuilderStore.getPrintJobByFileId(fileId)?.setTicket({
        ...printOption,
        isColor: option,
      });
      setIsColor(option);
    }
  };

  /**
   * print ticket 초기화시 isColor = true 함
   * 따라서 흑백출력만 가능한 kiosk일시, isColor = false 자동으로 바꿔주도록 함
   */
  useEffect(() => {
    setKioskIsMono(
      kioskStore.kiosks.find(
        kiosk =>
          kiosk.id === printJobBuilderStore.getPrintJobByFileId(fileId)?.kioskId
      )?.isMono
    );

    setKioskIsColor(
      kioskStore.kiosks.find(
        kiosk =>
          kiosk.id === printJobBuilderStore.getPrintJobByFileId(fileId)?.kioskId
      )?.isColor
    );

    if (kioskIsMono && !kioskIsColor) {
      const printOption =
        printJobBuilderStore.getPrintJobByFileId(fileId)?.ticket;
      if (printOption) {
        printJobBuilderStore.getPrintJobByFileId(fileId)?.setTicket({
          ...printOption,
          isColor: false,
        });
      }
      setIsColor(false);
    }

    if (!kioskIsMono && kioskIsColor) {
      const printOption =
        printJobBuilderStore.getPrintJobByFileId(fileId)?.ticket;
      if (printOption) {
        printJobBuilderStore.getPrintJobByFileId(fileId)?.setTicket({
          ...printOption,
          isColor: true,
        });
      }
      setIsColor(true);
    }

    if (kioskIsMono && kioskIsColor) {
      const printOption =
        printJobBuilderStore.getPrintJobByFileId(fileId)?.ticket;
      if (printOption) {
        setIsColor(printOption?.isColor);
      }
    }
  }, [fileId, kioskIsColor, kioskIsMono]);

  return (
    <Container>
      {/* 흑백 + 컬러 */}
      {kioskIsMono && kioskIsColor && (
        <>
          <OptionButton
            onClick={() => handleOption(true)}
            active={isColor}
            label="컬러"
          >
            <ColorOption src={ImgOptionColor} />
          </OptionButton>
          <OptionButton
            onClick={() => handleOption(false)}
            active={!isColor}
            label="흑백"
          >
            <ColorOption src={ImgOptionMono} />
          </OptionButton>
        </>
      )}

      {/* 흑백전용 */}
      {kioskIsMono && !kioskIsColor && (
        <>
          <OptionButton active={true}>
            <img
              src={IconMonoOnly}
              style={{
                width: '181px',
                height: '40px',
                backgroundPosition: 'center',
              }}
            ></img>
          </OptionButton>
        </>
      )}

      {/* 컬러 전용 */}
      {!kioskIsMono && kioskIsColor && (
        <>
          <OptionButton active={true}>
            <img
              src={IconColorOnly}
              style={{
                width: '181px',
                height: '40px',
                backgroundPosition: 'center',
              }}
            ></img>
          </OptionButton>
        </>
      )}
    </Container>
  );
};
export default observer(IsColorOption);

const Container = styled.div`
  gap: 16px;
  padding: 16px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ColorOption = styled.img`
  width: 40px;
  height: 40px;
  background-position: center40px;
`;
