import { jobService } from 'services/job.service';
import { StateStore } from 'stores/state/StateStore';
import { ScanCopyJobStore } from './ScanCopyJobStore';

type Service = 'scans' | 'copies';

export class ScanCopyJobBuilderStore {
  isLoading: boolean = false;

  serviceType: Service | null = null;
  kioskId: string | null = null;

  changeKioskMode: boolean = false;
  editJobIds: string[] = [];

  constructor(
    private readonly scanCopyJobstore: ScanCopyJobStore,
    private readonly stateStore: StateStore
  ) {}

  setService(service: Service | null) {
    this.serviceType = service;
  }

  setKiosk(kioskId: string | null) {
    this.kioskId = kioskId;
  }

  async createToServer() {
    if (!this.kioskId || !this.serviceType) {
      throw new Error('not select kioskId, serviceType!!');
    }
    this.startLoading();

    await jobService
      .createScanCopyJob(this.serviceType, this.kioskId)
      .then(async () => {
        this.updateFromServer();
      })
      .catch(() => {
        this.stateStore.setError(true);
      });

    this.clear();
    this.endLoading();
  }

  async changeKioskToServer() {
    if (!this.kioskId || !this.serviceType || !this.editJobIds.length) {
      throw new Error('not select kioskId, serviceType!!');
    }
    this.startLoading();

    await jobService
      .changeKiosk(this.editJobIds, this.serviceType, this.kioskId)
      .then(async () => {
        this.updateFromServer();
      })
      .catch(() => {
        this.stateStore.setError(true);
      });

    this.clear();
    this.endLoading();
  }

  async updateFromServer() {
    await this.scanCopyJobstore.getScanJobs();
    await this.scanCopyJobstore.getCopyJobs();
  }

  setChangeKioskMode(isChangeMode: boolean) {
    this.changeKioskMode = isChangeMode;
  }

  setEditJobId(jobIds: string[]) {
    this.editJobIds = jobIds;
  }

  clear() {
    this.serviceType = null;
    this.kioskId = null;
    this.changeKioskMode = false;
    this.editJobIds = [];
  }

  startLoading() {
    this.isLoading = true;
  }

  endLoading() {
    this.isLoading = false;
  }
}
