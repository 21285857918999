import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import trackUser from 'services/trackUser';
import { ampEvents } from 'amplitude-events';
import List from 'components/common/list/List';
import SolidButton from 'components/common/solid_button/SolidButton';
import Icon from 'components/common/icon/Icon';
import TopInfo from 'components/common/top_info/TopInfo';
import { getRelativeTimestampString } from 'utils/time.util';

interface ReservationTicketProps {
  serviceName: '인쇄' | '스캔' | '복사';
  kioskId: string;
  kioskName: string;
  kioskDescription: string;
  expireAt: Date;
}

export default function ReservationTicket({
  serviceName,
  kioskId,
  kioskName,
  kioskDescription,
  expireAt,
}: ReservationTicketProps) {

  const navigation = useNavigate();

  const handleClickTicket = () => {
    trackUser(ampEvents.WaitingList_SelectTicket);
    if (serviceName === '인쇄') {
      navigation(`/waiting-list/prints/${kioskId}`);
    } else {
      navigation(`/waiting-list/${kioskId}`, {
        state: {
          service: serviceName,
        },
      });
    }
  };

  return (
    <Container onClick={handleClickTicket}>
      <TopInfo
        title={serviceName}
        rightContent={
          <SolidButton
            kind="skyblue"
            area="sm">
            선택하기
          </SolidButton>
        }
      />
      <List
        title={kioskName}
        description={kioskDescription}
        right={
          <Icon
            icon="IconInfoCircle"
            onClick={(e) => {
              e.stopPropagation();
              e?.nativeEvent?.stopImmediatePropagation();
              trackUser(ampEvents.WaitingList_PrinterDetail);
              navigation(`/printzone/${kioskId}`);
            }}
          />
        }
      />
      <List
        title="요청 일시"
        right={
          <RequestDate>{getRelativeTimestampString(expireAt)}</RequestDate>
        }
      />
      <Boder />
    </Container>
  );
}

const Container = styled.div``;
const RequestDate = styled.p`
  width: max-content;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.gray600};
`;
const Boder = styled.div`
  margin-left: -24px;
  width: calc(100% + 48px);
  height: 8px;
  background: ${({ theme }) => theme.color.gray100};
`;
