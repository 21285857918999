import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores/RootStore';
import List from 'components/common/list/List';
import PageContainer from 'components/common/PageContainer';
import SolidButton from 'components/common/solid_button/SolidButton';
import TopBar from 'components/common/top_bar/TopBar';
import TopInfo from 'components/common/top_info/TopInfo';
import FixedButtons from 'components/common/solid_button/FixedButtons';
import ErrorPage from 'components/common/ErrorPage';
import FullScreenLoader from 'components/common/FullScreenLoader';
import Modal from 'components/common/modal/Modal';
import { getDatetimeKoreanString, getExpireTimeString } from 'utils/time.util';
import { PrintJobDto } from 'types/job/print-job/PrintJob.dto';
import { ScanJobDto } from 'types/job/scan-job/ScanJob.dto';
import { CopyJobDto } from 'types/job/copy-job/CopyJob.dto';

function WaitingListDetail() {
  const {
    stateStore,
    updateJobStore,
    jobPollingStore,
    scanCopyJobStore,
    scanCopyJobBuilderStore,
  } = useStores();
  const navigation = useNavigate();
  const { pathname, state } = useLocation();
  const id = pathname.split('/')[2];
  const serviceName = (state as { service: string }).service;
  const [job, setJob] = useState<PrintJobDto | ScanJobDto | CopyJobDto | null>(
    null
  );

  const [cancelConfirmModal, setCancelConfirmModal] = useState<boolean>(false);
  const [cancelModal, setCancelModal] = useState<boolean>(false);

  const handleChangeKiosk = () => {
    const service = serviceName === '스캔' ? 'scans' : 'copies';

    scanCopyJobBuilderStore.setChangeKioskMode(true);
    updateJobStore.setBuilderService(service);

    let jobIds: string[] | undefined;
    if (service === 'scans') {
      jobIds = scanCopyJobStore
        .getScanJobsExistingInThekiosk(id)
        .map(scanJob => scanJob.id);
    } else if (service === 'copies') {
      jobIds = scanCopyJobStore
        .getCopyJobsExistingInThekiosk(id)
        .map(copyJob => copyJob.id);
    }
    if (!jobIds) {
      return stateStore.setError(true);
    }

    scanCopyJobBuilderStore.setEditJobId(jobIds);
    navigation('/select-kiosk', { replace: true });
  };

  const handleCancelJobs = () => {
    stateStore.startLoading();
    const service = serviceName === '스캔' ? 'scans' : 'copies';
    updateJobStore
      .deleteAllJobs(service, id)
      .then(() => setCancelModal(true))
      .catch(() => {
        stateStore.setError(true);
      })
      .finally(() => {
        stateStore.endLoading();
        setCancelConfirmModal(false);
      });
  };

  const handleVerify = () => {
    if (job === null) {
      return stateStore.setError(true);
    }

    const service = serviceName === '스캔' ? 'scans' : 'copies';
    jobPollingStore.setService(service);
    jobPollingStore.setCurrentJobIds([job.id]);
    jobPollingStore.setKioskId(id);

    navigation('/verify-kiosk');
  };

  useEffect(() => {
    if (serviceName === '인쇄') {
      return;
    }
    if (serviceName === '스캔') {
      return setJob(scanCopyJobStore.getScanJobsExistingInThekiosk(id)[0]);
    } else if (serviceName === '복사') {
      return setJob(scanCopyJobStore.getCopyJobsExistingInThekiosk(id)[0]);
    }
  }, [id, serviceName]);

  if (job === null) {
    return <FullScreenLoader />;
  }

  if (!id) {
    return <ErrorPage />;
  }

  return (
    <PageContainer>
      <TopBar title="작업 대기 목록" />

      <>
        <TopInfo
          title={serviceName}
          rightContent={
            <SolidButton
              kind="lightRed"
              area="sm"
              onClick={() => setCancelConfirmModal(true)}
            >
              요청 취소
            </SolidButton>
          }
        />
        <List
          title="요청 일시"
          right={
            <CreatedAt>
              {getDatetimeKoreanString(job.modifiedAt.toString()) ?? '발급중'}
            </CreatedAt>
          }
        />
        <Border />
        <List
          title="프린터"
          right={
            <SolidButton kind="skyblue" area="sm" onClick={handleChangeKiosk}>
              프린터 변경
            </SolidButton>
          }
        />
        <List
          title={job.kiosk.name}
          description={job.kiosk.description}
          action="info"
          onActionClick={() => navigation(`/printzone/${job.kiosk.id}`)}
        />
        <Border />
        <List
          title="만료 시간"
          actionText={getExpireTimeString(job.expireAt)}
        />
        {job.price !== null && (
          <List title="결제 금액(장당)" actionText={`${job.price}원`} />
        )}
      </>
      <FixedButtons>
        <SolidButton onClick={handleVerify}>시작하기</SolidButton>
      </FixedButtons>

      <Modal
        isOpen={cancelModal}
        onRequestClose={() => {
          setCancelModal(false);
          navigation('/waiting-list', { replace: true });
        }}
        description="요청을 취소하였습니다."
        mainActionLabel="확인"
        onMainAction={() => {
          setCancelModal(false);
          navigation('/waiting-list', { replace: true });
        }}
      />
      {/* Modal: 인쇄 대기 중인 문서 삭제 시도시 */}
      <Modal
        isOpen={cancelConfirmModal}
        onRequestClose={() => setCancelConfirmModal(false)}
        title="요청 취소"
        description={`정말 요청을 취소하시겠습니까?`}
        mainActionLabel="요청 취소"
        mainActionColor="NEGATIVE"
        onMainAction={handleCancelJobs}
        subActionLabel="아니오"
        onSubAction={() => setCancelConfirmModal(false)}
      />
    </PageContainer>
  );
}

export default observer(WaitingListDetail);

const Border = styled.div`
  width: calc(100% + 48px);
  margin-left: -24px;
  height: 8px;
  background: ${({ theme }) => theme.color.gray100};
`;
const CreatedAt = styled.p`
  width: max-content;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.gray600};
`;
