import React from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as IconFile } from 'assets/icons/files/IconFile.svg';
import { ReactComponent as IconErrorFile } from 'assets/icons/files/IconErrorFile.svg';
import { Spacing } from 'styles/styles';
import CheckBox from './check_box/CheckBox';
import { useStores } from 'stores/RootStore';
import Icon from './icon/Icon';

interface Props {
  active?: boolean;
  title?: string;
  subTitle?: string;
  imageUrl?: string | null;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onOptionClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}
// onclick 바꾸기
const ImageList: React.FC<Props> = ({
  active,
  title,
  subTitle,
  imageUrl,
  disabled,
  onClick,
  onOptionClick,
}) => {
  const { fileStore } = useStores();

  return (
    <ListContainer>
      {active && (
        <CheckBoxContainer>
          <CheckBox checked={true} />
        </CheckBoxContainer>
      )}
      <ThumbnailImage disabled={disabled} active={active} onClick={onClick}>
        {imageUrl && (
          <Image imageUrl={imageUrl}>
            {!fileStore.selectMode && (
              <IconMoreButton
                icon="IconDotsHorizontal"
                size={20}
                onClick={onOptionClick}
              />
            )}
          </Image>
        )}
        {!imageUrl && (
          <Image imageUrl={imageUrl}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Spacing.Vertical height={25} />
              {!disabled ? (
                <IconFile width={80} height={80} />
              ) : (
                <IconErrorFile width={80} height={80} />
              )}
            </div>
            {!fileStore.selectMode && (
              <IconMoreButton
                icon="IconDotsHorizontal"
                size={20}
                onClick={onOptionClick}
              />
            )}
          </Image>
        )}
      </ThumbnailImage>
      <Spacing.Vertical height={8} />
      <Title>{title}</Title>
      <Description>{subTitle}</Description>
    </ListContainer>
  );
};
export default ImageList;

const ListContainer = styled.div`
  width: 143.5px;
  margin-bottom: 14px;
  position: relative;
`;

const ThumbnailImage = styled.div<Props>`
  width: 100%;
  height: 143.5px;
  padding: 12px;
  border-radius: 4px;
  background: #f2f2f2;
  ${props => {
    if (props.active)
      return css`
        background: #e6f2fe;
        border: 2px solid #2784ff;
      `;
  }}
  ${props => {
    if (props.disabled)
      return css`
        opacity: 0.5;
      `;
  }}
`;

const CheckBoxContainer = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 10;
`;

const Image = styled.div<Props>`
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  background: url('${props => props.imageUrl}');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const Title = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1d1d1d;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: #767676;
`;

const IconMoreButton = styled(Icon)`
  position: absolute;
  top: 0;
  z-index: 5;
  cursor: pointer;
`;
