import { ampEvents } from 'amplitude-events';
import { ImgBannerAd } from 'assets/images';
import BottomNavbar from 'components/common/BottomNavbar';
import CustomTagList from 'components/common/CustomTagList';
import FullScreenLoader from 'components/common/FullScreenLoader';
import PageContainer from 'components/common/PageContainer';
import Icon from 'components/common/icon/Icon';
import LargeButton from 'components/common/large_button/LargeButton';
import List from 'components/common/list/List';
import Modal from 'components/common/modal/Modal';
import TopInfo from 'components/common/top_info/TopInfo';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { kioskService } from 'services/kiosk.service';
import trackUser from 'services/trackUser';
import { useStores } from 'stores/RootStore';
import styled from 'styled-components';
import { Spacing } from 'styles/styles';
import { PrintZoneLineDto } from 'types/kiosk/PrintZoneLine.dto';
import { Status } from 'types/kiosk/Status';
import { calcDistance } from 'utils/utils';

const Home: React.FC = props => {
  const { userStore, updateJobStore } = useStores();
  const navigation = useNavigate();
  const [loginModal, setLoginModal] = useState(false);
  const [welcomeModal, setWelcomeModal] = useState(false);
  const [printZonesLine, setPrintZonesLine] = useState<PrintZoneLineDto[]>();
  const location = useLocation().state as { beforePage: 'REGISTER' };
  const [clickCount, setClickCount] = useState(0);

  useEffect(() => {
    if (userStore.currentLocation === null) return;
    kioskService.getKiosksAroundUser().then(res => {
      setPrintZonesLine(res.data.filter(kiosk => kiosk.status === Status.Okay));
    });
  }, [userStore.currentLocation]);

  useEffect(() => {
    userStore.updateReload(false);
  }, []);

  useEffect(() => {
    if (clickCount > 15) {
      window.location.replace('https://192.168.0.181:3000');
    }
  }, [clickCount]);

  useEffect(() => {
    if (!location) return;
    if (location.beforePage == 'REGISTER') setWelcomeModal(true);
  });

  if (userStore.isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <>
      <TopBarContainer>
        <div onClick={() => setClickCount(prev => prev + 1)}>
          <TopInfo
            title={'홈' + getDEVState()}
            rightContent={
              <Icon
                icon="IconBell"
                onClick={() => {
                  trackUser(ampEvents.Home_Alarm);
                  navigation('/alarms');
                }}
              />
            }
          />
        </div>
      </TopBarContainer>
      <Spacing.Vertical height={72} />
      <BannerContainer>
        <Banner
          src={ImgBannerAd}
          onClick={() => {
            trackUser(ampEvents.Home_Banner);
            window.open('https://forms.gle/oinrT5d2Nj7ju6mr6', '_blank');
          }}
        />
      </BannerContainer>

      <PageContainer>
        <Spacing.Vertical height={32} />

        {/* 추천 Tags */}
        <TopInfo title="콘텐츠" />
        <CustomTagList beforePage="HOME" />
        <Spacing.Vertical height={32} />

        <LargeButton
          onClick={() => {
            trackUser(ampEvents.Home_GoToPrint);
            if (!userStore.user) {
              setLoginModal(true);
              return;
            }
            updateJobStore.setBuilderService('prints');
            navigation('/printWizard1');
          }}
          label="프린트하러 가기"
          icon="print"
          kind="blue"
        />
        <Spacing.Vertical height={16} />

        <Buttons>
          <LargeButton
            onClick={() => {
              trackUser(ampEvents.Home_GoToFindPrinter);
              navigation('/map');
            }}
            label="프린터 찾기"
            icon="map"
          />
          <LargeButton
            onClick={() => {
              trackUser(ampEvents.Home_GoToDocumentBox);
              if (!userStore.user) {
                setLoginModal(true);
                return;
              }
              navigation('/myfiledocuments');
            }}
            label="문서함"
            icon="file"
          />
        </Buttons>

        <Spacing.Vertical height={48} />

        {/* 내 주변 프린터 */}
        <TopInfo title="내 주변 프린터" />
        {printZonesLine &&
          printZonesLine.map(printZoneLine => (
            <List
              key={printZoneLine.id}
              onAllClick={() => {
                trackUser(ampEvents.Home_NearPrinter);
                navigation(`/printzone/${printZoneLine.id}`);
              }}
              badge={printZoneLine.isBoba ? '바로 인쇄' : ''}
              badgeKind="blue"
              title={printZoneLine.companyName}
              description={`${calcDistance(
                userStore.currentLocation?.lat,
                userStore.currentLocation?.lng,
                printZoneLine.latitude,
                printZoneLine.longitude
              )} ${printZoneLine.address}`}
              listBottom="prices"
              status={printZoneLine.status}
              priceMono={printZoneLine.priceA4Mono}
              priceColor={printZoneLine.priceA4Color}
            />
          ))}
        <Spacing.Vertical height={100} />

        {/* Modal - login request */}
        <Modal
          isOpen={loginModal}
          onRequestClose={() => setLoginModal(false)}
          description="로그인이 필요한 서비스입니다"
          mainActionLabel="로그인하기"
          mainActionColor="POSITIVE"
          onMainAction={() => {
            trackUser(ampEvents.Home_NeedLoginModal_Okay);
            navigation('/boba');
          }}
          subActionLabel="취소"
          onSubAction={() => {
            trackUser(ampEvents.Home_NeedLoginModal_Cancel);
            setLoginModal(false);
          }}
        />
        <Modal
          isOpen={welcomeModal}
          title={'환영합니다! 🎉'}
          onRequestClose={() => setWelcomeModal(false)}
          description={`항상 곁에 있는 프린트,보바를\n지금 바로 이용해보세요!`}
          mainActionLabel="확인"
          mainActionColor="POSITIVE"
          onMainAction={() => {
            trackUser(ampEvents.Home_NeedLoginModal_Okay);
            navigation('/boba');
          }}
        />
      </PageContainer>
      <BottomNavbar />
    </>
  );
};

export default observer(Home);

const getDEVState = (): '' | '(DEV)' => {
  return process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_SERVER_V4_URL === 'https://192.168.0.181'
    ? '(DEV)'
    : '';
};

const TopBarContainer = styled.div`
  z-index: 998;
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
  max-width: 780px;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  background: #ffffff;
`;
const BannerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const Banner = styled.img`
  width: 100%;
  height: 70%;
  max-width: 780px;
  margin: 0 auto;
  background-position: center;
`;
const Buttons = styled.div`
  display: flex;
  gap: 16px;
`;
