import React from 'react';
import { TabPanel } from 'react-tabs';
import PageContainer from 'components/common/PageContainer';
import TabMenu from 'components/common/tab_menu/TabMenu';
import TopBar from 'components/common/top_bar/TopBar';
import HistoryLine from 'components/pages/usage-history/HistoryLine';

const UsageHistory: React.FC = props => {
  return (
    <PageContainer>
      <TopBar title="이용 내역" />
      <TabMenu names={['콘텐츠 구매 내역', '작업 이용 내역']}>
        <TabPanel>
          <HistoryLine kind="CONTENTS" />
        </TabPanel>
        <TabPanel>
          <HistoryLine kind="PRINTS" />
        </TabPanel>
      </TabMenu>
    </PageContainer>
  );
};

export default UsageHistory;
