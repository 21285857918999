/**
 * NOTE: 웹 환경에서만 동작시킬 목업 오브젝트
 */
import axios from 'axios';
import { Bridge } from 'bridges';

export default class implements Bridge {
  downloadFiles(files: { url: string; name: string }[]) {
    files.forEach(file => {
      axios({
        url: file.url,
        method: 'GET',
        responseType: 'blob',
      }).then(res => {
        this.createAndClickLink(res.data, file.name);
      });
    });
  }

  private createAndClickLink(blob: string, fileName: string) {
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  getVersionName() {
    return '';
  }

  toString() {
    return 'WEB';
  }
}
