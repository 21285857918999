import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useStores } from 'stores/RootStore';
import { observer } from 'mobx-react-lite';

export function ReloadAuth() {
  const { userStore } = useStores();
  const location = useLocation();

  if (userStore.reload) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return <Outlet />;
}

export default observer(ReloadAuth);
