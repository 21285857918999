import { ampEvents } from 'amplitude-events';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { kioskService } from 'services/kiosk.service';
import trackUser from 'services/trackUser';
import { useStores } from 'stores/RootStore';
import styled from 'styled-components';
import { Spacing } from 'styles/styles';
import { PrintZoneLineDto } from 'types/kiosk/PrintZoneLine.dto';
import List from '../common/list/List';
import Icon from 'components/common/icon/Icon';

type Prop = {
  findText: string;
};

const FindPrintZone: React.FC<Prop> = ({ findText }) => {
  const navigation = useNavigate();
  const { stateStore } = useStores();

  const [printZonesLine, setPrintZonesLine] = useState<PrintZoneLineDto[]>();

  useEffect(() => {
    // FIXME: 보바 개소만 뜨게 해둠
    kioskService
      .getKiosks(true, true, true, true, findText)
      .then(res => {
        setPrintZonesLine(res);
      })
      .catch(() => {
        stateStore.setError(true);
      });
  }, [findText]);

  return (
    <>
      {!findText && (
        <InfoContainer>
          <Slogan>이렇게 검색해보세요</Slogan>
          <Spacing.Vertical height={20} />

          <Title>지역명 + 번지</Title>
          <Example>도곡동 12-1</Example>
          <Spacing.Vertical height={12} />

          <Title>태그 검색</Title>
          <Example>#한국대학교 #스터디카페</Example>
          <Spacing.Vertical height={12} />

          <Title>매장 검색</Title>
          <Example>스타벅스 대치점</Example>
        </InfoContainer>
      )}
      {findText && printZonesLine?.length === 0 && (
        <NoSearchFile>
          <Icon icon="Icon3DNotFoundFile" size={200} />
          <Spacing.Vertical height={30} />
          <NoSearchFileText>검색 결과가 존재하지 않습니다</NoSearchFileText>
        </NoSearchFile>
      )}

      {findText !== '' && (
        <>
          <Spacing.Vertical height={74} />

          {printZonesLine &&
            printZonesLine.length > 0 &&
            printZonesLine.map(printZoneLine => (
              <List
                key={printZoneLine.id}
                onAllClick={() => {
                  localStorage.setItem('findText', findText);
                  trackUser(ampEvents.Map_SelectFromSearch);
                  navigation(`/printzone/${printZoneLine.id}`);
                }}
                badge={printZoneLine.isBoba ? '바로인쇄' : ''}
                badgeKind="blue"
                title={printZoneLine.companyName}
                description={printZoneLine.address}
                listBottom="prices"
                status={printZoneLine.status}
                priceMono={printZoneLine.priceA4Mono}
                priceColor={printZoneLine.priceA4Color}
              />
            ))}

          <Spacing.Vertical height={80} />
        </>
      )}
    </>
  );
};

export default FindPrintZone;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
`;
const Title = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  color: #646464;
`;
const Slogan = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 160%;
  color: #484848;
`;
const Example = styled.p`
  margin-top: 2px;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #9c9c9c;
`;

const NoSearchFile = styled.div`
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const NoSearchFileText = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
`;
