import React, { InputHTMLAttributes, useRef } from 'react';
import styled, { css } from 'styled-components';
import SolidButton from '../solid_button/SolidButton';
import Icon from '../icon/Icon';
import {
  FieldValues,
  UseFormRegister,
  Path,
  RegisterOptions,
} from 'react-hook-form';

type InputStatus = 'default' | 'correct' | 'error';

export interface FormInputProps<TFormValues extends FieldValues>
  extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  action?: InputStatus;
  helpText?: string;
  button?: string;
  onAction?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  searchMode?: boolean;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
  // react-hook-form 관련 속성
  fieldName?: Path<TFormValues>;
  register?: UseFormRegister<TFormValues>;
  rules?: RegisterOptions;
}

const InputBox = <TFormValues extends Record<string, unknown>>({
  searchMode,
  button,
  onAction,
  setValue,
  fieldName,
  value,
  label,
  action,
  register,
  helpText,
  rules,
  ...props
}: FormInputProps<TFormValues>): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Container>
      <InputHeader>{label && <Label>{label}</Label>}</InputHeader>

      <WithButton>
        <InputContainer action={action ?? 'default'}>
          {fieldName && register ? (
            <Input {...register(fieldName, rules)} {...props} />
          ) : (
            <Input ref={inputRef} value={value} {...props} /> // 기존
          )}

          {searchMode && !value && <Icon icon="IconSearch" size={20} />}
          {searchMode && value && (
            <Icon
              icon="IconXCircle"
              size={20}
              onClick={() => {
                if (inputRef.current === null || !setValue) return;
                inputRef.current.value = '';
                setValue('');
              }}
            />
          )}
        </InputContainer>
        {button && <SolidButton onClick={onAction}>{button}</SolidButton>}
      </WithButton>

      <HelpContainer>
        {helpText && action === 'default' && (
          <Icon icon="IconInfoCircle" size={16} />
        )}
        {action === 'correct' && (
          <Icon icon="IconCheckCircle" size={16} themeColor="primary600" />
        )}
        {action === 'error' && (
          <Icon icon="IconError" size={16} themeColor="destructive600" />
        )}
        {helpText && (
          <HelpText action={action ?? 'default'}>{helpText}</HelpText>
        )}
      </HelpContainer>
    </Container>
  );
};
export default InputBox;

InputBox.defaultProps = {
  action: 'default',
};
const Container = styled.div`
  width: 100%;
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const InputHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Label = styled.label`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #737373;
`;
const WithButton = styled.div`
  display: flex;
  gap: 16px;
  flex: 1;
`;
const InputContainer = styled.div<{ action: InputStatus }>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 10px;
  &:focus-within {
    outline: none;
    border: 1px solid #4483f7;
    box-shadow: 0px 0px 0px 2px #e6f2fe;
    border-radius: 10px;
  }
  ${props => {
    switch (props.action) {
      case 'default':
        return css`
          border: 1px solid #ebebeb;
        `;
      case 'correct':
        return css`
          border: 1px solid #ebebeb;
        `;
      case 'error':
        return css`
          border: 1px solid #d75e4e;
          &:focus-within {
            border: 1px solid #d75e4e;
            box-shadow: 0px 0px 0px 2px #fceced;
            border-radius: 10px;
          }
        `;
    }
  }}
`;
const Input = styled.input`
  width: 100%;
  padding: 12px 16px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.03em;
  border-radius: 10px;
  &::placeholder {
    color: #a3a3a3;
  }
  &:disabled {
    background: #fafafa;
  }
`;
const HelpContainer = styled.div`
  display: flex;
  align-items: center;
`;
const HelpText = styled.p<{ action: InputStatus }>`
  margin: 0 0 0 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.03em;
  ${props => {
    switch (props.action) {
      case 'default':
        return css`
          color: #767676;
        `;
      case 'correct':
        return css`
          color: #2784ff;
        `;
      case 'error':
        return css`
          color: #d75e4e;
        `;
    }
  }}
`;
