import { makeAutoObservable } from 'mobx';
import { CardDto } from 'types/card/Card.dto';
import { UserDto } from 'types/user/User.dto';

export class User {
  id: string;
  email: string;
  name: string;
  phoneNumber: string;
  points: number;
  storageAllocated: number;
  storageUsed: number;
  hasKakaoId: boolean;
  cards: CardDto[];
  isOnlyKakao: boolean;

  constructor(user: UserDto) {
    makeAutoObservable(this, {});

    this.id = user.id;
    this.email = user.email;
    this.name = user.name;
    this.phoneNumber = user.phoneNumber;
    this.points = user.points;
    this.storageAllocated = user.storageAllocated;
    this.storageUsed = user.storageUsed;
    this.hasKakaoId = user.hasKakaoId;
    this.cards = user.cards;
    this.isOnlyKakao = user.isOnlyKakao;
  }
}
