import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import InputBox from 'components/common/input_box/InputBox';
import Modal from 'components/common/modal/Modal';
import PageContainer from 'components/common/PageContainer';
import SolidButton from 'components/common/solid_button/SolidButton';
import TopBar from 'components/common/top_bar/TopBar';
import { EMAIL_REGEX } from 'constants/Constants';
import { Spacing } from 'styles/styles';
import { useStores } from 'stores/RootStore';
import { observer } from 'mobx-react-lite';
import FullScreenLoader from 'components/common/FullScreenLoader';
import trackUser from 'services/trackUser';
import { ampEvents } from 'amplitude-events';

const Login: React.FC = observer(() => {
  const [email, setEmail] = useState('');
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [pwd, setPwd] = useState('');
  const { userStore } = useStores();
  const navigation = useNavigate();

  // 로그인 시도
  const handleLogin = () => {
    userStore
      .login(email, pwd)
      .then(() => {
        trackUser(ampEvents.Login_LoginSuccess);
        navigation('/');
      })
      .catch(err => {
        userStore.errors.loginError = err;
      });
  };

  // email 형식 확인
  useEffect(() => {
    setEmailInvalid(email.match(EMAIL_REGEX) === null);
  }, [email]);

  useEffect(() => {
    setEmailInvalid(false);
  }, []);

  return (
    <>
      {userStore.isLoading && <FullScreenLoader message="로그인 중" />}
      <PageContainer>
        <Modal
          isOpen={userStore.errors.loginError ? true : false}
          onRequestClose={() => {
            userStore.clearError();
          }}
          description="이메일 또는 비밀번호를 다시 확인해주세요"
          mainActionLabel="확인"
          onMainAction={() => {
            userStore.clearError();
          }}
        />
        <Modal
          isOpen={userStore.errors.loginEmailError ? true : false}
          onRequestClose={() => {
            userStore.clearError();
          }}
          description="이메일을 다시 확인해주세요"
          mainActionLabel="확인"
          onMainAction={() => {
            userStore.clearError();
          }}
        />
        <Modal
          isOpen={userStore.errors.loginPasswordError ? true : false}
          onRequestClose={() => {
            userStore.clearError();
          }}
          description="비밀번호를 다시 확인해주세요"
          mainActionLabel="확인"
          onMainAction={() => {
            userStore.clearError();
          }}
        />
        <TopBar title="로그인" border={true} />
        <InputBox
          autoComplete="username"
          type="email"
          label="이메일"
          value={email}
          placeholder="boba@bobaprint.com"
          action={emailInvalid ? 'error' : 'default'}
          helpText={emailInvalid ? '유효하지 않은 이메일 형식입니다' : ''}
          onChange={e => {
            setEmail(e.target.value);
          }}
        />
        <InputBox
          autoComplete="current-password"
          type="password"
          label="비밀번호"
          value={pwd}
          onChange={e => {
            setPwd(e.target.value);
          }}
          onKeyPress={e => {
            if (e.key === 'Enter' && !emailInvalid && pwd !== '') {
              handleLogin();
            }
          }}
        />
        <OptionContainer>
          <OptionText
            onClick={() => {
              trackUser(ampEvents.Login_FindEmailPassword);
              navigation('/login/find');
            }}
          >
            {`이메일 / 비밀번호 찾기`}
          </OptionText>
          <OptionText
            onClick={() => {
              trackUser(ampEvents.Login_SignUp);
              navigation('/register1');
            }}
          >
            회원가입
          </OptionText>
        </OptionContainer>
        <Spacing.Vertical height={50} />
        <LoginButton>
          <SolidButton
            disabled={emailInvalid || pwd === ''}
            onClick={() => {
              trackUser(ampEvents.Login_LoginSubmit);
              handleLogin();
            }}
          >
            로그인
          </SolidButton>
        </LoginButton>
      </PageContainer>
    </>
  );
});

export default Login;

const OptionContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  padding: 0 40px 0 20px;
`;

const OptionText = styled.p`
  font-size: 14px;
  color: ${props => props.theme.color.gray500};
  cursor: pointer;
`;

const LoginButton = styled.div`
  width: 100%;
  max-width: 780px;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 0;
  padding: 0 30px 24px 30px;
  background: white;
`;
