import React from 'react';
import styled from 'styled-components';
import { Spacing } from 'styles/styles';
import { Status } from 'types/kiosk/Status';
import Icon from '../icon/Icon';
import { ImgOptionColor, ImgOptionMono } from 'assets/images';

export interface PriceProps {
  status?: Status;
  priceMono?: number | null;
  priceColor?: number | null;
}

const PriceList: React.FC<PriceProps> = ({ status, priceMono, priceColor }) => {
  return (
    <PriceContainer>
      {status === Status.Maintenance && (
        <PriceInfo>
          <Icon icon="IconErrorYellow" size={16} />
          <Spacing.Horizontal width={6} />
          <PriceLabel>소모품 부족</PriceLabel>
        </PriceInfo>
      )}
      {priceColor && (
        <PriceInfo>
          <ColorOption src={ImgOptionColor} />
          <Spacing.Horizontal width={6} />
          <PriceLabel>{`컬러 ${priceColor}원`}</PriceLabel>
        </PriceInfo>
      )}
      {priceMono && (
        <PriceInfo>
          <ColorOption src={ImgOptionMono} />
          <Spacing.Horizontal width={6} />
          <PriceLabel>{`흑백 ${priceMono}원`}</PriceLabel>
        </PriceInfo>
      )}
    </PriceContainer>
  );
};

export default PriceList;

const PriceContainer = styled.div`
  display: flex;
`;

const PriceInfo = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

const PriceLabel = styled.p`
  font-size: 12px;
  line-height: 14px;
  color: #767676;
`;

const ColorOption = styled.img`
  width: 16px;
  height: 16px;
  background-position: center;
`;
