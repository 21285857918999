import React from 'react';
import styled from 'styled-components';
import { Spacing } from 'styles/styles';

interface Props {
  name: string;
  iconSrc: string;
  onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const TagList: React.FC<Props> = ({ name, iconSrc, onClick }) => {
  return (
    <Container onClick={onClick}>
      <Icon src={iconSrc} />
      <Spacing.Vertical height={12} />
      <Tag>{name}</Tag>
    </Container>
  );
};
export default TagList;

const Container = styled.div`
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Icon = styled.img.attrs(props => ({
  src: props.src,
}))`
  background: no-repeat center;
  background-size: cover;
  width: 60px;
  height: 60px;
  border: none;
  border-width: 0;
  border-radius: 50%;
  box-shadow: 0px 0px 30px #00000014;
`;
const Tag = styled.div`
  width: max-content;
  height: 30px;
  padding: 4px 12px;
  background: #ffffff;
  border-radius: 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #737373;
  box-shadow: 0px 0px 20px #00000016;
`;
