import { httpRequestService } from './http-request.service';
import { ContentDto } from 'types/contents/Content.dto';
import { ContentLineDto } from 'types/contents/ContentLine.dto';
import { ContentPurchaseRequestDto } from 'types/contents/ContentPurchaseRequest.dto';
import { ContentPurchaseResponseDto } from 'types/contents/ContentPurchaseResponse.dto';

type params = {
  q: string;
  offset: number;
  limit: number;
};

export const CONTENT_API = {
  getContents: (params: params) =>
    httpRequestService.create().get<ContentLineDto[]>(`contents`, { params }),
  getContentDetail: (credential: boolean, contentId: string) =>
    httpRequestService
      .create(credential)
      .get<ContentDto>(`contents/${contentId}`),
  postPurchaseContent: (contentId: string, dto: ContentPurchaseRequestDto) =>
    httpRequestService
      .create(true)
      .post<ContentPurchaseResponseDto>(`contents/${contentId}/purchase`, dto),
};

export default { CONTENT_API };
