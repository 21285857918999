import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores/RootStore';
import { Spacing } from 'styles/styles';
import FullScreenLoader from 'components/common/FullScreenLoader';
import InputBox from 'components/common/input_box/InputBox';
import PageContainer from 'components/common/PageContainer';
import RadioButton from 'components/common/radio_button/RadioButton';
import RadioGroup from 'components/common/radio_button/RadioGroup';
import SolidButton from 'components/common/solid_button/SolidButton';
import TopBar from 'components/common/top_bar/TopBar';
import Icon from 'components/common/icon/Icon';
import useToast from 'hooks/useToast';
import FixedButtons from 'components/common/solid_button/FixedButtons';

const KakaoLogin: React.FC = props => {
  const { kakaoStore } = useStores();
  const navigation = useNavigate();
  const { showToast } = useToast();

  const [pwd, setPwd] = useState<string>('');
  const [selectedEmail, setSelectedEmail] = useState<string | null>(null);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<string | null>(
    null
  );

  const handleLogin = () => {
    kakaoStore
      .kakaoLogin(kakaoStore.userId!, selectedEmail, selectedPhoneNumber, pwd)
      .then(() => {
        navigation('/register/option', { replace: true });
      })
      .catch(err => {
        if (err.response.data.message === 'Invalid password') {
          showToast('비밀번호를 다시 확인해주세요');
        } else {
          showToast('다시 시도해주세요.');
        }
      });
  };

  useEffect(() => {
    if (kakaoStore.valueToChoice?.originEmail) {
      setSelectedEmail(kakaoStore.valueToChoice?.originEmail);
    }
    if (kakaoStore.valueToChoice?.originPhoneNumber) {
      setSelectedPhoneNumber(kakaoStore.valueToChoice?.originPhoneNumber);
    }
  }, [kakaoStore.valueToChoice]);

  useEffect(() => {
    if (!kakaoStore.isLoading && kakaoStore.valueToChoice === null) {
      handleLogin();
      return;
    }
  }, [kakaoStore.isLoading, kakaoStore.valueToChoice]);

  if (kakaoStore.isLoading || kakaoStore.valueToChoice === null)
    return (
      <PageContainer>
        <FullScreenLoader message="로그인 중" />
      </PageContainer>
    );

  return (
    <PageContainer>
      <TopBar left="back" title="회원가입" border={true} />
      <TextContainer>
        <Title>회원님의 카카오 계정이 연동되었습니다.</Title>
        <SubTitle>
          {`아래와 같이
      가입된 회원 정보가 있습니다. 암호를 입력하시면
      기존 회원으로 서비스 사용이 가능합니다.`}
        </SubTitle>
      </TextContainer>

      {kakaoStore.valueToChoice &&
        kakaoStore.valueToChoice.originEmail &&
        kakaoStore.valueToChoice.kakaoEmail && (
          <>
            <Label>이메일</Label>
            <RadioGroup direction="column">
              <RadioButton
                name="email"
                value={kakaoStore.valueToChoice.originEmail}
                checked={kakaoStore.valueToChoice.originEmail === selectedEmail}
                onChange={() =>
                  setSelectedEmail(kakaoStore.valueToChoice!.originEmail!)
                }
              >
                {kakaoStore.valueToChoice.originEmail}
              </RadioButton>
              <RadioButton
                name="email"
                value={kakaoStore.valueToChoice.kakaoEmail}
                checked={kakaoStore.valueToChoice.kakaoEmail === selectedEmail}
                onChange={() =>
                  setSelectedEmail(kakaoStore.valueToChoice!.kakaoEmail!)
                }
              >
                {kakaoStore.valueToChoice.kakaoEmail}
              </RadioButton>
            </RadioGroup>
          </>
        )}

      {kakaoStore.valueToChoice?.originEmail &&
        kakaoStore.valueToChoice?.originPhoneNumber && (
          <Spacing.Vertical height={32} />
        )}

      {kakaoStore.valueToChoice &&
        kakaoStore.valueToChoice.originPhoneNumber &&
        kakaoStore.valueToChoice.kakaoPhoneNumber && (
          <>
            <Label>휴대폰 번호</Label>
            <RadioGroup direction="column">
              <RadioButton
                name="phoneNumber"
                value={kakaoStore.valueToChoice.originPhoneNumber}
                checked={
                  kakaoStore.valueToChoice.originPhoneNumber ===
                  selectedPhoneNumber
                }
                onChange={() =>
                  setSelectedPhoneNumber(
                    kakaoStore.valueToChoice?.originPhoneNumber!
                  )
                }
              >
                {kakaoStore.valueToChoice.originPhoneNumber}
              </RadioButton>
              <RadioButton
                name="phoneNumber"
                value={kakaoStore.valueToChoice.kakaoPhoneNumber}
                checked={
                  kakaoStore.valueToChoice.kakaoPhoneNumber ===
                  selectedPhoneNumber
                }
                onChange={() =>
                  setSelectedPhoneNumber(
                    kakaoStore.valueToChoice?.kakaoPhoneNumber!
                  )
                }
              >
                {kakaoStore.valueToChoice.kakaoPhoneNumber}
              </RadioButton>
            </RadioGroup>
          </>
        )}

      <InputBox
        value={pwd}
        type="password"
        placeholder="비밀번호"
        onChange={e => {
          if (e.target.value.length > 100) return;
          setPwd(e.target.value);
        }}
        onKeyPress={e => {
          if (e.key === 'Enter' && pwd !== '') {
            handleLogin();
          }
        }}
      />
      <InfoContainer>
        <FindButton
          onClick={() => navigation('/login/find', { replace: true })}
        >{`이메일 / 비밀번호 찾기`}</FindButton>
        <InfoButton
          href="https://pf.kakao.com/_BPfWT"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon icon="IconKakao" size={16} />
          <ButtonText>문의하기</ButtonText>
        </InfoButton>
      </InfoContainer>

      <FixedButtons>
        <SolidButton disabled={!pwd} onClick={handleLogin}>
          로그인
        </SolidButton>
      </FixedButtons>
    </PageContainer>
  );
};

export default observer(KakaoLogin);

const TextContainer = styled.div`
  padding: 40px 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;
const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
`;
const SubTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #737373;
  text-align: center;
`;
const Label = styled.div`
  padding: 12px 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #737373;
`;
const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const FindButton = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #737373;
  padding: 8px;
`;
const InfoButton = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 2px 8px;
  background: #fafafa;
  border-radius: 16px;
`;
const ButtonText = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #737373;
`;
