import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Map, MapMarker, MarkerClusterer } from 'react-kakao-maps-sdk';
import { useStores } from 'stores/RootStore';
import { kioskService } from 'services/kiosk.service';
import trackUser from 'services/trackUser';
import { ampEvents } from 'amplitude-events';
import Modal from '../common/modal/Modal';
import Icon from 'components/common/icon/Icon';
import { PrintZoneLocationDto } from 'types/kiosk/PrintZoneLocation.dto';
import { ImgCurrentLocationMarker, ImgPrinterBobaMarker } from 'assets/images';

const KioskMap: React.FC = () => {
  const defaultLat = 37.56803229401946, defaultLng = 126.92519680992935, defaultLevel = 9 // 서울전체
  const navigation = useNavigate();
  const { stateStore, userStore, updateJobStore, scanCopyJobBuilderStore } =
    useStores();

  const [map, setMap] = useState<kakao.maps.Map>();
  const [lat, setLat] = useState<number>(defaultLat);
  const [lng, setLng] = useState<number>(defaultLng);
  const [level, setLevel] = useState<number>();

  const [kiosks, setKiosks] = useState<PrintZoneLocationDto[]>();

  const [changeModal, setChangeModal] = useState<boolean>(false);

  const handleCurrentLocation = () => {
    trackUser(ampEvents.PrintWizard2_MyLocation);
    if (userStore.currentLocation === null) return;

    const moveLatLng = new kakao.maps.LatLng(
      userStore.currentLocation.lat,
      userStore.currentLocation.lng
    );
    map?.panTo(moveLatLng);
    setMap(map);
  };

  const handleMarker = (kioskId: string) => {
    updateJobStore.setBuilderKioskId(kioskId);
    if (scanCopyJobBuilderStore.changeKioskMode) {
      scanCopyJobBuilderStore
        .changeKioskToServer()
        .then(() => setChangeModal(true));
      return;
    }
    if (updateJobStore.builderService === 'prints') {
      trackUser(ampEvents.PrintWizard2_Next);
      updateJobStore.setBuilderKioskId(kioskId);
      navigation(`/printwizard3/0`);
      return;
    } else {
      stateStore.startLoading();
      updateJobStore.scanCopyJobBuilderStore
        .createToServer()
        .then(() => {
          navigation('/reservation-complete', { replace: true });
        })
        .finally(() => {
          stateStore.endLoading();
        });
    }
  };

  useEffect(() => {
    setLat(parseFloat(localStorage.getItem("centerLat")?? defaultLat.toString()))
    setLng(parseFloat(localStorage.getItem("centerLng")?? defaultLng.toString()))
    setLevel(parseInt(localStorage.getItem("level") ?? defaultLevel.toString()))

    setMap(map);
  }, []);

  useEffect(() => {
    stateStore.startLoading();
    kioskService
      .getKiosksLocation(
        true,
        updateJobStore.builderService === 'prints',
        updateJobStore.builderService === 'scans',
        updateJobStore.builderService === 'copies'
      )
      .then(res => {
        setKiosks(res.data);
      })
      .catch(() => {
        stateStore.setError(true);
      })
      .finally(() => {
        stateStore.endLoading();
      });
  }, []);

  return (
    <Container>
      <Map
        center={{ lat: lat, lng: lng }}
        style={{
          width: 'calc(100% + 48px)',
          height: 'calc(100vh - 190px )',
          position: 'fixed',
          left: 0,
        }}
        level={level}
        onCreate={map => {
          setMap(map);
        }}
        onCenterChanged={target => {
          localStorage.setItem("centerLat", target.getCenter().getLat().toString())
          localStorage.setItem("centerLng", target.getCenter().getLng().toString())
        }}
        onZoomChanged={target => {
          localStorage.setItem("level", target.getLevel().toString())
        }}
      >
        <MarkerClusterer
          averageCenter={true}
          minLevel={8}
          styles={[
            {
              width: '50px',
              height: '50px',
              background: '#2784FF',
              borderRadius: '50%',
              color: 'white',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
          ]}
        >
          {kiosks &&
            kiosks.map(kiosk => (
              <MapMarker
                onClick={() => handleMarker(kiosk.i)}
                key={kiosk.i}
                image={{
                  src: ImgPrinterBobaMarker,
                  size: { width: 63, height: 78 },
                }}
                position={{ lat: kiosk.y, lng: kiosk.x }}
              />
            ))}
        </MarkerClusterer>

        {userStore.currentLocation !== null && (
          <MapMarker
            image={{
              src: ImgCurrentLocationMarker,
              size: { width: 18, height: 18 },
            }}
            position={{
              lat: userStore.currentLocation.lat,
              lng: userStore.currentLocation.lng,
            }}
          />
        )}
        <FloatingButton>
          <Icon
            icon="IconCurrentLocation"
            themeColor="primary600"
            onClick={handleCurrentLocation}
          />
        </FloatingButton>
      </Map>

      <Modal
        isOpen={changeModal}
        onRequestClose={() => {
          setChangeModal(false);
          navigation('/waiting-list', { replace: true });
        }}
        description="프린터 변경이 완료되었습니다."
        mainActionLabel="확인"
        onMainAction={() => {
          setChangeModal(false);
          navigation('/waiting-list', { replace: true });
        }}
      />
    </Container>
  );
};

export default observer(KioskMap);

const Container = styled.div`
  position: relative;
`;
const FloatingButton = styled.div`
  z-index: 4;
  position: fixed;
  right: 20px;
  bottom: 92px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: ${({ theme }) => theme.color.white};
  border: 1px solid #e0e0e0;
  border-radius: 50px;
  cursor: pointer;
`;
