import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { ampEvents } from 'amplitude-events';
import trackUser from 'services/trackUser';
import { useStores } from 'stores/RootStore';
import Icon from './icon/Icon';

const BottomNavbar: React.FC = props => {
  const { stateStore, userStore, contentStore, fileStore, updateJobStore } =
    useStores();

  const { pathname } = useLocation();
  const navigation = useNavigate();

  const handleContents = () => {
    if (pathname === '/contents') {
      contentStore.setFindText('');
      return;
    }
    navigation('/contents');
  };

  useEffect(() => {
    if (userStore.user) {
      fileStore
        .loadFiles()
        .then(() => {
          updateJobStore.getAllJobs();
        })
        .catch(() => {
          stateStore.setError(true);
        });
    }
  }, [userStore.user]);

  return (
    <Container>
      <Wrapper>
        <TabItem
          onClick={() => {
            trackUser(ampEvents.NavBar_Home);
            navigation('/');
          }}
        >
          <Icon
            icon="IconHome"
            themeColor={pathname === '/' ? 'primary600' : 'gray300'}
          />
          <TabLabel active={pathname === '/'}>홈</TabLabel>
        </TabItem>
        <TabItem
          onClick={() => {
            trackUser(ampEvents.NavBar_Content);
            handleContents();
          }}
        >
          <Icon
            icon="IconContents"
            themeColor={pathname === '/contents' ? 'primary600' : 'gray300'}
          />
          <TabLabel active={pathname === '/contents'}>콘텐츠</TabLabel>
        </TabItem>
        <TabItem
          onClick={() => {
            trackUser(ampEvents.NavBar_Print);
            navigation('/print');
          }}
        >
          <PrintContainer>
            <Icon
              icon="IconPrinter"
              themeColor={pathname === '/print' ? 'primary600' : 'gray300'}
            />
            {updateJobStore.jobLength > 0 && (
              <KioskJobBadge>{updateJobStore.jobLength}</KioskJobBadge>
            )}
          </PrintContainer>

          <TabLabel active={pathname === '/print'}>프린트</TabLabel>
        </TabItem>
        <TabItem
          onClick={() => {
            trackUser(ampEvents.NavBar_Map);
            navigation('/map');
          }}
        >
          <Icon
            icon="IconMap"
            size={24}
            themeColor={pathname === '/map' ? 'primary600' : 'gray300'}
          />
          <TabLabel active={pathname === '/map'}>지도</TabLabel>
        </TabItem>
        <TabItem
          onClick={() => {
            trackUser(ampEvents.NavBar_Menu);
            navigation('/menu');
          }}
        >
          <Icon
            icon="IconMenu"
            themeColor={pathname === '/menu' ? 'primary600' : 'gray300'}
          />
          <TabLabel active={pathname === '/menu'}>메뉴</TabLabel>
        </TabItem>
      </Wrapper>
    </Container>
  );
};
export default observer(BottomNavbar);

const Container = styled.div`
  z-index: 10;
  padding: 8px 0px 16px 0;
  width: 100%;
  height: 70px;
  position: fixed;
  bottom: 0;
  border-top: 1px solid ${props => props.theme.color.gray100};
  background: white;
`;
const Wrapper = styled.div`
  width: 100%;
  max-width: 780px;
  height: 100%;
  display: flex;
  margin: 0 auto;
  align-items: flex-start;
  flex: none;
  align-self: stretch;
`;
const TabItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  flex: none;
  flex-grow: 1;
  text-align: center;
  position: relative;
`;
const TabLabel = styled.p<{ active: boolean }>`
  height: 18px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  margin: 2px;
  color: ${({ active }) => (active ? '#2784ff' : '#cacaca')};
  flex: none;
  align-self: stretch;
  flex-grow: 0;
`;
const PrintContainer = styled.div`
  display: flex;
`;
const KioskJobBadge = styled.div`
  position: absolute;
  left: 70%;
  top: 5px;
  padding: 0 4px;
  background-color: #2784ff;
  border-radius: 3px;
  font-weight: bold;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
`;
