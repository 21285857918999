import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useStores } from 'stores/RootStore';
import FullScreenLoader from '../common/FullScreenLoader';
import List from '../common/list/List';
import { observer } from 'mobx-react-lite';
import FloatingButton from '../common/FloatingButton';
import Modal from '../common/modal/Modal';
import { getFileLabel } from 'utils/file.util';
import { Spacing } from 'styles/styles';
import ImageList from '../common/ImageList';
import UploadProgress from 'components/pages/UploadProgress';
import { useNavigate } from 'react-router-dom';
import { ampEvents } from 'amplitude-events';
import trackUser from 'services/trackUser';
import Icon from 'components/common/icon/Icon';
import { MODAL_PRINT_WIZARD } from 'constants/modalMessage';
import useUploadValidator from 'hooks/useUploadValidator';

type Props = {
  findText?: string;
};

const MyFiles: React.FC<Props> = ({ findText }) => {
  const { userStore, fileStore, uploadStore } = useStores();
  const fileRef = useRef<HTMLInputElement>(null);
  const navigation = useNavigate();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalMsg, setModalMsg] = useState<string>('');

  const { checkUploadPossible } = useUploadValidator({
    storageUsed: userStore.user?.storageUsed,
    storageAllocated: userStore.user?.storageAllocated,
  });

  function openModal(msg: string) {
    setShowModal(true);
    setModalMsg(msg);
  }

  /**
   * 검색 결과 파일
   */
  const findFile = fileStore.files
    .filter(file => file.origin === 'My')
    .filter(file => {
      if (findText) {
        return file.name.includes(findText.trim());
      }
      return file;
    });

  // 업로드 중인 파일 존재시, 업로드 불가 모달 띄움
  const onUploadButtonClick = () => {
    trackUser(ampEvents.DocumentBox_Upload);
    if (uploadStore.uploadables.length > 0) {
      openModal(MODAL_PRINT_WIZARD.ERROR_UPLOADING);
      return;
    }
    fileRef.current?.click();
  };

  const onSuccess = async (files: FileList) => {
    try {
      await uploadStore.addFileList(files);
    } catch (e) {
      openModal(MODAL_PRINT_WIZARD.ERROR_UPLOAD_FAILED);
    } finally {
      uploadStore.clear();
      await fileStore.loadFiles();
      fileStore.setSortMode(fileStore.sortMode);
      fileStore.setSortDescending(fileStore.sortDescending);
    }
  };

  const onError = (errMsg: string) => {
    openModal(errMsg);
  };

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (
      files == null ||
      userStore.user?.storageAllocated == null ||
      userStore.user.storageUsed == null
    ) {
      return;
    }

    checkUploadPossible(files, onSuccess, onError);
  };

  useEffect(() => {
    fileStore
      .loadFiles()
      .then(() => {
        fileStore.setSortMode(fileStore.sortMode);
        fileStore.setSortDescending(fileStore.sortDescending);
      })
      .catch(() => {
        userStore.showErrorModal();
      });
    fileStore.setSelectMode(false);
  }, []);

  return (
    <>
      {fileStore.isLoading && <FullScreenLoader message="파일 로드중" />}

      {fileStore.files.find(file => file.errorType) && (
        <List
          visual="error"
          text="변환에 실패한 파일이 있습니다"
          action="arrow"
          onAllClick={() => {
            navigation('/errorfileinfo');
          }}
        />
      )}

      {uploadStore.uploadables.length > 0 && (
        <UploadProgress files={uploadStore.uploadables} />
      )}

      {/* list view */}
      {fileStore.viewMode === 'list' &&
        (findFile.length > 0 ? (
          findFile.map(file => (
            <List
              key={file.id}
              onLeftClick={() => {
                trackUser(ampEvents.DocumentBox_SelectDocument);
                if (file.errorType) {
                  return;
                }
                {
                  !fileStore.selectMode &&
                    fileStore.setSelectMode(!fileStore.selectMode);
                }
                fileStore.updateSelectedFile(file.id);
              }}
              select={fileStore.selectMode ? 'check' : 'none'}
              checked={fileStore.selectedFile.includes(file.id)}
              onSelect={() => {}}
              disabled={file.errorType !== null}
              visual={
                file.errorType ? 'fileError' : file.imageUrls ? 'image' : 'file'
              }
              imageUrl={file.imageUrls && file.imageUrls[0]}
              title={file.name}
              description={getFileLabel(file, 'list')}
              action={fileStore.selectMode ? 'none' : 'more'}
              onActionClick={e => {
                fileStore.setOptionMode(true);
                fileStore.setOptionModeFile(
                  file.id,
                  file.name,
                  file.origin,
                  file.errorType
                );
              }}
            />
          ))
        ) : (
          <NoFilesView
            isFilesEmpty={
              fileStore.files.filter(file => file.origin === 'My').length === 0
            }
          />
        ))}

      {/* grid view */}
      {fileStore.viewMode === 'grid' &&
        (findFile.length > 0 ? (
          <>
            <Spacing.Vertical height={10} />
            <ImageListContainer>
              {findFile.map(file => (
                <ImageList
                  key={file.id}
                  onClick={() => {
                    if (file.errorType) {
                      return;
                    }
                    {
                      !fileStore.selectMode &&
                        fileStore.setSelectMode(!fileStore.selectMode);
                    }
                    fileStore.updateSelectedFile(file.id);
                  }}
                  active={fileStore.selectedFile.includes(file.id)}
                  disabled={file.errorType !== null}
                  title={file.name}
                  subTitle={getFileLabel(file, 'image')}
                  imageUrl={file.imageUrls && file.imageUrls[0]}
                  onOptionClick={e => {
                    e.stopPropagation();
                    if (!fileStore.selectMode) fileStore.setOptionMode(true);
                    fileStore.setOptionModeFile(
                      file.id,
                      file.name,
                      file.origin,
                      file.errorType
                    );
                  }}
                />
              ))}
            </ImageListContainer>
          </>
        ) : (
          <NoFilesView
            isFilesEmpty={
              fileStore.files.filter(file => file.origin === 'My').length === 0
            }
          />
        ))}
      <Spacing.Vertical height={80} />

      {!fileStore.selectMode && (
        <FabContainer>
          <input
            type="file"
            multiple
            id="inpUploadFile"
            hidden
            ref={fileRef}
            onChange={handleFile}
          />
          <FloatingButton onClick={onUploadButtonClick} />
        </FabContainer>
      )}

      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        title={MODAL_PRINT_WIZARD.TITLE}
        description={modalMsg}
        mainActionLabel="확인"
        onMainAction={() => setShowModal(false)}
      />
    </>
  );
};

const NoFilesView: React.FC<{ isFilesEmpty: boolean }> = ({ isFilesEmpty }) => {
  if (isFilesEmpty) {
    return (
      <>
        <LogoContainer>
          <Logo>
            <Icon icon="Icon3DEmptyFolder" size={200} />
          </Logo>
          <Title>내 문서가 비었습니다</Title>
          <Slogan>아래 업로드 버튼을 눌러</Slogan>
          <Slogan>인쇄할 파일을 업로드 해보세요</Slogan>
          <Spacing.Vertical height={57} />
        </LogoContainer>
      </>
    );
  }

  return (
    <NoSearchFile>
      <Icon icon="Icon3DNotFoundFile" size={200} />
      <Spacing.Vertical height={30} />
      <NoSearchFileText>검색 결과가 존재하지 않습니다</NoSearchFileText>
    </NoSearchFile>
  );
};

export default observer(MyFiles);

const FabContainer = styled.div`
  position: fixed;
  bottom: 80px;
  right: 25px;
  z-index: 6;
`;

const ImageListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  width: 100%;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75vh;
`;

const Logo = styled.div`
  margin: 41px 80px 32px 80px;
`;

const Title = styled.p`
  font-size: 18px;
  font-weight: 500;
  color: #1d1d1d;
  margin-bottom: 8px;
`;

const Slogan = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: #767676;
`;

const NoSearchFile = styled.div`
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const NoSearchFileText = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
`;
