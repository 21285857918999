export enum PrintJobStatus {
  Requested = 'requested',
  CheckoutCompleted = 'checkout-completed',
  ConvertPending = 'convert-pending',
  Printing = 'printing',
  Done = 'done',
  Error = 'error',
  Stopped = 'stopped',
  Expired = 'expired',
  Deleted = 'deleted',
}

/**
 * 각 상태별 있어야 하는 페이지
 * 결제시 => '/job-payment'
 * checkout-completed, convert-pending, printing => '/job-working' page
 * done => '/job-complete'
 * error => '/job-error'
 */
