import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  children: React.ReactNode;
  value: string;
  name: string;
}

const RadioButton: React.FC<Props> = ({ children, value, name, ...props }) => {
  return (
    <Label>
      <Input type="radio" value={value} name={name} {...props} />
      <RadioButtonLabel />
      {children}
    </Label>
  );
};

export default RadioButton;

const Label = styled.label`
  position: relative;
  flex: 1;
  display: flex;
  gap: 8px;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #737373;
`;

const RadioButtonLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  border: 1.5px solid #d4d4d4;
`;

const Input = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  &:hover ~ ${RadioButtonLabel} {
    background: #bebebe;
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      margin: 4px;
      background: #eeeeee;
    }
  }
  &:checked + ${RadioButtonLabel} {
    background: #4483f7;
    border: 1px solid #4483f7;
    z-index: 3;
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      margin: 4px;
      background: white;
    }
  }
`;
