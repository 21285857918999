import { GetUploadUrlResponseDto } from 'types/file/GetUploadUrlResponse.dto';
import { uuidv4 } from '@firebase/util';
import { AxiosRequestConfig } from 'axios';
import { makeAutoObservable } from 'mobx';
import { HttpRequestService } from 'services/http-request.service';
import { CreateFileDto } from 'types/file/CreateFile.dto';
import { Uploadable } from './Uploadable';
import { GetUploadUrlRequestDto } from 'types/file/GetUploadUrlRequest.dto';
import { UserStore } from '../user/UserStore';

export class UploadStore {
  isLoading: boolean = false;
  uploadables: Uploadable[] = [];

  constructor(
    private readonly userStore: UserStore,
    private readonly http: HttpRequestService
  ) {
    makeAutoObservable(this, {});
  }

  /**
   * 파일을 업로드하는 유일한 방법. FileList 를 추가하면 업로드 작업이 시작된다.
   * 이후 DB 에 record 추가까지 완료하면 isEveryCompleted 가 true 가 되어 업로드 작업이 완료되었음을 알 수 있다.
   *
   * isEveryCompleted 이후 clear() 을 통해 upload store 을 비울 수 있고, (안비워도 작동하긴 함.)
   * 새로운 파일들이 업로드 되었으므로 file store 에서 loadFiles 를 호출해줘야 한다.
   *
   * 업로드의 진행 상황은 UploadStore.uploadables[] 에서 observable 로 확인 할 수 있다.
   * @param fileList
   */
  async addFileList(fileList: FileList) {
    this.startLoading();

    const filesArr = Array.from(fileList);

    const uploadPromises = filesArr.map(async file => {
      const id = uuidv4();
      const dto: GetUploadUrlRequestDto = {
        fileName: file.name,
      };
      try {
        const response = await this.http
          .create(true)
          .post<GetUploadUrlResponseDto>(
            `users/${this.userStore.userId}/files/${id}/upload-url`,
            dto
          );
        const uploadable = new Uploadable(
          id,
          file,
          response.data.url,
          response.data.objectName
        );
        console.log('addFileList', uploadable, response.data.objectName);
        this.pushUploadable(uploadable);
        return this.upload(uploadable);
      } catch (error) {
        throw new Error('upload file error');
      }
    });

    await Promise.all(uploadPromises); // 모든 파일 업로드가 완료될 때까지 기다림

    this.endLoading();
  }

  clear() {
    this.uploadables = [];
  }

  /**
   * 업로드를 완료하고 DB 에 record 추가까지 했는지 나타냄.
   */
  get isEveryCompleted() {
    return (
      this.uploadables.every(u => !!u.isCreated) && this.uploadables.length > 0
    );
  }

  /**
   * 단지 스토리지에 업로드를 완료했는지만 파악 함.
   */
  get isEveryUploaded() {
    return (
      this.uploadables.every(u => u.isUploaded) && this.uploadables.length > 0
    );
  }

  private upload(uploadable: Uploadable) {
    console.log('upload', uploadable);

    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
        // 'Content-Disposition': `form-data; name="fieldName"; filename=${uuidv4()}${extname(uploadable.file.name)}"`
      },
      onUploadProgress: evt => uploadable.setFileTransferred(evt.loaded),
    };

    return this.http
      .create(false)
      .put(uploadable.uploadUrl, uploadable.file, config)
      .then(() => {
        uploadable.setIsUploaded();
        return this.createRecordFromUploadable(uploadable);
      });
  }

  /**
   * 생성자의 autorun 을 통해 모든 uploadable 의 업로드가 완료되면 그것을 DB 에 저장한다.
   * @param uploadable
   * @returns
   */
  private createRecordFromUploadable(uploadable: Uploadable) {
    if (!uploadable.isUploaded) {
      console.warn(
        'createRecordFromUploadable can not record not uploaded file'
      );
      return;
    }

    const dto: CreateFileDto = {
      name: uploadable.file.name,
      url: uploadable.objectName,
    };

    return this.http
      .create(true)
      .post(`users/${this.userStore.userId}/files/${uploadable.id}/create`, dto)
      .then(() => {
        uploadable.setIsCreated();
      });
  }

  private pushUploadable(uploadable: Uploadable) {
    console.log('pushUploadable', uploadable);
    this.uploadables.push(uploadable);
  }

  startLoading() {
    this.isLoading = true;
  }

  endLoading() {
    this.isLoading = false;
  }
}
