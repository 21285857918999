import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { HTTP_STATUS } from './HttpStatusCode';

export class ApiError<T = unknown> extends Error implements AxiosError<T> {
  config: AxiosRequestConfig;
  code?: string;
  request?: any;
  response?: AxiosResponse<T>;
  isAxiosError: boolean;
  toJSON: () => any;

  constructor(error: AxiosError<T>, message?: string) {
    super(message ?? error.message);

    const errorStatus = error.response?.status || 0;
    let name = 'ApiError';

    switch (errorStatus) {
      case HTTP_STATUS.BAD_REQUEST: // 400
        name = 'ApiBadRequestError';
        break;
      case HTTP_STATUS.UNAUTHORIZED: // 401
        name = 'ApiUnauthorizedError';
        break;
      case HTTP_STATUS.FORBIDDEN: // 403
        name = 'ApiForbiddenError';
        break;
      case HTTP_STATUS.NOT_FOUND: // 404
        name = 'ApiNotFoundError';
        break;
      case HTTP_STATUS.INTERNAL_SERVER_ERROR: // 500
        name = 'ApiInternalServerError';
        break;
    }

    this.name = name;
    this.stack = error.stack;
    this.config = error.config;
    this.code = error.code;
    this.request = error.request;
    this.response = error.response;
    this.isAxiosError = error.isAxiosError;
    this.toJSON = error.toJSON;
  }
}
