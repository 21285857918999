import { ampEvents } from 'amplitude-events';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import SolidButton from 'components/common/solid_button/SolidButton';
import trackUser from 'services/trackUser';

interface Props {}

const ButtonToMoveLogin: React.FC<Props> = ({
  children,
}: PropsWithChildren) => {
  const navigation = useNavigate();

  return (
    <SolidButton
      onClick={() => {
        trackUser(ampEvents.Boba_LoginOrSignUp);
        navigation('/login');
      }}
    >
      {children}
    </SolidButton>
  );
};

export default ButtonToMoveLogin;
