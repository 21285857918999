import React from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import { useNavigate } from 'react-router-dom';
import PageContainer from 'components/common/PageContainer';
import SolidButton from 'components/common/solid_button/SolidButton';
import TopBar from 'components/common/top_bar/TopBar';
import TopInfo from 'components/common/top_info/TopInfo';
import FixedButtons from 'components/common/solid_button/FixedButtons';
import { useStores } from 'stores/RootStore';
import { observer } from 'mobx-react-lite';

const PointCharge: React.FC = props => {
  const navigation = useNavigate();
  const { userStore } = useStores();

  return (
    <PageContainer>
      <TopBar title="포인트 충전" />
      <TopInfo
        text="잔여 포인트"
        right="title"
        rightLabel={
          userStore.user ? `${userStore.user?.points.toString()} P` : '0 P'
        }
      />
      <FixedButtons>
        <SolidButton onClick={() => navigation('/coupon')}>
          쿠폰 등록하기
        </SolidButton>
      </FixedButtons>
    </PageContainer>
  );
};

export default observer(PointCharge);
