import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import PageContainer from 'components/common/PageContainer';
import SolidButton from 'components/common/solid_button/SolidButton';
import TopBar from 'components/common/top_bar/TopBar';
import InputBox from 'components/common/input_box/InputBox';
import Icon from 'components/common/icon/Icon';
import { PHONE_REGEX, REG_NUMBER } from 'constants/Constants';
import { Spacing } from 'styles/styles';
import { useStores } from 'stores/RootStore';
import Modal from 'components/common/modal/Modal';
import { observer } from 'mobx-react-lite';
import FullScreenLoader from 'components/common/FullScreenLoader';
import * as Sentry from '@sentry/react';
import { ApiError } from 'types/error/ApiError';
import FixedButtons from 'components/common/solid_button/FixedButtons';

const ChangePhoneNumber2: React.FC = props => {
  const navigation = useNavigate();

  const { userStore, authStore } = useStores();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [code, setCode] = useState('');
  const [sentCode, setSentCode] = useState(false);
  const [reSentCode, setReSentCode] = useState(false);
  const [phoneInvalid, setPhoneInvalid] = useState(false);
  const [codeInvalid, setCodeInvalid] = useState(false);
  const [codeVerify, setCodeVerify] = useState(false);

  const [successChangePhone, setSuccessChangePhone] = useState(false);
  const [failureChangePhone, setFailureChangePhone] = useState(false);
  const [invalidPhoneModal, setInvalidPhoneModal] = useState(false);

  const [time, setTime] = useState(180);

  const handleHistoryBack = () => {
    navigation(-1);
  };

  /**
   * 인증번호 전송 요청
   */
  const handleSendCode = () => {
    // 해당 계정과 입력한 휴대폰 번호가 일치하는지 확인
    authStore
      .isExist(
        `+82${phoneNumber.length === 11 ? phoneNumber.slice(1) : phoneNumber}`
      )
      .then(res => {
        if (res.data.exists) {
          setInvalidPhoneModal(true);
        } else {
          setSentCode(true);
          setReSentCode(false);
          setCodeVerify(false);
          setCode('');
          authStore.sendPhoneAuthMessage(
            `+82${
              phoneNumber.length === 11 ? phoneNumber.slice(1) : phoneNumber
            }`
          );
        }
      });
  };

  /**
   * 인증번호 유효 확인
   */
  const handleCodeVerity = () => {
    authStore
      .verifyPhone(code)
      .then(() => {
        if (userStore.user) {
          authStore
            .updateUser(
              undefined,
              undefined,
              `+82${
                phoneNumber.length === 11 ? phoneNumber.slice(1) : phoneNumber
              }`,
              undefined,
              undefined
            )
            ?.then(() => {
              setSuccessChangePhone(true);
              userStore.loadUser();
            })
            .catch(err => {
              setFailureChangePhone(true);
              Sentry.captureException(new ApiError(err, 'updateUserError'));
            });
        }
      })
      .catch(() => {
        setCodeVerify(true);
        setSentCode(false);
        setReSentCode(true);
      });
  };

  // 인증시간 초과 시
  useEffect(() => {
    if (time < 1) {
      setSentCode(false);
      setReSentCode(true);
    }
  }, [time]);

  // 인증시간 초과 후 time 재설정
  useEffect(() => {
    if (!sentCode) return;
    const countdown = setInterval(() => {
      setTime(t => t - 1);
    }, 1000);
    // eslint-disable-next-line consistent-return
    setTime(180);
    return () => clearInterval(countdown);
  }, [sentCode]);

  // phone number 형식 확인
  useEffect(() => {
    setPhoneInvalid(phoneNumber.match(PHONE_REGEX) === null);
  }, [phoneNumber]);

  // code number 형식 확인
  useEffect(() => {
    setCodeInvalid(code.match(/^[0-9]{5}$/) === null);
  }, [code]);

  useEffect(() => {
    setPhoneInvalid(false);
    setCodeInvalid(false);
  }, []);

  return (
    <PageContainer>
      {authStore.isLoading && <FullScreenLoader />}
      <TopBar title="휴대폰 인증" />
      <InputBox
        type="text"
        inputMode="numeric"
        pattern="[0-9]*"
        label="휴대폰 번호"
        placeholder="01000000000"
        action={phoneInvalid ? 'error' : 'default'}
        helpText={phoneInvalid ? '유효하지 않은 휴대폰 번호입니다' : ''}
        onChange={e => {
          setPhoneNumber(e.target.value.replace(REG_NUMBER, ''));
        }}
        onKeyPress={e => {
          if (e.key === 'Enter' && !phoneInvalid && phoneNumber !== '') {
            handleSendCode();
          }
        }}
      />

      {(sentCode || reSentCode) && (
        <InputConatiner>
          <InputBox
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            autoComplete="one-time-code"
            disabled={reSentCode}
            value={code}
            label="인증번호"
            placeholder="00000"
            action={codeInvalid || reSentCode ? 'error' : 'default'}
            helpText={
              codeVerify
                ? '인증번호가 일치하지 않습니다'
                : reSentCode
                ? '입력시간이 초과되었으니 다시 시도해주세요'
                : '문자로 받은 인증번호 5자리를 입력해주세요'
            }
            onChange={e => {
              setCode(e.target.value);
            }}
            onKeyPress={e => {
              if (e.key === 'Enter' && !codeInvalid && code !== '') {
                handleCodeVerity();
              }
            }}
          />
          <RemainingTime>
            <Icon icon="IconClock" size={16} />
            <Spacing.Horizontal width={4} />
            {`${Math.floor(time / 60).toLocaleString(undefined, {
              minimumIntegerDigits: 2,
            })}:${
              (time % 60).toLocaleString(undefined, {
                minimumIntegerDigits: 2,
              }) || '00'
            }`}
          </RemainingTime>
        </InputConatiner>
      )}

      {/* button */}
      <FixedButtons>
        {!sentCode ? (
          <SolidButton
            disabled={phoneInvalid || phoneNumber === ''}
            onClick={handleSendCode}
          >
            {reSentCode ? '인증번호 재전송' : '인증번호 전송'}
          </SolidButton>
        ) : (
          <SolidButton
            disabled={codeInvalid || code === ''}
            onClick={handleCodeVerity}
          >
            휴대폰 번호 변경하기
          </SolidButton>
        )}
      </FixedButtons>

      {/* Modal - Error */}
      <Modal
        isOpen={invalidPhoneModal}
        onRequestClose={() => {
          setInvalidPhoneModal(false);
        }}
        description={'현재 사용중인 번호입니다'}
        mainActionLabel="확인"
        onMainAction={() => {
          setInvalidPhoneModal(false);
        }}
      />

      {/* Modal - Error */}
      <Modal
        isOpen={failureChangePhone}
        onRequestClose={handleHistoryBack}
        description={'휴대폰 번호 변경에 실패했습니다'}
        mainActionLabel="확인"
        onMainAction={handleHistoryBack}
      />

      {/* modal */}
      <Modal
        isOpen={successChangePhone}
        onRequestClose={handleHistoryBack}
        description="휴대폰 번호가 변경되었습니다"
        mainActionLabel="확인"
        onMainAction={handleHistoryBack}
      />
    </PageContainer>
  );
};
export default observer(ChangePhoneNumber2);

const InputConatiner = styled.div`
  position: relative;
`;

const RemainingTime = styled.p`
  position: absolute;
  top: 4px;
  right: 0;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #737373;
`;
