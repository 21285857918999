import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import PageContainer from 'components/common/PageContainer';
import TopBar from 'components/common/top_bar/TopBar';
import ReservationTicket from 'components/pages/waiting-list/ReservationTicket';
import { useStores } from 'stores/RootStore';
import useHistoryBack from 'hooks/useHistoryBack';

export default observer(function WaitingList() {
  const navigation = useNavigate();
  const {
    stateStore,
    printJobStore,
    scanCopyJobStore,
    updateJobStore,
    jobPollingStore,
  } = useStores();

  async function getAllJobs() {
    stateStore.startLoading();
    await updateJobStore.getAllJobs();
    stateStore.endLoading();

    if (updateJobStore.jobLength === 0) {
      navigation('/print', { replace: true });
    }
  }

  useEffect(() => {
    getAllJobs();
    jobPollingStore.clearStore();
  }, []);

  useHistoryBack(() => {
    navigation('/print', { replace: true });
  });

  return (
    <PageContainer>
      <TopBar title="작업 대기 목록" />
      {/* prints */}
      {printJobStore.groupByKiosk.map(printJob => (
        <ReservationTicket
          key={printJob[0].kiosk.id}
          serviceName="인쇄"
          kioskId={printJob[0].kiosk.id}
          kioskName={printJob[0].kiosk.name}
          kioskDescription={printJob[0].kiosk.description}
          expireAt={printJob[0].modifiedAt}
        />
      ))}

      {/* scan */}
      {scanCopyJobStore.getScanJobsGroupByKiosk().map(scanJob => (
        <ReservationTicket
          key={scanJob[0].kiosk.id}
          serviceName="스캔"
          kioskId={scanJob[0].kiosk.id}
          kioskName={scanJob[0].kiosk.name}
          kioskDescription={scanJob[0].kiosk.description}
          expireAt={scanJob[0].modifiedAt}
        />
      ))}
      {/* copy */}
      {scanCopyJobStore.getCopyJobsGroupByKiosk().map(copyJob => (
        <ReservationTicket
          key={copyJob[0].kiosk.id}
          serviceName="복사"
          kioskId={copyJob[0].kiosk.id}
          kioskName={copyJob[0].kiosk.name}
          kioskDescription={copyJob[0].kiosk.description}
          expireAt={copyJob[0].modifiedAt}
        />
      ))}
    </PageContainer>
  );
});
