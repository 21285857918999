import { ALLOWED_FILE_EXTENSIONS } from 'constants/Constants';
import { MODAL_PRINT_WIZARD } from 'constants/modalMessage';

export default function ({
  storageUsed,
  storageAllocated,
}: {
  storageUsed?: number;
  storageAllocated?: number;
}) {
  // 저장용량 확인
  function checkStorageCapacity(fileList: FileList): boolean {
    if (storageUsed == null || storageAllocated == null) {
      return false;
    }
    const uploadSize: number = Array.from(fileList).reduce(
      (acc, cur) => acc + cur.size,
      0
    );
    const expectedSize: number = storageUsed + uploadSize;
    if (expectedSize > storageAllocated) {
      return false;
    }
    return true;
  }

  // 파일 확장자 확인
  function checkFileExtensions(fileList: FileList): boolean {
    const files: File[] = Array.from(fileList);
    const extensionList = files.map(file => {
      return file.name.split('.').pop()?.toLowerCase();
    });
    // 하나라도 불가능한 확장자명이면 업로드 막음
    const checkFileExt: boolean = extensionList.every(ext =>
      ALLOWED_FILE_EXTENSIONS.includes(ext ?? '')
    );
    if (!checkFileExt) {
      return false;
    }
    return true;
  }

  const checkUploadPossible = (
    files: FileList,
    onSuccess: (files: FileList) => void,
    onError: (error: string) => void
  ) => {
    const storageCapacityAvailable = checkStorageCapacity(files);

    if (!storageCapacityAvailable) {
      onError(MODAL_PRINT_WIZARD.ERROR_INSUFFICIENT_STORAGE_SPACE);
      return;
    }

    const fileExtensionsAvailable = checkFileExtensions(files);
    if (!fileExtensionsAvailable) {
      onError(MODAL_PRINT_WIZARD.ERROR_UNSUPPORTED);
      return;
    }

    onSuccess(files);
  };

  return {
    checkUploadPossible,
  };
}
