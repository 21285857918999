import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores/RootStore';
import { ampEvents } from 'amplitude-events';
import trackUser from 'services/trackUser';
import { kioskService } from 'services/kiosk.service';
import PageContainer from 'components/common/PageContainer';
import TopBar from 'components/common/top_bar/TopBar';
import SolidButton from 'components/common/solid_button/SolidButton';
import FullScreenLoader from 'components/common/FullScreenLoader';
import List from 'components/common/list/List';
import FixedButtons from 'components/common/solid_button/FixedButtons';
import Icon from 'components/common/icon/Icon';
import PrintWizardProgressBar from 'components/pages/PrintWizardProgressBar';
import KioskMap from 'components/pages/KioskMap';
import { calcDistance, sortKiosksByDistance } from 'utils/utils';
import { PrintZoneLineDto } from 'types/kiosk/PrintZoneLine.dto';
import useDebounce from 'hooks/useDebounce';
import Border from 'components/common/border/Border';
import ListHeader from 'components/common/list_header/ListHeader';
import * as Sentry from '@sentry/react';
import { Spacing } from 'styles/styles';
import useHistoryBack from 'hooks/useHistoryBack';

const PrintWizard2: React.FC = props => {
  const navigation = useNavigate();
  const {
    stateStore,
    userStore,
    fileStore,
    kioskStore,
    printJobBuilderStore,
    uploadStore,
    updateJobStore,
  } = useStores();

  const [findText, setFindText] = useState<string>('');
  const debouncedSearchText = useDebounce<string>(findText, 200);

  const [mapMode, setMapMode] = useState<boolean>(false);
  const [kiosks, setKiosks] = useState<PrintZoneLineDto[]>([]);
  const [kioskId, setKioskId] = useState<string | null>(null);
  const [latestKiosks, setLatestKiosks] = useState<PrintZoneLineDto[]>([]);

  const handleBack = () => {
    if (mapMode) {
      return setMapMode(false);
    }
    navigation(-1);
  };

  useHistoryBack(() => {
    // printWizard2 페이지 벗어날 시, printjobbuilder 비우기
    fileStore.setSelectMode(false);
    printJobBuilderStore.clear();
  });

  /**
   * mapMode진입시 검색창 clear
   */
  useEffect(() => {
    if (mapMode) setFindText('');
  }, [mapMode]);

  /**
   * kiosk line dto 불러오기
   */
  useEffect(() => {
    kioskService
      .getKiosks(true, true, false, false, debouncedSearchText)
      .then(res => {
        setKiosks(res);
      })
      .catch(() => {
        // TODO: What about Sentry error report?
        stateStore.setError(true);
      });
    uploadStore.clear();
  }, [debouncedSearchText]);

  useEffect(() => {
    if (!updateJobStore.builderService) return;
    kioskService
      .getRecentlyUsedKiosks(updateJobStore.builderService)
      .then(({ data }) => {
        // print 지원하는 개소인지 확인
        const pj = data.filter(
          pj =>
            pj.services.filter(sv => sv.serviceNameEn === 'Print').length >= 1
        );

        setLatestKiosks(pj);
      })
      .catch((err: any) => {
        Sentry.captureException(err);
        stateStore.setError(true);
      });
  }, [updateJobStore.builderService]);

  useEffect(() => {
    kioskStore.getKiosks();
    updateJobStore.setBuilderService('prints');
    updateJobStore.setBuilderKioskId(null);
  }, []);

  return (
    <PageContainer>
      {kioskStore.isLoading && <FullScreenLoader message="로딩중" />}
      <TopBar
        left="back"
        onLeftClick={handleBack}
        title="출력할 프린터를 선택하세요"
        right={!mapMode ? 'map' : 'none'}
        onRightClick={() => {
          trackUser(ampEvents.PrintWizard2_Map);
          setMapMode(true);
        }}
      />
      <PrintWizardProgressBar step={2} />

      {/* searching + input */}
      <SearchContainer>
        <InputContainer>
          <Input
            value={findText}
            placeholder="프린터 검색"
            onChange={e => setFindText(e.target.value)}
            onClick={() => {
              trackUser(ampEvents.PrintWizard2_SearchBox);
              setMapMode(false);
            }}
          />
          <Icon icon="IconSearch" size={20} />
        </InputContainer>
      </SearchContainer>

      {/* kiosk map mode */}
      {mapMode && <KioskMap />}

      {/* kiosk list mode */}
      {!mapMode && (
        <>
          {/* 최근 사용 프린터 */}
          {!debouncedSearchText && latestKiosks.length > 0 && (
            <>
              <ListHeader title="최근 사용 프린터" />
              <List
                key={latestKiosks[0].id}
                onLeftClick={() => {
                  if (latestKiosks[0].status === 'MAINTENANCE') {
                    return;
                  }
                  setKioskId(latestKiosks[0].id);
                }}
                active={latestKiosks[0].id === kioskId}
                disabled={latestKiosks[0].status === 'MAINTENANCE'}
                title={latestKiosks[0].companyName}
                description={`${calcDistance(
                  userStore.currentLocation?.lat,
                  userStore.currentLocation?.lng,
                  latestKiosks[0].latitude,
                  latestKiosks[0].longitude
                )} ${latestKiosks[0].address}`}
                listBottom="prices"
                status={latestKiosks[0].status}
                priceMono={latestKiosks[0].priceA4Mono}
                priceColor={latestKiosks[0].priceA4Color}
                action="info"
                onActionClick={() => {
                  navigation(`/printzone/${latestKiosks[0].id}`);
                }}
              />
              <Border size={16} />
            </>
          )}
          {sortKiosksByDistance(kiosks).map(kiosk => (
            <List
              key={kiosk.id}
              onLeftClick={() => {
                if (kiosk.status === 'MAINTENANCE') {
                  return;
                }
                setKioskId(kiosk.id);
              }}
              active={kiosk.id === kioskId}
              disabled={kiosk.status === 'MAINTENANCE'}
              badgeKind="blue"
              title={kiosk.companyName}
              description={`${calcDistance(
                userStore.currentLocation?.lat,
                userStore.currentLocation?.lng,
                kiosk.latitude,
                kiosk.longitude
              )} ${kiosk.address}`}
              listBottom="prices"
              status={kiosk.status}
              priceMono={kiosk.priceA4Mono}
              priceColor={kiosk.priceA4Color}
              action="info"
              onActionClick={() => {
                trackUser(ampEvents.PrintWizard2_PrinterDetail);
                navigation(`/printzone/${kiosk.id}`);
              }}
            />
          ))}
          {debouncedSearchText && kiosks.length === 0 && (
            <NoSearchFile>
              <Icon icon="Icon3DNotFoundFile" size={200} />
              <Spacing.Vertical height={30} />
              <NoSearchFileText>검색 결과가 존재하지 않습니다</NoSearchFileText>
            </NoSearchFile>
          )}

          <FixedButtons>
            <SolidButton
              disabled={!kioskId}
              onClick={() => {
                if (!kioskId) return;
                trackUser(ampEvents.PrintWizard2_Next);
                updateJobStore.setBuilderKioskId(kioskId);
                navigation('/printwizard3/0');
              }}
            >
              다음단계
            </SolidButton>
          </FixedButtons>
        </>
      )}
    </PageContainer>
  );
};

export default observer(PrintWizard2);

const SearchContainer = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  padding: 16px 0;
  background: ${({ theme }) => theme.color.white};
`;
const InputContainer = styled.div`
  padding: 3px 16px 3px 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  background: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.gray200};
  border-radius: 10px;
`;
const Input = styled.input`
  z-index: 3;
  background: transparent;
  padding: 12px 16px;
  width: 100%;
  height: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.color.gray400};
  }
  outline: none;
  border-width: 0;
`;

const NoSearchFile = styled.div`
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const NoSearchFileText = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
`;
