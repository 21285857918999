import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Spacing } from 'styles/styles';
import { ImgOptionColor, ImgOptionMono } from 'assets/images';
import { getPrintCopyColorSet } from 'utils/utils';
import { Service } from 'types/kiosk/Service';

type ColorSet = 'both' | 'mono' | 'color' | 'none';

export default function PrintZoneColorBadge({
  services,
}: {
  services: Service[];
}) {
  const [colorSet, setColorSet] = useState<ColorSet>('none');

  useEffect(() => {
    setColorSet(getPrintCopyColorSet(services));
  }, [services]);

  return (
    <SubTitleContainer>
      {(colorSet === 'color' || colorSet === 'both') && (
        <>
          <ColorOption src={ImgOptionColor} />
          <Spacing.Horizontal width={8} />
        </>
      )}
      {(colorSet === 'mono' || colorSet === 'both') && (
        <>
          <ColorOption src={ImgOptionMono} />
          <Spacing.Horizontal width={8} />
        </>
      )}
      {colorSet === 'both' && <SubTitle>컬러 흑백 출력 가능</SubTitle>}
      {colorSet === 'mono' && <SubTitle>흑백 출력 가능</SubTitle>}
      {colorSet === 'color' && <SubTitle>컬러 출력 가능</SubTitle>}
    </SubTitleContainer>
  );
}

const SubTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SubTitle = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.gray600};
`;

const ColorOption = styled.img`
  width: 20px;
  height: 20px;

  background-position: center;
`;
