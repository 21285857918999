import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { useStores } from 'stores/RootStore';
import { Kiosk } from 'stores/kiosk/Kiosk';
import { PrintJobBuilder } from 'stores/job/PrintJobBuilder';
import { PaperSize } from 'types/job/print-job/print-ticket/PaperSize';
import OptionButton from './OptionButton';

type Props = {
  fileId: string;
};

function PaperSizeOption({ fileId }: Props) {
  const { printJobBuilderStore, kioskStore } = useStores();
  const [option, setOption] = useState<PaperSize>(PaperSize.A4);

  const printJob: PrintJobBuilder | undefined =
    printJobBuilderStore.getPrintJobByFileId(fileId);

  const selectedKiosk: Kiosk | undefined = kioskStore.kiosks.find(
    kiosk => kiosk.id === printJob?.kioskId
  );
  const kioskPaperSizes: PaperSize[] = selectedKiosk?.PaperSizes ?? [
    PaperSize.A4,
  ];

  const handleOption = (option: PaperSize) => {
    setOption(option);

    const printOption = printJob?.ticket;
    if (!printOption) {
      return;
    }
    printJobBuilderStore.setPrintJobTicket(fileId, {
      ...printOption,
      paperSize: option,
    });
  };

  useEffect(() => {
    const ticketPaperSize = printJob?.ticket?.paperSize;
    if (!ticketPaperSize) {
      return handleOption(PaperSize.A4);
    }
    if (kioskPaperSizes.includes(ticketPaperSize)) {
      return setOption(printJob?.ticket?.paperSize ?? PaperSize.A4);
    }
    return handleOption(PaperSize.A4);
  }, [fileId]);

  return (
    <Container>
      {kioskPaperSizes.includes(PaperSize.A4) && (
        <OptionButton
          onClick={() => handleOption(PaperSize.A4)}
          active={option === PaperSize.A4}
        >
          A4
        </OptionButton>
      )}

      {kioskPaperSizes.includes(PaperSize.A3) && (
        <OptionButton
          onClick={() => handleOption(PaperSize.A3)}
          active={option === PaperSize.A3}
        >
          A3
        </OptionButton>
      )}

      {kioskPaperSizes.includes(PaperSize.B4) && (
        <OptionButton
          onClick={() => handleOption(PaperSize.B4)}
          active={option === PaperSize.B4}
        >
          B4
        </OptionButton>
      )}
    </Container>
  );
}

export default observer(PaperSizeOption);

const Container = styled.div`
  display: flex;
  gap: 16px;

  padding: 16px 0;
`;
