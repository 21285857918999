import { makeAutoObservable } from 'mobx';
import { NotFoundError } from 'errors';
import { PrintTicket } from 'types/job/print-job/print-ticket/PrintTicket';
import { PrintJobDto } from 'types/job/print-job/PrintJob.dto';
import { JobKiosk } from 'types/job/JobKiosk';
import { UpdatePrintJobDto } from 'types/print-job/UpdatePrintJob.dto';
import { FileDocument } from '../file/File';
import { PrintJobStore } from './PrintJobStore';
import { PrintJobStatus } from 'types/job/print-job/PrintJobStatus';
import { StatusCode } from 'types/job/StatusCode';

export class PrintJob {
  isLoading = false;

  id: string;
  createdAt: Date;
  modifiedAt: Date;
  expireAt: Date;
  userId: string;
  price: number | null;
  status: PrintJobStatus;
  statusCode: StatusCode | null;
  statusMessage: string | null;
  numPrintPages: number | null;
  file: FileDocument;
  kiosk: JobKiosk;
  ticket: PrintTicket;

  constructor(private readonly store: PrintJobStore, dto: PrintJobDto) {
    makeAutoObservable(this, {});

    this.id = dto.id;
    this.createdAt = dto.createdAt;
    this.modifiedAt = dto.modifiedAt;
    this.expireAt = dto.expireAt;
    this.userId = dto.userId;
    this.price = dto.price;
    this.status = dto.status;
    this.statusCode = dto.statusCode;
    this.statusMessage = dto.statusMessage;
    this.numPrintPages = dto.numPrintPages;

    this.kiosk = dto.kiosk;
    const file = this.store.fileStore.findOneWithId(dto.file.id);
    if (!file) {
      throw new NotFoundError(
        `Cannot find file in fileStore / id: ${dto.file.id}`
      );
    }
    this.file = file;
    this.ticket = dto.ticket;
  }

  // ticket 업데이트
  async updateTicket(ticket: PrintTicket) {
    const dto: UpdatePrintJobDto = {
      layout: {
        order: ticket.layout.order,
        nUp: ticket.layout.nUp,
      },
      copies: ticket.copies,
      duplex: ticket.duplex,
      fitToPage: ticket.fitToPage,
      isColor: ticket.isColor,
      pageRanges: ticket.pageRanges,
      paperOrientation: ticket.paperOrientation,
      paperSize: ticket.paperSize,
    };

    return await this.store.http
      .create(true)
      .patch(`users/${this.store.userStore.userId}/jobs/prints/${this.id}`, dto)
      .then(() => this.sync());
  }

  async sync() {
    return await this.store.http
      .create(true)
      .get<PrintJobDto>(
        `users/${this.store.userStore.userId}/jobs/prints/${this.id}`
      )
      .then(response => this.updatePrintJobFromDto(response.data))
      .catch(err => {
        console.warn('PrintJob sync failed', this, err);
        throw err;
      });
  }

  editTicket(ticket: PrintTicket) {
    this.ticket = ticket;
  }

  updatePrintJobFromDto(dto: PrintJobDto) {
    this.id = dto.id;
    this.createdAt = dto.createdAt;
    this.modifiedAt = dto.modifiedAt;
    this.expireAt = dto.expireAt;
    this.userId = dto.userId;
    this.price = dto.price;
    this.status = dto.status;
    this.statusCode = dto.statusCode;
    this.statusMessage = dto.statusMessage;
    this.numPrintPages = dto.numPrintPages;

    this.kiosk = dto.kiosk;
    const file = this.store.fileStore.findOneWithId(dto.file.id);
    if (!file) {
      throw new NotFoundError(
        `Cannot find file in fileStore / id: ${dto.file.id}`
      );
    }
    this.file = file;
    this.ticket = dto.ticket;
  }
}
