import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Spacing } from 'styles/styles';
import { PHONE_REGEX } from 'constants/Constants';
import InputBox from '../common/input_box/InputBox';
import SolidButton from '../common/solid_button/SolidButton';
import Icon from 'components/common/icon/Icon';
import FixedButtons from 'components/common/solid_button/FixedButtons';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { ApiError } from 'types/error/ApiError';
import Modal from '../common/modal/Modal';
import { useStores } from 'stores/RootStore';
import FullScreenLoader from '../common/FullScreenLoader';
import { ampEvents } from 'amplitude-events';
import trackUser from 'services/trackUser';

const FindEmail: React.FC = props => {
  const navigation = useNavigate();
  const { authStore } = useStores();
  const regNumber = /[^0-9]/g;

  const [successFindEmail, setSuccessFindEmail] = useState(false);
  const [notExistEmailModal, setNotExistEmailModal] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState('');
  const [code, setCode] = useState('');
  const [sentCode, setSentCode] = useState(false);
  const [reSentCode, setReSentCode] = useState(false);
  const [phoneInvalid, setPhoneInvalid] = useState(false);
  const [codeInvalid, setCodeInvalid] = useState(false);
  const [codeVerify, setCodeVerify] = useState(false);

  const [time, setTime] = useState(180);

  /**
   * 인증번호 전송 요청
   */
  const handleSendCode = async () => {
    setSentCode(true);
    setReSentCode(false);
    setCodeVerify(false);
    setCode('');
    authStore.sendPhoneAuthMessage(
      `+82${phoneNumber.length === 11 ? phoneNumber.slice(1) : phoneNumber}`
    );
  };

  /**
   * 인증번호 유효 확인
   */
  const handleCodeVerify = () => {
    authStore
      .verifyPhone(code)
      .then(() => {
        authStore
          .findEmail(
            `+82${
              phoneNumber.length === 11 ? phoneNumber.slice(1) : phoneNumber
            }`
          )
          .then(() => {
            trackUser(
              ampEvents.FindEmailPassword_SendVerification_VerifySuccess
            );
            setSuccessFindEmail(true);
          })
          .catch(err => {
            Sentry.captureException(new ApiError(err, 'findEmailError'));
            setNotExistEmailModal(true);
          });
      })
      .catch(err => {
        Sentry.captureException(new ApiError(err, 'validPhoneError'));
        setCodeVerify(true);
        setSentCode(false);
        setReSentCode(true);
      })
      .finally(() => {
        authStore.clear();
      });
  };

  // 인증시간 초과 시
  useEffect(() => {
    if (time < 1) {
      setSentCode(false);
      setReSentCode(true);
    }
  }, [time]);

  // 인증시간 초과 후 time 재설정
  useEffect(() => {
    if (!sentCode) return;
    const countdown = setInterval(() => {
      setTime(t => t - 1);
    }, 1000);
    // eslint-disable-next-line consistent-return
    setTime(180);
    return () => clearInterval(countdown);
  }, [sentCode]);

  // phone number 형식 확인
  useEffect(() => {
    setPhoneInvalid(phoneNumber.match(PHONE_REGEX) === null);
  }, [phoneNumber]);

  // code number 형식 확인
  useEffect(() => {
    setCodeInvalid(code.match(/^[0-9]{5}$/) === null);
  }, [code]);

  useEffect(() => {
    setPhoneInvalid(false);
    setCodeInvalid(false);
  }, []);

  return !successFindEmail ? (
    <>
      {authStore.isLoading && <FullScreenLoader />}
      {/* Modal - 가입 정보 없음 */}
      <Modal
        isOpen={notExistEmailModal}
        onRequestClose={() => {
          setNotExistEmailModal(false);
          navigation('/login', { replace: true });
        }}
        title="가입정보 없음"
        description="해당 휴대폰 번호로 가입된 정보가 없습니다"
        mainActionLabel="확인"
        onMainAction={() => {
          setNotExistEmailModal(false);
          navigation('/login', { replace: true });
        }}
      />
      <InputBox
        label="휴대폰 번호"
        type="text"
        inputMode="numeric"
        pattern="[0-9]*"
        placeholder="010-0000-0000"
        action={phoneInvalid ? 'error' : 'default'}
        helpText={phoneInvalid ? '유효하지 않은 휴대폰 번호입니다' : ''}
        onChange={e => {
          setPhoneNumber(e.target.value.replace(regNumber, ''));
        }}
        onKeyPress={e => {
          if (e.key === 'Enter' && !phoneInvalid && phoneNumber !== '') {
            handleSendCode();
          }
        }}
      />

      {(sentCode || reSentCode) && (
        <InputConatiner>
          <InputBox
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            autoComplete="one-time-code"
            disabled={reSentCode}
            value={code}
            label="인증번호"
            placeholder="00000"
            action={codeInvalid || reSentCode ? 'error' : 'default'}
            helpText={
              codeVerify
                ? '인증번호가 일치하지 않습니다'
                : reSentCode
                ? '입력시간이 초과되었으니 다시 시도해주세요'
                : '문자로 받은 인증번호 5자리를 입력해주세요'
            }
            onChange={e => {
              setCode(e.target.value);
            }}
            onKeyPress={e => {
              if (e.key === 'Enter' && !codeInvalid && code !== '') {
                handleCodeVerify();
              }
            }}
          />
          <RemainingTime>
            <Icon icon="IconClock" size={16} />
            <Spacing.Horizontal width={4} />
            {`${Math.floor(time / 60).toLocaleString(undefined, {
              minimumIntegerDigits: 2,
            })}:${
              (time % 60).toLocaleString(undefined, {
                minimumIntegerDigits: 2,
              }) || '00'
            }`}
          </RemainingTime>
        </InputConatiner>
      )}

      {/* button */}
      <FixedButtons>
        {!sentCode ? (
          <SolidButton
            disabled={phoneInvalid || phoneNumber === ''}
            onClick={() => {
              trackUser(ampEvents.FindEmailPassword_SendVerification_Submit);
              handleSendCode();
            }}
          >
            {reSentCode ? '인증번호 재전송' : '인증번호 전송'}
          </SolidButton>
        ) : (
          <SolidButton
            disabled={codeInvalid || code === ''}
            onClick={handleCodeVerify}
          >
            인증하기
          </SolidButton>
        )}
      </FixedButtons>
    </>
  ) : (
    // 이메일 인증 성공시 화면
    <>
      <MailContainer>
        <Mail>
          <Icon icon="Icon3DMail" size={200} />
        </Mail>
        <Slogan>고객님의 가입하신 이메일 주소는</Slogan>
        <Slogan>
          <Email>{authStore.foundEmail}</Email> 입니다
        </Slogan>
        <Spacing.Vertical height={57} />
      </MailContainer>

      <FixedButtons>
        <SolidButton onClick={() => navigation('/login')}>
          로그인하기
        </SolidButton>
      </FixedButtons>
    </>
  );
};
export default FindEmail;

const InputConatiner = styled.div`
  position: relative;
`;

const RemainingTime = styled.p`
  position: absolute;
  top: 4px;
  right: 0;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #737373;
`;

const MailContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75vh;
`;

const Mail = styled.div`
  margin: 41px 80px 32px 80px;
`;

const Slogan = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #767676;
`;

const Email = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: black;
`;
