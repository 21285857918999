import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import List from 'components/common/list/List';
import PageContainer from 'components/common/PageContainer';
import TopBar from 'components/common/top_bar/TopBar';
import { NoticeLineDto } from 'types/notice/NoticeLine.dto';
import { getRelativeTimestampString } from 'utils/time.util';
import { httpRequestService } from 'services/http-request.service';
import { useStores } from 'stores/RootStore';
import { observer } from 'mobx-react-lite';

const Notices: React.FC = props => {
  const navigation = useNavigate();
  const { userStore } = useStores();

  const [notices, setNotices] = useState<NoticeLineDto[]>();

  useEffect(() => {
    httpRequestService
      .create()
      .get<NoticeLineDto[]>('notices')
      .then(res => {
        setNotices(res.data);
      })
      .catch(() => {
        userStore.showErrorModal();
      });
  }, []);

  return (
    <PageContainer>
      <TopBar left="back" title="공지사항" />
      {notices
        ?.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )
        .map(notice => (
          <List
            key={notice.id}
            onAllClick={() => {
              navigation(`/notices/${notice.id}`);
            }}
            title={notice.title}
            description={getRelativeTimestampString(
              notice.createdAt.toString()
            )}
          />
        ))}
    </PageContainer>
  );
};

export default observer(Notices);
