import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Border from 'components/common/border/Border';
import ContextMenu from 'components/common/context_menu/ContextMenu';
import List from 'components/common/list/List';
import ListFooter from 'components/common/list_footer/ListFooter';
import PageContainer from 'components/common/PageContainer';
import SolidButton from 'components/common/solid_button/SolidButton';
import TopBar from 'components/common/top_bar/TopBar';
import TopInfo from 'components/common/top_info/TopInfo';
import { useStores } from 'stores/RootStore';
import { Spacing } from 'styles/styles';
import { observer } from 'mobx-react-lite';
import { CardDto } from 'types/card/Card.dto';
import FullScreenLoader from 'components/common/FullScreenLoader';
import Icon from 'components/common/icon/Icon';
import Modal from 'components/common/modal/Modal';
import { ampEvents } from 'amplitude-events';
import trackUser from 'services/trackUser';
import FixedButtons from 'components/common/solid_button/FixedButtons';

const ContentPurchase: React.FC = props => {
  const navigation = useNavigate();
  const { id } = useParams();
  const { userStore, cardStore, contentStore, fileStore } = useStores();
  const [paymentCard, setPaymentCard] = useState<CardDto>();
  const [openContextMenu, setOpenContextMenu] = useState(false);

  const [paymentFailureModal, setPaymentFailureModal] = useState(false);

  /**
   * 결제 시도
   * 성공시 구매완료 페이지로 이동
   * 실패시 모달 등장 (결제 실패 모달)
   */
  const handlePurchase = () => {
    if (id && paymentCard?.id) {
      contentStore
        .purchaseContent(id, paymentCard.id)
        .then(res => {
          if (res.data.isSuccess) {
            trackUser(ampEvents.ContentDetail_PurchaseSuccess);
            fileStore.loadFiles();
            userStore.loadUser();
            navigation(`/contents/${id}/completed`, { replace: true });
            return;
          }
          if (res.data.errorCode) {
            setPaymentFailureModal(true);
          }
        })
        .catch(() => {
          userStore.showErrorModal();
        });
    }
  };

  /**
   * 결제카드 선택
   * 주결제 카드가 존재할 경우 -> 주결제 카드 우선 선택
   * 주결제 카드가 존재하지 않을 경우 -> user가 등록한 첫번째 카드를 결제 카드로 선택
   */
  useEffect(() => {
    if (!cardStore.isLoading && cardStore.cards) {
      let card = cardStore.cards.filter(card => card.isPriority === false)[0];
      if (!card) {
        card = cardStore.cards[0];
      }
      setPaymentCard(card);
      return;
    }
  }, [cardStore.isLoading]);

  useEffect(() => {
    cardStore.loadCards();
  }, []);

  return (
    <PageContainer>
      {cardStore.isLoading && <FullScreenLoader />}
      {contentStore.isLoading && <FullScreenLoader message="결제 시도중" />}
      <TopBar />
      <TopInfo title={contentStore.content?.title} />

      <List text="구매 목록" />
      {contentStore.content?.files.map(file => (
        <FileList key={file.id}>{file.name}</FileList>
      ))}

      <ListFooter
        leftTitle="가격"
        rightTitle={
          contentStore.content?.price === 0
            ? '무료'
            : `${contentStore.content?.price}원`
        }
      />
      <Border size={24} />
      <Spacing.Vertical height={24} />

      <List
        title="결제 수단 선택"
        description="포인트가 남아있는 경우 차감 후 잔여금액 만큼 결제됩니다"
      />
      <List
        text="카드 선택"
        right={
          <CardName>
            {paymentCard && (
              <div
                onClick={() => setOpenContextMenu(true)}
                style={{ display: 'flex' }}
              >
                {paymentCard.name} <Spacing.Horizontal width={12} />
                <Icon icon="IconArrowDown" />
              </div>
            )}
            {/* user Card empty */}
            {!paymentCard && (
              <SolidButton
                kind="skyblue"
                area="sm"
                onClick={() => {
                  trackUser(ampEvents.ContentDetail_RegisterCard);
                  navigation('/cards');
                }}
              >
                카드 등록하기
              </SolidButton>
            )}
          </CardName>
        }
      />

      <FixedButtons>
        <SolidButton
          disabled={paymentCard === undefined}
          onClick={() => {
            trackUser(ampEvents.ContentDetail_PurchaseSubmit);
            handlePurchase();
          }}
        >
          구매하기
        </SolidButton>
      </FixedButtons>

      {/* Context Menu - select payment card */}
      <ContextMenu
        isOpen={openContextMenu}
        onRequestClose={() => {
          setOpenContextMenu(false);
        }}
        title="카드 목록"
      >
        {cardStore.cards.map(card => (
          <>
            <ContextItem
              key={card.id}
              onClick={() => {
                setPaymentCard(card);
                setOpenContextMenu(false);
              }}
            >
              <Spacing.Horizontal width={12} />
              <ContextLabel>{card.name}</ContextLabel>
            </ContextItem>
          </>
        ))}
      </ContextMenu>

      {/* Modal - payment failure */}
      <Modal
        isOpen={paymentFailureModal}
        onRequestClose={() => setPaymentFailureModal(false)}
        title="결제 실패"
        description="카드 결제가 실패했습니다"
        mainActionLabel="확인"
        onMainAction={() => {
          setPaymentFailureModal(false);
        }}
      />
    </PageContainer>
  );
};
export default observer(ContentPurchase);

const FileList = styled.div`
  padding: 8px 0;
  width: 100%;
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #737373;
`;

const ContextItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
`;

const ContextLabel = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #767676;
`;

const CardName = styled.div`
  padding-top: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #525252;
`;
