import React, { SVGAttributes } from 'react';
import * as icons from 'assets/icons/index';
import myTheme from 'styles/theme';

export type IconType = keyof typeof icons;
export const iconTypes: IconType[] = Object.keys(icons) as IconType[];

export type ColorType = keyof typeof myTheme['color'];
export const colorTypes: ColorType[] = Object.keys(
  myTheme.color
) as ColorType[];

export interface IconProps extends SVGAttributes<SVGElement> {
  icon: IconType;
  size?: number;
  themeColor?: ColorType;
}

export default function Icon({ icon, size, themeColor, ...props }: IconProps) {
  const SVGIcon = icons[icon];
  const color = themeColor ? myTheme.color[themeColor] : undefined;

  return <SVGIcon width={size} height={size} fill={color} {...props} />;
}

Icon.defaultProps = {
  size: 24,
  themeColor: 'gray400',
};
