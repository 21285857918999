import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores/RootStore';
import { Spacing } from 'styles/styles';
import PageContainer from 'components/common/PageContainer';
import SolidButton from 'components/common/solid_button/SolidButton';
import TopBar from 'components/common/top_bar/TopBar';
import FullScreenLoader from 'components/common/FullScreenLoader';
import EditCopiesOption from 'components/pages/editPrintJobOptions/EditCopiesOption';
import EditDuplexOption from 'components/pages/editPrintJobOptions/EditDuplexOption';
import EditFitToOptionOption from 'components/pages/editPrintJobOptions/EditFitToPageOption';
import EditIsColorOption from 'components/pages/editPrintJobOptions/EditIsColorOption';
import EditLayoutContextMenu from 'components/pages/editPrintJobOptions/EditLayoutContextMenu';
import EditLayoutOption from 'components/pages/editPrintJobOptions/EditLayoutOption';
import EditPageRangeContextMenu from 'components/pages/editPrintJobOptions/EditPageRangeContextMenu';
import EditPaperOrientationOption from 'components/pages/editPrintJobOptions/EditPaperOrientationOption';
import PrintWizardProgressBar from 'components/pages/PrintWizardProgressBar';
import PreviewButton from 'components/pages/print/PreviewButton';
import EditPaperSizeOption from 'components/pages/editPrintJobOptions/EditPaperSizeOption';

/**
 * Edit ticket page
 * printjob id를 url로 부터 받아옴 (params: id)
 */

const PrintWizard3Edit: React.FC = props => {
  const navigation = useNavigate();
  const { printJobStore, kioskStore, userStore } = useStores();

  const { id } = useParams();
  const [printJobId, setPrintJobId] = useState<string>('');
  const [openLayoutContextMenu, setOpenLayoutContextMenu] =
    useState<boolean>(false);

  const handleRequest = () => {
    const ticket = printJobStore.printJobs.find(
      pj => pj.id === printJobId
    )?.ticket;
    if (ticket) {
      printJobStore.printJobs
        .find(pj => pj.id === printJobId)
        ?.updateTicket(ticket)
        .then(() => {
          navigation('/waiting-list', { replace: true });
          printJobStore.getPrintJobs();
        })
        .catch(() => userStore.showErrorModal());
    }
  };

  useEffect(() => {
    setPrintJobId(printJobStore.printJobs.filter(pj => pj.id === id)[0].id);
  }, [id]);

  return (
    <PageContainer>
      {kioskStore.isLoading && <FullScreenLoader />}
      <TopBar
        title="인쇄 설정"
        rightNode={<PreviewButton isEdit={true} id={printJobId} />}
      />

      <PrintWizardProgressBar step={3} />
      <Spacing.Vertical height={16} />

      {/* title */}
      <TitleLabel>파일명</TitleLabel>
      <Title>
        {printJobStore.printJobs.find(pj => pj.id === printJobId)?.file.name}
      </Title>
      <Spacing.Vertical height={16} />

      <OptionLabel>색상 설정</OptionLabel>
      <EditIsColorOption printJobId={printJobId} />

      {/* TODO: printzone dto로 설정가능한 용지 크기 보여주기 */}
      <OptionLabel>용지 크기</OptionLabel>
      <EditPaperSizeOption printJobId={printJobId} />

      <OptionLabel>용지 방향</OptionLabel>
      <EditPaperOrientationOption printJobId={printJobId} />

      <OptionLabel>모아찍기</OptionLabel>
      <EditLayoutOption
        printJobId={printJobId}
        onClick={() => {
          setOpenLayoutContextMenu(true);
        }}
      />
      <EditLayoutContextMenu
        printJobId={printJobId}
        isOpen={openLayoutContextMenu}
        setOpen={setOpenLayoutContextMenu}
      />

      <OptionLabel>양단면</OptionLabel>
      <EditDuplexOption printJobId={printJobId} />

      <OptionLabel>부 수</OptionLabel>
      <EditCopiesOption printJobId={printJobId} />

      {/* <OptionLabel>페이지 크기</OptionLabel>
      <EditFitToOptionOption /> */}

      <OptionLabel>범위 지정</OptionLabel>
      <EditPageRangeContextMenu printJobId={printJobId} />

      <Spacing.Vertical height={120} />
      <Fade />

      <FixedButton>
        <SolidButton onClick={handleRequest}>인쇄설정 수정</SolidButton>
      </FixedButton>
    </PageContainer>
  );
};

export default observer(PrintWizard3Edit);

const TitleLabel = styled.div`
  margin-bottom: 4px;

  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #c2c2c2;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #515151;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const OptionLabel = styled.div`
  padding-top: 16px;

  font-weight: 400;
  font-size: 12px;
  line-height: 165%;
  color: #c2c2c2;
`;

const FixedButton = styled.div`
  z-index: 3;

  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 0;
  padding: 8px 0;

  width: calc(100% - 32px);
  max-width: calc(780px - 32px);
  background: white;
`;

const Fade = styled.div`
  z-index: 2;

  position: fixed;
  left: 0;
  bottom: 80px;

  display: flex;
  justify-content: center;

  width: 100%;
  height: 44px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
`;
