import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores/RootStore';
import { FileDocument } from 'stores/file/File';
import { getFileSize } from 'utils/file.util';
import { getRelativeTimestampString } from 'utils/time.util';
import { FAQS_ERROR_FILE } from 'constants/Constants';
import ListHeader from 'components/common/list_header/ListHeader';
import PageContainer from 'components/common/PageContainer';
import TopBar from 'components/common/top_bar/TopBar';
import List from 'components/common/list/List';
import Border from 'components/common/border/Border';
import SolidButton from 'components/common/solid_button/SolidButton';
import ContactButton from 'components/pages/print/ContactButton';
import { Spacing } from 'styles/styles';
import Icon from 'components/common/icon/Icon';

const ErrorFileInfo: React.FC = props => {
  const navigation = useNavigate();
  const { stateStore, fileStore, printJobStore } = useStores();

  const [openIndices, setOpenIndices] = useState<number[]>([]);

  const handleErrorFiles = async () => {
    stateStore.startLoading();
    const errorFiles: FileDocument[] = fileStore.files.filter(
      file => file.errorType
    );
    const reservedFileIds: string[] = [];

    // 1. error 파일 중, reserved된 파일 찾기
    errorFiles.filter(file => {
      printJobStore.printJobs.filter(pj => {
        if (pj.file.id === file.id) {
          reservedFileIds.push(pj.file.id);
        }
      });
    });

    // 2-1. reserved job 먼저 삭제
    if (reservedFileIds.length > 0) {
      await Promise.all(
        reservedFileIds.map(fileId => {
          printJobStore.printJobs
            .filter(pj => pj.file.id === fileId)
            .map(pj => {
              printJobStore.deletePrintJob(pj.id);
            });
        })
      );
    }

    // 2-2. error file 삭제
    await Promise.all(errorFiles.map(file => fileStore.deleteFile(file.id)));

    stateStore.endLoading();
    navigation(-1);
  };

  return (
    <PageContainer>
      <TopBar title="파일 변환 실패 안내" border={true} />

      <ListHeader title="변환 실패 파일 목록" />
      {fileStore.files
        .filter(file => file.errorType)
        .map(file => (
          <List
            key={file.id}
            title={file.name}
            description={`${getFileSize(
              file.size
            )} · ${getRelativeTimestampString(file.createdAt.toString())}${
              new Date().getTime() - new Date(file.createdAt).getTime() <
              1000 * 3600 * 24
                ? '에 업로드'
                : ''
            }`}
          />
        ))}

      <Spacing.Vertical height={24} />

      <ListHeader title="고객 지원" />

      {FAQS_ERROR_FILE.map((faq, index) => (
        <React.Fragment key={faq.key}>
          <FaqItem
            onClick={() => {
              if (openIndices.includes(index)) {
                setOpenIndices(openIndices.filter(it => it !== index));
              } else {
                setOpenIndices([...openIndices, index]);
              }
            }}
          >
            <Row>
              <FaqTitle>{faq.title}</FaqTitle>
              {openIndices.includes(index) ? (
                <Icon icon="IconArrowUp" />
              ) : (
                <Icon icon="IconArrowDown" />
              )}
            </Row>
            {openIndices.includes(index) && <FaqBody>{faq.body}</FaqBody>}
          </FaqItem>
          <Border size={16} />
        </React.Fragment>
      ))}

      <ContactButtonContainer>
        <ContactButton type="Call" />
        <Spacing.Vertical height={16} />
        <ContactButton type="Kakao" />
      </ContactButtonContainer>

      <DeleteMessage>
        버튼을 누르면 내 문서에서 변환 실패 파일이 삭제됩니다
      </DeleteMessage>
      <SolidButton onClick={handleErrorFiles} kind="lightRed">
        변환 실패 파일 삭제
      </SolidButton>

      <Spacing.Vertical height={40} />
    </PageContainer>
  );
};

export default observer(ErrorFileInfo);

const ContactButtonContainer = styled.div`
  padding: 16px 0;
`;

const FaqItem = styled.li`
  width: 100%;

  padding: 16px 0;
`;

const Row = styled.div`
  flex: 1;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FaqTitle = styled.p`
  font-size: 16px;
  line-height: 24px;
`;

const FaqBody = styled.p`
  margin-top: 16px;

  font-size: 14px;
  line-height: 158.22%;
  letter-spacing: -0.05em;
  color: ${props => props.theme.color.gray500};
  white-space: pre-wrap;
`;

const DeleteMessage = styled.p`
  padding: 16px 0;

  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #737373;
`;
