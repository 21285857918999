import * as Sentry from '@sentry/react';
import bridges from 'bridges';
import { FILE_API } from 'services/file.service';

export default function (userId: string | null) {
  // 어느 컴포넌트든 파일 아이디만 있으면 다운로드 할 수 있도록 고차함수로 해줌
  const downloadFiles = (fileIds: string[]) =>
    async function () {
      if (userId) {
        try {
          // name에는 파일 이름이 담기는데, 파일 이름의 끝에는 파일 확장자가 있어야 동작함
          const urls: Promise<{ url: string; name: string }>[] = fileIds.map(
            fileId => createFileDownloadURLPromise(userId, fileId)
          );

          const filesToDownload = await Promise.allSettled(urls);
          const files = getFilesfrom(filesToDownload);
          bridges.downloadFiles(files);
        } catch (error) {
          Sentry.captureException(error);
        }
      }
    };

  return {
    downloadFiles,
  };
}

function createFileDownloadURLPromise(userId: string, fileId: string) {
  return new Promise<{ url: string; name: string }>((res, rej) => {
    (async () => {
      try {
        const { data } = await FILE_API.createFileDownloadURL(userId, fileId);
        res(data);
      } catch (err) {
        rej(err);
      }
    })();
  });
}

function getFilesfrom(
  files: PromiseSettledResult<{
    url: string;
    name: string;
  }>[]
): { url: string; name: string }[] {
  const arr: { url: string; name: string }[] = [];

  files.forEach(f => {
    if (f.status === 'fulfilled') arr.push(f.value);
  });

  return arr;
}
