import 'styles/font/font.css';
import * as amplitude from '@amplitude/analytics-browser';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import axios from 'axios';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from 'styled-components';
import App from './App';
import bridge from 'bridges';
import Modal from './components/common/modal/Modal';
import { ToastProvider } from 'components/common/toast-message/ToastProvider';
import { GlobalStyle } from './styles/GlobalStyle';
import { Store, StoreContext } from './stores/RootStore';
import myTheme from './styles/theme';
import { getEnvironment } from 'utils/utils';
import HistoryProvider from 'contexts/HistoryBackContext';

if (process.env.REACT_APP_AMPLITUDE_API_KEY) {
  amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY);
  separateAmplitudeUserPlatform();
}

Sentry.init({
  dsn: 'https://26f8d1cee06745c5946e12a74e55a981@o1261201.ingest.sentry.io/6692323',
  integrations: [new BrowserTracing()],
  environment: getEnvironment(),

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

(window as any).Kakao.init(process.env.REACT_APP_KAKAO_JS_KEY);

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={myTheme}>
      <GlobalStyle />

      <StoreContext.Provider value={Store}>
        <Sentry.ErrorBoundary
          fallback={
            <Modal
              isOpen={true}
              onRequestClose={() => window.location.reload()}
              description={`에러가 발생하였습니다.\n잠시 후 다시 시도해주세요.`}
              mainActionLabel="다시 시도"
              mainActionColor="NEGATIVE"
              onMainAction={() => window.location.reload()}
            ></Modal>
          }
        >
          <HistoryProvider>
            <ToastProvider>
              <App />
            </ToastProvider>
          </HistoryProvider>
        </Sentry.ErrorBoundary>
      </StoreContext.Provider>
    </ThemeProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
  // </React.StrictMode>
);

axios.defaults.baseURL = process.env.REACT_APP_SERVER_V4_URL as string;

function separateAmplitudeUserPlatform() {
  const identifyEvent = new amplitude.Identify();
  identifyEvent.setOnce('platform_user', bridge.toString());
  amplitude.identify(identifyEvent);
}
