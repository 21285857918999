import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { ampEvents } from 'amplitude-events';
import trackUser from 'services/trackUser';
import { useStores } from 'stores/RootStore';
import LargeButton from 'components/common/large_button/LargeButton';
import Modal from 'components/common/modal/Modal';
import PageContainer from 'components/common/PageContainer';
import TopBar from 'components/common/top_bar/TopBar';
import FullScreenLoader from 'components/common/FullScreenLoader';
import PrintWizardProgressBar from 'components/pages/PrintWizardProgressBar';
import { Spacing } from 'styles/styles';
import { MODAL_PRINT_WIZARD } from 'constants/modalMessage';
import useUploadValidator from 'hooks/useUploadValidator';
import useHistoryBack from 'hooks/useHistoryBack';

const PrintWizard1: React.FC = props => {
  const navigation = useNavigate();
  const {
    userStore,
    uploadStore,
    printJobBuilderStore,
    fileStore,
    kioskStore,
    updateJobStore,
  } = useStores();
  const fileRef = useRef<HTMLInputElement>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalMsg, setModalMsg] = useState<string>('');

  const { checkUploadPossible } = useUploadValidator({
    storageUsed: userStore.user?.storageUsed,
    storageAllocated: userStore.user?.storageAllocated,
  });

  function openModal(msg: string) {
    setShowModal(true);
    setModalMsg(msg);
  }

  async function processPrintWizard() {
    await fileStore.loadFiles();
    const uploadableIds: string[] = uploadStore.uploadables.map(up => up.id);

    const file = fileStore.files.filter(file =>
      uploadableIds.includes(file.id)
    );

    file.map(f => {
      fileStore.updateSelectedFile(f.id);
    });

    printJobBuilderStore.createBuilders(file);
    updateJobStore.setBuilderService('prints');

    if (updateJobStore.builderKioskId !== null) {
      printJobBuilderStore.setKiosk(updateJobStore.builderKioskId);
      navigation('/printwizard3/0');
    } else {
      navigation('/printwizard2');
    }
  }

  // 업로드 버튼 클릭시
  const onUploadButtonClick = () => {
    trackUser(ampEvents.PrintWizard_LoadFile);
    if (uploadStore.uploadables.length > 0) {
      openModal(MODAL_PRINT_WIZARD.ERROR_UPLOADING);
      return;
    }
    fileRef.current?.click();
  };

  const onSuccess = async (files: FileList) => {
    setIsLoading(true);
    try {
      await uploadStore.addFileList(files);
      await processPrintWizard();
    } catch (e) {
      openModal(MODAL_PRINT_WIZARD.ERROR_UPLOAD_FAILED);
      fileStore.setSelectMode(false);
    } finally {
      setIsLoading(false);
    }
  };

  const onError = (errMsg: string) => {
    openModal(errMsg);
  };

  // 파일 업로드
  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (
      files == null ||
      userStore.user?.storageUsed == null ||
      userStore.user?.storageAllocated == null
    )
      return;

    checkUploadPossible(files, onSuccess, onError);
  };

  useEffect(() => {
    kioskStore.getKiosks();
  }, []);

  useHistoryBack(() => {
    updateJobStore.clear();
  });

  return (
    <PageContainer>
      {isLoading && <FullScreenLoader message="파일 업로드 중" />}
      <TopBar title="출력할 문서를 선택하세요" />
      <PrintWizardProgressBar step={1} />

      <Spacing.Vertical height={24} />
      <LargeButton
        onClick={onUploadButtonClick}
        label="파일 가져오기"
        icon="upload"
      />
      <Spacing.Vertical height={16} />
      <LargeButton
        onClick={() => {
          trackUser(ampEvents.PrintWizard_DocumentBox);
          navigation('/myfiledocuments');
        }}
        label="문서함"
        icon="file"
      />

      <FabContainer>
        <input
          type="file"
          multiple
          id="inpUploadFile"
          hidden
          ref={fileRef}
          onChange={handleFile}
        />
      </FabContainer>

      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        title={MODAL_PRINT_WIZARD.TITLE}
        description={modalMsg}
        mainActionLabel="확인"
        onMainAction={() => setShowModal(false)}
      />
    </PageContainer>
  );
};

export default observer(PrintWizard1);

const FabContainer = styled.div`
  position: fixed;
  bottom: 80px;
  right: 25px;
`;
