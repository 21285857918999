import React from 'react';
import { Tab, Tabs, TabList, TabsProps } from 'react-tabs';
import styled from 'styled-components';

export interface Props extends TabsProps {
  names?: Array<string>;
  children?: React.ReactNode;
}

const TabMenu: React.FC<Props> = ({ names, children, ...props }) => {
  return (
    <Tabs selectedTabClassName="is-selected" {...props}>
      <CustomTabList>
        {names &&
          names.map(name => (
            <CustomTab key={name.toString()}>{name}</CustomTab>
          ))}
      </CustomTabList>
      {children}
    </Tabs>
  );
};

export default TabMenu;

const CustomTabList = styled(TabList)`
  width: 100%;
  height: 56px;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  text-align: center;
`;
const CustomTab = styled(Tab)`
  height: 56px;
  flex-basis: 100%;
  flex-grow: 1;
  color: #d4d4d4;
  font-size: 16px;
  font-weight: 500;
  line-height: 56px;
  border-bottom: 1px solid #d4d4d4;
  cursor: pointer;
  &.is-selected {
    color: #4483f7;
    font-weight: 600;
    line-height: 58px;
    border-bottom: 3px solid #4483f7;
    margin-bottom: 2px;
  }
  &:focus {
    outline: none;
  }
`;
