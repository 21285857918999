import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores/RootStore';
import trackUser from 'services/trackUser';
import { ampEvents } from 'amplitude-events';
import PageContainer from 'components/common/PageContainer';
import TopBar from 'components/common/top_bar/TopBar';
import FullScreenLoader from 'components/common/FullScreenLoader';
import Modal from 'components/common/modal/Modal';
import ContextMenu from 'components/common/context_menu/ContextMenu';
import SolidButton from 'components/common/solid_button/SolidButton';
import Icon from 'components/common/icon/Icon';
import CheckBox from 'components/common/check_box/CheckBox';
import { Spacing } from 'styles/styles';
import { ImgPrinterInfo } from 'assets/images';
import useHistoryBack from 'hooks/useHistoryBack';

// Waiting List에서 넘어옴 -> waiting list에서 현재 service 선택 (copy, scan ...)
export default observer(function VerifyKiosk() {
  const navigation = useNavigate();
  const { jobPollingStore } = useStores();
  const [verifyNum, setVerifyNum] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(true);
  const [infoModalOpen, setInfoModalOpen] = useState<boolean>(true);

  const handleVerifyNumber = (
    key: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '0' | 'back'
  ) => {
    if (key === 'back') {
      setVerifyNum(verifyNum.slice(0, verifyNum.length - 1));
      return;
    }
    setVerifyNum(verifyNum + key);
  };

  const handleModalView = () => {
    setInfoModalOpen(!infoModalOpen);
    localStorage.setItem('infoModalOpen', !infoModalOpen ? 'true' : 'false');
  };

  useEffect(() => {
    if (verifyNum.length !== 6) {
      return;
    }
    setLoading(true);

    if (!jobPollingStore.kioskId) {
      console.warn('not found kiosk id!!');
      setError(true);
      return;
    }
    jobPollingStore
      .verifyKiosk(verifyNum, jobPollingStore.kioskId)
      .then(() => {
        if (jobPollingStore.serviceType === 'prints') {
          navigation('/job-payment', { replace: true });
          jobPollingStore.startPaymentTimer();
        } else {
          navigation('/job-in-progress', { replace: true });
        }
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [verifyNum]);

  useEffect(() => {
    const strToBool: boolean =
      localStorage.getItem('infoModalOpen') === 'false' ? false : true;
    setShowModal(strToBool);
    setInfoModalOpen(strToBool);
  }, []);

  // print / scan 뒤로갈 경우 나누기 위해
  const handleBack = () => {
    if (jobPollingStore.serviceType == 'scans') jobPollingStore.clearStore();
  };

  useHistoryBack(handleBack);

  if (loading) {
    return <FullScreenLoader />;
  }

  return (
    <PageContainer>
      <TopBar title="프린터 인증하기" />

      <Wrapper>
        <Text>프린터 화면에 표시된</Text>
        <Text>프린터 번호를 입력해주세요</Text>
        <VerifyNumbers>
          <VerifyNumber str={verifyNum[0]}>{verifyNum[0]}</VerifyNumber>
          <VerifyNumber str={verifyNum[1]}>{verifyNum[1]}</VerifyNumber>
          <VerifyNumber str={verifyNum[2]}>{verifyNum[2]}</VerifyNumber>
          <Spacing.Horizontal width={16} />
          <VerifyNumber str={verifyNum[3]}>{verifyNum[3]}</VerifyNumber>
          <VerifyNumber str={verifyNum[4]}>{verifyNum[4]}</VerifyNumber>
          <VerifyNumber str={verifyNum[5]}>{verifyNum[5]}</VerifyNumber>
        </VerifyNumbers>

        <SolidButton
          area="sm"
          kind="lightGray"
          style={{
            width: 'max-content',
            alignSelf: 'center',
          }}
          onClick={() => {
            trackUser(ampEvents.VerifyKiosk_Guide);
            setShowModal(true);
          }}
        >
          <Icon icon="IconInfo" size={16} />
          이용안내
        </SolidButton>

        <Digits>
          <Digit onClick={() => handleVerifyNumber('1')}>1</Digit>
          <Digit onClick={() => handleVerifyNumber('2')}>2</Digit>
          <Digit onClick={() => handleVerifyNumber('3')}>3</Digit>
          <Digit onClick={() => handleVerifyNumber('4')}>4</Digit>
          <Digit onClick={() => handleVerifyNumber('5')}>5</Digit>
          <Digit onClick={() => handleVerifyNumber('6')}>6</Digit>
          <Digit onClick={() => handleVerifyNumber('7')}>7</Digit>
          <Digit onClick={() => handleVerifyNumber('8')}>8</Digit>
          <Digit onClick={() => handleVerifyNumber('9')}>9</Digit>
          <Digit />
          <Digit onClick={() => handleVerifyNumber('0')}>0</Digit>
          <Digit onClick={() => handleVerifyNumber('back')}>
            {<Icon icon="IconDelete" />}
          </Digit>
        </Digits>
      </Wrapper>

      <Modal
        isOpen={error}
        onRequestClose={() => {
          setVerifyNum('');
          setError(false);
        }}
        description={`유효하지 않은 번호입니다.\n다시 입력해주세요.`}
        mainActionLabel="확인"
        mainActionColor="NEGATIVE"
        onMainAction={() => {
          setVerifyNum('');
          setError(false);
        }}
      ></Modal>
      <ContextMenu
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        title=""
      >
        <InnerModal>
          <Icon
            icon="IconX"
            style={{ position: 'absolute', top: 20, right: 20 }}
            onClick={() => setShowModal(false)}
          />
          <Spacing.Vertical height={33} />
          <div>
            <p>프린터 화면에 표시되고 있는</p>
            <p>프린터 식별번호를 입력해주세요</p>
          </div>
          <Spacing.Vertical height={30} />
          <img
            src={ImgPrinterInfo}
            style={{
              width: '100%',
              backgroundPosition: 'center',
            }}
          />

          <Spacing.Vertical height={20} />
          <Option onClick={handleModalView}>
            <CheckBox checked={!infoModalOpen} />
            <span>다시 보지 않기</span>
          </Option>
        </InnerModal>
      </ContextMenu>
    </PageContainer>
  );
});

const Wrapper = styled.div`
  height: calc(100vh - 56px);
  height: calc(var(--vh, 1vh) * 100 - 56px);
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Text = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.color.gray500};
`;
const VerifyNumbers = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0.9;
  gap: 8px;
`;
const VerifyNumber = styled.div<{ str: string }>`
  width: 16px;
  height: 16px;
  background: ${props =>
    props.str ? 'transparent' : props.theme.color.gray300};
  margin: 10px;
  border-radius: 50%;
  font-weight: 600;
  font-size: 36px;
  line-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.color.gray500};
`;
const Digits = styled.div`
  width: calc(100% + 48px);
  flex: 2;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-left: -24px;
`;
const Digit = styled.button`
  padding: 26.85px 0;
  font-size: 27.8232px;
  line-height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.color.gray500};
  &:active {
    background: ${({ theme }) => theme.color.gray50};
  }
`;
const InnerModal = styled.div`
  color: ${({ theme }) => theme.color.gray700};
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
  span {
    font-weight: 700;
    font-size: 12px;
  }
`;
const Option = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
`;
