import { FileDocument } from 'stores/file/File';
import { getRelativeTimestampString } from './time.util';
import { ALLOWED_FILE_EXTENSIONS } from 'constants/Constants';

export const getExtension = (filename?: string) => {
  if (!filename) return '';
  return `.${filename.split('.').pop()}`;
};

export const removeExtension = (filename?: string) => {
  if (!filename) return '';
  return filename.replace(/\.[^.]+$/, '');
};

export const getFileSize = (bytes: number) => {
  if (bytes < 1024 * 1024) return `${Math.round(bytes / 1024)} KB`;
  if (bytes < 1024 * 1024 * 1024)
    return `${(bytes / 1024 / 1024).toFixed(1)} MB`;
  return `${(bytes / 1024 / 1024 / 1024).toFixed(0)} GB`;
};

export const isUploadWithin24Hours = (createdAt: Date) => {
  const currentTime = new Date().getTime();
  const uploadTime = new Date(createdAt).getTime();
  const timeDifference = currentTime - uploadTime;

  return timeDifference < 1000 * 3600 * 24;
};

export const getFileLabel = (
  file: FileDocument,
  labelType: 'list' | 'image'
): string => {
  let ext = file.name.split('.').pop();
  if (ext == null || !ALLOWED_FILE_EXTENSIONS.includes(ext)) {
    ext = '';
  }
  const fileSize = getFileSize(file.size);
  const time = getRelativeTimestampString(file.createdAt.toString());
  const uploadStatus = isUploadWithin24Hours(file.createdAt) ? '에 업로드' : '';

  if (labelType === 'image') return `${ext.toUpperCase()} · ${fileSize}`;
  return `${ext.toUpperCase()} · ${fileSize} · ${time}${uploadStatus}`;
};
