import { useEffect, useState } from 'react';
import { httpRequestService } from 'services/http-request.service';
import { AlarmDto } from 'types/user/Alarm.dto';
import * as Sentry from '@sentry/react';
import { ApiError } from 'types/error/ApiError';
import { AxiosError } from 'axios';

type Props = {
  userId: string | null;
};

export default function useAlarms({ userId }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [alarms, setAlarms] = useState<AlarmDto[]>();

  useEffect(() => {
    if (userId === null) {
      return;
    }
    setIsLoading(true);

    const fetchData = async () => {
      const params = { n: 10 };
      try {
        const res = await httpRequestService
          .create(true)
          .get<AlarmDto[]>(`users/${userId}/alarms`, { params });

        setAlarms(res.data);
      } catch (err) {
        setError(true);
        Sentry.captureException(
          new ApiError(err as AxiosError, 'get alarm 에러 발생')
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  return [isLoading, error, alarms] as const;
}
