import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import Icon from '../icon/Icon';

type MainActionColor = 'NEUTRAL' | 'POSITIVE' | 'NEGATIVE';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  title?: string;
  description: string;
  mainActionLabel: string;
  onMainAction: () => void;
  mainActionColor?: MainActionColor;
  subActionLabel?: string;
  onSubAction?: () => void;
  // input
  originalValue?: string;
  onSubmit?: (value: string) => void;
}

const Modal: React.FC<Props> = ({
  isOpen,
  onRequestClose,
  title,
  description,
  mainActionLabel,
  mainActionColor = 'NEUTRAL',
  onMainAction,
  subActionLabel,
  onSubAction,
  originalValue,
  onSubmit,
}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue('');
  }, [isOpen]);

  return (
    <ReactModal
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          zIndex: 1001,
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(38, 38, 38, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '24px',
        },
        content: {
          position: 'static',
          width: '300px',
          border: 'none',
          background: '#fff',
          outline: 'none',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '8px',
          padding: '0',
          textAlign: 'center',
          boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.25)',
        },
      }}
      contentLabel="Modal"
    >
      <Container>
        <Main>
          {title && <Title>{title}</Title>}
          <Description>{description}</Description>
          {originalValue && (
            <InputRow>
              <Input
                type="text"
                maxLength={50}
                placeholder={originalValue}
                value={value}
                onChange={e => setValue(e.target.value)}
              />
              {value && (
                <ClearInput>
                  <Icon
                    icon="IconXCircle"
                    size={16}
                    onClick={e => {
                      e.stopPropagation();
                      setValue('');
                    }}
                  />
                </ClearInput>
              )}
            </InputRow>
          )}
        </Main>
        <ButtonRow>
          {subActionLabel && (
            <SubActionButton
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  onSubAction;
                }
              }}
              onClick={onSubAction}
            >
              {subActionLabel}
            </SubActionButton>
          )}
          <MainActionButton
            onKeyPress={e => {
              if (e.key === 'Enter') {
                if (onSubmit) {
                  onSubmit(value);
                }
                onMainAction();
              }
            }}
            onClick={() => {
              if (onSubmit) {
                onSubmit(value);
              }
              onMainAction();
            }}
            mainActionColor={mainActionColor}
          >
            {mainActionLabel}
          </MainActionButton>
        </ButtonRow>
      </Container>
    </ReactModal>
  );
};

export default Modal;

const Container = styled.div``;
const Main = styled.div`
  padding: 30px 0;
`;
const Title = styled.p`
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.05em;
  color: #3d3d3d;
`;
const Description = styled.p`
  white-space: pre-line;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.05em;
  color: #676767;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ButtonRow = styled.div`
  height: 50px;
  border-top: 0.5px solid #ebebeb;
  display: flex;
`;
const MainActionButton = styled.button<{ mainActionColor: MainActionColor }>`
  flex: 1;
  font-weight: bold;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: -0.05em;
  background: white;
  border-color: transparent;
  color: ${({ mainActionColor }) =>
    mainActionColor === 'NEUTRAL'
      ? '#4e4e4e'
      : mainActionColor === 'POSITIVE'
      ? '#2784ff'
      : '#DD3D2A'};
`;
const SubActionButton = styled.button`
  flex: 1;
  font-weight: bold;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: -0.05em;
  color: #4e4e4e;
  background: white;
  border-color: transparent;
  border-width: 0;
  border-right: 0.5px solid #ebebeb;
`;
const InputRow = styled.div`
  position: relative;
  padding: 0 20px;
  height: 50px;
`;
const ClearInput = styled.div`
  position: absolute;
  top: 27px;
  right: 50px;
  display: flex;
  align-items: center;
`;
const Input = styled.input`
  margin-top: 10px;
  padding: 14px 15px;
  padding-right: 40px;
  width: 240px;
  height: 50px;
  border: 1.5px solid #f5f5f5;
  border-radius: 8px;
`;
