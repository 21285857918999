import { httpRequestService } from './http-request.service';
import { Store } from 'stores/RootStore';
import { PayPrintRequestDto } from 'types/payment/PayPrintRequest.dto';
import { PayCardResponseDto } from 'types/payment/PayCardResponse.dto';
import { PayScanCopyDto } from 'types/payment/PayScanCopy.dto';
import { RequestPayParams, RequestPayResponse } from 'types/import/import';

export class PaymentService {
  constructor() {}

  async payScanCopyWithMyCard(
    serviceType: string | null,
    jobIds: string[] | null,
    cardId: string | null
  ) {
    if (!serviceType || !jobIds) {
      throw Error('not exist jobs!!');
    }
    const dto: PayScanCopyDto = {
      cardId: cardId,
    };
    return await httpRequestService
      .create(true)
      .post<PayCardResponseDto>(
        `users/${Store.userStore.userId}/jobs/${serviceType}/${jobIds[0]}/pay-with-my-card`,
        dto
      );
  }

  async payPrintWithMyCard(
    kioskId: string | null, // verify response dto로 오는 kioskId
    jobIds: string[] | null,
    cardId: string | null
  ) {
    if (!kioskId || !jobIds) {
      throw Error('not exist jobs!!');
    }
    const dto: PayPrintRequestDto = {
      cardId: cardId,
      printJobIds: jobIds,
      kioskId: kioskId,
    };
    return await httpRequestService
      .create(true)
      .post<PayCardResponseDto>(
        `users/${Store.userStore.userId}/jobs/prints/pay-with-my-card`,
        dto
      );
  }

  // TODO: 모바일 웹뷰 설정중
  // TODO: 결제는 되는데 백엔드에서 에러 뜨는 중
  // TODO: 결제 후, 라우팅 처리 필요
  /**
  async payPrintJobWithPG(amount: number) {
    // TODO: printjob만 kioskId 존재
    if (Store.jobStore.currentJobIds === null) {
      throw Error('not find jobs!!');
    }
    window.IMP?.init(process.env.REACT_APP_IMP_MERCHANT_ID!);
    const data: RequestPayParams = {
      pg: 'html5_inicis',
      pay_method: 'card',
      merchant_uid: `${Store.jobStore.currentJobIds[0]}`,
      name: `보바 클라우드 프린트 결제 서비스 (v6)`,
      amount: amount, // TODO: 가격 어디서 가져오지
      custom_data: { bcp_service: 'prints' },
      buyer_tel: `${Store.userStore.user?.phoneNumber}`,
      buyer_name: `${Store.userStore.user?.name}`,
      buyer_email: `${Store.userStore.user?.email}`,
      m_redirect_url: process.env.REACT_APP_IMP_REDIRECT_URI,
    };
    const callback = async (response: RequestPayResponse) => {
      const { success, error_code, error_msg, imp_uid, merchant_uid } =
        response;
      if (success) {
        if (Store.jobStore.currentJobIds === null) {
          throw Error('not exist current job!!');
        }
        const params = {
          jobIds: [...Store.jobStore.currentJobIds],
          kioskId: `${Store.jobStore.selectedKioskId}`,
          imp_uid: imp_uid,
          imp_success: success === true,
          error_msg: error_msg,
        };
        await httpRequestService
          .create(true)
          .post(`users/${Store.userStore.userId}/jobs/prints/pay-with-pg`, {
            params,
          });
      } else {
        console.warn();
      }
    };
    window.IMP?.request_pay(data, callback);
  }

  async payScanCopyJobWithPG(amount: number) {
    if (Store.jobStore.currentJobIds === null) {
      throw Error('not find jobs!!');
    }
    window.IMP?.init(process.env.REACT_APP_IMP_MERCHANT_ID!);
    const data: RequestPayParams = {
      pg: 'html5_inicis',
      pay_method: 'card',
      merchant_uid: `${Store.jobStore.currentJobIds[0]}`,
      name: `보바 클라우드 프린트 결제 서비스 (v6)`,
      amount: amount,
      custom_data: { bcp_service: `${Store.jobStore.currentService}` },
      buyer_tel: `${Store.userStore.user?.phoneNumber}`,
      buyer_name: `${Store.userStore.user?.name}`,
      buyer_email: `${Store.userStore.user?.email}`,
      m_redirect_url: process.env.REACT_APP_IMP_REDIRECT_URI,
    };
    const callback = async (response: RequestPayResponse) => {
      const { success, error_code, error_msg, imp_uid, merchant_uid } =
        response;
      if (success) {
        if (Store.jobStore.currentJobIds === null) {
          throw Error('not exist current job!!');
        }
        const params = {
          jobId: Store.jobStore.currentJobIds[0],
          imp_uid: imp_uid,
          imp_success: success === true,
          error_msg: error_msg,
        };
        await httpRequestService
          .create(true)
          .post(
            `users/${Store.userStore.userId}/jobs/${Store.jobStore.currentService}/pay-with-pg`,
            {
              params,
            }
          );
      } else {
        console.warn();
      }
    };
    window.IMP?.request_pay(data, callback);
  }
**/
  // TODO: 콘텐츠 구매
}

export const paymentService = new PaymentService();
