import { makeAutoObservable, runInAction } from 'mobx';
import { PhoneAuthSendMessageDto } from 'types/auth/PhoneAuthSendMessage.dto';
import { FindEmailError, ParamError, VerifyPhoneError } from '../errors';
import { PhoneAuthVerifyDto } from 'types/auth/PhoneAuthVerify.dto';
import { Agreement, SignUpDto, AgreementType } from 'types/auth/SignUp.dto';
import { FindEmailDto } from 'types/auth/FindEmail.dto';
import { FindEmailResponseDto } from 'types/auth/FindEmailResponse.dto';
import { PhoneAuthVerifyResultDto } from 'types/auth/PhoneAuthVerifyResultDto.dto';
import { UpdateUserDto } from 'types/user/UpdateUser.dto';
import * as Sentry from '@sentry/react';
import { ApiError } from 'types/error/ApiError';
import { Gender } from 'types/auth/Gender';
import { UserStore } from './user/UserStore';
import { HttpRequestService } from 'services/http-request.service';
import { PatchPasswordDto } from 'types/auth/PatchPassword.dto';
import { PasswordIsExistResponseDto } from 'types/auth/PasswordIsExistResponse.dto';

type TAuthErrors = {
  loginError?: Error;
  loginPasswordError?: Error;
  loginEmailError?: Error;
  loginNetworkError?: Error;
  loginManyRequestsError?: Error;
  logoutError?: Error;
  phoneAuthSendError?: Error;
  verifyPhoneError?: Error;
  signUpError?: Error;
  findEmailError?: Error;
  changePasswordError?: Error;
};

type TSignUpProps = {
  email?: string;
  password?: string;
  name?: string;
  phoneNumber?: string;
  birthday?: Date | null;
  gender?: Gender | null;
  agreements?: Agreement[];
};

/**
 * Auth 에 관한 처리 store
 */
export class AuthStore {
  isLoading = false;
  signUpProps: TSignUpProps = {};
  phoneAuthSentAt: Date | null = null;
  phoneAuthSessionKey: string | null = null;
  foundEmail: string | null = null;
  userIdforFindPassword: string | null = null;
  accessTokenForPasswordChange: string | null = null;
  errors: TAuthErrors = {};

  constructor(
    private readonly userStore: UserStore,
    private readonly http: HttpRequestService
  ) {
    makeAutoObservable(this, {});
  }

  /**
   * @param email
   * @param password
   */

  async isExist(phoneNumber?: string, email?: string) {
    const dto = {
      PhoneNumber: phoneNumber,
      Email: email,
    };
    return await this.http.create().post('users/is-exist', dto);
  }

  sendPhoneAuthMessage(phoneNumber: string) {
    this.startLoading();

    const reqDto: PhoneAuthSendMessageDto = {
      phoneNumber,
    };
    this.http
      .create()
      .post<any, any, PhoneAuthSendMessageDto>(
        'auth/phone-auth/send-message',
        reqDto
      )
      .then(() => {
        this.setPhoneNumber(phoneNumber);
        this.setPhoneAuthSent();
      })
      .catch(err => {
        runInAction(() => {
          this.errors.phoneAuthSendError = err;
          Sentry.captureException(
            new ApiError(err, 'sendPhoneAuthMessageError')
          );
        });
      })
      .finally(() => {
        this.endLoading();
      });
  }

  async verifyPhone(verifyNumber: string, passwordChange?: boolean) {
    if (!this.signUpProps.phoneNumber) {
      throw new VerifyPhoneError("phoneNumber doesn't exist");
    }
    this.startLoading();

    const reqDto: PhoneAuthVerifyDto = {
      phoneNumber: this.signUpProps.phoneNumber,
      verifyNumber,
    };

    const url = passwordChange ? 'auth/phone-auth/password-change-verify' : 'auth/phone-auth/signup-verify';

    return await this.http
      .create()
      .post<PhoneAuthVerifyResultDto>(url, reqDto)
      .then(({ data }) => {
        if (!data.isSuccess) {
          throw new VerifyPhoneError();
        }
        this.setPhoneAuthSessionKey(data.phoneAuthSessionKey);
        if(passwordChange) this.accessTokenForPasswordChange = data.accessTokenForPasswordChange;
      })
      .finally(() => {
        this.endLoading();
      });
  }

  async signUp() {
    this.startLoading();

    // validation
    let dto: SignUpDto;
    try {
      dto = this.getSignUpDto();
    } catch (err) {
      this.endLoading();
      throw new Error();
    }
    return await this.http
      .create()
      .post('users/create', dto)
      .then(() => {
        this.userStore.login(dto.email, dto.password);
        this.resetPhoneAuth();
      })
      .finally(() => {
        this.endLoading();
      });
  }

  async findEmail(phoneNumber: string) {
    // validation
    if (!this.phoneAuthSessionKey) {
      throw new FindEmailError("phoneAuthSessionKey doesn't exist");
    }

    const dto: FindEmailDto = {
      phoneNumber,
      // phoneAuthSessionKey: this.phoneAuthSessionKey,
    };
    return await this.http
      .create()
      .post<FindEmailResponseDto>('users/find-email', dto)
      .then(response => {
        this.setFoundEmail(response.data.email);
      })
      .catch(err => {
        throw new FindEmailError(err);
      });
  }

  async isCurrentPassword(password: string) {
    this.startLoading();
    const dto = {
      password: password,
    };

    return await this.http
      .create(true)
      .post<PasswordIsExistResponseDto>(
        `users/${this.userStore.userId}/password/is-exist`,
        dto
      )
      .finally(() => {
        this.endLoading();
      });
  }

  async changePassword(userId: string, password: string) {
    this.startLoading();
    const dto: PatchPasswordDto = {
      password: password,
    };

    const accessToken = this.accessTokenForPasswordChange || this.http.accessToken;
    const headers = {
      Authorization: `Bearer ${accessToken}`
    };
    
    return this.http
      .create()
      .patch(
        `users/${userId}/password`,
        dto,
        { headers }
      )
      .finally(() => {
        this.endLoading();
      });
  }

  async updateUser(
    name?: string,
    nickName?: string,
    phoneNumber?: string,
    birthday?: Date,
    gender?: string | null
  ) {
    this.startLoading();
    if (this.phoneAuthSessionKey) {
      const dto: UpdateUserDto = {
        name: name,
        phoneNumber: phoneNumber,
        phoneAuthSessionKey: this.phoneAuthSessionKey,
      };
      return await this.http
        .create(true)
        .patch(`users/${this.userStore.userId}`, dto)
        .catch(() => {
          throw new Error();
        })
        .finally(() => {
          this.endLoading();
          this.phoneAuthSessionKey = null;
        });
    }
    const dto: UpdateUserDto = {
      name: name ?? undefined,
      nickName: nickName ?? undefined,
      phoneNumber: phoneNumber ?? undefined,
      birthday: birthday ?? undefined,
      gender: gender ?? undefined,
    };
    return this.http
      .create(true)
      .patch(`users/${this.userStore.userId}`, dto)
      .catch(() => {
        throw new Error();
      })
      .finally(() => this.endLoading());
  }

  /**
   *
   */
  async withdrawal() {
    this.startLoading();

    return await this.http
      .create(true)
      .delete(`users/${this.userStore.userId}`)
      .finally(() => this.endLoading());
  }

  setEmail(email: string) {
    this.signUpProps.email = email;
  }

  setPassword(password: string) {
    this.signUpProps.password = password;
  }

  setName(name: string) {
    this.signUpProps.name = name;
  }

  setPhoneNumber(phoneNumber: string) {
    // 국제 번호 형식
    this.signUpProps.phoneNumber = phoneNumber;
  }

  setPhoneAuthSessionKey(phoneAuthSessionKey: string) {
    this.phoneAuthSessionKey = phoneAuthSessionKey;
  }

  setBirthday(birthday: Date) {
    this.signUpProps.birthday = birthday;
  }

  setGender(gender: Gender) {
    this.signUpProps.gender = gender;
  }

  setAgreements(term4: boolean, term5: boolean) {
    // eslint-disable-next-line prefer-const
    let arr: Agreement[] = [
      {
        name: AgreementType.Boba,
        version: 1,
      },
      {
        name: AgreementType.Privacy,
        version: 1,
      },
      {
        name: AgreementType.Location,
        version: 1,
      },
    ];

    if (term4) {
      const obj: Agreement = {
        name: AgreementType.Marketing,
        version: 1,
      };
      arr.push(obj);
    }
    if (term5) {
      const obj: Agreement = {
        name: AgreementType.ThildPartyProvision,
        version: 1,
      };
      arr.push(obj);
    }

    this.signUpProps.agreements = arr;
  }

  setFoundEmail(email: string) {
    this.foundEmail = email;
  }

  setUserIdforFindPassword(userId: string) {
    this.userIdforFindPassword = userId;
  }

  setResetError() {
    const errors: TAuthErrors = {};
    this.errors = errors;
  }

  clear() {
    this.signUpProps = {};
    this.phoneAuthSentAt = null;
    this.phoneAuthSessionKey = null;
    this.foundEmail = null;
    this.errors = {};
  }

  get isPhoneAuthSent() {
    return !!this.phoneAuthSentAt;
  }

  private getSignUpDto() {
    const props = this.signUpProps;
    if (
      !props.email ||
      !props.password ||
      !props.phoneNumber ||
      !props.name ||
      !this.phoneAuthSessionKey ||
      !props.agreements
    ) {
      throw new ParamError();
    }

    const dto: SignUpDto = {
      email: props.email,
      password: props.password,
      phoneNumber: props.phoneNumber,
      name: props.name,
      phoneAuthSessionKey: this.phoneAuthSessionKey,
      birthday: props.birthday ?? null,
      gender: props.gender ?? null,
      agreements: props.agreements,
    };
    return dto;
  }

  private startLoading() {
    this.isLoading = true;
  }

  private endLoading() {
    this.isLoading = false;
  }

  private setPhoneAuthSent() {
    this.phoneAuthSentAt = new Date();
  }

  private resetPhoneAuth() {
    this.phoneAuthSentAt = null;
    this.phoneAuthSessionKey = null;
  }
}
