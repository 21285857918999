import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PageContainer from 'components/common/PageContainer';
import TabMenu from 'components/common/tab_menu/TabMenu';
import TopBar from 'components/common/top_bar/TopBar';
import Icon from 'components/common/icon/Icon';
import MyContents from 'components/pages/MyContents';
import MyFiles from 'components/pages/MyFiles';
import { observer } from 'mobx-react';
import { TabPanel } from 'react-tabs';
import { useStores } from 'stores/RootStore';
import ContextMenu from 'components/common/context_menu/ContextMenu';
import Modal from 'components/common/modal/Modal';
import SolidButton from 'components/common/solid_button/SolidButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { getExtension, removeExtension } from 'utils/file.util';
import { ampEvents } from 'amplitude-events';
import trackUser from 'services/trackUser';
import { SortMode } from 'stores/file/FileStore';
import { Spacing } from 'styles/styles';
import useFileDownload from 'hooks/useFileDownload';

const MyFileDocuments: React.FC = props => {
  const {
    fileStore,
    printJobBuilderStore,
    printJobStore,
    userStore,
    updateJobStore,
  } = useStores();
  const navigation = useNavigate();
  const location = useLocation().state as { type: 'CONTENTS' | 'FILES' };

  const [tabIndex, setTabIndex] = useState<number>(0);

  const [openSortTypeSelector, setOpenSortTypeSelector] =
    useState<boolean>(false);
  const [fileUidToRename, setFileUidToRename] = useState<string>('');

  const [findText, setFindText] = useState<string>();
  const [searchMode, setSearchMode] = useState(false);

  const [modalTxt, setModalTxt] = useState<string>('');
  const [deleteFileModal, setDeleteFileModal] = useState<boolean>(false);
  const [deleteOptionFileModal, setDeleteOptionFileModal] =
    useState<boolean>(false);
  const [deleteErrorModal, setDeleteErrorModal] = useState<boolean>(false);
  const { downloadFiles } = useFileDownload(userStore.userId);

  const SortTypeDictionary = {
    filename: '이름순',
    size: '크기순',
    recent: '최신순',
    extension: '형식순',
  };

  const SortArrowIcon = (
    <Icon
      icon={fileStore.sortDescending ? 'IconSortArrowDown' : 'IconSortArrowUp'}
      themeColor="primary600"
    />
  );

  const handleSort = (mode: SortMode) => {
    if (fileStore.sortMode === mode) {
      fileStore.setSortDescending(!fileStore.sortDescending);
    } else {
      fileStore.setSortMode(mode);
      fileStore.setSortDescending(true);
    }
    setOpenSortTypeSelector(false);
  };

  const checkFileToDelete = (fileIds: string[], mode: 'select' | 'option') => {
    let hasReservedJob = 0; // 해당 파일에 예약된 printjob이 존재하는지 (0: 없음, 1: 있음)
    fileIds.map(fileId =>
      printJobStore.printJobs.map(pj => {
        if (pj.file.id === fileId) hasReservedJob = 1; // 존재하면 1로
      })
    );

    if (fileIds.length > 1) {
      if (hasReservedJob) {
        setModalTxt(
          `인쇄 대기중인 문서가 있습니다.\n인쇄를 취소하고 삭제하시겠습니까?`
        );
      } else {
        setModalTxt(`${fileIds.length}개 항목을 정말 삭제하시겠습니까?`);
      }
    } else {
      if (hasReservedJob) {
        setModalTxt(
          `인쇄 대기중인 문서입니다.\n인쇄를 취소하고 삭제하시겠습니까?`
        );
      } else {
        setModalTxt('정말 삭제하시겠습니까?');
      }
    }

    if (mode === 'select') setDeleteFileModal(true);
    else setDeleteOptionFileModal(true);
  };

  const deleteFiles = async (fileIds: string[]) => {
    const deletePrintJobIds: string[] = [];

    fileIds.map(fileId =>
      printJobStore.printJobs.map(pj => {
        if (pj.file.id === fileId) {
          deletePrintJobIds.push(pj.id);
        }
      })
    );

    await Promise.all(
      deletePrintJobIds.map(jobId => printJobStore.deletePrintJob(jobId))
    );

    await Promise.all(
      fileIds.map(fileId => fileStore.deleteFile(fileId))
    ).catch(() => {
      setDeleteErrorModal(true);
    });

    fileStore.loadFiles();
  };

  useEffect(() => {
    if (!location) return;

    if (location.type === 'CONTENTS') {
      setTabIndex(1);
    }
  }, [location]);

  return (
    <PageContainer>
      {/* Header */}
      {!searchMode && (
        <TopBar
          left="back"
          onLeftClick={
            fileStore.selectMode
              ? () => fileStore.setSelectMode(false)
              : () => navigation(-1)
          }
          label={
            fileStore.selectMode
              ? `${fileStore.selectedFile.length}개 항목 선택됨`
              : '문서함'
          }
          right={
            !fileStore.selectMode ? 'search' : tabIndex ? undefined : 'trash'
          }
          onRightClick={() => {
            if (!fileStore.selectMode) {
              return setSearchMode(true);
            }
            checkFileToDelete(fileStore.selectedFile, 'select');
          }}
        />
      )}

      {/* searching + input */}
      {searchMode && (
        <>
          <TopBar
            left="back"
            onLeftClick={() => {
              setSearchMode(false);
              setFindText('');
            }}
            label={
              fileStore.selectMode
                ? `${fileStore.selectedFile.length}개 항목 선택됨`
                : ''
            }
            // 콘텐츠 탭이거나 파일을 선택하지 않았을 경우 삭제 아이콘이 뜨지 않도록 함
            right={tabIndex || !fileStore.selectMode ? undefined : 'trash'}
            onRightClick={() => {
              checkFileToDelete(fileStore.selectedFile, 'select');
            }}
          />
          <SearchContainer>
            <InputContainer>
              <Input
                value={findText}
                placeholder="검색어를 입력하세요"
                onChange={e => setFindText(e.target.value)}
                onClick={() => {
                  trackUser(ampEvents.DocumentBox_Search);
                }}
              />
              <Icon icon="IconSearch" size={20} />
            </InputContainer>
          </SearchContainer>
        </>
      )}

      <ToolBar>
        {fileStore.selectMode ? (
          <>
            {fileStore.files.find(file => file.id === fileStore.selectedFile[0])
              ?.origin === 'My' && (
              <>
                <Icon icon="IconDownload" />
                <ToolLabel
                  onClick={() => {
                    trackUser(ampEvents.DocumentBox_Download);
                    downloadFiles(fileStore.selectedFile)();
                    fileStore.setSelectMode(false);
                  }}
                  style={{ flexGrow: 1 }}
                >
                  다운로드
                </ToolLabel>
              </>
            )}
          </>
        ) : (
          <>
            <Icon
              icon={fileStore.sortDescending ? 'IconSortDesc' : 'IconSortAsc'}
              onClick={() => {
                setOpenSortTypeSelector(true);
              }}
              style={{ flexGrow: 0 }}
            />

            <ToolLabel
              onClick={() => {
                setOpenSortTypeSelector(true);
              }}
              style={{ flexGrow: 1 }}
            >
              {SortTypeDictionary[fileStore.sortMode]}
            </ToolLabel>
          </>
        )}

        <Icon
          icon={fileStore.viewMode === 'list' ? 'IconGrid' : 'IconList'}
          size={fileStore.viewMode === 'list' ? 20 : 24}
          onClick={() => {
            trackUser(ampEvents.DocumentBox_ChangeLayout);
            fileStore.setViewMode(
              fileStore.viewMode === 'list' ? 'grid' : 'list'
            );
          }}
          style={{ flexGrow: 0 }}
        />
      </ToolBar>

      {/* Tab */}
      <TabMenu
        names={['업로드한 문서', '구매한 콘텐츠']}
        selectedIndex={tabIndex}
        onSelect={index => setTabIndex(index)}
      >
        <TabPanel onClick={() => trackUser(ampEvents.DocumentBox_Tab_Uploaded)}>
          <MyFiles findText={findText} />
        </TabPanel>
        <TabPanel
          onClick={() => trackUser(ampEvents.DocumentBox_Tab_PurchasedContent)}
        >
          <MyContents findText={findText} />
        </TabPanel>
      </TabMenu>

      {/* Bottom -- print button */}
      {/* 1단계 파일선택 */}
      {fileStore.selectMode && (
        <PrintButton>
          <SolidButton
            onClick={() => {
              trackUser(ampEvents.DocumentBox_Print);
              updateJobStore.setBuilderService('prints');
              const files = fileStore.selectedFile.map(fileId =>
                fileStore.files.filter(file => file.id === fileId)
              );
              const file = files.map(file => file[0]);
              printJobBuilderStore.createBuilders(file);
              if (updateJobStore.builderKioskId !== null) {
                printJobBuilderStore.setKiosk(updateJobStore.builderKioskId);
                navigation('/printwizard3/0');
              } else {
                navigation('/printwizard2');
              }
            }}
          >{`${fileStore.selectedFile.length}건 인쇄하기`}</SolidButton>
        </PrintButton>
      )}

      {/* ContextMenu -- File sort type selector */}
      <ContextMenu
        isOpen={openSortTypeSelector}
        onRequestClose={() => {
          setOpenSortTypeSelector(false);
        }}
        title="정렬 기준"
      >
        <ul>
          <CommandItem onClick={() => handleSort('filename')}>
            <p>이름</p>
            {fileStore.sortMode === 'filename' && SortArrowIcon}
          </CommandItem>
          <CommandItem onClick={() => handleSort('size')}>
            <p>크기</p>
            {fileStore.sortMode === 'size' && SortArrowIcon}
          </CommandItem>
          <CommandItem onClick={() => handleSort('recent')}>
            <p>최신</p>
            {fileStore.sortMode === 'recent' && SortArrowIcon}
          </CommandItem>
          <CommandItem onClick={() => handleSort('extension')}>
            <p>파일 형식</p>
            {fileStore.sortMode === 'extension' && SortArrowIcon}
          </CommandItem>
        </ul>
      </ContextMenu>

      {/* File command selector */}
      <ContextMenu
        isOpen={fileStore.optionMode}
        onRequestClose={() => {
          fileStore.setOptionMode(false);
          fileStore.clearOptionModeFile();
        }}
        title={fileStore.optionModeFile?.fileName ?? ''}
      >
        <ul>
          {fileStore.optionModeFile?.errorType === null && (
            <>
              <CommandItem
                onClick={() => {
                  trackUser(ampEvents.DocumentBox_Print);
                  const file = fileStore.files.filter(
                    f => f.id === fileStore.optionModeFile?.fileId
                  );
                  fileStore.updateSelectedFile(file[0].id);
                  printJobBuilderStore.createBuilders(file);
                  fileStore.setOptionMode(false);
                  fileStore.clearOptionModeFile();
                  if (updateJobStore.builderKioskId !== null) {
                    printJobBuilderStore.setKiosk(
                      updateJobStore.builderKioskId
                    );
                    navigation('/printwizard3/0');
                  } else {
                    navigation('/printwizard2');
                  }
                }}
              >
                <Spacing.Horizontal width={2} />
                <CommandIcon icon="IconPrinter" size={18} />
                <Spacing.Horizontal width={4} />
                <p>인쇄하기</p>
              </CommandItem>

              <CommandItem
                onClick={() => {
                  downloadFiles([fileStore.optionModeFile?.fileId!])();
                  fileStore.clearOptionModeFile();
                  fileStore.setOptionMode(false);
                }}
              >
                <Spacing.Horizontal width={2} />
                <CommandIcon icon="IconDownload" size={18} />
                <Spacing.Horizontal width={4} />
                <p>다운로드</p>
              </CommandItem>

              {!fileStore.files.find(
                file => file.id === fileStore.optionModeFile?.fileId
              )?.isConverting && (
                <CommandItem
                  onClick={() => {
                    trackUser(ampEvents.DocumentBox_Preview);
                    navigation(
                      `/files/${fileStore.optionModeFile?.fileId}/preview`
                    );
                    fileStore.setOptionMode(false);
                    fileStore.clearOptionModeFile();
                  }}
                >
                  <CommandIcon icon="IconVisual" />
                  <p>미리보기</p>
                </CommandItem>
              )}
            </>
          )}

          {/* MyFile만 이름 변경 가능 */}
          {fileStore.optionModeFile?.fileOrigin === 'My' &&
            fileStore.optionModeFile?.errorType === null && (
              <CommandItem
                onClick={() => {
                  setFileUidToRename(fileStore.optionModeFile?.fileId ?? '');
                  fileStore.setOptionMode(false);
                  fileStore.clearOptionModeFile();
                }}
              >
                <CommandIcon icon="IconEdit" />
                <p>이름 변경</p>
              </CommandItem>
            )}

          {/* printjob에 있으면, printjob 같이 삭제 여부 확인 */}
          {fileStore.optionModeFile?.fileOrigin === 'My' && (
            <CommandItem
              onClick={() => {
                fileStore.setOptionMode(false);
                checkFileToDelete(
                  [fileStore.optionModeFile?.fileId ?? ''],
                  'option'
                );
              }}
            >
              <CommandIcon icon="IconTrashBin" themeColor="destructive600" />
              <p style={{ color: '#DD3D2A' }}>삭제</p>
            </CommandItem>
          )}
        </ul>
      </ContextMenu>

      {/* Modal: Rename file */}
      <Modal
        isOpen={!!fileUidToRename}
        onRequestClose={() => {
          setFileUidToRename('');
          fileStore.setOptionMode(false);
          fileStore.clearOptionModeFile();
        }}
        description="변경할 파일 이름을 입력 하세요"
        mainActionLabel="이름 바꾸기"
        mainActionColor="POSITIVE"
        onMainAction={() => {}}
        subActionLabel="취소"
        onSubAction={() => {
          setFileUidToRename('');
          fileStore.setOptionMode(false);
          fileStore.clearOptionModeFile();
        }}
        originalValue={removeExtension(
          (function () {
            return fileStore.files.find(f => f.id === fileUidToRename)?.name;
          })()
        )}
        onSubmit={filename => {
          if (!filename) return;
          const currentFileViewName = fileStore.files.find(
            f => f.id === fileUidToRename
          )?.name;

          const newName = filename + getExtension(currentFileViewName);
          fileStore.files
            .find(f => f.id === fileUidToRename)
            ?.rename(newName)
            .catch(() => {
              userStore.showErrorModal();
            })
            .finally(() => {
              setFileUidToRename('');
              fileStore.setOptionMode(false);
              fileStore.clearOptionModeFile();
            });
        }}
      />

      {/* Modal: Error when delete file */}
      <Modal
        isOpen={deleteErrorModal}
        onRequestClose={() => setDeleteErrorModal(false)}
        description={`파일삭제에 실패하였습니다`}
        mainActionLabel="확인"
        onMainAction={() => setDeleteErrorModal(false)}
      />

      <Modal
        isOpen={deleteOptionFileModal}
        onRequestClose={() => setDeleteOptionFileModal(false)}
        title="파일 삭제"
        description={modalTxt}
        mainActionLabel="삭제"
        mainActionColor="NEGATIVE"
        onMainAction={() => {
          setDeleteOptionFileModal(false);
          deleteFiles([fileStore.optionModeFile?.fileId ?? '']);
        }}
        subActionLabel="취소"
        onSubAction={() => setDeleteOptionFileModal(false)}
      />

      <Modal
        isOpen={deleteFileModal}
        onRequestClose={() => setDeleteFileModal(false)}
        title="파일 삭제"
        description={modalTxt}
        mainActionLabel="삭제"
        mainActionColor="NEGATIVE"
        onMainAction={() => {
          setDeleteFileModal(false);
          deleteFiles(fileStore.selectedFile);
        }}
        subActionLabel="취소"
        onSubAction={() => setDeleteFileModal(false)}
      />
    </PageContainer>
  );
};
export default observer(MyFileDocuments);

const SearchContainer = styled.div`
  z-index: 13;
  position: sticky;
  top: 56px;
  background: #ffffff;
  padding-bottom: 16px;
`;

const InputContainer = styled.div`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
`;

const Input = styled.input`
  background: #ffffff;
  width: 100%;
  height: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
  ::placeholder,
  ::-webkit-input-placeholder {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #a3a3a3;
  }
  outline: none;
  border-width: 0;
`;

const ToolBar = styled.div`
  height: 38px;
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ToolLabel = styled.p`
  z-index: 12;
  margin-left: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #737373;
`;

const CommandItem = styled.li`
  height: 50px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.05em;
  color: #525252;
  display: flex;
  align-items: center;
`;

const CommandIcon = styled(Icon)`
  margin-right: 8px;
`;

const PrintButton = styled.div`
  width: 100%;
  max-width: 780px;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 0;
  padding: 0 30px 24px 30px;
  background: white;
  border-width: 0;
  border-color: transparent;
  z-index: 6;
`;
