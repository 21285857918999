import styled, { keyframes } from 'styled-components';

interface ToastProps {
  message: string;
}

const ToastMessage = ({ message }: ToastProps) => {
  return <Container>{message}</Container>;
};

export default ToastMessage;

const fadeIn = keyframes`
  0% {
    opacity: 0;
    visibility:hidden;
  }
  50% {
    opacity: 1;
    visibility:visible;
  }
`;

const Container = styled.div`
  z-index: 100;

  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 100px;

  padding: 10px 20px;

  background-color: #0000007f;
  border-radius: 8px;

  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.color.white};

  display: flex;
  align-items: center;
  justify-content: center;

  animation: ${fadeIn} 3s;
`;
