import React from 'react';
import styled from 'styled-components';
import { getFileSize } from 'utils/file.util';

interface Props {
  used: number;
  allocated: number;
}
const StorageStatus: React.FC<Props> = ({ used, allocated }) => {
  return (
    <Container>
      <TextRow>
        <Label>저장 공간</Label>
        <Value>{`${getFileSize(used)} / ${getFileSize(allocated)}`}</Value>
      </TextRow>
      <BarBackground>
        <Bar percentage={(used / allocated) * 100} />
      </BarBackground>
    </Container>
  );
};

export default StorageStatus;

const Container = styled.div`
  width: 100%;
  height: 65px;
  padding: 16px 0;
`;
const TextRow = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Label = styled.p`
  font-size: 14px;
  letter-spacing: -0.05em;
  color: ${props => props.theme.color.gray500};
`;
const Value = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 159.37%;
  color: ${props => props.theme.color.gray900};
`;
const BarBackground = styled.div`
  width: 100%;
  height: 3px;
  background: #f4f4f4;
  border-radius: 8px;
`;
const Bar = styled.div<{ percentage: number }>`
  width: ${({ percentage }) => `${percentage}%`};
  height: 100%;
  background: #2784ff;
  border-radius: 8px;
`;
