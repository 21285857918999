export const SITE_URL = Object.freeze({
  BOBA_APP: 'https://app.bobaprint.com',
  SEARCH_IT: 'https://search.bobaprint.com',
});

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PASSWORD_REGEX = new RegExp('^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$');
export const PHONE_REGEX = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
export const ALL_NUMBER_REGEX = /^[0-9]+$/;
export const NUMBER_AND_SPACE = /^[0-9 ]+$/;
export const EXPIRY_REGEX = /^[0-9/]+$/;
export const REG_NUMBER = /[^0-9]/g;
export const BIRTH_REGEX =
  /^(19[0-9][0-9]|20\d{2})(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/;

export const FAQS_ERROR_FILE = Object.freeze([
  {
    key: 1,
    title: `파일 변환이 무엇인가요?`,
    body: '보바는 문서의 보안성과 품질을 위해 다양한 확장자의 문서 파일을 인쇄에 적합한 포맷으로 변환하고 있습니다.',
  },
  {
    key: 2,
    title: '왜 파일 변환에 실패하나요?',
    body: '• 암호화된 파일은 포맷 변환에 실패할 수 있습니다. \n• 이미 손상된 파일을 업로드할 경우 포맷을 변환할 수 없습니다.',
  },
  {
    key: 3,
    title: `파일 변환에 실패한 경우 어떻게 하나요?`,
    body: '• 암호화된 파일은 암호화를 해제한 뒤 다시 업로드 해주세요.\n• 손상된 파일은 변환할 수 없으니 파일이 손상되어 있는지 확인하고 업로드 해주세요.\n• 원인 모를 파일 변환 실패나 위의 내용으로 해결되지 않는 경우 고객센터에 문의해주세요.',
  },
]);

export const ALLOWED_FILE_EXTENSIONS = Object.freeze([
  'doc',
  'docx',
  'docs',
  'xls',
  'xlsx',
  'xlsb',
  'ppt',
  'pptx',
  'pptm',
  'hwp',
  'txt',
  'png',
  'jpg',
  'jpeg',
  'tiff',
  'gif',
  'bmp',
  'pdf',
]);
