import { httpRequestService } from './http-request.service';
import { ContentOrderLineDto } from 'types/user/ContentOrderLine.dto';
import { JobOrderLineDto } from 'types/user/orders/JobOrderLine.dto';

export const USER_API = {
  getContentsHistory: (userId: string) =>
    httpRequestService
      .create(true)
      .get<ContentOrderLineDto[]>(`users/${userId}/orders/contents`),
  getPrintsHistory: (userId: string) =>
    httpRequestService
      .create(true)
      .get<JobOrderLineDto[]>(`users/${userId}/orders/jobs`),
};

export default { USER_API };
