import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Tag from './Tag';

interface HashTagsProps {
  tags: string[] | undefined;
}

const HashTags: React.FC<HashTagsProps> = ({ tags }) => {
  const [isFolded, setIsFolded] = useState<boolean>(true);

  const handleFolded = () => {
    setIsFolded(!isFolded);
  };

  return (
    <OuterContainer>
      <InnerContainer>
        {tags && tags.length > 3 && isFolded && (
          <>
            {tags.slice(0, 3).map((tag, idx) => (
              <Tag key={idx}>{tag}</Tag>
            ))}
            <Tag kind="blue" onClick={handleFolded}>
              {`+ ${tags.length - 3}`}
            </Tag>
          </>
        )}
        {tags && tags.length > 3 && !isFolded && (
          <>
            {tags.map((tag, idx) => (
              <Tag key={idx}>{tag}</Tag>
            ))}
            <Tag kind="blue" onClick={handleFolded}>
              {`접기`}
            </Tag>
          </>
        )}

        {tags &&
          tags.length <= 3 &&
          tags.map((tag, idx) => <Tag key={idx}>{tag}</Tag>)}
      </InnerContainer>
    </OuterContainer>
  );
};

export default HashTags;

const OuterContainer = styled.div`
  width: 100%;
`;

const InnerContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;
