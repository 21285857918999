import React from 'react';
import styled from 'styled-components';
import Lottie from 'react-lottie-player';
import LottieLoading from 'assets/lottie/LottieLoading.json';

interface Props {
  message?: string;
}

const FullScreenLoader: React.FC<Props> = ({ message }) => {
  return (
    <Background>
      <Container>
        <Lottie
          loop
          animationData={LottieLoading}
          play
          style={{ width: 50, height: 50 }}
        />
        <LoadingMessage>{message}</LoadingMessage>
      </Container>
    </Background>
  );
};

export default FullScreenLoader;

const Background = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  top: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const LoadingMessage = styled.p`
  margin-top: 10px;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #707070;
`;
