/* eslint-disable no-extra-boolean-cast */
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { jobService } from 'services/job.service';
import trackUser from 'services/trackUser';
import { ampEvents } from 'amplitude-events';
import { useStores } from 'stores/RootStore';
import List from 'components/common/list/List';
import ListHeader from 'components/common/list_header/ListHeader';
import PageContainer from 'components/common/PageContainer';
import BottomFixedButton from 'components/common/solid_button/FixedButtons';
import SolidButton from 'components/common/solid_button/SolidButton';
import TopBar from 'components/common/top_bar/TopBar';
import TopInfo from 'components/common/top_info/TopInfo';
import Border from 'components/common/border/Border';
import Modal from 'components/common/modal/Modal';
import Icon from 'components/common/icon/Icon';
import { ScanJobDto } from 'types/job/scan-job/ScanJob.dto';
import { CopyJobDto } from 'types/job/copy-job/CopyJob.dto';
import useStatusOnBackground from 'hooks/useStatusOnBackground';
import usePayment from 'hooks/usePayment';
import { Spacing } from 'styles/styles';
import { PrintJob } from 'stores/job/PrintJob';
import { captureException } from '@sentry/react';

// TODO: PG결제 전, point 차감 후 patch users/:userId

export default observer(function JobPayment() {
  const {
    stateStore,
    cardStore,
    printJobStore,
    userStore,
    jobPollingStore,
    updateJobStore,
  } = useStores();
  const navigation = useNavigate();

  useStatusOnBackground();

  const [cardId, setCardId] = useState<string | null>(null);
  const [printJobs, setPrintJobs] = useState<PrintJob[] | null>(null);
  const [scanCopyJob, setScanCopyJob] = useState<
    ScanJobDto | CopyJobDto | null
  >(null);

  const [onModal, setOnModal] = useState<boolean>(false);
  const [onCancelModal, setOnCancelModal] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const [paymentLoading, setPaymentLoading] = useState(false);

  const {
    getServiceTotalPrice,
    calculateNumPages,
    isNotPrepared,
    isPointlimitExcess,
    cardPayment,
  } = usePayment({
    serviceType: jobPollingStore.serviceType,
    kioskId: jobPollingStore.verifyKioskId,
    jobIds: jobPollingStore.jobIds,
    printJobs,
    scanCopyJob,
    convertStatus: printJobs ? printJobStore.isConverted(printJobs) : null,
    userPoint: userStore.user?.points,
  });

  const handlePayment = () => {
    setPaymentLoading(true);
    (async () => {
      try {
        trackUser(ampEvents.JobPayment_PurchaseSubmit);
        stateStore.startLoading();

        await cardPayment(cardId).then(res => {
          if (res.isSuccess) {
            return jobPollingStore.setOrderId(res.orderId);
          }
          // 결제 실패
          setErrorMsg(res.errorMsg);
          setOnModal(true);
        });
        await userStore.loadUser(); // point 정보 refetch
        setTimeout(() => {
          stateStore.endLoading();
          navigation('/job-working', { replace: true });
        }, 5000);
        setPaymentLoading(false);
      } catch (e) {
        captureException(e);
        setPaymentLoading(false);
      }
    })();
  };

  const handleCancelJob = () => {
    stateStore.startLoading();
    updateJobStore
      .deleteAllJobs(jobPollingStore.serviceType!, jobPollingStore.kioskId!)
      .then(() => {
        navigation('/waiting-list', { replace: true });
        jobPollingStore.clearStore();
      })
      .catch(e => {
        stateStore.setError(e);
      })
      .finally(() => {
        stateStore.endLoading();
      });
  };

  useEffect(() => {
    if (!jobPollingStore.jobIds) {
      return stateStore.setError(true);
    }
    if (jobPollingStore.serviceType === 'prints') {
      setPrintJobs(
        printJobStore.getPrintJobsExistingInThekiosk(jobPollingStore.kioskId!)
      );
      return;
    }
    jobService
      .getOneScanCopyJob(
        jobPollingStore.serviceType!,
        jobPollingStore.jobIds[0]
      )
      .then(res => {
        setScanCopyJob(res.data);
      });
  }, []);

  useEffect(() => {
    cardStore.loadCards().then(() => {
      setCardId(cardStore.priorityCard);
    });
  }, []);

  useEffect(() => {
    userStore.updateReload(false);
  }, []);

  return (
    <PageContainer>
      <TopBar
        title="결제"
        left={jobPollingStore.serviceType === 'prints' ? 'back' : 'exit'}
        onLeftClick={() => {
          if (jobPollingStore.serviceType === 'prints') {
            navigation('/waiting-list', { replace: true });
            jobPollingStore.stopPaymentTime();
          } else {
            // 스캔, 복사
            setOnCancelModal(true);
          }
        }}
      />
      <TopInfo
        title={
          jobPollingStore.serviceType === 'prints'
            ? '인쇄'
            : jobPollingStore.serviceType === 'scans'
            ? '스캔'
            : '복사'
        }
        rightContent={
          <RemainingTime>
            <Icon icon="IconClock" size={16} />
            <Spacing.Horizontal width={4} />
            {`${Math.floor(jobPollingStore.paymentTime / 60).toLocaleString(
              undefined,
              {
                minimumIntegerDigits: 2,
              }
            )}:${
              (jobPollingStore.paymentTime % 60).toLocaleString(undefined, {
                minimumIntegerDigits: 2,
              }) || '00'
            } 후 만료`}
          </RemainingTime>
        }
      />
      {jobPollingStore.serviceType === 'prints' && (
        <Info>
          <Icon icon="IconInfoCircle" themeColor="primary600" />
          <p>결제 후 출력이 시작됩니다</p>
        </Info>
      )}

      <List
        title="페이지 수"
        actionText={
          printJobs !== null
            ? calculateNumPages()
            : scanCopyJob !== null
            ? scanCopyJob.numPages?.toString()
            : '-'
        }
      />
      <List title="결제 금액" actionText={getServiceTotalPrice()} />
      <BorderLine />
      <ListHeader
        title="결제 수단 선택"
        description={`포인트가 남아있는 경우 차감 후 잔여금액 만큼 결제됩니다`}
      />
      <BorderLine />
      <List title="등록된 카드로 결제" />
      {cardStore.cards &&
        cardStore.cards.map(card => (
          <List
            key={card.id}
            title={card.name}
            selectNode={<RadioButton active={card.id === cardId} />}
            right={
              !card.isPriority && (
                <Icon icon="IconStar" themeColor="primary600" />
              )
            }
            onLeftClick={() => setCardId(card.id)}
          />
        ))}
      {cardStore.cards.length < 3 && (
        <SolidButton
          kind="skyblue"
          onClick={() => {
            trackUser(ampEvents.JobPayment_RegisterCard);
            navigation('/cards/register');
          }}
        >
          카드 등록하기
        </SolidButton>
      )}
      <Border size={32} />
      {/* <List
        title="일반 카드 결제 / 카카오페이"
        selectNode={<RadioButton active={'PG' === cardId} />}
        onLeftClick={() => setCardId('PG')}
      /> */}

      <BottomFixedButton>
        <NotPaymentInfo>
          {isPointlimitExcess(cardId)
            ? '잔여 포인트가 부족합니다. 카드를 등록해주세요.'
            : ''}
        </NotPaymentInfo>
        <SolidButton
          disabled={
            isPointlimitExcess(cardId) || isNotPrepared() || paymentLoading
          }
          onClick={handlePayment}
        >
          {paymentLoading ? '결제가 진행 중입니다' : '결제하기'}
        </SolidButton>
      </BottomFixedButton>

      <Modal
        isOpen={jobPollingStore.paymentTime <= 0}
        onRequestClose={() => {
          navigation('/waiting-list', { replace: true });
          jobPollingStore.clearStore();
        }}
        title="인증시간이 만료되었습니다."
        description="다시 시도해주세요!"
        mainActionLabel="확인"
        onMainAction={() => {
          navigation('/waiting-list', { replace: true });
          jobPollingStore.clearStore();
        }}
      />

      <Modal
        isOpen={onModal}
        onRequestClose={() => {
          setOnModal(false);
        }}
        title="결제 실패"
        description={errorMsg ?? ''}
        mainActionLabel="확인"
        onMainAction={() => {
          setOnModal(false);
        }}
      />

      <Modal
        isOpen={onCancelModal}
        onRequestClose={() => {
          setOnCancelModal(false);
        }}
        description="작업을 취소하시겠습니까?"
        mainActionLabel="확인"
        mainActionColor="NEGATIVE"
        onMainAction={() => handleCancelJob()}
        subActionLabel="취소"
        onSubAction={() => setOnCancelModal(false)}
      />
    </PageContainer>
  );
});

const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: -24px;
  width: calc(100% + 48px);
  padding: 17px 24px;
  background: ${({ theme }) => theme.color.primary50};
  p {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #5591ff;
  }
`;
const BorderLine = styled.div`
  width: calc(100% + 48px);
  margin-left: -24px;
  height: 8px;
  background: ${({ theme }) => theme.color.gray100};
`;
const RadioButton = styled.div<{ active: boolean }>`
  width: 20px;
  height: 20px;
  background: ${({ theme }) => theme.color.white};
  border: ${props =>
    props.active
      ? `5px solid ${props.theme.color.primary600}`
      : `1.5px solid ${props.theme.color.gray300}`};
  border-radius: 50%;
  margin-right: 12px;
`;
const RemainingTime = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.gray500};
`;
const NotPaymentInfo = styled.p`
  font-size: 13px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.destructive500};
`;
