import { makeAutoObservable, reaction } from 'mobx';
import { HttpRequestService } from 'services/http-request.service';
import { jobService } from 'services/job.service';
import { UserStore } from '../user/UserStore';
import { PrintJobStatus } from 'types/job/print-job/PrintJobStatus';
import { ScanJobStatus } from 'types/job/scan-job/ScanJobStatus';
import { CopyJobStatus } from 'types/job/copy-job/CopyJobStatus';
import { PrintJobDto } from 'types/job/print-job/PrintJob.dto';
import { ErrorType } from 'types/job/ErrorType.enum';

type ServiceType = 'prints' | 'scans' | 'copies';

// print verify 이후에 currentJobs의 status에 따라 page navigation
export class JobPollingStore {
  serviceType: ServiceType | null = null;
  kioskId: string | null = null;

  verifyKioskId: string | null = null; // verify 후에 받아오는 kioskId임 (*printZoneId랑 다름!!) -> payment dto에 보내야함
  orderId: string | null = null; // payment 후에 받아오는 orderId

  jobIds: string[] | null = null;
  status: PrintJobStatus | ScanJobStatus | CopyJobStatus | null = null;

  pollingTimer: NodeJS.Timer | undefined;
  pollingInterval = (1 / 12) * 60_000;

  // print-job은 인쇄 작업 진행 중시 파일별 status를 표시해줘야 하므로 필드를 추가하게 됨
  pollingPrintJobDtos: PrintJobDto[] | null = null;

  paymentTime: number = 180;
  countDownTimer: NodeJS.Timer | undefined;

  constructor(
    public readonly userStore: UserStore,
    public readonly http: HttpRequestService
  ) {
    makeAutoObservable(this, {});

    reaction(
      () => this.status,
      status => {
        switch (status) {
          case CopyJobStatus.Printing:
            break;
          case ScanJobStatus.WaitingCheckout:
            return this.startPaymentTimer();
          default:
            if (this.serviceType !== 'prints') {
              return this.stopPaymentTime(); // scan, copy job에서 waiting-checkout, Printing상태가 아니면 타이머 중단
            }
            break;
        }
      }
    );
  }

  async verifyKiosk(verifyNum: string, kioskId: string) {
    if (!this.serviceType) {
      throw new Error('not select kiosk service!!');
    }
    await jobService
      .verifyNumber(this.serviceType, verifyNum, kioskId)
      .then(res => {
        this.setVerifyKioskId(res.data.kioskId);

        switch (this.serviceType) {
          case 'prints':
            this.setCurrentJobIds(res.data.printJobIds!);
            break;
          case 'scans':
            this.setCurrentJobIds(res.data.scanJobIds!);
            break;
          case 'copies':
            this.setCurrentJobIds(res.data.copyJobIds!);
            break;
        }
      });
  }

  setVerifyKioskId(kioskId: string) {
    this.verifyKioskId = kioskId;
  }

  setOrderId(orderId: string | null) {
    this.orderId = orderId;
  }

  setCurrentJobIds(jobs: string[] | null) {
    this.jobIds = jobs;
  }

  startPaymentTimer() {
    this.setPaymentTime(180);

    this.countDownTimer = setInterval(() => {
      this.setPaymentTime(this.paymentTime - 1 ?? 0);
    }, 1000);
    const timeout = setTimeout(() => {
      this.stopPaymentTime();
    }, 180_000);
  }

  setPaymentTime(time: number) {
    this.paymentTime = time;
  }

  stopPaymentTime() {
    clearInterval(this.countDownTimer);
    // this.setCurrentStatus(null); // 지우면 안됨 -> 주용: useStatusOnBackground에서 navigation 작동하기전에 null 값이 되어버려서 지움
  }

  // print job polling
  startPrintJobPollingTimer() {
    this.pollingPrintJobs();
    this.setLocalStorage();

    this.pollingTimer = setInterval(() => {
      this.pollingPrintJobs();
    }, this.pollingInterval);
  }

  async pollingPrintJobs() {
    if (!this.jobIds || !this.serviceType) {
      throw Error('not found jobs!!');
    }

    const arr: PrintJobDto[] = [];

    await Promise.all(
      this.jobIds.map(async jobId => {
        return await jobService.getOnePrintJob(jobId).then(res => {
          arr.push(res.data);
        });
      })
    ).catch(() => {
      this.setCurrentStatus(PrintJobStatus.Error);
      this.stopPollingTimer();
    });

    this.setPollingPrintJobs([...arr]); // 파일별 진행상황 lottie를 위해 jobStore에 저장

    const statusSet = new Set(arr.map(arr => arr.status));
    const statusArr = Array.from(statusSet);

    /**
     * print-job이 여러개일 때 각 print-job에 따른 status는 다를 수 있음
     * 그러나, 페이지 이동을 위해 하나의 상태로 정의해봄
     TODO: 이게 최선일까..?
    */

    if (statusArr.includes(PrintJobStatus.Error)) {
      // 한 개의 작업이라도 Error가 뜨면 중단시켜야 하지 않나 싶음
      this.setCurrentStatus(PrintJobStatus.Error);
      this.stopPollingTimer();
    } else if (statusArr.length === 1 && statusArr[0] === PrintJobStatus.Done) {
      // 모든 작업이 Done상태여야 Done상태로 간주
      this.setCurrentStatus(PrintJobStatus.Done);
      this.stopPollingTimer();
    } else if (statusArr.includes(PrintJobStatus.Printing)) {
      this.setCurrentStatus(PrintJobStatus.Printing);
    } else if (statusArr.includes(PrintJobStatus.ConvertPending)) {
      this.setCurrentStatus(PrintJobStatus.ConvertPending);
    } else if (statusArr.includes(PrintJobStatus.CheckoutCompleted)) {
      this.setCurrentStatus(PrintJobStatus.CheckoutCompleted);
    } else if (statusArr.includes(PrintJobStatus.Deleted)) {
      this.setCurrentStatus(PrintJobStatus.Deleted);
      this.stopPollingTimer();
    } else if (statusArr.includes(PrintJobStatus.Expired)) {
      this.setCurrentStatus(PrintJobStatus.Expired);
      this.stopPollingTimer();
    } else if (statusArr.includes(PrintJobStatus.Requested)) {
      this.setCurrentStatus(PrintJobStatus.Requested);
      this.stopPollingTimer();
    }
  }

  // scan, copy job polling
  startScanCopyJobPollingTimer() {
    this.pollingScanCopyJobs();

    this.pollingTimer = setInterval(() => {
      this.pollingScanCopyJobs();
    }, this.pollingInterval);
  }

  async pollingScanCopyJobs() {
    if (!this.jobIds?.length) {
      return this.stopPollingTimer();
    }
    if (!this.jobIds || !this.serviceType) {
      return this.stopPollingTimer();
    }

    this.setLocalStorage();
    await jobService
      .getOneScanCopyJob(this.serviceType ?? '', this.jobIds?.[0] ?? '')
      .then(res => {
        this.setCurrentStatus(res.data.status);
      })
      .then(() => {
        switch (this.serviceType) {
          case 'scans':
            switch (this.status) {
              case ScanJobStatus.Requested:
              case ScanJobStatus.Done:
              case ScanJobStatus.Error:
              case ScanJobStatus.Expired:
              case ScanJobStatus.Canceled:
              case ScanJobStatus.Deleted:
                this.stopPollingTimer();
                break;
            }
            break;
          case 'copies':
            switch (this.status) {
              case CopyJobStatus.Requested:
              case CopyJobStatus.Canceled:
              case CopyJobStatus.Error:
              case CopyJobStatus.Done:
              case CopyJobStatus.Deleted:
                this.stopPollingTimer();
                break;
            }
            break;
        }
      })
      .catch(() => {
        this.stopPollingTimer();
      });
  }

  stopPollingTimer() {
    clearInterval(this.pollingTimer);
    this.clearLocalStorage();
  }

  setPollingPrintJobs(printJobs: PrintJobDto[]) {
    this.pollingPrintJobDtos = printJobs;
  }

  setCurrentStatus(
    status: PrintJobStatus | ScanJobStatus | CopyJobStatus | null
  ) {
    this.status = status;
  }

  setKioskId(kioskId: string | null) {
    this.kioskId = kioskId;
  }

  setService(service: ServiceType | null) {
    this.serviceType = service;
  }

  setLocalStorage() {
    localStorage.setItem('serviceType', this.serviceType!);
    localStorage.setItem('jobIds', JSON.stringify(this.jobIds!));
    localStorage.setItem('orderId', this.orderId!);
  }

  clearLocalStorage() {
    localStorage.removeItem('serviceType');
    localStorage.removeItem('jobIds');
    localStorage.removeItem('orderId');
  }

  async writeReview(stars: number, message: string) {
    if (!this.orderId) {
      throw Error('not found jobs!!');
    }
    await jobService.writeReview(this.orderId, stars, message);
  }

  async reportError(
    jobIds: string[],
    serviceType: string,
    type: ErrorType | undefined,
    message: string | null
  ) {
    if (!this.jobIds) {
      throw Error('not found jobs!!');
    }
    await jobService.reportError(jobIds, serviceType, type, message);
    this.clearStore();
  }

  clearStore() {
    this.stopPollingTimer();
    this.stopPaymentTime();
    this.serviceType = null;
    this.verifyKioskId = null;
    this.orderId = null;
    this.jobIds = null;
    this.status = null;
    this.pollingPrintJobDtos = null;
    this.clearLocalStorage();
  }
}
