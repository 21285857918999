import { Bridge } from 'bridges';

export default class implements Bridge {
  downloadFiles(files: { url: string; name: string }[]) {
    const data = JSON.stringify(files);
    (window as any)?.Android?.downloadFiles(data);
  }

  getVersionName() {
    return (window as any)?.Android?.getVersionName() as string;
  }

  toString() {
    return 'APP';
  }
}
