import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import OptionButton from './EditOptionButton';
import { ReactComponent as IconPlusActive } from 'assets/icons/printSettings/icon_plus_active.svg';
import { ReactComponent as IconPlusInActive } from 'assets/icons/printSettings/icon_plus_inactive.svg';
import { ReactComponent as IconMinusActive } from 'assets/icons/printSettings/icon_minus_active.svg';
import { ReactComponent as IconMinusInActive } from 'assets/icons/printSettings/icon_minus_inactive.svg';
import { ALL_NUMBER_REGEX } from 'constants/Constants';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores/RootStore';

type Props = {
  printJobId: string;
};

const EditCopiesOption: React.FC<Props> = ({ printJobId }) => {
  const { printJobStore } = useStores();
  const [copiesNumber, setCopiesNumber] = useState<number>(1);

  const handleNumCopy = (copies: number) => {
    const printOption =
      printJobStore.getPrintJobByPrintJobId(printJobId)?.ticket;
    if (printOption) {
      printJobStore.getPrintJobByPrintJobId(printJobId)?.editTicket({
        ...printOption,
        copies: copies,
      });
      setCopiesNumber(copies);
    }
  };

  useEffect(() => {
    setCopiesNumber(
      printJobStore.getPrintJobByPrintJobId(printJobId)?.ticket?.copies ?? 1
    );
  }, [printJobId]);

  return (
    <Container>
      <OptionButton
        active={copiesNumber ? copiesNumber > 1 : false}
        onClick={() => {
          if (copiesNumber && copiesNumber > 1 && copiesNumber < 101)
            handleNumCopy(copiesNumber - 1);
        }}
      >
        {copiesNumber && copiesNumber > 1 ? (
          <IconMinusActive />
        ) : (
          <IconMinusInActive />
        )}
      </OptionButton>
      <Input
        inputMode="numeric"
        pattern="[0-9]*"
        type="text"
        size={3}
        value={copiesNumber}
        onChange={e => {
          const { value } = e.target;
          if (value && !ALL_NUMBER_REGEX.test(value)) return;
          const copyCount = Number(value);
          if (copyCount > 100) return;
          handleNumCopy(copyCount);
        }}
        onBlur={() => {
          if (copiesNumber === 0) {
            handleNumCopy(1);
          }
        }}
      />
      <OptionButton
        active={copiesNumber ? copiesNumber < 100 : false}
        onClick={() => {
          if (copiesNumber && copiesNumber > 0 && copiesNumber < 100)
            handleNumCopy(copiesNumber + 1);
        }}
      >
        {copiesNumber && copiesNumber < 100 ? (
          <IconPlusActive />
        ) : (
          <IconPlusInActive />
        )}
      </OptionButton>
    </Container>
  );
};

export default observer(EditCopiesOption);

const Container = styled.div`
  display: flex;
  gap: 16px;
  margin: 16px 0;
`;

const Input = styled.input`
  flex-basis: 200%;
  width: 100%;
  text-align: center;
  border: 1px solid #d2d2d2;
  border-radius: 8px;
  font-size: 14px;
  line-height: 22px;
  color: #1d1d1d;
`;
