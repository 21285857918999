import React from 'react';
import PageContainer from 'components/common/PageContainer';
import TopBar from 'components/common/top_bar/TopBar';
import styled from 'styled-components';
import { AlarmDto } from 'types/user/Alarm.dto';
import List from 'components/common/list/List';
import { getRelativeTimestampString } from 'utils/time.util';
import { AlarmContext } from 'types/user/AlarmContext';
import { useNavigate } from 'react-router-dom';
import { useStores } from 'stores/RootStore';
import { observer } from 'mobx-react-lite';
import useAlarms from 'hooks/useAlarms';
import ErrorPage from 'components/common/ErrorPage';
import FullScreenLoader from 'components/common/FullScreenLoader';
import Icon from 'components/common/icon/Icon';

const Alarms: React.FC = props => {
  const navigation = useNavigate();
  const { userStore } = useStores();
  const [isLoading, error, alarms] = useAlarms({
    userId: userStore.userId,
  });

  const handleAlarm = (alarm: AlarmDto) => {
    // FIXME: 각 alarm별 이동 페이지
    switch (alarm.context) {
      case AlarmContext.Print:
        navigation('/usagehistory');
        return;
      case AlarmContext.Refund:
        navigation('/usagehistory');
        return;
      case AlarmContext.PaymentError:
        navigation('/usagehistory');
        return;
      case AlarmContext.Notice:
        navigation(`/notices/${alarm.detailUid}`);
        return;
      case AlarmContext.ConversionError:
        navigation('/myfiledocuments');
        return;
    }
  };

  if (error) {
    return <ErrorPage />;
  }

  return (
    <PageContainer>
      {isLoading && <FullScreenLoader />}

      <TopBar title="알림" border={true} />

      {!alarms && (
        <EmptyCase>
          <Icon icon="Icon3DAlarm" size={200} />
          <NoAlarmText>알림이 없습니다</NoAlarmText>
        </EmptyCase>
      )}

      {alarms &&
        alarms.map(alarm => (
          <List
            key={alarm.uid}
            onAllClick={() => handleAlarm(alarm)}
            indicator={!alarm.hasRead}
            title={alarm.contents}
            description={`${alarm.context} · ${getRelativeTimestampString(
              alarm.addedAt.toString()
            )}`}
          />
        ))}
    </PageContainer>
  );
};

export default observer(Alarms);

const EmptyCase = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
`;
const NoAlarmText = styled.div`
  font-size: 18px;
  color: #1d1d1d;
`;
