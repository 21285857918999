export class TextUtils {
  static canvas = document.createElement('canvas');

  static getAbbreviatedText(text: string, maxWidth: number) {
    let abbreviatedText = text;
    if (TextUtils.getTextWidth(abbreviatedText) >= maxWidth) {
      while (
        TextUtils.getTextWidth(
          TextUtils.getTextWithAbbreviationInMiddle(abbreviatedText)
        ) >= maxWidth
      ) {
        abbreviatedText = TextUtils.removeLetterFromMiddle(abbreviatedText);
      }
      abbreviatedText =
        TextUtils.getTextWithAbbreviationInMiddle(abbreviatedText);
    }
    return abbreviatedText;
  }

  static removeLetterFromMiddle(text: string) {
    const indexToRemove = text.length / 2;
    return text.slice(0, indexToRemove) + text.slice(indexToRemove + 1);
  }

  static getTextWithAbbreviationInMiddle(text: string) {
    const midIndex = text.length / 2;
    return `${text.slice(0, midIndex + 1)}...${text.slice(midIndex + 1)}`;
  }

  static getTextWidth(text: string, font = 'normal normal 500 16px SUIT') {
    const context = this.canvas.getContext('2d')!;
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  }
}
