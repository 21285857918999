import { makeAutoObservable } from 'mobx';
import { CardDto } from 'types/card/Card.dto';

export class Card {
  id: string;
  name: string;
  isPriority: boolean;
  rejectionMessage: string | null;

  constructor(cardDto: CardDto) {
    makeAutoObservable(this, {});

    this.id = cardDto.id;
    this.name = cardDto.name;
    this.isPriority = cardDto.isPriority;
    this.rejectionMessage = cardDto.rejectionMessage;
  }
}
