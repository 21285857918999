import React from 'react';
import styled, { css } from 'styled-components';
import {
  Img3DCopy,
  Img3DDocumentBox,
  Img3DMapPin,
  Img3DPrinter,
  Img3DScan,
  Img3DUploadFile,
} from 'assets/images';

interface LargeButtonProp extends React.HTMLProps<HTMLButtonElement> {
  label?: string;
  icon?: 'print' | 'file' | 'upload' | 'map' | 'scan' | 'copy';
  kind?: 'blue' | 'gray' | 'skyblue';
}

const LargeButton: React.FC<LargeButtonProp> = ({
  label,
  icon,
  kind,
  onClick,
}) => {
  return (
    <Button onClick={onClick} kind={kind}>
      <Label kind={kind}>{label}</Label>
      <Icon3D>
        {icon === 'print' && <ImageIcon src={Img3DPrinter} />}
        {icon === 'file' && <ImageIcon src={Img3DDocumentBox} />}
        {icon === 'upload' && <ImageIcon src={Img3DUploadFile} />}
        {icon === 'map' && <ImageIcon src={Img3DMapPin} />}
        {icon === 'scan' && <ImageIcon src={Img3DScan} />}
        {icon === 'copy' && <ImageIcon src={Img3DCopy} />}
      </Icon3D>
    </Button>
  );
};

LargeButton.defaultProps = {
  kind: 'gray',
};

export default LargeButton;

const Button = styled.button<LargeButtonProp>`
  position: relative;

  width: 100%;
  height: 141px;

  border-radius: 16px;
  outline: 0;
  border-width: 0;
  cursor: pointer;

  background: #fafafa;
  ${props => {
    if (props.kind === 'blue')
      return css`
        background: #4483f7;
      `;
    if (props.kind === 'skyblue')
      return css`
        background: #e6f2fe;
      `;
  }}
`;

const Label = styled.p<LargeButtonProp>`
  position: absolute;
  left: 24px;
  top: 24px;

  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #404040;
  ${props => {
    if (props.kind === 'blue')
      return css`
        color: #ffffff;
      `;
  }}
`;

const Icon3D = styled.div`
  position: absolute;
  right: 12px;
  bottom: 12px;
`;

const ImageIcon = styled.img`
  width: 80px;
  height: 80px;
  background-position: center;
`;
