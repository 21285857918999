import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Spacing } from 'styles/styles';
import ContextMenu from 'components/common/context_menu/ContextMenu';
import SolidButton from 'components/common/solid_button/SolidButton';
import Icon from 'components/common/icon/Icon';
import OptionButton from './EditOptionButton';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores/RootStore';
import Lottie from 'react-lottie-player';
import Spinner from 'assets/lottie/LottieSpinner.json';
import { PageRange } from 'types/job/print-job/print-ticket/PageRange';

type Props = {
  printJobId: string;
};

const EditPageRangeContextMenu: React.FC<Props> = ({ printJobId }) => {
  const { printJobStore } = useStores();

  const [pageRangeInvalid, setPageRangeInvalid] = useState(false);
  const [pageRange, setPageRange] = useState<PageRange[]>();
  const [totalMode, setTotalMode] = useState(false);

  const [openPageRangeContextMenu, setOpenPageRangeContextMenu] =
    useState(false);

  const handleRange = (option: { start: number; end: number }[]) => {
    const printOption =
      printJobStore.getPrintJobByPrintJobId(printJobId)?.ticket;
    if (printOption) {
      printJobStore.getPrintJobByPrintJobId(printJobId)?.editTicket({
        ...printOption,
        pageRanges: option,
      });
      setPageRange(option);
    }
  };

  // 초기값 or ticket 값
  useEffect(() => {
    if (
      printJobStore.getPrintJobByPrintJobId(printJobId)?.file.numPages !== null
    ) {
      const option = [];
      option[0] = {
        start: 1,
        end: printJobStore.getPrintJobByPrintJobId(printJobId)?.file.numPages,
      };
      // setPageRange(option);
    }
    if (
      printJobStore.getPrintJobByPrintJobId(printJobId)?.ticket?.pageRanges[0]
    ) {
      setPageRange(
        printJobStore.getPrintJobByPrintJobId(printJobId)?.ticket?.pageRanges
      );
    }
  }, [printJobId]);

  return (
    <>
      <Container onClick={() => setOpenPageRangeContextMenu(true)}>
        {pageRange?.length && pageRange.length > 0 ? (
          <Label>{`${pageRange[0].start} ~ ${pageRange[0].end}`}</Label>
        ) : (
          <Label>전체페이지</Label>
        )}

        <Icon icon="IconArrowRight" />
      </Container>

      <ContextMenu
        isOpen={openPageRangeContextMenu}
        onRequestClose={() => {
          setOpenPageRangeContextMenu(!openPageRangeContextMenu);
        }}
        title=""
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <SettingLabel>범위 지정</SettingLabel>
          {printJobStore.printJobs.find(pj => pj.id === printJobId)?.file
            .isConverting == true && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Lottie
                loop
                animationData={Spinner}
                play
                style={{
                  width: 12,
                  height: 12,
                  marginTop: '-12px',
                  marginRight: '4px',
                  overflow: 'visible',
                }}
              />
              <Converting>파일을 변환하는 중입니다</Converting>
            </div>
          )}
        </div>
        <SettingOptions>
          <OptionButton
            active={
              !printJobStore.getPrintJobByPrintJobId(printJobId)?.ticket
                ?.pageRanges[0]
            }
            onClick={() => {
              setTotalMode(true);
              setPageRangeInvalid(true);
              handleRange([]);
            }}
          >
            전체 인쇄
          </OptionButton>
          <OptionButton
            active={
              !!printJobStore.getPrintJobByPrintJobId(printJobId)?.ticket
                ?.pageRanges[0]
            }
            onClick={() => {
              // 파일 변환 중일 경우, 부분 인쇄 불가
              if (
                printJobStore.printJobs.find(pj => pj.id === printJobId)?.file
                  .isConverting == true
              ) {
                return;
              }
              if (
                printJobStore.getPrintJobByPrintJobId(printJobId)?.file.numPages
              ) {
                setTotalMode(false);
                setPageRangeInvalid(true);
                handleRange([
                  {
                    start: 1,
                    end: printJobStore.getPrintJobByPrintJobId(printJobId)?.file
                      .numPages!,
                  },
                ]);
              }
            }}
          >
            부분 인쇄
          </OptionButton>
        </SettingOptions>

        <Spacing.Vertical height={45} />
        <RangeModalPageLabel active>페이지</RangeModalPageLabel>
        <RangeRow>
          <RangeInput
            inputMode="numeric"
            type="text"
            disabled={
              !printJobStore.getPrintJobByPrintJobId(printJobId)?.file.numPages
            }
            value={pageRange?.[0]?.start! ?? 1}
            onChange={e => {
              if (e.target.value.match(/(^[0-9]{0,10}$)/) == null) return;

              const limit =
                printJobStore.getPrintJobByPrintJobId(printJobId)?.file
                  .numPages!;
              const start =
                Number(e.target.value) > limit ? limit : Number(e.target.value);
              const end = pageRange ? pageRange[0].end : limit;

              setPageRangeInvalid(start >= end ? false : true);
              handleRange([
                {
                  start,
                  end,
                },
              ]);
            }}
            onBlur={() => {
              const start = pageRange && pageRange[0].start;
              const end = pageRange && pageRange[0].end;
              if (Number(start) < 1 && end) {
                handleRange([{ start: 1, end }]);
              }
              if (end && Number(start) > end) {
                handleRange([{ start: 1, end }]);
              }
              if (pageRangeInvalid && end) {
                handleRange([{ start: 1, end }]);
              }
            }}
          />
          <RangeTilda>~</RangeTilda>
          <RangeInput
            inputMode="numeric"
            type="text"
            disabled={
              !printJobStore.getPrintJobByPrintJobId(printJobId)?.file
                .numPages || totalMode
            }
            value={
              pageRange?.[0]?.end! ??
              printJobStore.getPrintJobByPrintJobId(printJobId)?.file.numPages
            }
            onChange={e => {
              if (e.target.value.match(/(^[0-9]{0,10}$)/) == null) return;

              const limit =
                printJobStore.getPrintJobByPrintJobId(printJobId)?.file
                  .numPages;
              const start = pageRange && pageRange[0]?.start!;
              if (limit && start) {
                const end =
                  Number(e.target.value) > limit
                    ? limit
                    : Number(e.target.value);

                setPageRangeInvalid(start > end ? false : true);
                handleRange([
                  {
                    start,
                    end,
                  },
                ]);
              }
            }}
            onBlur={() => {
              const start = pageRange && pageRange[0]?.start!;
              const end = pageRange && pageRange[0]?.end;
              const limit =
                printJobStore.getPrintJobByPrintJobId(printJobId)?.file
                  .numPages;
              if (limit && start) {
                if (Number(end) < Number(start) || Number(end) > limit) {
                  handleRange([{ start, end: limit }]);
                }
              }
            }}
          />
        </RangeRow>
        <SolidButton
          onClick={() => {
            setOpenPageRangeContextMenu(false);
          }}
        >
          확인
        </SolidButton>
      </ContextMenu>
    </>
  );
};
export default observer(EditPageRangeContextMenu);

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 16px 0;
  padding: 8px 16px;
  height: 56px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
`;

const Label = styled.div`
  padding-left: 10%;
  width: 90%;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #515151;
`;

const SettingLabel = styled.p`
  margin: 20px 0 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.05em;
  color: #646464;
`;
const SettingOptions = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 12px;
`;

const RangeModalPageLabel = styled.p<{ active: boolean }>`
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.04em;
  color: ${({ active }) => (active ? '#525252' : '#b6b6b6')};
`;

const RangeRow = styled.div`
  padding: 15px 10px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const RangeInput = styled.input`
  width: 110px;
  height: 50px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #3e3e3e;
  &:disabled {
    border: 1px solid #f5f5f5;
    color: #b6b6b6;
    -webkit-text-fill-color: #b6b6b6;
    opacity: 1;
  }
`;

const RangeTilda = styled.p`
  flex: 1;
  margin: 0 auto;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #b6b6b6;
`;

const Converting = styled.p`
  font-size: 12px;
  line-height: 18px;
  color: #707070;
`;
