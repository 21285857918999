import { httpRequestService } from './http-request.service';
import { Store } from 'stores/RootStore';
import { PrintZoneLocationDto } from 'types/kiosk/PrintZoneLocation.dto';
import { PrintZoneDto } from 'types/kiosk/PrintZone.dto';
import { PrintZoneLineDto } from 'types/kiosk/PrintZoneLine.dto';
import { sortKiosksByDistance } from 'utils/utils';

export class KioskService {
  constructor() {}

  async getRecentlyUsedKiosks(serviceType: string | null) {
    if (!serviceType) {
      throw Error('not select service!!');
    }

    return await httpRequestService
      .create(true)
      .get<PrintZoneLineDto[]>(
        `users/${Store.userStore.userId}/print-zones/latest`
      );
  }

  /**
   *
   * @param boba true: 보바만 불러오기 / false: 보바 제외하고 불러오기 / 누락: 둘 다 불러오기
   * @param printer true: PrintZone에 해당하는 기능이 하나라도 있으면 불러오기 (셋 중에 하나라도 해당되면) / false: 안 불러오기 /	누락: false 와 같음
   * @param scanner 위와 동일함
   * @param copier  위와 동일함
   * @returns
   */
  async getKiosks(
    boba: boolean | undefined,
    printer: boolean | undefined,
    scanner: boolean | undefined,
    copier: boolean | undefined,
    searchWord?: string
  ) {
    const params = {
      boba: boba,
      printer: printer,
      scanner: scanner,
      copier: copier,
      q: searchWord ?? '',
    };
    return await httpRequestService
      .create()
      .get<PrintZoneLineDto[]>('print-zones', { params })
      .then(res => {
        return sortKiosksByDistance(res.data);
      });
  }

  // 위의 getKiosks()와 params 규칙이 동일함
  async getKiosksLocation(
    boba: boolean | undefined,
    printer: boolean | undefined,
    scanner: boolean | undefined,
    copier: boolean | undefined
  ) {
    const params = {
      boba: boba,
      printer: printer,
      scanner: scanner,
      copier: copier,
    };
    return await httpRequestService
      .create()
      .get<PrintZoneLocationDto[]>('print-zones/locations', { params });
  }

  async getKiosksAroundUser() {
    const userStore = Store.userStore;
    const params = {
      lat: userStore.currentLocation?.lat,
      long: userStore.currentLocation?.lng,
      km: 2,
    };
    return await httpRequestService
      .create(userStore.userId ? true : false)
      .get<PrintZoneLineDto[]>('print-zones/nearest', { params });
  }

  async getKioskDetails(id: string) {
    return await httpRequestService
      .create()
      .get<PrintZoneDto>(`print-zones/${id}`);
  }
}

export const kioskService = new KioskService();
