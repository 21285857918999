import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from 'stores/RootStore';
import trackUser from 'services/trackUser';
import { ampEvents } from 'amplitude-events';
import LargeButton from 'components/common/large_button/LargeButton';
import TopInfo from 'components/common/top_info/TopInfo';
import SolidButton from 'components/common/solid_button/SolidButton';
import ImageList from 'components/common/ImageList';
import PageContainer from 'components/common/PageContainer';
import BottomNavbar from 'components/common/BottomNavbar';
import ContextMenu from 'components/common/context_menu/ContextMenu';
import Modal from 'components/common/modal/Modal';
import { Spacing } from 'styles/styles';
import { getFileLabel } from 'utils/file.util';
import { getExtension, removeExtension } from 'utils/file.util';
import AlarmButton from 'components/pages/print/AlarmButton';
import Icon from 'components/common/icon/Icon';
import useFileDownload from 'hooks/useFileDownload';
import useHistoryBack from 'hooks/useHistoryBack';

const Print: React.FC = props => {
  const navigation = useNavigate();
  const {
    userStore,
    printJobStore,
    fileStore,
    printJobBuilderStore,
    kioskStore,
    updateJobStore,
    scanCopyJobBuilderStore,
    jobPollingStore,
  } = useStores();
  const [loginModal, setLoginModal] = useState(false);
  let idx = 0;
  const [openSortTypeSelector, setOpenSortTypeSelector] =
    useState<boolean>(false);
  const [fileUidToRename, setFileUidToRename] = useState('');
  const [deleteErrorModal, setDeleteErrorModal] = useState<boolean>(false);
  const [withPrintJobDeleteModal, setWithPrintJobDeleteModal] = useState(false);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const { downloadFiles } = useFileDownload(userStore.userId);

  /**
   * 한개 파일 삭제
   * 해당 파일에 대한 printjob을 모두 삭제한 후, file delete
   */
  const handleOneFileDelete = () => {
    setWithPrintJobDeleteModal(false);
    setDeleteConfirmModal(false);
    const fileId = fileStore.optionModeFile?.fileId;
    fileStore.clearOptionModeFile();
    if (fileId) {
      printJobStore.printJobs
        .filter(pj => pj.file.id === fileId)
        .map(pj => {
          printJobStore.deletePrintJob(pj.id);
        });
      fileStore
        .deleteFile(fileId)
        .then(() => fileStore.setOptionMode(false))
        .catch(() => {
          setDeleteErrorModal(true);
        })
        .finally(() => fileStore.loadFiles());
    }
  };

  const navigationAfterScan = () => {
    if (jobPollingStore != null) {
      if (jobPollingStore.serviceType == 'scans') {
        navigation('/myfiledocuments');
      }
      jobPollingStore.clearStore();
    }
  };

  useEffect(() => {
    navigationAfterScan();
    if (userStore.user) {
      fileStore
        .loadFiles()
        .then(async () => {
          await updateJobStore.getAllJobs();
        })
        .catch(() => {
          userStore.showErrorModal();
        });
    }
    kioskStore.getKiosks();
    printJobBuilderStore.clear();
    scanCopyJobBuilderStore.clear();
    updateJobStore.clear();
  }, []);

  useHistoryBack(() => {
    navigation('/', { replace: true });
  });

  return (
    <>
      <PageContainer>
        <AlarmButton type="Reserved" />
        <AlarmButton type="ErrorFile" />

        <TopInfo title="프린트" />

        {/* 프린트하러 가기 */}
        <LargeButton
          label="프린트하러 가기"
          icon="print"
          kind="blue"
          onClick={() => {
            trackUser(ampEvents.Print_GoToPrint);
            if (!userStore.user) {
              setLoginModal(true);
              return;
            }
            updateJobStore.setBuilderService('prints');
            navigation('/printWizard1');
          }}
        />
        {/* TODO: scan, copy */}
        <Spacing.Vertical height={16} />
        <Buttons>
          <LargeButton
            label="스캔"
            icon="scan"
            kind="skyblue"
            onClick={() => {
              if (!userStore.user) {
                setLoginModal(true);
                return;
              }
              updateJobStore.setBuilderService('scans');
              navigation('/select-kiosk');
            }}
          />
          <LargeButton
            label="복사"
            icon="copy"
            kind="skyblue"
            onClick={() => {
              if (!userStore.user) {
                setLoginModal(true);
                return;
              }
              updateJobStore.setBuilderService('copies');
              navigation('/select-kiosk');
            }}
          />
        </Buttons>
        <Spacing.Vertical height={64} />

        {/* 문서함가기 */}
        <LargeButton
          label="문서함"
          icon="file"
          onClick={() => {
            trackUser(ampEvents.Print_DocumentBox);
            if (!userStore.user) {
              setLoginModal(true);
              return;
            }
            navigation('/myfiledocuments');
          }}
        />
        <Spacing.Vertical height={40} />

        {/* 최근 문서 보기 */}
        {userStore.user && (
          <>
            <TopInfo
              title="최근 문서"
              rightContent={
                fileStore.selectMode && (
                  <DownLoadButton
                    onClick={() => {
                      trackUser(ampEvents.Print_DownloadRecentDocument);
                      downloadFiles(fileStore.selectedFile)();
                      fileStore.setSelectMode(false);
                    }}
                  >
                    <Icon icon="IconDocumentDownload" size={16} />
                    다운로드
                  </DownLoadButton>
                )
              }
            />

            <Spacing.Vertical height={20} />
          </>
        )}

        <ImageListContainer>
          {fileStore.files
            .filter(file => file.origin === 'My')
            .sort(
              (a, b) =>
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
            )
            .filter(file => {
              idx = idx + 1;
              return idx < 5;
            })
            .map(file => (
              <ImageList
                key={file.id}
                onClick={() => {
                  trackUser(ampEvents.Print_RecentDocument);

                  if (file.errorType) {
                    return;
                  }
                  {
                    !fileStore.selectMode &&
                      fileStore.setSelectMode(!fileStore.selectMode);
                  }
                  fileStore.updateSelectedFile(file.id);
                }}
                active={fileStore.selectedFile.includes(file.id)}
                disabled={file.errorType !== null}
                title={file.name}
                subTitle={getFileLabel(file, 'image')}
                imageUrl={file.imageUrls && file.imageUrls[0]}
                onOptionClick={e => {
                  e.stopPropagation();
                  if (!fileStore.selectMode) {
                    fileStore.setOptionModeFile(
                      file.id,
                      file.name,
                      file.origin,
                      file.errorType
                    );
                    fileStore.setOptionMode(true);
                  }
                }}
              />
            ))}
        </ImageListContainer>

        {/* Bottom -- print button */}
        {fileStore.selectMode && (
          <>
            <Spacing.Vertical height={100} />
            <PrintButton>
              <Fade />
              <SolidButton
                onClick={() => {
                  const files = fileStore.selectedFile.map(fileId =>
                    fileStore.files.filter(file => file.id === fileId)
                  );
                  const file = files.map(file => file[0]);
                  printJobBuilderStore.createBuilders(file);
                  updateJobStore.setBuilderService('prints');
                  navigation('/printwizard2');
                  trackUser(ampEvents.Print_PrintRecentDocument);
                }}
              >{`${fileStore.selectedFile.length}건 인쇄하기`}</SolidButton>
            </PrintButton>
          </>
        )}

        <Spacing.Vertical height={20} />

        {/* Modal - login request */}
        <Modal
          isOpen={loginModal}
          onRequestClose={() => setLoginModal(false)}
          description="로그인이 필요한 서비스입니다"
          mainActionLabel="로그인하기"
          mainActionColor="POSITIVE"
          onMainAction={() => navigation('/boba')}
          subActionLabel="취소"
          onSubAction={() => setLoginModal(false)}
        />

        {/* File command selector */}
        <ContextMenu
          isOpen={fileStore.optionMode}
          onRequestClose={() => {
            fileStore.setOptionMode(false);
            fileStore.clearOptionModeFile();
          }}
          title={fileStore.optionModeFile?.fileName ?? ''}
        >
          <ul>
            {fileStore.optionModeFile?.errorType === null && (
              <>
                <CommandItem
                  onClick={() => {
                    const file = fileStore.files.filter(
                      f => f.id === fileStore.optionModeFile?.fileId
                    );
                    printJobBuilderStore.createBuilders(file);
                    fileStore.updateSelectedFile(
                      fileStore.optionModeFile?.fileId!
                    );
                    fileStore.setOptionMode(false);
                    fileStore.clearOptionModeFile();
                    navigation('/printwizard2');
                    trackUser(ampEvents.Print_PrintRecentDocument);
                  }}
                >
                  <div
                    style={{
                      marginLeft: -10,
                      width: 40,
                      height: 40,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Icon icon="IconPrinter" size={18} />
                  </div>
                  <p>인쇄하기</p>
                </CommandItem>

                <CommandItem
                  onClick={() => {
                    downloadFiles([fileStore.optionModeFile?.fileId!])();
                    fileStore.setOptionMode(false);
                    fileStore.clearOptionModeFile();
                  }}
                >
                  <div
                    style={{
                      marginLeft: -10,
                      width: 40,
                      height: 40,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Icon icon="IconDownload" size={18} />
                  </div>
                  <p>다운로드</p>
                </CommandItem>
                {!fileStore.files.find(
                  file => file.id === fileStore.optionModeFile?.fileId
                )?.isConverting && (
                  <CommandItem
                    onClick={() => {
                      navigation(
                        `/files/${fileStore.optionModeFile?.fileId}/preview`
                      );
                      setOpenSortTypeSelector(false);
                      fileStore.setOptionMode(false);

                      fileStore.clearOptionModeFile();
                    }}
                  >
                    <div
                      style={{
                        marginLeft: -10,
                        width: 40,
                        height: 40,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Icon icon="IconVisual" />
                    </div>
                    <p>미리보기</p>
                  </CommandItem>
                )}
              </>
            )}

            {/* MyFile만 이름 변경 가능 */}
            {fileStore.optionModeFile?.fileOrigin === 'My' &&
              fileStore.optionModeFile?.errorType === null && (
                <CommandItem
                  onClick={() => {
                    setFileUidToRename(fileStore.optionModeFile?.fileId ?? '');
                    fileStore.clearOptionModeFile();
                    fileStore.setOptionMode(false);
                  }}
                >
                  <div
                    style={{
                      marginLeft: -10,
                      width: 40,
                      height: 40,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Icon icon="IconEdit" />
                  </div>
                  <p>이름 변경</p>
                </CommandItem>
              )}

            <CommandItem
              onClick={() => {
                fileStore.setOptionMode(false);
                const fileId = fileStore.optionModeFile?.fileId;
                if (fileId) {
                  if (
                    printJobStore.printJobs.find(pj => pj.file.id === fileId)
                  ) {
                    setWithPrintJobDeleteModal(true);
                    return;
                  }
                }
                setDeleteConfirmModal(true);
              }}
            >
              <div
                style={{
                  marginLeft: -10,
                  width: 40,
                  height: 40,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Icon icon="IconTrashBin" themeColor="destructive600" />
              </div>
              <p style={{ color: '#DD3D2A' }}>삭제</p>
            </CommandItem>
          </ul>
        </ContextMenu>

        {/* Modal: Rename file */}
        <Modal
          isOpen={!!fileUidToRename}
          onRequestClose={() => {
            setFileUidToRename('');
            fileStore.clearOptionModeFile();
          }}
          description="변경할 파일 이름을 입력 하세요"
          mainActionLabel="이름 바꾸기"
          mainActionColor="POSITIVE"
          onMainAction={() => {}}
          subActionLabel="취소"
          onSubAction={() => {
            setFileUidToRename('');
            fileStore.clearOptionModeFile();
          }}
          originalValue={removeExtension(
            (function () {
              return fileStore.files.find(f => f.id === fileUidToRename)?.name;
            })()
          )}
          onSubmit={filename => {
            if (!filename) return;
            const currentFileViewName = fileStore.files.find(
              f => f.id === fileUidToRename
            )?.name;

            const newName = filename + getExtension(currentFileViewName);
            fileStore.files
              .find(f => f.id === fileUidToRename)
              ?.rename(newName)
              .catch(() => {
                userStore.showErrorModal();
              })
              .finally(() => {
                setFileUidToRename('');
                fileStore.clearOptionModeFile();
              });
          }}
        />

        {/* Modal: Error when delete file */}
        <Modal
          isOpen={deleteErrorModal}
          onRequestClose={() => setDeleteErrorModal(false)}
          description={`파일삭제에 실패하였습니다`}
          mainActionLabel="확인"
          onMainAction={() => setDeleteErrorModal(false)}
        />
        {/* Modal: 인쇄 대기 중인 문서 삭제 시도시 */}
        <Modal
          isOpen={withPrintJobDeleteModal}
          onRequestClose={() => setWithPrintJobDeleteModal(false)}
          title="파일 삭제"
          description={`인쇄 대기중인 문서입니다.\n인쇄를 취소하고 삭제하시겠습니까?`}
          mainActionLabel="삭제"
          mainActionColor="NEGATIVE"
          onMainAction={handleOneFileDelete}
          subActionLabel="취소"
          onSubAction={() => setWithPrintJobDeleteModal(false)}
        />
        {/* Modal: 파일 삭제 여부 한번 더 묻기 */}
        <Modal
          isOpen={deleteConfirmModal}
          onRequestClose={() => setDeleteConfirmModal(false)}
          title="파일 삭제"
          description={`정말 삭제하시겠습니까?`}
          mainActionLabel="삭제"
          mainActionColor="NEGATIVE"
          onMainAction={handleOneFileDelete}
          subActionLabel="취소"
          onSubAction={() => setDeleteConfirmModal(false)}
        />
        <Spacing.Vertical height={40} />
      </PageContainer>
      <BottomNavbar />
    </>
  );
};

export default observer(Print);

const DownLoadButton = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  height: 38px;
  border: 1px solid ${({ theme }) => theme.color.gray200};
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: ${({ theme }) => theme.color.gray500};
`;
const ImageListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  width: 100%;
`;
const Fade = styled.div`
  position: fixed;
  left: 0;
  bottom: 80px;
  display: flex;
  justify-content: center;
  z-index: 2;
  width: 100%;
  height: 44px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
`;
const PrintButton = styled.div`
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 70px;
  width: 100%;
  max-width: 780px;
  padding: 0 30px 24px 30px;
  background: ${({ theme }) => theme.color.white};
  border-width: 0;
  border-color: transparent;
  z-index: 10;
`;
const CommandItem = styled.li`
  height: 50px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.05em;
  color: ${({ theme }) => theme.color.gray600};
  display: flex;
  align-items: center;
`;
const Buttons = styled.div`
  display: flex;
  gap: 16px;
`;
