import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import OptionButton from './EditOptionButton';
import IconMonoOnly from 'assets/icons/printSettings/icon_mono_only.png';
import IconColorOnly from 'assets/icons/printSettings/icon_color_only.png';
import { useStores } from 'stores/RootStore';
import { ImgOptionColor, ImgOptionMono } from 'assets/images';

type Props = {
  printJobId: string | undefined;
};

const EditIsColorOption: React.FC<Props> = ({ printJobId }) => {
  const { printJobStore } = useStores();

  const [kioskIsMono, setKioskIsMono] = useState<boolean>();
  const [kioskIsColor, setKioskIsColor] = useState<boolean>();

  const [isColor, setIsColor] = useState(false);

  const handleOption = (option: boolean) => {
    const printOption = printJobStore.printJobs.find(
      pj => pj.id === printJobId
    )?.ticket;
    if (printOption) {
      printJobStore.printJobs
        .find(pj => pj.id === printJobId)
        ?.editTicket({
          ...printOption,
          isColor: option,
        });
      setIsColor(option);
    }
  };

  /**
   */
  useEffect(() => {
    setKioskIsMono(
      printJobStore.printJobs.find(pj => pj.id === printJobId)?.kiosk.mono !==
        null
    );

    setKioskIsColor(
      printJobStore.printJobs.find(pj => pj.id === printJobId)?.kiosk.color !==
        null
    );

    setIsColor(
      printJobStore.printJobs.find(pj => pj.id === printJobId)?.ticket.isColor!
    );
  }, [printJobId, kioskIsColor, kioskIsMono]);

  return (
    <Container>
      {/* 흑백 + 컬러 */}
      {kioskIsMono && kioskIsColor && (
        <>
          <OptionButton
            onClick={() => handleOption(true)}
            active={isColor}
            label="컬러"
          >
            <ColorOption src={ImgOptionColor} />
          </OptionButton>
          <OptionButton
            onClick={() => handleOption(false)}
            active={!isColor}
            label="흑백"
          >
            <ColorOption src={ImgOptionMono} />
          </OptionButton>
        </>
      )}

      {/* 흑백전용 */}
      {kioskIsMono && !kioskIsColor && (
        <>
          <OptionButton active={true}>
            <img
              src={IconMonoOnly}
              style={{
                width: '181px',
                height: '40px',
                backgroundPosition: 'center',
              }}
            ></img>
          </OptionButton>
        </>
      )}

      {/* 컬러 전용 */}
      {!kioskIsMono && kioskIsColor && (
        <>
          <OptionButton active={true}>
            <img
              src={IconColorOnly}
              style={{
                width: '181px',
                height: '40px',
                backgroundPosition: 'center',
              }}
            ></img>
          </OptionButton>
        </>
      )}
    </Container>
  );
};
export default EditIsColorOption;

const Container = styled.div`
  gap: 16px;
  padding: 16px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ColorOption = styled.img`
  width: 40px;
  height: 40px;
  background-position: center;
`;
