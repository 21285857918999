import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import OptionButton from './OptionButton';
import { ReactComponent as IconOneSidedActive } from 'assets/icons/printSettings/icon_one_sided_active.svg';
import { ReactComponent as IconOneSidedInActive } from 'assets/icons/printSettings/icon_one_sided_inactive.svg';
import { ReactComponent as IconTwoSideLongActive } from 'assets/icons/printSettings/icon_two_side_long_active.svg';
import { ReactComponent as IconTwoSideLongInActive } from 'assets/icons/printSettings/icon_two_side_long_inactive.svg';
import { ReactComponent as IconTwoSideShortActive } from 'assets/icons/printSettings/icon_two_side_short_active.svg';
import { ReactComponent as IconTwoSideShortInActive } from 'assets/icons/printSettings/icon_two_side_short_inactive.svg';
import { observer } from 'mobx-react-lite';
import { Duplex } from 'types/job/print-job/print-ticket/Duplex';
import { useStores } from 'stores/RootStore';

type Props = {
  fileId: string;
};

const DuplexOption: React.FC<Props> = ({ fileId }) => {
  const { printJobBuilderStore } = useStores();
  const [option, setOption] = useState<Duplex>();

  const handleOption = (option: Duplex) => {
    const printOption =
      printJobBuilderStore.getPrintJobByFileId(fileId)?.ticket;
    if (printOption) {
      printJobBuilderStore.getPrintJobByFileId(fileId)?.setTicket({
        ...printOption,
        duplex: option,
      });
      setOption(option);
    }
  };

  useEffect(() => {
    setOption(
      printJobBuilderStore.getPrintJobByFileId(fileId)?.ticket?.duplex ??
        Duplex.ONE_SIDED
    );
  }, [fileId]);

  return (
    <Container>
      <OptionButton
        onClick={() => handleOption(Duplex.ONE_SIDED)}
        active={option === Duplex.ONE_SIDED}
        label="단면"
      >
        {option === Duplex.ONE_SIDED && <IconOneSidedActive />}
        {option !== Duplex.ONE_SIDED && <IconOneSidedInActive />}
      </OptionButton>
      <OptionButton
        onClick={() => handleOption(Duplex.TWO_SIDE_SHORT)}
        active={option === Duplex.TWO_SIDE_SHORT}
        label={`양면\n짧은면으로 넘기기`}
      >
        {option === Duplex.TWO_SIDE_SHORT && <IconTwoSideShortActive />}
        {option !== Duplex.TWO_SIDE_SHORT && <IconTwoSideShortInActive />}
      </OptionButton>
      <OptionButton
        onClick={() => handleOption(Duplex.TWO_SIDE_LONG)}
        active={option === Duplex.TWO_SIDE_LONG}
        label={`양면\n긴면으로 넘기기`}
      >
        {option === Duplex.TWO_SIDE_LONG && <IconTwoSideLongActive />}
        {option !== Duplex.TWO_SIDE_LONG && <IconTwoSideLongInActive />}
      </OptionButton>
    </Container>
  );
};

export default observer(DuplexOption);

const Container = styled.div`
  gap: 16px;
  padding: 16px 0;
  width: 100%;
  display: flex;
  flex-basis: 33%;
  justify-content: space-between;
`;
