import React from 'react';
import styled from 'styled-components';
import PageContainer from 'components/common/PageContainer';
import { Spacing } from 'styles/styles';
import { useNavigate } from 'react-router-dom';
import TopBar from 'components/common/top_bar/TopBar';
import ListHeader from 'components/common/list_header/ListHeader';
import List from 'components/common/list/List';
import Icon from 'components/common/icon/Icon';
import { ampEvents } from 'amplitude-events';
import trackUser from 'services/trackUser';

const CustomerService: React.FC = props => {
  const navigation = useNavigate();

  return (
    <PageContainer>
      <TopBar left="back" title="고객지원" />
      <ListHeader title="고객센터" />
      <ContactButtonContainer>
        <ContactButton
          href="tel:0269592677"
          onClick={() => trackUser(ampEvents.CustomerService_Call)}
        >
          <Icon icon="IconPhone" size={40} />
          <Spacing.Horizontal width={20} />
          <Text>
            <HelpTitle>전화 문의</HelpTitle>
            <HelpLabel>평일 10:00 ~ 19:00</HelpLabel>
          </Text>
        </ContactButton>
        <Spacing.Vertical height={16} />
        <ContactButton
          href="https://pf.kakao.com/_BPfWT"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => trackUser(ampEvents.CustomerService_Kakao)}
        >
          <Icon icon="IconKakao" size={40} />
          <Spacing.Horizontal width={20} />
          <Text>
            <HelpTitle>카톡 문의</HelpTitle>
            <HelpLabel>평일/주말 10:00 ~ 19:00</HelpLabel>
          </Text>
        </ContactButton>
      </ContactButtonContainer>

      <Spacing.Vertical height={30} />

      <ListHeader title="이용 안내" />
      <List
        title="공지사항"
        action="arrow"
        onAllClick={() => {
          trackUser(ampEvents.CustomerService_Notice);
          navigation('/notices');
        }}
      />
      <List
        title="자주하는 질문"
        action="arrow"
        onAllClick={() => {
          trackUser(ampEvents.CustomerService_Faq);
          navigation('/faq');
        }}
      />
      <List
        title="이용 정책 안내"
        action="arrow"
        onAllClick={() => {
          trackUser(ampEvents.CustomerService_Policy);
          navigation('/terms');
        }}
      />

      <Spacing.Vertical height={30} />

      <Footer>
        <FooterTitle>기타 문의</FooterTitle>
        <Email>sales@bobaprint.com</Email>
      </Footer>
    </PageContainer>
  );
};

export default CustomerService;

const ContactButtonContainer = styled.div`
  padding: 16px 0;
`;

const ContactButton = styled.a`
  padding: 16px 24px;
  width: 100%;
  display: flex;
  align-items: center;
  background: #fafafa;
  border-radius: 16px;
`;

const Text = styled.div``;

const HelpTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: ${props => props.theme.color.gray700};
  line-height: 24px;
`;

const HelpLabel = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: ${props => props.theme.color.gray500};
  margin-top: 4px;
`;

const Footer = styled.div`
  width: 100%;
  max-width: 780px;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 0;
  padding-bottom: 32px;
  text-align: center;
  background: white;
`;

const FooterTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
`;

const Email = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #737373;
`;
