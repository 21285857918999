import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores/RootStore';
import { httpRequestService } from 'services/http-request.service';
import Border from 'components/common/border/Border';
import FullScreenLoader from 'components/common/FullScreenLoader';
import List from 'components/common/list/List';
import ListFooter from 'components/common/list_footer/ListFooter';
import ListHeader from 'components/common/list_header/ListHeader';
import PageContainer from 'components/common/PageContainer';
import TopBar from 'components/common/top_bar/TopBar';
import { Spacing } from 'styles/styles';
import { getDatetimeKoreanString } from 'utils/time.util';
import { JobOrderDto } from 'types/user/orders/JobOrder.dto';

const PrintHistoryDetail: React.FC = props => {
  const { stateStore, userStore } = useStores();
  const { historyUid } = useParams();
  const navigation = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [printOrder, setPrintOrder] = useState<JobOrderDto>();

  useEffect(() => {
    httpRequestService
      .create(true)
      .get<JobOrderDto>(`users/${userStore.userId}/orders/jobs/${historyUid}`)
      .then(res => {
        setPrintOrder(res.data);
      })
      .catch(() => {
        stateStore.setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <PageContainer>
      {isLoading && <FullScreenLoader message="로딩중" />}
      <TopBar title="이용 내역" />
      {!isLoading && printOrder && (
        <>
          <List
            title={printOrder?.kiosk.name}
            description={printOrder?.kiosk.description}
            action="info"
            onActionClick={() => {
              navigation(`/printzone/${printOrder?.kiosk.id}`);
            }}
          />
          <List
            title="요청 일시"
            actionText={getDatetimeKoreanString(printOrder.createdAt)}
          />
          <ListHeader title="파일 목록" />
          {printOrder.Files.map(file => (
            <FileList key={file.id}>{file.name}</FileList>
          ))}

          <Spacing.Vertical height={32} />
          <List title="카드 결제" actionText={`${printOrder.cardPayment}원`} />
          <List
            title="포인트 차감"
            actionText={`${printOrder.pointPayment}원`}
          />
          {/* FIXME: 환불금액 dto에 없음 */}
          {/* <List title="환불 금액" actionText="0P" /> */}
          <Border size={24} />
          <ListFooter
            leftTitle="총 결제 금액"
            rightTitle={`${printOrder.cardPayment + printOrder.pointPayment}원`}
          />
        </>
      )}
    </PageContainer>
  );
};

export default observer(PrintHistoryDetail);

const FileList = styled.div`
  padding: 15 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.gray700};
`;
