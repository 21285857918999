import React from 'react';
import styled, { css } from 'styled-components';

type Props = {
  direction: 'row' | 'column';
  label?: string;
  children: React.ReactNode;
};

const RadioGroup: React.FC<Props> = ({ direction, label, children }) => {
  return (
    <Fieldset direction={direction}>
      <legend>{label}</legend>
      {children}
    </Fieldset>
  );
};
export default RadioGroup;

const Fieldset = styled.fieldset<Props>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: none;
  ${props => {
    if (props.direction === 'column') {
      return css`
        flex-direction: column;
        gap: 10px;
      `;
    }
  }}
`;
