import React, { useEffect, useState } from 'react';
import BottomNavbar from 'components/common/BottomNavbar';
import PageContainer from 'components/common/PageContainer';
import SolidButton from 'components/common/solid_button/SolidButton';
import StorageStatus from 'components/pages/StorageStatus';
import { useNavigate } from 'react-router-dom';
import Border from 'components/common/border/Border';
import { useStores } from 'stores/RootStore';
import TopInfo from 'components/common/top_info/TopInfo';
import List from 'components/common/list/List';
import { Spacing } from 'styles/styles';
import Modal from 'components/common/modal/Modal';
import { observer } from 'mobx-react-lite';
import FullScreenLoader from 'components/common/FullScreenLoader';
import { ampEvents } from 'amplitude-events';
import { track } from '@amplitude/analytics-browser';

const Menu: React.FC = observer(() => {
  const navigation = useNavigate();
  const { userStore } = useStores();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModal = (page: string) => {
    if (!userStore.user) {
      setIsModalOpen(true);
      return;
    }
    navigation(page);
  };

  return (
    <>
      {userStore.isLoading && <FullScreenLoader message="로딩중" />}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
        }}
        description="로그인이 필요합니다"
        mainActionLabel="확인"
        onMainAction={() => {
          setIsModalOpen(false);
        }}
      />
      <PageContainer>
        {!userStore.user && <TopInfo title="로그인이 필요합니다" />}
        {userStore.user && (
          <TopInfo
            title={userStore.user?.name}
            right="button"
            rightLabel="계정 설정"
            kind="skyblue"
            onClick={() => {
              track(ampEvents.Menu_AccountSetting);
              navigation('/accountsettings');
            }}
          />
        )}
        {!userStore.user && (
          <>
            <SolidButton onClick={() => navigation('/boba')}>
              로그인
            </SolidButton>
            <Spacing.Vertical height={16} />
            <Border size={24} />
          </>
        )}
        {userStore.user && (
          <>
            <StorageStatus
              used={userStore.user?.storageUsed ?? 0}
              allocated={userStore.user?.storageAllocated ?? 1}
            ></StorageStatus>
            <Border size={32} />
          </>
        )}

        <List
          title="카드관리"
          action="arrow"
          onAllClick={() => {
            track(ampEvents.Menu_CardManagement);
            handleModal('/cards');
          }}
        />
        <List
          title="포인트 충전"
          action="arrow"
          actionText={
            userStore.user ? `${userStore.user?.points.toString()} P` : ''
          }
          onAllClick={() => {
            track(ampEvents.Menu_PointRecharge);
            handleModal('/point');
          }}
        />
        <List
          title="이용 내역"
          action="arrow"
          onAllClick={() => {
            track(ampEvents.Menu_UsageHistory);
            handleModal('/usagehistory');
          }}
        />
        <List
          title="고객지원"
          action="arrow"
          onAllClick={() => {
            track(ampEvents.Menu_CustomerSupport);
            navigation('/customerservice');
          }}
        />
      </PageContainer>
      <BottomNavbar />
    </>
  );
});

export default Menu;
