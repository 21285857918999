export class NotAuthorizedError extends Error {}; // 로그인 되어있지 않아 해당 서비스를 사용하지 못할 때
export class LoginFailedError extends Error {}; // 로그인시 정보 (이메일 또는 OAuth) 가 잘못되었을 때
export class ParamError extends Error {};
export class NotFoundError extends Error {};

// Auth
export class WrongPhoneAuthNumberError extends Error {};
export class UserNotExistError extends Error {};

/** AuthStore Error */
export class VerifyPhoneError extends Error {};
export class FindEmailError extends Error {};
export class IsExistError extends Error {};