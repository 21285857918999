import isCreditCard from 'validator/es/lib/isCreditCard';
import * as yup from 'yup';

export type CardFormData = {
  cardNumber: string;
  expiry: string;
  birth: string;
  pwd2digit: string;
};

yup.addMethod(yup.string, 'creditCard', function () {
  return this.test({
    name: 'creditCard',
    message: '유효한 카드 번호를 입력해주세요',
    test: (value: string | undefined) =>
      (value?.split(' ').join('') ?? '').length >= 15,
  });
});

export const cardSchema = yup.object().shape({
  cardNumber: yup.string().required('카드번호를 입력해주세요').creditCard(),
  expiry: yup
    .string()
    .required('유효기간을 입력해주세요')
    .matches(/^(0[1-9]|1[0-2])\s\/\s\d{2}$/, '올바른 유효기간을 입력해주세요'),
  birth: yup
    .string()
    .required('생년월일을 입력해주세요')
    .min(6, '올바른 생년월일을 입력해주세요')
    .max(6, '올바른 생년월일을 입력해주세요')
    .matches(/^\d+$/, '올바른 생년월일을 입력해주세요'),
  pwd2digit: yup
    .string()
    .required('비밀번호를 입력해주세요.')
    .min(2, '올바른 비밀번호를 입력해주세요')
    .max(2, '올바른 비밀번호를 입력해주세요')
    .matches(/^\d+$/, '올바른 비밀번호를 입력해주세요'),
});
