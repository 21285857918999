import React from 'react';
import styled from 'styled-components';
import CheckBox from '../check_box/CheckBox';
import { Spacing } from 'styles/styles';

interface Props {
  check?: boolean;
  checked?: boolean;
  onChecked?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  title?: string;
  description?: string;
}

const ListHeader: React.FC<Props> = ({
  check,
  checked,
  onChecked,
  title,
  description,
}) => {
  return (
    <Container>
      <Contents>
        {check ? (
          <>
            <CheckBox checked={checked} onClick={onChecked} />
            <Spacing.Horizontal width={12} />
          </>
        ) : (
          ''
        )}
        <Title>{title}</Title>
      </Contents>
      {check ? (
        <Spacing.Vertical height={8} />
      ) : (
        <Spacing.Vertical height={4} />
      )}
      <Description>{description}</Description>
    </Container>
  );
};
export default ListHeader;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 0;
`;
const Contents = styled.div`
  display: flex;
  align-items: center;
`;
const Title = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.color.gray700};
`;
const Description = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme }) => theme.color.gray400};
`;
