import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { EMAIL_REGEX, PHONE_REGEX, REG_NUMBER } from 'constants/Constants';
import InputBox from '../common/input_box/InputBox';
import SolidButton from '../common/solid_button/SolidButton';
import { useNavigate } from 'react-router-dom';
import Modal from '../common/modal/Modal';
import { useStores } from 'stores/RootStore';
import * as Sentry from '@sentry/react';
import { ApiError } from 'types/error/ApiError';
import { ampEvents } from 'amplitude-events';
import trackUser from 'services/trackUser';
import Icon from 'components/common/icon/Icon';
import { Spacing } from 'styles/styles';
import FixedButtons from 'components/common/solid_button/FixedButtons';

const FindPassword: React.FC = props => {
  const navigation = useNavigate();
  const { authStore } = useStores();

  const [notExistEmailModal, setNotExistEmailModal] = useState(false);

  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [noUserInfoModal, setNoUserInfoModal] = useState(false);

  const [emailInvalid, setEmailInvalid] = useState(false);

  const [code, setCode] = useState('');
  const [sentCode, setSentCode] = useState(false);
  const [reSentCode, setReSentCode] = useState(false);
  const [phoneInvalid, setPhoneInvalid] = useState(false);
  const [codeInvalid, setCodeInvalid] = useState(false);
  const [codeVerify, setCodeVerify] = useState(false);

  const [time, setTime] = useState(180);

  /* 인증번호 전송 요청*/
  const handleSendCode = async () => {
    try {
      const { data } = await authStore.isExist(
        `+82${phoneNumber.length === 11 ? phoneNumber.slice(1) : phoneNumber}`,
        email
      );

      if (!data.exists) {
        setNoUserInfoModal(true);
        return;
      }

      // TODO: trackUser
      trackUser(ampEvents.FindEmailPassword_FindPassword_Success);
      authStore.setUserIdforFindPassword(data.userId);

      setSentCode(true);
      setReSentCode(false);
      setCodeVerify(false);
      setCode('');
      authStore.sendPhoneAuthMessage(
        `+82${phoneNumber.length === 11 ? phoneNumber.slice(1) : phoneNumber}`
      );
    } catch (err: any) {
      Sentry.captureException(new ApiError(err, 'isExistError'));
    }
  };

  const handleCodeVerify = async () => {
    try {
      trackUser(ampEvents.ResetPassword_Code_Verify);
      await authStore.verifyPhone(code, true);
      navigation('/login/reset-password', { replace: true });
    } catch (err: any) {
      if (err?.constructor?.name === 'VerifyPhoneError') {
        Sentry.captureException(new ApiError(err, 'validPhoneError'));
        setCodeVerify(true);
        setSentCode(false);
        setReSentCode(true);
        return;
      }

      // TODO: 에러 이름 다시 써줘야함
      Sentry.captureException(new ApiError(err, 'findEmailError'));
      setNotExistEmailModal(true);
    } finally {
      authStore.clear();
    }
  };

  /**
   * email, phoneNumber 형식 확인
   */
  useEffect(() => {
    setPhoneInvalid(phoneNumber?.match(PHONE_REGEX) === null);
  }, [phoneNumber]);
  useEffect(() => {
    setEmailInvalid(email?.match(EMAIL_REGEX) === null);
  }, [email]);

  useEffect(() => {
    setEmailInvalid(false);
    setPhoneInvalid(false);
  }, []);

  // 인증시간 초과 시
  useEffect(() => {
    if (time < 1) {
      setSentCode(false);
      setReSentCode(true);
    }
  }, [time]);

  // 인증시간 초과 후 time 재설정
  useEffect(() => {
    if (!sentCode) return;
    const countdown = setInterval(() => {
      setTime(t => t - 1);
    }, 1000);
    // eslint-disable-next-line consistent-return
    setTime(180);
    return () => clearInterval(countdown);
  }, [sentCode]);

  return (
    <>
      <InputBox
        type="email"
        label="이메일"
        value={email}
        placeholder="boba@bobaprint.com"
        action={emailInvalid ? 'error' : 'default'}
        helpText={emailInvalid ? '유효하지 않은 이메일 형식입니다' : ''}
        onChange={e => {
          setEmail(e.target.value);
        }}
      />
      <InputBox
        type="text"
        inputMode="numeric"
        pattern="[0-9]*"
        label="휴대폰 번호"
        placeholder="01000000000"
        value={phoneNumber}
        action={phoneInvalid ? 'error' : 'default'}
        helpText={phoneInvalid ? '유효하지 않은 휴대폰 번호입니다' : ''}
        onChange={e => {
          setPhoneNumber(e.target.value.replace(REG_NUMBER, ''));
        }}
      />
      {(sentCode || reSentCode) && (
        <InputConatiner>
          <InputBox
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            autoComplete="one-time-code"
            disabled={reSentCode}
            value={code}
            label="인증번호"
            placeholder="00000"
            action={codeInvalid || reSentCode ? 'error' : 'default'}
            helpText={
              codeVerify
                ? '인증번호가 일치하지 않습니다'
                : reSentCode
                ? '입력시간이 초과되었으니 다시 시도해주세요'
                : '문자로 받은 인증번호 5자리를 입력해주세요'
            }
            onChange={e => {
              setCode(e.target.value);
            }}
            onKeyPress={e => {
              if (e.key === 'Enter' && !codeInvalid && code !== '') {
                handleCodeVerify();
              }
            }}
          />
          <RemainingTime>
            <Icon icon="IconClock" size={16} />
            <Spacing.Horizontal width={4} />
            {`${Math.floor(time / 60).toLocaleString(undefined, {
              minimumIntegerDigits: 2,
            })}:${
              (time % 60).toLocaleString(undefined, {
                minimumIntegerDigits: 2,
              }) || '00'
            }`}
          </RemainingTime>
        </InputConatiner>
      )}
      <FixedButtons>
        {!sentCode ? (
          <SolidButton
            disabled={!phoneNumber || emailInvalid || phoneInvalid}
            onClick={() => {
              trackUser(ampEvents.ResetPassword_Code_Send);
              handleSendCode();
            }}
          >
            {reSentCode ? '인증번호 재전송' : '인증번호 전송'}
          </SolidButton>
        ) : (
          <SolidButton
            disabled={codeInvalid || code === ''}
            onClick={handleCodeVerify}
          >
            인증하기
          </SolidButton>
        )}
      </FixedButtons>

      {/* Modal - failure */}
      <Modal
        isOpen={noUserInfoModal}
        onRequestClose={() => setNoUserInfoModal(false)}
        title="가입정보 없음"
        description="해당 정보로 가입된 정보가 없습니다"
        mainActionLabel="확인"
        onMainAction={() => setNoUserInfoModal(false)}
      />
    </>
  );
};

export default FindPassword;

const InputConatiner = styled.div`
  position: relative;
`;

const RemainingTime = styled.p`
  position: absolute;
  top: 4px;
  right: 0;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #737373;
`;
