import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import Lottie from 'react-lottie-player';
import { useNavigate } from 'react-router-dom';
import { useStores } from 'stores/RootStore';
import PageContainer from 'components/common/PageContainer';
import SolidButton from 'components/common/solid_button/SolidButton';
import FixedButtons from 'components/common/solid_button/FixedButtons';
import FullScreenLoader from 'components/common/FullScreenLoader';
import ErrorPage from 'components/common/ErrorPage';
import { Spacing } from 'styles/styles';
import LottieComplete from 'assets/lottie/LottieComplete.json';
import useStatusOnBackground from 'hooks/useStatusOnBackground';
import { ImgPrinter } from 'assets/images';
import { CopyJobStatus } from 'types/job/copy-job/CopyJobStatus';

/**
 * scan status: seleting-option, scanning
 * copy status: seleting-option, copying
 */
export default observer(function JobInProgress() {
  const navigation = useNavigate();
  const { updateJobStore, jobPollingStore } = useStores();
  const serviceName = jobPollingStore.serviceType === 'scans' ? '스캔' : '복사';
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [cancelJobsComplete, setCancelJobsComplete] = useState<boolean>(false);
  useStatusOnBackground();

  const handleCancelJob = () => {
    setLoading(true);

    const { serviceType, kioskId, jobIds } = jobPollingStore;
    const deleteAllJobs = () =>
      updateJobStore.deleteAllJobs(serviceType!, kioskId!);
    // 현재 scan, copy job은 하나씩만 가능함
    const patchJobStatus = () =>
      updateJobStore.patchJobStatus(
        serviceType!,
        jobIds![0],
        CopyJobStatus.Requested
      );

    (serviceType === 'prints' ? deleteAllJobs() : patchJobStatus())
      .then(() => setCancelJobsComplete(true))
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (
      jobPollingStore.serviceType === 'scans' ||
      jobPollingStore.serviceType === 'copies'
    ) {
      jobPollingStore.startScanCopyJobPollingTimer();
    }
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  if (error) {
    return <ErrorPage />;
  }

  if (cancelJobsComplete) {
    return (
      <PageContainer>
        <Main>
          <Lottie
            loop={false}
            animationData={LottieComplete}
            play
            style={{
              width: 200,
              height: 200,
            }}
          />
          <MainText>
            <CompleteTitle>
              {`${serviceName}`} 작업 취소가 완료되었습니다.
            </CompleteTitle>
          </MainText>
        </Main>

        <FixedButtons>
          <SolidButton
            onClick={() => {
              jobPollingStore.jobIds = null;
              jobPollingStore.serviceType = null;
              navigation('/print', { replace: true });
            }}
          >
            확인
          </SolidButton>
        </FixedButtons>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      {loading && <FullScreenLoader message="작업 취소중" />}
      <Container>
        <TextContainer>
          <Title>{`프린터 화면에서`}</Title>
          <Title>{`${serviceName} 작업을 진행해주세요`}</Title>
          <Spacing.Vertical height={8} />
          <SubTitle>
            {`프린터 화면에서 ${serviceName} 작업을 마무리한 뒤`}
          </SubTitle>
          <SubTitle>{`결제가 진행 됩니다`}</SubTitle>
        </TextContainer>

        <StyledImg src={ImgPrinter} />
        <Fade />
      </Container>

      <FixedButtons>
        <SolidButton
          kind="lightRed"
          area="sm"
          style={{ width: 'max-content' }}
          onClick={handleCancelJob}
        >
          작업 취소
        </SolidButton>
      </FixedButtons>
    </PageContainer>
  );
});

const Container = styled.section`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  gap: 69px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const TextContainer = styled.div`
  padding: 0 24px;
  p {
    text-align: center;
  }
`;
const Title = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.03em;
  color: ${({ theme }) => theme.color.gray900};
  white-space: pre-line;
`;
const SubTitle = styled.p`
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.03em;
  color: ${({ theme }) => theme.color.gray500};
`;
const Fade = styled.div`
  position: fixed;
  left: 0;
  bottom: 80px;
  display: flex;
  justify-content: center;
  z-index: 2;
  width: 100%;
  height: 50%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
`;
const Main = styled.section`
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const MainText = styled.div`
  margin-top: -12px;
  padding: 16px 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const CompleteTitle = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.03em;
  color: ${({ theme }) => theme.color.gray900};
`;
const StyledImg = styled.img`
  width: 300px;
  height: 300px;
`;
